export function verifyGamepadState(e, presses) {
  if (!e.detail) return false;
  const state = normalizeStateToStandardController(e.detail);
  let verified = true;
  presses.forEach(press => {
    if (!pressSatisfied(press, state)) verified = false;
  });
  return verified;
}

function normalizeStateToStandardController(gamepad) {
  return {
    controlStick: {
      xaxis: gamepad.axes[0],
      yaxis: gamepad.axes[1]
    },
    cStick: {
      xaxis: gamepad.axes[2],
      yaxis: gamepad.axes[3]
    },
    buttons: {
      b: gamepad.buttons[0],
      a: gamepad.buttons[1],
      y: gamepad.buttons[2],
      x: gamepad.buttons[3]
    },
    bumpers: {
      left: gamepad.buttons[4],
      right: gamepad.buttons[5]
    },
    triggers: {
      left: gamepad.buttons[6],
      right: gamepad.buttons[7]
    }
  };
}

function pressSatisfied(press, gamepad) {
  const parts = press.type.split("_");
  if (parts[1] === "BUTTON") {
    const buttonKey = parts[0].toLowerCase();
    return gamepad.buttons[buttonKey].pressed;
  }

  if (parts[1] === "TRIGGER") {
    const buttonKey = parts[0] === "L" ? "left" : "right";
    return gamepad.triggers[buttonKey].pressed;
  }

  if (parts[1] === "Z") {
    const buttonKey = parts[0] === "L" ? "left" : "right";
    return gamepad.bumpers[buttonKey].pressed;
  }

  if (parts[0] === "CONTROL") {
    if (press.hold === "END_HOLD") {
      return true;
    }
    const primaryDirection = parts[2];
    const secondaryDirection = parts[3];
    if (primaryDirection === "RIGHT") return gamepad.controlStick.xaxis > 0.5;
    if (primaryDirection === "LEFT") return gamepad.controlStick.xaxis < -0.5;
    if (primaryDirection === "DOWN") {
      if (gamepad.controlStick.yaxis < 0.5) return false;
      if (secondaryDirection === "RIGHT" && gamepad.controlStick.xaxis < 0.5)
        return false;
      if (secondaryDirection === "LEFT" && gamepad.controlStick.xaxis > -0.5)
        return false;
      return true;
    }
    if (primaryDirection === "UP") {
      if (gamepad.controlStick.yaxis > -0.5) return false;
      if (secondaryDirection === "RIGHT" && gamepad.controlStick.xaxis < 0.5)
        return false;
      if (secondaryDirection === "LEFT" && gamepad.controlStick.xaxis > -0.5)
        return false;
      return true;
    }
  }

  if (parts[0] === "C") {
    if (press.hold === "END_HOLD") {
      return true;
    }
    const primaryDirection = parts[2];
    const secondaryDirection = parts[3];
    if (primaryDirection === "RIGHT") return gamepad.cStick.xaxis > 0.5;
    if (primaryDirection === "LEFT") return gamepad.cStick.xaxis < -0.5;
    if (primaryDirection === "DOWN") {
      if (gamepad.cStick.yaxis < 0.5) return false;
      if (secondaryDirection === "RIGHT" && gamepad.cStick.xaxis < 0.5)
        return false;
      if (secondaryDirection === "LEFT" && gamepad.cStick.xaxis > -0.5)
        return false;
      return true;
    }
    if (primaryDirection === "UP") {
      if (gamepad.cStick.yaxis > -0.5) return false;
      if (secondaryDirection === "RIGHT" && gamepad.cStick.xaxis < 0.5)
        return false;
      if (secondaryDirection === "LEFT" && gamepad.cStick.xaxis > -0.5)
        return false;
      return true;
    }
  }
}
