import "./controllerAnimations.css";

import * as buttonControls from "../../PostEditor/CreateComboInterface/ActionEditor/ducks/types";

import {
  AButton,
  AnimatedControllerJoystick,
  BButton,
  Joystick,
  LeftRTrigger,
  LeftZTrigger,
  RightRTrigger,
  RightZTrigger,
  XButton,
  YButton,
} from "../../../2-utils/switchProController";
import React, { useEffect, useRef } from "react";
import { doHitBreakpoint, doSetUpcomingActionIndex } from "../ducks/actions";
import { moveStick, pressButton } from "./animateControllerComponents";

import { connect } from "react-redux";
import { selectProperController } from "./customControllers/helpers";
import styled from "@emotion/styled";
import { verifyGamepadState } from "./tutorModeHelpers";

var metronomeAudio = new Audio(
  "https://github.com/wilson428/metronome/blob/master/tick.wav?raw=true"
);
const ANIMATION_DURATION = 350;

const select = ({ post, resultsPage, userInfo }) => {
  const combo = resultsPage.posts[resultsPage.activePostIndex];
  const preferredController = userInfo.preferredControllers[combo.gameId];
  return {
    frame: post.frame,
    audioEnabled: post.audioEnabled,
    upcomingActionIndex: post.upcomingActionIndex,
    preferredController: preferredController,
    tutorMode: post.tutorMode,
  };
};

const actions = {
  setUpcomingActionIndex: doSetUpcomingActionIndex,
  hitBreakpoint: doHitBreakpoint,
};

function AnimatedController({
  frame,
  actions,
  audioEnabled,
  upcomingActionIndex,
  hitBreakpoint,
  setUpcomingActionIndex,
  preferredController,
  tutorMode,
  showInputsOnly,
}) {
  // this couild rerender too many times
  const videoRef = document.getElementById("post-video-tag");

  // hooks into the control sticks and buttons
  const controlStickRef = useRef();
  const cStickRef = useRef();
  const AButtonRef = useRef();
  const BButtonRef = useRef();
  const XButtonRef = useRef();
  const YButtonRef = useRef();
  const RTriggerRef = useRef();
  const LTriggerRef = useRef();
  const RZRef = useRef();
  const LZRef = useRef();

  const Controller = selectProperController(preferredController);

  useEffect(() => {
    animateActionWhenInFrame();
  }, [frame]);

  return (
    <Container>
      {!showInputsOnly && (
        <ControllerBackdrop src={Controller.backgroundImage} />
      )}

      <Controller.JoyStickWrapper>
        <AnimatedControllerJoystick ref={controlStickRef} />
      </Controller.JoyStickWrapper>

      <Controller.BButtonWrapper>
        <BButton ref={BButtonRef} />
      </Controller.BButtonWrapper>

      <Controller.XButtonWrapper>
        <XButton ref={XButtonRef} />
      </Controller.XButtonWrapper>

      <Controller.YButtonWrapper>
        <YButton ref={YButtonRef} />
      </Controller.YButtonWrapper>

      <Controller.AButtonWrapper>
        <AButton ref={AButtonRef} />
      </Controller.AButtonWrapper>

      <Controller.CStickWrapper>
        <AnimatedControllerJoystick ref={cStickRef} />
      </Controller.CStickWrapper>

      <Controller.LeftTriggerWrapper>
        <LeftRTrigger ref={LTriggerRef}>LR</LeftRTrigger>
      </Controller.LeftTriggerWrapper>

      <Controller.RightTriggerWrapper>
        <RightRTrigger ref={RTriggerRef}>RR</RightRTrigger>
      </Controller.RightTriggerWrapper>

      <Controller.LeftZWrapper>
        <LeftZTrigger ref={LZRef}>LZ</LeftZTrigger>
      </Controller.LeftZWrapper>

      <Controller.RightZWrapper>
        <RightZTrigger ref={RZRef}>RZ</RightZTrigger>
      </Controller.RightZWrapper>
    </Container>
  );

  function animateActionWhenInFrame() {
    const action = actions[upcomingActionIndex];
    if (action) {
      if (frame > action.frame) {
        if (action.breakpoint) handleBreakpoint();
        if (tutorMode) handleTutorMode(action.presses);
        else {
          audioEnabled && metronomeAudio.play();
          animatePresses(action.presses);
          setUpcomingActionIndex(upcomingActionIndex + 1);
        }
      }
    }
  }

  function handleBreakpoint() {
    videoRef.pause();
    hitBreakpoint();
  }

  function handleTutorMode(presses) {
    videoRef.pause();
    const handleUserEngagement = (e) => {
      if (verifyGamepadState(e, presses)) {
        videoRef.play();
        animatePresses(presses);
        setUpcomingActionIndex(upcomingActionIndex + 1);
        window.removeEventListener("gamepadstate", handleUserEngagement);
      }
    };
    window.addEventListener("gamepadstate", handleUserEngagement);
  }

  function animatePresses(presses) {
    presses.forEach((press) => {
      switch (press.type) {
        case buttonControls.CONTROL_STICK_UP:
          return moveStick(controlStickRef, "up", press.hold);
        case buttonControls.CONTROL_STICK_DOWN:
          return moveStick(controlStickRef, "down", press.hold);
        case buttonControls.CONTROL_STICK_RIGHT:
          return moveStick(controlStickRef, "right", press.hold);
        case buttonControls.CONTROL_STICK_LEFT:
          return moveStick(controlStickRef, "left", press.hold);
        case buttonControls.CONTROL_STICK_UP_RIGHT:
          return moveStick(controlStickRef, "up-right", press.hold);
        case buttonControls.CONTROL_STICK_UP_LEFT:
          return moveStick(controlStickRef, "up-left", press.hold);
        case buttonControls.CONTROL_STICK_DOWN_RIGHT:
          return moveStick(controlStickRef, "down-right", press.hold);
        case buttonControls.CONTROL_STICK_DOWN_LEFT:
          return moveStick(controlStickRef, "down-left", press.hold);
        case buttonControls.C_STICK_UP:
          return moveStick(cStickRef, "up", press.hold);
        case buttonControls.C_STICK_DOWN:
          return moveStick(cStickRef, "down", press.hold);
        case buttonControls.C_STICK_RIGHT:
          return moveStick(cStickRef, "right", press.hold);
        case buttonControls.C_STICK_LEFT:
          return moveStick(cStickRef, "left", press.hold);
        case buttonControls.C_STICK_UP_RIGHT:
          return moveStick(cStickRef, "up-right", press.hold);
        case buttonControls.C_STICK_UP_LEFT:
          return moveStick(cStickRef, "up-left", press.hold);
        case buttonControls.C_STICK_DOWN_RIGHT:
          return moveStick(cStickRef, "down-right", press.hold);
        case buttonControls.C_STICK_DOWN_LEFT:
          return moveStick(cStickRef, "down-left", press.hold);

        case buttonControls.A_BUTTON:
          return pressButton(AButtonRef, press.hold);
        case buttonControls.B_BUTTON:
          return pressButton(BButtonRef, press.hold);
        case buttonControls.X_BUTTON:
          return pressButton(XButtonRef, press.hold);
        case buttonControls.Y_BUTTON:
          return pressButton(YButtonRef, press.hold);

        case buttonControls.R_TRIGGER:
          return pressButton(RTriggerRef, press.hold);
        case buttonControls.L_TRIGGER:
          return pressButton(LTriggerRef, press.hold);
        case buttonControls.L_Z:
          return pressButton(LZRef, press.hold);
        case buttonControls.R_Z:
          return pressButton(RZRef, press.hold);
      }
    });
  }
}

export default connect(select, actions)(AnimatedController);

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    transform: scale(0.8);
  }
  @media (min-width: 1000px) {
    zoom: ${(window.innerWidth / 2 - 200) / 350};
  }
`;

const ControllerBackdrop = styled.img`
  height: 200px;
  filter: grayscale(1);
`;
