import {
  ACTIVATED_TUTOR_MODE,
  CHANGED_LIKE_STATUS_FOR_COMBO,
  CHANGED_NEW_COMMENT,
  CLEARED_POST_METADATA,
  DEACTIVATED_TUTOR_MODE,
  FINISHED_FETCHING_COMMENTS_FOR_POST,
  FINISHED_POSTING_COMMENT,
  FINISHED_REMOVING_COMMENT,
  HIT_BREAKPOINT,
  JUMPED_POST_VIDEO_FRAME,
  RESET_POST,
  RESOLVED_BREAKPOINT,
  SET_ANIMATION_SPEED,
  SET_COMBO_FAVORITE_STATUS,
  SET_UPCOMING_ACTION_INDEX,
  STARTED_POSTING_COMMENT,
  TOGGLED_BREAKPOINT,
  TOGGLED_EDITING_POST,
  TOGGLED_POST_AUDIO,
  TOGGLED_POST_VERIFICATION,
  TOGGLED_SHOW_CONTROLLER_OVERLAY,
} from "./types";
import { BACKEND_URL, FRONT_END_URL } from "../../../App";

import Axios from "axios";
import { getStorage } from "firebase/storage";

export function doTogglePostVerification(verified) {
  return async (dispatch, getState) => {
    const state = getState();
    const currentCombo =
      state.resultsPage.posts[state.resultsPage.activePostIndex];
    const url = `${BACKEND_URL}/combo/verify`;
    await Axios.post(url, {
      comboId: currentCombo._id,
      verified,
    });
    dispatch({
      type: TOGGLED_POST_VERIFICATION,
      payload: {
        _id: currentCombo._id,
        verified,
      },
    });
  };
}

export function doRemoveComment(commentId) {
  return (dispatch, getState) => {
    const url = `${BACKEND_URL}/remove-comment`;
    Axios.post(url, { commentId: commentId }).then((r) => {
      dispatch({
        type: FINISHED_REMOVING_COMMENT,
        payload: { commentId },
      });
    });
  };
}

export function doFetchCommentsForCombo(comboId) {
  return (dispatch, getState) => {
    const userId = getState().userInfo._id;
    const url = `${BACKEND_URL}/get-comments/${comboId}`;
    Axios.get(url).then((r) =>
      dispatch({
        type: FINISHED_FETCHING_COMMENTS_FOR_POST,
        payload: {
          comments: r.data,
        },
      })
    );
  };
}

export function doPostComment() {
  return (dispatch, getState) => {
    dispatch({
      type: STARTED_POSTING_COMMENT,
    });
    const { userInfo } = getState();
    const userId = userInfo._id;
    const { activePostIndex, posts } = getState().resultsPage;
    const { newComment } = getState().post;
    const combo = posts[activePostIndex];
    const comboId = combo._id;
    const url = `${BACKEND_URL}/combo/add-comment`;
    Axios.post(url, { comboId, userId, comment: newComment }).then((r) => {
      dispatch({
        type: FINISHED_POSTING_COMMENT,
        payload: {
          comment: {
            comboId,
            userId,
            text: newComment,
            _id: r.data.insertedId,
            authorData: [
              {
                _id: userId,
                photoUrl: userInfo.photoUrl,
              },
            ],
          },
        },
      });
    });
  };
}

export function doChangeNewComment(newComment) {
  return {
    type: CHANGED_NEW_COMMENT,
    payload: {
      newComment,
    },
  };
}

export function doToggleEditingPost() {
  return {
    type: TOGGLED_EDITING_POST,
  };
}

export function doSetUpcomingActionIndex(upcomingActionIndex) {
  return {
    type: SET_UPCOMING_ACTION_INDEX,
    payload: { upcomingActionIndex },
  };
}

export function doJumpPostVideoFrame(newFrame) {
  return (dispatch, getState) => {
    dispatch({
      type: JUMPED_POST_VIDEO_FRAME,
      payload: {
        newFrame,
      },
    });
  };
}

export function doSetAnimationSpeed(animationSpeed) {
  return {
    type: SET_ANIMATION_SPEED,
    payload: {
      animationSpeed,
    },
  };
}

export function doDeleteCombo() {
  return async (dispatch, getState) => {
    const { activePostIndex, posts } = getState().resultsPage;
    const postToDelete = posts[activePostIndex];
    const comboId = postToDelete._id;
    const url = BACKEND_URL + "/delete-combo";
    try {
      await getStorage().refFromURL(postToDelete.videoFile).delete();
    } catch (e) {
      console.log(e);
    }
    try {
      await getStorage().refFromURL(postToDelete.thumbnail).delete();
    } catch (e) {
      console.log(e);
    }
    try {
      getStorage().refFromURL(postToDelete.preview).delete();
    } catch (e) {
      console.log(e);
    }

    Axios.post(url, {
      comboId,
    }).then((r) => {
      window.location = FRONT_END_URL;
    });
  };
}

export function doLikeCombo() {
  return (dispatch, getState) => {
    const { activePostIndex, posts } = getState().resultsPage;
    const post = posts[activePostIndex];
    const comboId = post._id;
    const likeStatus = post.likeStatus;
    const likeFlag = likeStatus ? 0 : 1;
    const userId = getState().userInfo._id;
    const url = BACKEND_URL + "/combo/add-feedback";
    post.likeStatus = likeFlag;
    post.dislikeStatus = false;
    Axios.post(url, { comboId, likeStatus: likeFlag, userId }).then((r) => {
      dispatch({
        type: CHANGED_LIKE_STATUS_FOR_COMBO,
        payload: {
          posts,
        },
      });
    });
  };
}

export function doDislikeCombo() {
  return (dispatch, getState) => {
    const { activePostIndex, posts } = getState().resultsPage;
    const post = posts[activePostIndex];
    const comboId = post._id;
    let dislikeStatus = post.dislikeStatus;
    const likeFlag = dislikeStatus ? 0 : -1;
    const userId = getState().userInfo._id;
    const url = BACKEND_URL + "/combo/add-feedback";
    post.dislikeStatus = likeFlag;
    post.likeStatus = false;
    Axios.post(url, { comboId, likeStatus: likeFlag, userId }).then((r) => {
      dispatch({
        type: CHANGED_LIKE_STATUS_FOR_COMBO,
        payload: {
          posts,
        },
      });
    });
  };
}

export function doTogglePostAudio() {
  return {
    type: TOGGLED_POST_AUDIO,
  };
}

export function doResetPost() {
  return {
    type: RESET_POST,
  };
}

export function doSetComboFavoriteStatus() {
  return (dispatch, getState) => {
    const { userInfo, resultsPage } = getState();
    const { activePostIndex, posts } = resultsPage;
    const post = posts[activePostIndex];

    const comboId = post._id;
    const { favoriteCombos } = userInfo;
    const userId = getState().userInfo._id;
    const shouldFavorite = !favoriteCombos.filter((id) => id === comboId)
      .length;

    const url = shouldFavorite
      ? `${BACKEND_URL}/add-favorite-combo`
      : `${BACKEND_URL}/remove-favorite-combo`;

    const newFavoriteCombos = shouldFavorite
      ? [...favoriteCombos, comboId]
      : favoriteCombos.filter((id) => id !== comboId);

    Axios.post(url, { comboId, userId }).then((r) => {
      dispatch({
        type: SET_COMBO_FAVORITE_STATUS,
        payload: {
          favoriteCombos: newFavoriteCombos,
        },
      });
    });
  };
}

export function doActivateTutorMode() {
  return {
    type: ACTIVATED_TUTOR_MODE,
  };
}

export function doDeactivateTutorMode() {
  return {
    type: DEACTIVATED_TUTOR_MODE,
  };
}

export function doClearPostMetadata(noReset) {
  return (dispatch) => {
    const videoRef = document.getElementById("post-video-tag");
    videoRef.pause();
    videoRef.currentTime = 0;
    dispatch({
      type: CLEARED_POST_METADATA,
      payload: {
        noReset,
      },
    });
  };
}

export function doGeneratePreview() {
  return async (dispatch, getState) => {
    const state = getState();
    const currentCombo =
      state.resultsPage.posts[state.resultsPage.activePostIndex];

    const comboId = currentCombo._id;
    const authorId = currentCombo.authorData[0]._id;
    const characterId = currentCombo.characterData[0]._id;
    const { gameId, videoFile } = currentCombo;
    if (currentCombo.preview)
      await getStorage().refFromURL(currentCombo.preview).delete();
    const url = `${BACKEND_URL}/generate-preview`;

    const response = await Axios.post(url, {
      comboId,
      authorId,
      characterId,
      gameId,
      videoFile,
    });
  };
}

export async function generatePreviewAndDeleteOld(currentCombo) {
  const comboId = currentCombo._id;

  const authorId = currentCombo.authorData[0]._id;
  const characterId = currentCombo.characterData[0]._id;
  const { gameId, videoFile } = currentCombo;

  return;
  if (currentCombo.preview)
    await getStorage().refFromURL(currentCombo.preview).delete();
  const url = `${BACKEND_URL}/generate-preview`;

  const response = await Axios.post(url, {
    comboId,
    authorId,
    characterId,
    gameId,
    videoFile,
  });
}

export function doToggleBreakpoint(frame) {
  return (dispatch, getState) => {
    const state = getState();
    const { activePostIndex, posts } = state.resultsPage;
    const post = posts[activePostIndex];
    post.actions = post.actions.map((action) => {
      if (action.frame === frame)
        return { ...action, breakpoint: !action.breakpoint };
      return action;
    });
    dispatch({
      type: TOGGLED_BREAKPOINT,
      payload: {
        posts,
      },
    });
  };
}

export function doHitBreakpoint() {
  return {
    type: HIT_BREAKPOINT,
  };
}

export function doResolveBreakpoint() {
  return {
    type: RESOLVED_BREAKPOINT,
  };
}

export function doToggleShowControllerOverlay() {
  return {
    type: TOGGLED_SHOW_CONTROLLER_OVERLAY,
  };
}
