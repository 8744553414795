import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
// import { doChangeHoldForPress } from "../1-modules/PostEditor/ducks/actions";

// Button
const SwitchButton = styled.div`
  height: 35px;
  width: 35px;
  background-color: #424242;
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.8);
  /* box-shadow: var(--shadow-1); */
  position: relative;
  transition: 0.1s ease;
  /* box-sizing: border-box; */
  filter: ${({ selected }) => (selected ? "invert(1)" : null)};
  &:hover {
    color: white;
  }
  cursor: pointer;
  &:before {
    display: none;
  }
  &:after {
    content: "default";
    position: absolute;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: currentColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 600px) {
    height: 30px;
    width: 30px;
    font-size: 12px;
  }
  ${({ hold, isComplete }) => getHoldStyling(hold, isComplete)}
`;

function showHoldIcon(holdStatus) {
  if (holdStatus === "START_HOLD") return true;
  if (holdStatus === "END_HOLD") return true;
  return false;
}

function getHoldStyling(holdStatus, isComplete) {
  let styles = "";
  if (holdStatus === "START_HOLD") {
    styles += `
      margin-right: -1.4em !important; 
      border-radius: 50px 0 0 50px;
      width: 55px !important;
    `;
    if (isComplete === false) {
      styles += `
          background-color: lightcoral !important;  
        `;
    }
  }
  if (holdStatus === "MAINTAIN_HOLD") {
    styles += `
      border-radius: 0px;
      margin-left: -1em !important;
      margin-right: -1em !important;
      width: 70px !important;
      &:before {
        content: "" !important;
        font-size: 10px !important;
        opacity: 0.2 !important;
      }
      &:after {
        content: "hold" !important;
        font-size: 10px !important;
        opacity: 0.2 !important;
      }
      `;
    if (isComplete === false) {
      styles += `
          background-color: lightcoral !important;  
        `;
    }
  }

  if (holdStatus === "END_HOLD")
    styles += `
      border-radius: 0 50px 50px 0;
      margin-left: -1em !important; 
      width: 50px !important;
      &:before {
        content: "" !important;
        font-size: 10px !important;
        opacity: 0.2 !important;
      }
      &:after {
        content: "rel" !important;
        font-size: 10px !important;
        opacity: 0.2 !important;
      }
    `;
  return styles;
}

export const BButton = styled(SwitchButton)`
  &:after {
    content: "B";
  }
`;

export const AButton = styled(SwitchButton)`
  &:after {
    content: "A";
  }
`;

export const XButton = styled(SwitchButton)`
  &:after {
    content: "X";
  }
`;

export const YButton = styled(SwitchButton)`
  &:after {
    content: "Y";
  }
`;

export const LTrigger = styled(SwitchButton)`
  &:after {
    content: "LT";
  }
`;

export const RTrigger = styled(SwitchButton)`
  &:after {
    content: "RT";
  }
`;

export const LZ = styled(SwitchButton)`
  &:after {
    content: "LZ";
  }
`;

export const RZ = styled(SwitchButton)`
  &:after {
    content: "RZ";
  }
`;

// Control Stick
export const AnimatedControllerJoystick = styled(SwitchButton)`
  width: 50px !important;
  height: 50px !important;
  &:after {
    content: "";
    width: 35px;
    height: 35px;
    font-size: 20px;
    border-radius: 50px;
    border: 2px solid rgba(255, 255, 255, 0.3);
  }

  &:before {
    display: block;
    content: "";
    color: currentColor;
    z-index: 10;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

export const Joystick = styled(SwitchButton)`
  &:after {
    content: "";
  }

  &:before {
    display: block;
    content: "";
    color: currentColor;
    z-index: 10;
    font-size: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

export const ControlStickLeft = styled(Joystick)`
  &:before {
    content: "\u2190";
  }
`;

export const ControlStickRight = styled(Joystick)`
  &:before {
    content: "\u2192";
  }
`;

export const ControlStickUp = styled(Joystick)`
  &:before {
    content: "\u2191";
  }
`;

export const ControlStickDown = styled(Joystick)`
  &:before {
    content: "\u2193";
  }
`;

export const ControlStickUpLeft = styled(Joystick)`
  &:before {
    content: "\u2190";
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const ControlStickUpRight = styled(Joystick)`
  &:before {
    content: "\u2192";
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

export const ControlStickDownRight = styled(Joystick)`
  &:before {
    content: "\u2192";
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const ControlStickDownLeft = styled(Joystick)`
  &:before {
    content: "\u2190";
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`;

// C Stick
const CStick = styled(ControlStickLeft)`
  &:after {
    content: "c";
    color: rgba(255, 255, 255, 0.8);
    border: none;
    font-size: 30px;
    left: 0;
    top: 0;
    transform: translate(-25%, -50%);

    @media (max-width: 600px) {
      font-size: 14px;
    }
  }
`;

export const CStickLeft = styled(CStick)`
  &:before {
    content: "\u2190";
  }
`;

export const CStickRight = styled(CStick)`
  &:before {
    content: "\u2192";
  }
`;

export const CStickUp = styled(CStick)`
  &:before {
    content: "\u2191";
  }
`;

export const CStickDown = styled(CStick)`
  &:before {
    content: "\u2193";
  }
`;

// Triggers
const Trigger = styled.div`
  background-color: var(--purple);
  padding: 0 1em;
  color: white;
  border-radius: 8px 8px 0 0;
`;
export const LeftRTrigger = styled(Trigger)`
  transform: rotate(-20deg);
`;

export const RightRTrigger = styled(Trigger)`
  transform: rotate(20deg);
`;

export const LeftZTrigger = styled(Trigger)`
  transform: rotate(-20deg);
`;

export const RightZTrigger = styled(Trigger)`
  transform: rotate(20deg);
`;

// With Hold

// const actions = { changeHold: doChangeHoldForPress };

// function ButtonWithHold({ children, state, onClick, changeHold, type }) {
//   const selected = state.state !== null;

//   return (
//     <ButtonWithHoldWrapper>
//       <GenericButton
//         disabled={selected && state.state !== "SELECT_NO_HOLD"}
//         color={getButtonColor()}
//         borderColor={getButtonBorderColor()}
//         onClick={onClick}
//       >
//         {children}
//       </GenericButton>
//       {selected && !(state.state === "MAINTAIN_HOLD" && state.isComplete) && (
//         <HoldButton
//           color={getButtonBorderColor()}
//           onClick={() => changeHold(type)}
//         >
//           {getHoldText()}
//         </HoldButton>
//       )}
//     </ButtonWithHoldWrapper>
//   );

//   function getHoldText() {
//     switch (state.state) {
//       case "START_HOLD":
//         return "rem. hold";
//       case "END_HOLD":
//         return "rem. release";
//       case "MAINTAIN_HOLD":
//         return "release";
//       // case "SELECT_NO_HOLD":
//       //   return "#ccc";
//       case "SELECT_NO_HOLD":
//         return "start hold";
//     }
//   }

//   function getButtonColor() {
//     switch (state.state) {
//       case "START_HOLD":
//       case "END_HOLD":
//       case "MAINTAIN_HOLD":
//       case "SELECT_NO_HOLD":
//         return "#ccc";
//       default:
//         return "#424242";
//     }
//   }

//   function getButtonBorderColor() {
//     switch (state.state) {
//       case "START_HOLD":
//         return "lightgreen";
//       case "END_HOLD":
//         return "lightcoral";
//       case "MAINTAIN_HOLD":
//         return "yellow";
//       case "SELECT_NO_HOLD":
//         return "#ccc";
//       default:
//         return "#424242";
//     }
//   }
// }

// export default connect(
//   null,
//   actions
// )(ButtonWithHold);

const ButtonWithHoldWrapper = styled.div`
  position: relative;
`;

const GenericButton = styled.div`
  height: 50px;
  width: 50px;
  background-color: ${({ color }) => color};
  border-radius: 50px;
  color: rgba(255, 255, 255, 0.8);
  box-shadow: var(--shadow-1);
  border: 6px solid ${({ borderColor }) => borderColor};
  position: relative;
  box-sizing: border-box;
  transition: 0.1s ease;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "all")};
  &:hover {
    color: white;
  }
  @media (max-width: 600px) {
    height: 30px;
    width: 30px;
    font-size: 12px;
  }
`;

const HoldButton = styled.div`
  padding: 0.5em;
  background-color: ${({ color }) => color};
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  &:hover {
    background-color: ${({ color }) => color};
    color: rgba(0, 0, 0, 1);
  }
  border-radius: 50px;
  font-size: 7px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  right: 0;
  box-shadow: var(--shadow-1);
  transform: translate(15%, 25%);
  z-index: 1;
`;
