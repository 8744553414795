import {
  JUMPED_POST_VIDEO_FRAME,
  SET_UPCOMING_ACTION_INDEX,
  FINISHED_FETCHING_COMBO,
  SET_ANIMATION_SPEED,
  SET_ACTIVATED_BY_USER
} from "./types";

export default function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case JUMPED_POST_VIDEO_FRAME:
      return {
        ...state,
        frame: payload.newFrame,
        manualResetKey:
          payload.newFrame < state.frame
            ? state.manualResetKey + 1
            : state.manualResetKey
      };

    case SET_UPCOMING_ACTION_INDEX: {
      return {
        ...state,
        upcomingActionIndex: payload.upcomingActionIndex
      };
    }

    case FINISHED_FETCHING_COMBO:
      return {
        ...state,
        combo: payload.combo
      };

    case SET_ANIMATION_SPEED:
      return {
        ...state,
        animationSpeed: payload.animationSpeed
      };
    case SET_ACTIVATED_BY_USER: {
      return {
        ...state,
        activatedByUser: true
      };
    }
    default:
      return state;
  }
}
