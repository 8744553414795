import React from "react";
import * as switchProController from "../AnimatedController/switchProController";
import * as buttonTypes from "../AnimatedController/buttonTypes";

export function pressToJSX(press) {
  switch (press.type) {
    case buttonTypes.CONTROL_STICK_LEFT:
      return (
        <switchProController.ControlStickLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_RIGHT:
      return (
        <switchProController.ControlStickRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_UP:
      return (
        <switchProController.ControlStickUp
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN:
      return (
        <switchProController.ControlStickDown
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.C_STICK_LEFT:
      return (
        <switchProController.CStickLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_RIGHT:
      return (
        <switchProController.CStickRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_UP:
      return (
        <switchProController.CStickUp
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_DOWN:
      return (
        <switchProController.CStickDown
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.CONTROL_STICK_UP_RIGHT:
      return (
        <switchProController.ControlStickUpRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_UP_LEFT:
      return (
        <switchProController.ControlStickUpLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN_RIGHT:
      return (
        <switchProController.ControlStickDownRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN_LEFT:
      return (
        <switchProController.ControlStickDownLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.A_BUTTON:
      return (
        <switchProController.AButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.B_BUTTON:
      return (
        <switchProController.BButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.X_BUTTON:
      return (
        <switchProController.XButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.Y_BUTTON:
      return (
        <switchProController.YButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.L_TRIGGER:
      return (
        <switchProController.LTrigger
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.R_TRIGGER:
      return (
        <switchProController.RTrigger
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.L_Z:
      return (
        <switchProController.LZ
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.R_Z:
      return (
        <switchProController.RZ
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
  }
}
