import {
  FINISHED_FETCHING_GAMES_AVAILABLE,
  STARTED_FETCHING_GAMES_AVAILABLE
} from "./types";

const initialState = {
  availableGames: [],
  fetchingAvailableGames: false
};

export default function homePageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case STARTED_FETCHING_GAMES_AVAILABLE: {
      return {
        ...state,
        fetchingAvailableGames: true
      };
    }
    case FINISHED_FETCHING_GAMES_AVAILABLE:
      return {
        ...state,
        availableGames: payload.availableGames,
        fetchingAvailableGames: false
      };
    default:
      return state;
  }
}
