import {
  ACTIVATED_CONFIRMATION_MODAL,
  CANCELED_CONFIRMATION_MODAL,
  CONFIRMED_CONFIRMATION_MODAL
} from "./types";

export function doActivateConfirmationModal(callback, message) {
  return {
    type: ACTIVATED_CONFIRMATION_MODAL,
    payload: {
      callback,
      message
    }
  };
}

export function doCancelConfirmationModal() {
  return {
    type: CANCELED_CONFIRMATION_MODAL
  };
}

export function dooConfirmConfirmationModal() {
  return (dispatch, getState) => {
    getState().confirmationModal.callback();
    dispatch({ type: CONFIRMED_CONFIRMATION_MODAL });
  };
}
