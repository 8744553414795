import React from "react";
import Slider from "rc-slider";
import { connect } from "react-redux";
import { doSetPercentRangeForResultsPage } from "../ducks/actions";
import styled from "@emotion/styled";

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);

const select = ({ resultsPage }) => ({
  percentRange: resultsPage.percentRange,
});

const actions = {
  changePercentRange: doSetPercentRangeForResultsPage,
};

function FilterPercentRange({ percentRange, changePercentRange }) {
  return (
    <>
      {/* <h3 style={{ display: "flex" }}>
        Percent{" "}
        <b style={{ color: "var(--green)", marginLeft: "auto" }}>
          {percentRange[0]} - {percentRange[1]}%
        </b>
      </h3> */}
      <Slider
        range
        min={0}
        max={200}
        value={percentRange}
        tipFormatter={tipFormatter}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        onChange={(range) => changePercentRange(range)}
      />
    </>
  );
}

export default connect(select, actions)(FilterPercentRange);

const Container = styled.div`
  margin: 0 10px;
`;

function tipFormatter(value) {
  return value + "%";
}

var trackStyle = [
  {
    backgroundColor: "var(--green)",
  },
];

var handleStyle = [
  {
    borderColor: "var(--green)",
    backgroundColor: "var(--green)",
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
];
