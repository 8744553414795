import React, { useEffect, useRef } from "react";

import { HEIGHT_OF_LANE } from "./AnimatedInputListWithLanes";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import { doToggleBreakpoint } from "../ducks/actions";
import { pressToJSX } from "../../../2-utils/pressToJSX";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const actions = {
  toggleBreakpoint: doToggleBreakpoint
};

function Column({
  action,
  currentFrame,
  nextAction,
  index,
  upcomingActionIndex,
  pressToLaneTable,
  pending,
  onClick,
  toggleBreakpoint,
  highlightBorder = false,
  isPlaystation
}) {
  const isMobile = useIsMobile();
  const containerRef = useRef();
  const { frame, presses } = action;
  const pressesAsJSX = getPressesAsJSX(
    presses,
    pressToLaneTable,
    isPlaystation
  );
  const shouldHighlightRightBorder =
    highlightBorder &&
    currentFrame > action.frame &&
    nextAction &&
    nextAction.frame > currentFrame;

  // useEffect(() => {
  //   if (upcomingActionIndex < index && !isMobile)
  //     containerRef.current.scrollIntoViewIfNeeded({
  //       behavior: "smooth"
  //     });
  // }, [upcomingActionIndex, index]);

  return (
    <>
      <Container
        ref={containerRef}
        pending={pending}
        onClick={onClick}
        highlightRightBorder={shouldHighlightRightBorder}
        breakpoint={action.breakpoint}
      >
        <Frame
          active={currentFrame === action.frame}
          breakpoint={action.breakpoint}
        >
          {frame}
        </Frame>
        {pressesAsJSX}
        {!highlightBorder && (
          <BreakPointWrapper
            active={action.breakpoint}
            onClick={handleBreakpointPress}
          >
            <ToolTip message="toggle breakpoint">
              <BreakPoint active={action.breakpoint} />
            </ToolTip>
          </BreakPointWrapper>
        )}
      </Container>
    </>
  );

  function handleBreakpointPress(e) {
    e.stopPropagation();
    toggleBreakpoint(action.frame);
  }
}

function getPressesAsJSX(presses, pressToLaneTable, isPlaystation) {
  return presses.map(press => (
    <Lane
      key={press.type}
      lane={pressToLaneTable[press.type.slice(0, 7)]}
      className="gamepad-button"
    >
      {pressToJSX(press, isPlaystation)}
    </Lane>
  ));
}

export default connect(null, actions)(Column);

const Container = styled.div`
  --breakpoint-color: #880e4f;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid
    ${({ highlightRightBorder }) =>
      highlightRightBorder ? "var(--green)" : "rgba(255, 255, 255, 0.1)"};
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    ${({ breakpoint }) => breakpoint && "background: var(--breakpoint-color);"}
  }
  ${({ breakpoint }) => breakpoint && "background: var(--breakpoint-color);"}

  /* Control styling of buttons */
  .gamepad-button > div {
    margin-right: 0em;
    @media (min-width: 600px) {
      width: 35px;
      height: 35px;
      font-size: 10px;
    }
    background-color: ${({ pending }) =>
      pending ? "#673ab7" : "var(--green)"};
    color: ${({ pending }) => (pending ? "white" : "black")};
  }
`;

const Frame = styled.div`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  width: 35px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

const Lane = styled.div`
  position: absolute;
  top: calc(25px + ${({ lane }) => lane * HEIGHT_OF_LANE}px);
`;

const BreakPointWrapper = styled.div`
  position: absolute;
  z-index: 4;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 25px);
  padding: 6px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ active }) =>
    active ? "var(--breakpoint-color)" : "transparent"};
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 0 0 50px 50px;
  &:hover div {
    opacity: 1;
  }
`;

const BreakPoint = styled.div`
  border: 2px solid white;
  width: 10px;
  height: 10px;
  background-color: ${({ active }) => (active ? "#f50057" : "grey")};
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  border-radius: 10px;
`;
