import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function GhostPreview({ delay }) {
  return (
    <Container delay={delay}>
      <GhostVideo />
      <GhostDescription>
        <GhostPicture />
        <GhostText>
          <Line />
          <Line />
        </GhostText>
      </GhostDescription>
    </Container>
  );
}

export default connect()(GhostPreview);

const Container = styled.div`
  min-height: 60px;
  margin: 1em 0;
  border-radius: 4px 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 600px) {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  @media (min-width: 600px) {
    min-width: calc(50% - 2em);
    width: calc(50% - 2em);
    margin: 1em;
  }
  @media (min-width: 1200px) {
    min-width: calc(33.3333% - 2em);
    width: calc(33.3333% - 2em);
    margin: 1em;
  }
  animation: ghostFade 2s ease infinite;
  animation-delay: ${({ delay }) => delay}s;
  @keyframes ghostFade {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
`;

const GhostVideo = styled.div`
  width: 100%;
  padding-bottom: 56.75%;
  background: #311b92;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.6)
  );
  opacity: 0.1;
`;

const GhostDescription = styled.div`
  height: 50px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
`;

const GhostPicture = styled.div`
  height: 40px;
  width: 40px;
  background: white;
  opacity: 0.1;
  border-radius: 50px;
  margin-left: 0.5em;
`;

const GhostText = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 0 1em;
`;

const Line = styled.div`
  width: 100%;
  border-radius: 50px;
  height: 10px;
  margin: 6px 0;
  opacity: 0.1;
  background-color: white;
`;
