import React, { useState } from "react";
import styled from "@emotion/styled";
import { MoonLoader } from "react-spinners";

function AuthorBubble({ name, src, hideName }) {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <Container>
      {src ? (
        <AuthorCircle src={src} onLoad={() => setIsLoaded(true)} />
      ) : (
        <DefaultImage>{name.charAt(0)}</DefaultImage>
      )}
      {!hideName && <AuthorName>{name}</AuthorName>}
      {!isLoaded && (
        <Overlay>
          <MoonLoader color="white" size={20} />
        </Overlay>
      )}
    </Container>
  );
}

export default AuthorBubble;

const Container = styled.aside`
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px 0; */
  padding-bottom: 0;
  flex-direction: column;
  background-color: inherit;
  border-radius: 0 0 0 16px;
  position: relative;
  cursor: pointer;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthorCircle = styled.img`
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  border-radius: 40px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  border: 1px solid #eee;
`;

const AuthorName = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  padding: 0 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  &:hover {
    color: var(--green);
  }
`;

const DefaultImage = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background: var(--background-gradient);
  color: white;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;
