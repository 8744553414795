import bayonetta from "../3-misc/icons/bayonetta.png";
import bowser from "../3-misc/icons/bowser.png";
import bowser_jr from "../3-misc/icons/bowser_jr.png";
import captain_falcon from "../3-misc/icons/captain_falcon.png";
import chrom from "../3-misc/icons/chrom.png";
import cloud from "../3-misc/icons/cloud.png";
import corrin from "../3-misc/icons/corrin.png";
import daisy from "../3-misc/icons/daisy.png";
import dark_pit from "../3-misc/icons/dark_pit.png";
import dark_samus from "../3-misc/icons/dark_samus.png";
import diddy_kong from "../3-misc/icons/diddy_kong.png";
import donkey_kong from "../3-misc/icons/donkey_kong.png";
import dr_mario from "../3-misc/icons/dr_mario.png";
import duck_hunt from "../3-misc/icons/duck_hunt.png";
import falco from "../3-misc/icons/falco.png";
import fox from "../3-misc/icons/fox.png";
import ganondorf from "../3-misc/icons/ganondorf.png";
import greninja from "../3-misc/icons/greninja.png";
import ice_climbers from "../3-misc/icons/ice_climbers.png";
import ike from "../3-misc/icons/ike.png";
import incineroar from "../3-misc/icons/gaogaen.png";
import inkling from "../3-misc/icons/inkling.png";
import isabelle from "../3-misc/icons/shizue.png";
import jigglypuff from "../3-misc/icons/jigglypuff.png";
import joker from "../3-misc/icons/joker.png";
import ken from "../3-misc/icons/ken.png";
import king_dedede from "../3-misc/icons/king_dedede.png";
import king_k_rool from "../3-misc/icons/king_k_rool.png";
import kirby from "../3-misc/icons/kirby.png";
import link from "../3-misc/icons/link.png";
import little_mac from "../3-misc/icons/little_mac.png";
import lucario from "../3-misc/icons/lucario.png";
import lucas from "../3-misc/icons/lucas.png";
import lucina from "../3-misc/icons/lucina.png";
import luigi from "../3-misc/icons/luigi.png";
import mario from "../3-misc/icons/mario.png";
import marth from "../3-misc/icons/marth.png";
import mega_man from "../3-misc/icons/mega_man.png";
import meta_knight from "../3-misc/icons/meta_knight.png";
import mewtwo from "../3-misc/icons/mewtwo.png";
import mii_fighter from "../3-misc/icons/mii_fighter.png";
import mr_game_and_watch from "../3-misc/icons/mr_game_and_watch.png";
import ness from "../3-misc/icons/ness.png";
import olimar from "../3-misc/icons/olimar.png";
import pac_man from "../3-misc/icons/pac_man.png";
import palutena from "../3-misc/icons/palutena.png";
import peach from "../3-misc/icons/peach.png";
import pichu from "../3-misc/icons/pichu.png";
import pikachu from "../3-misc/icons/pikachu.png";
import piranha_plant from "../3-misc/icons/packun_flower.png";
import pit from "../3-misc/icons/pit.png";
import pokemon_trainer from "../3-misc/icons/pokemon_trainer.png";
import richter from "../3-misc/icons/richter.png";
import ridley from "../3-misc/icons/ridley.png";
import rob from "../3-misc/icons/rob.png";
import robin from "../3-misc/icons/robin.png";
import rosalina_and_luma from "../3-misc/icons/rosalina_and_luma.png";
import roy from "../3-misc/icons/roy.png";
import ryu from "../3-misc/icons/ryu.png";
import samus from "../3-misc/icons/samus.png";
import sheik from "../3-misc/icons/sheik.png";
import shulk from "../3-misc/icons/shulk.png";
import simon from "../3-misc/icons/simon.png";
import snake from "../3-misc/icons/snake.png";
import sonic from "../3-misc/icons/sonic.png";
import toon_link from "../3-misc/icons/toon_link.png";
import villager from "../3-misc/icons/villager.png";
import wario from "../3-misc/icons/wario.png";
import wii_fit_trainer from "../3-misc/icons/wii_fit_trainer.png";
import wolf from "../3-misc/icons/wolf.png";
import yoshi from "../3-misc/icons/yoshi.png";
import young_link from "../3-misc/icons/young_link.png";
import zelda from "../3-misc/icons/zelda.png";
import zero_suit_samus from "../3-misc/icons/zero_suit_samus.png";

const characters = [
  {
    _id: "5d641b5a95e865586a219a71",
    name: "Bayonetta",
    imageUrl: bayonetta
  },
  {
    _id: "5d5ece915a0b3d4a4e444280",
    name: "Bowser",
    imageUrl: bowser
  },
  {
    _id: "5d641ce495e865586a219a7b",
    name: "Bowser Jr.",
    imageUrl: bowser_jr
  },
  {
    _id: "5d64165195e865586a219a4b",
    name: "Captain Falcon",
    imageUrl: captain_falcon
  },
  {
    _id: "5d64174e95e865586a219a56",
    name: "Chrom",
    imageUrl: chrom
  },
  {
    _id: "5d641c9695e865586a219a78",
    name: "Cloud",
    imageUrl: cloud
  },
  {
    _id: "5d641af595e865586a219a6f",
    name: "Corrin",
    imageUrl: corrin
  },
  {
    _id: "5d64167595e865586a219a4d",
    name: "Daisy",
    imageUrl: daisy
  },
  {
    _id: "5d64184e95e865586a219a5a",
    name: "Dark Pit",
    imageUrl: dark_pit
  },
  {
    _id: "5d64161795e865586a219a48",
    name: "Dark Samus",
    imageUrl: dark_samus
  },
  {
    _id: "5d5b79fac92d5c142f2cb894",
    name: "Diddy Kong",
    imageUrl: diddy_kong
  },
  {
    _id: "5d5b7a0cc92d5c142f2cb895",
    name: "Donkey Kong",
    imageUrl: donkey_kong
  },
  {
    _id: "5d6416d695e865586a219a51",
    name: "Dr. Mario",
    imageUrl: dr_mario
  },
  {
    _id: "5d641cb495e865586a219a79",
    name: "Duck Hunt",
    imageUrl: duck_hunt
  },
  {
    _id: "5d5b796fc92d5c142f2cb890",
    name: "Falco",
    imageUrl: falco
  },
  {
    _id: "5d64153395e865586a219a40",
    name: "Fox",
    imageUrl: fox
  },
  {
    _id: "5d5ed4125a0b3d4a4e444282",
    name: "Ganondorf",
    imageUrl: ganondorf
  },
  {
    _id: "5d641ccc95e865586a219a7a",
    name: "Greninja",
    imageUrl: greninja
  },
  {
    _id: "5d64169d95e865586a219a4e",
    name: "Ice Climbers",
    imageUrl: ice_climbers
  },
  {
    _id: "5d64194d95e865586a219a60",
    name: "Ike",
    imageUrl: ike
  },
  {
    _id: "5d641c1f95e865586a219a74",
    name: "Incineroar",
    imageUrl: incineroar
  },
  {
    _id: "5d5c2f0631901514645174b3",
    name: "Inkling",
    imageUrl: inkling
  },
  {
    _id: "5d6419ef95e865586a219a66",
    name: "Isabelle",
    imageUrl: isabelle
  },
  {
    _id: "5d5b6d642e571d1229fe1f47",
    name: "Jigglypuff",
    imageUrl: jigglypuff
  },
  {
    _id: "5d64159a95e865586a219a44",
    name: "Joker",
    imageUrl: joker
  },
  {
    _id: "5d64192995e865586a219a5f",
    name: "Ken",
    imageUrl: ken
  },
  {
    _id: "5d641a8995e865586a219a6b",
    name: "King Dedede",
    imageUrl: king_dedede
  },
  {
    _id: "5d64196b95e865586a219a61",
    name: "King K Rool",
    imageUrl: king_k_rool
  },
  {
    _id: "5d64163b95e865586a219a4a",
    name: "Kirby",
    imageUrl: kirby
  },
  {
    _id: "5d64160795e865586a219a47",
    name: "Link",
    imageUrl: link
  },
  {
    _id: "5d6419d995e865586a219a65",
    name: "Little Mac",
    imageUrl: little_mac
  },
  {
    _id: "5d641a7295e865586a219a6a",
    name: "Lucario",
    imageUrl: lucario
  },
  {
    _id: "5d641abd95e865586a219a6d",
    name: "Lucas",
    imageUrl: lucas
  },
  {
    _id: "5d6416ec95e865586a219a52",
    name: "Lucina",
    imageUrl: lucina
  },
  {
    _id: "5d5b7960c92d5c142f2cb88f",
    name: "Luigi",
    imageUrl: luigi
  },
  {
    _id: "5d5b7981c92d5c142f2cb891",
    name: "Mario",
    imageUrl: mario
  },
  {
    _id: "5d64186c95e865586a219a5b",
    name: "Marth",
    imageUrl: marth
  },
  {
    _id: "5d641b4895e865586a219a70",
    name: "Mega Man",
    imageUrl: mega_man
  },
  {
    _id: "5d64176e95e865586a219a58",
    name: "Meta Knight",
    imageUrl: meta_knight
  },
  {
    _id: "5d64171695e865586a219a54",
    name: "Mewtwo",
    imageUrl: mewtwo
  },
  {
    _id: "5d64175d95e865586a219a57",
    name: "Mr. Game & Watch",
    imageUrl: mr_game_and_watch
  },
  {
    _id: "5d5b7e62c92d5c142f2cb896",
    name: "Ness",
    imageUrl: ness
  },
  {
    _id: "5d641c4795e865586a219a75",
    name: "Olimar",
    imageUrl: olimar
  },
  {
    _id: "5d641c5f95e865586a219a76",
    name: "Pac-Man",
    imageUrl: pac_man
  },
  {
    _id: "5d5b79d3c92d5c142f2cb892",
    name: "Palutena",
    imageUrl: palutena
  },
  {
    _id: "5d64166795e865586a219a4c",
    name: "Peach",
    imageUrl: peach
  },
  {
    _id: "5d64156e95e865586a219a42",
    name: "Pichu",
    imageUrl: pichu
  },
  {
    _id: "5d5b75bbc92d5c142f2cb88d",
    name: "Pikachu",
    imageUrl: pikachu
  },
  {
    _id: "5d6415dc95e865586a219a46",
    name: "Piranha Plant",
    imageUrl: piranha_plant
  },
  {
    _id: "5d64177d95e865586a219a59",
    name: "Pit",
    imageUrl: pit
  },
  {
    _id: "5d641a5795e865586a219a69",
    name: "Pokemon Trainer",
    imageUrl: pokemon_trainer
  },
  {
    _id: "5d641aa195e865586a219a6c",
    name: "R.O.B.",
    imageUrl: rob
  },
  {
    _id: "5d64157f95e865586a219a43",
    name: "Richter",
    imageUrl: richter
  },
  {
    _id: "5d641a3a95e865586a219a68",
    name: "Ridley",
    imageUrl: ridley
  },
  {
    _id: "5d641c7e95e865586a219a77",
    name: "Robin",
    imageUrl: robin
  },
  {
    _id: "5d641b9495e865586a219a73",
    name: "Rosalina & Luma",
    imageUrl: rosalina_and_luma
  },
  {
    _id: "5d64172c95e865586a219a55",
    name: "Roy",
    imageUrl: roy
  },
  {
    _id: "5d64191195e865586a219a5e",
    name: "Ryu",
    imageUrl: ryu
  },
  {
    _id: "5d64155395e865586a219a41",
    name: "Samus",
    imageUrl: samus
  },
  {
    _id: "5d6416af95e865586a219a4f",
    name: "Sheik",
    imageUrl: sheik
  },
  {
    _id: "5d641a0495e865586a219a67",
    name: "Shulk",
    imageUrl: shulk
  },
  {
    _id: "5d64199795e865586a219a63",
    name: "Simon",
    imageUrl: simon
  },
  {
    _id: "5d641ae295e865586a219a6e",
    name: "Snake",
    imageUrl: snake
  },
  {
    _id: "5d64198395e865586a219a62",
    name: "Sonic",
    imageUrl: sonic
  },
  {
    _id: "5d641b7a95e865586a219a72",
    name: "Toon Link",
    imageUrl: toon_link
  },
  {
    _id: "5d6418d595e865586a219a5c",
    name: "Villager",
    imageUrl: villager
  },
  {
    _id: "5d5b79e8c92d5c142f2cb893",
    name: "Wario",
    imageUrl: wario
  },
  {
    _id: "5d6418f095e865586a219a5d",
    name: "Wii Fit Trainer",
    imageUrl: wii_fit_trainer
  },
  {
    _id: "5d6415c995e865586a219a45",
    name: "Wolf",
    imageUrl: wolf
  },
  {
    _id: "5d64162795e865586a219a49",
    name: "Yoshi",
    imageUrl: yoshi
  },
  {
    _id: "5d64170295e865586a219a53",
    name: "Young Link",
    imageUrl: young_link
  },
  {
    _id: "5d6416c095e865586a219a50",
    name: "Zelda",
    imageUrl: zelda
  },
  {
    _id: "5d6419b895e865586a219a64",
    name: "Zero Suit Samus",
    imageUrl: zero_suit_samus
  }
];

export default function characterIdToIcon(characterId) {
  return characters.find(char => char._id === characterId).imageUrl;
}
