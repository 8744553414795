import {
  ACTIVATED_CONFIRMATION_MODAL,
  CANCELED_CONFIRMATION_MODAL,
  CONFIRMED_CONFIRMATION_MODAL
} from "./types";

const initialState = {
  callback: null,
  message: ""
};

export default function confirmationModalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVATED_CONFIRMATION_MODAL: {
      return {
        ...state,
        callback: payload.callback,
        message: payload.message
      };
    }

    case CONFIRMED_CONFIRMATION_MODAL:
    case CANCELED_CONFIRMATION_MODAL: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
