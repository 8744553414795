import { doJumpPostVideoFrame, doResolveBreakpoint } from "../ducks/actions";

import Column from "./Column";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

export const HEIGHT_OF_LANE = 40;

const select = ({ post, postEditor }) => {
  return {
    frame: post.frame,
    postEditorFrame: postEditor.frame,
    upcomingActionIndex: post.upcomingActionIndex
  };
};

const actions = {
  jumpToFrame: doJumpPostVideoFrame,
  resolveBreakpoint: doResolveBreakpoint
};

function AnimatedInputListWithLanes({
  actions,
  frame,
  jumpToFrame,
  postEditorFrame,
  onActionClick,
  resolveBreakpoint,
  highlightWhenPressed = true,
  highlightBorder = false,
  isPostEditor,
  isPlaystation,
  upcomingActionIndex,
  ...props
}) {
  const pressToLaneTable = generatePresstoLaneTable(actions);
  const numLanes = Object.keys(pressToLaneTable).length;

  return (
    <Container numLanes={numLanes} style={props.style}>
      {actions.map((action, actionIndex, actions) => (
        <Column
          onClick={() => {
            resolveBreakpoint();
            jumpToFrame(action.frame);
            onActionClick(action.frame, actionIndex);
          }}
          index={actionIndex}
          upcomingActionIndex={upcomingActionIndex}
          key={action.frame}
          action={action}
          currentFrame={isPostEditor ? postEditorFrame : frame}
          pressToLaneTable={pressToLaneTable}
          pending={getIsPending(action)}
          highlightBorder={highlightBorder}
          nextAction={actions[actionIndex + 1]}
          isPlaystation={isPlaystation}
        />
      ))}
      {!highlightBorder && (
        <>
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
          <DummyColumn />
        </>
      )}
    </Container>
  );

  function getIsPending(action) {
    if (!highlightWhenPressed) return true;
    return action.frame > frame;
  }
}

export default connect(select, actions)(AnimatedInputListWithLanes);

function generatePresstoLaneTable(actions) {
  const defaultTable = {};
  const tableOfKeyToTimesUsed = actions.reduce(_reducer, defaultTable);
  const pressTypesInOrderOfMostUsed = Object.entries(tableOfKeyToTimesUsed)
    .sort((l, r) => r[1] - l[1])
    .map(item => item[0]);

  const table = {};
  pressTypesInOrderOfMostUsed.forEach((press, i) => (table[press] = i));
  return table;

  function _reducer(table, currentAction) {
    const FIRST_N_CHARS = 7;
    currentAction.presses.forEach(press => {
      const key = press.type.slice(0, FIRST_N_CHARS);
      if (table[key] === undefined) table[key] = 0;
      table[key] += 1;
    });
    return table;
  }
}

const Container = styled.div`
  height: ${({ numLanes }) => numLanes * HEIGHT_OF_LANE + 25}px;
  display: flex;
  overflow-x: scroll;
  /* box-sizing: border-box;  */
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0em 1em;
  width: calc(100% - 2em);
  padding-bottom: 2em;
`;

const DummyColumn = styled.div`
  min-width: 35px;
  --breakpoint-color: #880e4f;
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid
    ${({ highlightRightBorder }) =>
      highlightRightBorder ? "var(--green)" : "rgba(255, 255, 255, 0.05)"};
`;
