import { buildAttack, buildAerial, buildGrab, buildProjectile } from "../utils";

const smashes = {
  up: buildAttack({
    startup: [14],
    totalFrames: [49],
    shieldStun: [13],
  }),
  down: buildAttack({
    startup: [11],
    totalFrames: [55],
    shieldStun: [11]
  }),
  forward: buildAttack({
    startup: [22],
    totalFrames: [54],
    shieldStun: [14]
  })
};

const tilts = {
  up: buildAttack({
    startup: [5],
    totalFrames: [38],
    shieldStun: [10]
  }),
  down: buildAttack({
    startup: [6],
    totalFrames: [24],
    shieldStun: [6]
  }),
  forward: buildAttack({
    startup: [7],
    totalFrames: [34],
    shieldStun: [8,9]
  })
};

const aerials = {
  up: buildAerial({
    startup: [6],
    totalFrames: [37],
    shieldStun: [5],
    landingLag: 15,
    autoCancelWindow: [[1,5], [26, 999]]
  }),
  down: buildAerial({
    startup: [14],
    totalFrames: [54],
    shieldStun: [6],
    landingLag: 14,
    autoCancelWindow: [[1,2], [50, 999]]
  }),
  forward: buildAerial({
    startup: [18],
    totalFrames: [55],
    shieldStun: [6,5],
    landingLag: 17,
    autoCancelWindow: [[56, 999]]
  }),
  back: buildAerial({
    startup: [7],
    totalFrames: [31],
    shieldStun: [5,4],
    landingLag: 11,
    autoCancelWindow: [[1,6], [31, 999]]
  }),
  neutral: buildAerial({
    startup: [10],
    totalFrames: [38],
    shieldStun: [4,4],
    landingLag: 10,
    autoCancelWindow: [[1,9], [27, 999]]
  }),
};

const specials = {
  up: buildAttack({
    startup: [19,25,32,40,49,55,62],
    totalFrames: [104],
    shieldStun: [6,3,5]
  }),
  down: buildAttack({
    startup: [12,23],
    totalFrames: [46],
    shieldStun: [8]
  }),
  forward: buildAttack({
    startup: [20],
    totalFrames: [62],
    shieldStun: [10]
  }),
  neutral: buildAttack({
    startup: [19],
    totalFrames: [47],
    shieldStun: [24]
  }),
};

const dashAttack = buildAttack({
  startup: [9],
  totalFrames: [34],
  shieldStun: [11,9]
});

const jab = buildAttack({
  startup: [5],
  totalFrames: [8],
  shieldStun: [5]
});

const grab = buildGrab({ startup: [8], totalFrames: [38] });

export default {
  name: "Donkey Kong",
  smashes,
  tilts,
  specials,
  aerials,
  dashAttack,
  jab,
  grab
};
