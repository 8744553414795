export function buildAttack({ startup, totalFrames, shieldStun, other }) {
  return {
    type: "ATTACK",
    startup,
    totalFrames,
    shieldStun,
    ...other
  };
}

export function buildGrab({ startup, totalFrames, other }) {
  return {
    type: "GRAB",
    startup,
    totalFrames,
    ...other
  };
}

export function buildAerial({
  startup,
  totalFrames,
  shieldStun,
  landingLag,
  autoCancelWindow,
  other
}) {
  return {
    type: "AERIAL",
    startup,
    totalFrames,
    shieldStun,
    landingLag,
    autoCancelWindow,
    ...other
  };
}

export function buildProjectile({
  startup,
  totalFrames,
  shieldStun,
  shieldLag,
  other
}) {
  return {
    type: "PROJECTILE",
    startup,
    totalFrames,
    shieldStun,
    shieldLag,
    ...other
  };
}
