import React, { useState } from "react";
import {
  doActivateDraftManager,
  doDeactivateDraftManager
} from "./ducks/actions";

import Collapsible from "../../2-utils/Collapsible";
import Draft from "./Draft";
import { Menu } from "styled-icons/material";
import NewGuideButton from "./NewGuideButton";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ guidePage }) => ({
  showDraftManager: guidePage.showDraftManager,
  drafts: guidePage.drafts,
  publications: guidePage.publications,
  activeGuideId: guidePage.activeGuideId
});

const actions = {
  deactivateDraftManager: doDeactivateDraftManager,
  activateDraftManager: doActivateDraftManager
};

function DraftManager({
  deactivateDraftManager,
  activateDraftManager,
  showDraftManager,
  drafts,
  publications,
  activeGuideId
}) {
  const navButtonCallback = showDraftManager
    ? deactivateDraftManager
    : activateDraftManager;

  return (
    <Container>
      <SideNavButton onClick={navButtonCallback}>
        <MenuIcon />
      </SideNavButton>

      <div>
        <Collapsible
          noMargin
          header={<Title>Published</Title>}
          content={publications.map(data => (
            <Draft
              key={data._id}
              {...data}
              title={data.sections[0] && data.sections[0].value}
              activeGuideId={activeGuideId}
            />
          ))}
        />
        {!publications.length && <Message>No published guides</Message>}
      </div>

      <div>
        <Collapsible
          noMargin
          header={
            <Title>
              Drafts <NewGuideButton />
            </Title>
          }
          content={drafts.map(data => (
            <Draft
              key={data._id}
              {...data}
              title={data.sections[0] && data.sections[0].value}
              activeGuideId={activeGuideId}
            />
          ))}
        />
        {!drafts.length && <Message>No drafts yet</Message>}
      </div>
    </Container>
  );
}

export default connect(select, actions)(DraftManager);

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const Title = styled.h3`
  /* padding-left: 1em; */
  margin-top: 1em;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
`;

const SideNavButton = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--purple);
  border: 1px solid transparent;
  border-left: none;
  border-radius: 0 8px 8px 0;
  transform: translateX(100%);
  cursor: pointer;
`;

const MenuIcon = styled(Menu)`
  width: 25px;
`;

const Message = styled.div`
  margin-left: 18px;
  opacity: 0.5;
`;
