import * as firebase from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDC79bhm-oZjVji5X9tE0iH2wq5m19tzzw",
  authDomain: "the-combo-guide.firebaseapp.com",
  databaseURL: "https://the-combo-guide.firebaseio.com",
  projectId: "the-combo-guide",
  storageBucket: "the-combo-guide.appspot.com",
  messagingSenderId: "334781358748",
  appId: "1:334781358748:web:0dcc9d15cc3d9a3f",
};

firebase.initializeApp(firebaseConfig);
