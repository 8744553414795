import React, { useEffect } from "react";

import Collapsible from "../../../2-utils/Collapsible";
import DarkSelect from "../../../2-utils/DarkSelect";
import { FlexRowWithWrap } from "../../../2-utils/Flex";
import GamePreviewSquare from "../../HomePage/GameSelector/GamePreviewSquare";
import { connect } from "react-redux";
import { doFetchAvailableGames } from "../../HomePage/ducks/actions";
import { doSelectGameForNewCombo } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor, homePage }) => ({
  selectedGameId: postEditor.gameId,
  availableGames: homePage.availableGames
});

const actions = {
  selectGame: doSelectGameForNewCombo,
  fetchAvailableGames: doFetchAvailableGames
};

function SelectGameForPostEditor({
  selectedGameId,
  availableGames,
  selectGame,
  fetchAvailableGames
}) {
  useEffect(() => {
    fetchAvailableGames();
  }, []);

  const selectedGame = availableGames.find(game => game._id === selectedGameId);
  const selectedGameName = selectedGame && selectedGame.name;
  return (
    <div>
      <h3>* Select Game</h3>
      <DarkSelect
        placeholder="Select game..."
        options={convertGamesToSelectFormat(availableGames)}
        value={concertIndividualGameToSelectFormat(selectedGame)}
        onChange={data => {
          selectGame(data && data.value);
        }}
      />
    </div>
  );
}
function convertGamesToSelectFormat(games) {
  return games.map(game => concertIndividualGameToSelectFormat(game));
}

function concertIndividualGameToSelectFormat(game) {
  if (!game) return null;
  return { value: game, label: game.name };
}

export default connect(select, actions)(SelectGameForPostEditor);
