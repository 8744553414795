import { SwitchButton } from "./switchProController";
import styled from "@emotion/styled";

export const SquareButton = styled(SwitchButton)`
  &:after {
    content: "";
    height: 13px;
    width: 13px;
    border: 2px solid white;
    border-radius: 50px;
  }
`;

export const CircleButton = styled(SwitchButton)`
  &:after {
    content: "";
    height: 13px;
    width: 13px;
    border: 2px solid white;
    border-radius: 50px;
  }
`;

export const TriangleButton = styled(SwitchButton)`
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid white;
  }
`;

export const XButton = styled(SwitchButton)`
  &:after {
    content: "X";
  }
`;
