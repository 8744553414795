export const SELECTED_GAME_FOR_NEW_GUIDE = "SELECTED_GAME_FOR_NEW_GUIDE";
export const SELECTED_CHARACTER_FOR_NEW_GUIDE = "SELECTED_CHARACTER_FOR_NEW_GUIDE";
export const RESET_NEW_GUIDE = "RESET_NEW_GUIDE"
export const ADDED_COMBO_TO_NEW_GUIDE = "ADDED_COMBO_TO_NEW_GUIDE"
export const REMOVED_COMBO_FROM_NEW_GUIDE = "REMOVED_COMBO_FROM_NEW_GUIDE"
export const EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE = "EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE"
export const EDITED_SECTION_HEADER_FOR_NEW_GUIDE = "EDITED_SECTION_HEADER_FOR_NEW_GUIDE"
export const EDITED_TITLE_FOR_NEW_GUIDE = "EDITED_TITLE_FOR_NEW_GUIDE"
export const POSTED_NEW_GUIDE = "POSTED_NEW_GUIDE"
export const FINISHED_FETCHING_USER_COMBOS = "FINISHED_FETCHING_USER_COMBOS"
export const FINISHED_FETCHING_USER_FOR_PROFILE_PAGE = 'FINISHED_FETCHING_USER_FOR_PROFILE_PAGE'