import { Button, GreenButton, NeutralButton } from "../../2-utils/buttons";
import React, { useEffect } from "react";
import {
  doChangeNewDisplayName,
  doResetSettingsPage,
  doToggleFullScreenMode
} from "./ducks/actions";

import Divider from "../../2-utils/Divider";
import GradientText from "../../2-utils/editables/GradientText";
import { Link } from "react-router-dom";
import { PacmanAnimation } from "../../2-utils/loadingAnimations";
import { Person } from "styled-icons/octicons";
import ProfilePictureUploadInterface from "./ProfilePictureUploadInterface";
import TextInput from "../../2-utils/editables/TextInput";
import { connect } from "react-redux";
import { doLogoutUser } from "../LoginPage/ducks/actions";
import { doUpdateUser } from "../UserInfo/ducks/actions";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

const select = ({ userInfo, settingsPage }) => {
  const displayName =
    settingsPage.newDisplayName !== null
      ? settingsPage.newDisplayName
      : userInfo.displayName;
  return {
    email: userInfo.email,
    emailVerified: userInfo.emailVerified,
    displayName,
    updating: userInfo.updating,
    changed: settingsPage.newDisplayName || settingsPage.newProfilePicture
  };
};

const actions = {
  changeDisplayName: doChangeNewDisplayName,
  resetSettingsPage: doResetSettingsPage,
  updateUser: doUpdateUser,
  logoutUser: doLogoutUser
};

function SettingsPage({
  changed,
  email,
  emailVerified,
  displayName,
  changeDisplayName,
  resetSettingsPage,
  logoutUser,
  updateUser,
  updating
}) {
  useEffect(() => {
    recordPageView();
  }, []);

  useEffect(() => {
    return resetSettingsPage;
  }, []);

  return (
    <Container>
      <Header color="white">Settings</Header>
      <ProfilePictureUploadInterface />

      <TextInput
        value={displayName}
        type="text"
        placeholder="display name"
        IconJSX={Person}
        onChange={e => changeDisplayName(e.target.value)}
      />
      <GreenButton onClick={updateUser} disable={!changed}>
        save
      </GreenButton>
      <Divider style={{ margin: "2em 0" }} />
      <Link to="/custom-mappings">
        <Button>configure custom controllers</Button>
      </Link>
      <NeutralButton onClick={logoutUser}>logout</NeutralButton>
      {/* <Row>todo delete account</Row> */}

      {updating && (
        <Overlay>
          <PacmanAnimation />
        </Overlay>
      )}
      {/* <h3>email: {email}</h3>
      <h3>email verified: {emailVerified ? "yes" : "no"}</h3> */}
    </Container>
  );
}

export default connect(select, actions)(SettingsPage);

const Container = styled.div`
  background-color: var(--black-purple);
  padding: 0.5em;
  box-sizing: border-box;
  color: white;
  position: relative;
  min-height: 100vh;
  h3 {
    opacity: 0.5;
  }
  @media (min-width: 800px) {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.h2`
  /* font-size: 30px; */
  margin: 0.6em 0 1em 0;
  font-weight: bold;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
`;
