import {
  FINISHED_FETCHING_GUIDE_FOR_STANDALONE_PAGE,
  STARTED_FETCHING_GUIDE_FOR_STANDALONE_PAGE,
  RESET_SINGLE_PAGE_GUIDE
} from "./types";

const initialState = {
  guide: {
    metaData: {},
    sections: [],
    _id: null,
    authorId: null
  }
};

export default function singleGuidePageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case RESET_SINGLE_PAGE_GUIDE: {
      return initialState;
    }
    case STARTED_FETCHING_GUIDE_FOR_STANDALONE_PAGE: {
      return {
        ...initialState
      };
    }
    case FINISHED_FETCHING_GUIDE_FOR_STANDALONE_PAGE: {
      return {
        ...state,
        guide: payload.guide
      };
    }
    default:
      return state;
  }
}
