import PublishConfig from "./PublishConfig";
import PublishPreview from "./PublishPreview";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function PublishInterface() {
  return (
    <Container>
      <PreviewWrapper>
        <PublishPreview />
      </PreviewWrapper>

      <ConfigWrapper>
        <PublishConfig />
      </ConfigWrapper>
    </Container>
  );
}

export default connect()(PublishInterface);

const Container = styled.div`
  background-color: var(--black-purple);
  border: 2px solid var(--purple);
  min-height: 500px;
  width: 1000px;
  display: grid;
  grid-template-areas: "preview config";
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 3fr;
`;

const PreviewWrapper = styled.div`
  grid-area: preview;
`;

const ConfigWrapper = styled.div`
  grid-area: config;
`;
