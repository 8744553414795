import React, { useEffect } from "react";
import convertActionsForController, {
  convertActionsForCustomControls,
} from "./SelectController/actionsConverters";
import {
  doFetchCommentsForCombo,
  doGeneratePreview,
  doResetPost,
  doSetUpcomingActionIndex,
  doToggleEditingPost,
  doToggleShowControllerOverlay,
} from "./ducks/actions";
import {
  doGoToNextCombo,
  doGoToPreviousCombo,
} from "../ResultsPage/ducks/actions";
import { doResetPostEditor, reprocessVideo } from "../PostEditor/ducks/actions";

import AdditionalComboInfo from "./AdditionalComboInfo";
import AnimatedController from "./AnimatedController";
import AnimatedInputListWithLanes from "./AnimatedInputList/AnimatedInputListWithLanes";
import { Card } from "../../2-utils/Card";
import CommentsManager from "./CommentsManager";
import ConnectedController from "./ConnectedController";
import Description from "./Description";
import Options from "./Options";
import { useNavigate } from "react-router-dom";
import SelectController from "./SelectController";
import Video from "./Video";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../2-utils/snippets";
import Toggle from "../../2-utils/Toggle";

const select = ({ customMappingPage, resultsPage, post, userInfo }) => {
  const { activePostIndex, posts } = resultsPage;
  const currentPost = posts[activePostIndex];
  // if (!userInfo.preferredControllers) userInfo.preferredControllers = {};
  currentPost.actions = currentPost.actions || [];
  return {
    videoUrl: currentPost.videoFile,
    actions: currentPost.actions || [],
    frame: post.frame,
    combo: currentPost,
    canGoNext: activePostIndex !== posts.length - 1 && !post.isEditing,
    canGoBack: activePostIndex > 0 && !post.isEditing,
    audioEnabled: post.audioEnabled,
    postName: currentPost.name,
    postAuthor: currentPost.authorData[0],
    // canEdit: userInfo._id === currentPost.authorData[0]._id,
    canLike: userInfo.authenticated,
    selectedControllerType: userInfo.preferredControllers[currentPost.gameId],
    showEditInterface: post.isEditing,
    tutorMode: post.tutorMode,
    manualResetKey: post.manualResetKey,
    showControllerOverlay: post.showControllerOverlay,
    customMapping:
      (userInfo.customMappings[currentPost.gameId] || {})[
        userInfo.preferredControllers[currentPost.gameId]
      ] || {},
  };
};

const actions = {
  generatePreview: doGeneratePreview,
  goToNextCombo: doGoToNextCombo,
  goToPreviousCombo: doGoToPreviousCombo,
  resetPost: doResetPost,
  resetPostEditor: doResetPostEditor,
  setUpcomingActionIndex: doSetUpcomingActionIndex,
  toggleEditingPost: doToggleEditingPost,
  fetchComments: doFetchCommentsForCombo,
  toggleShowControllerOverlay: doToggleShowControllerOverlay,
};

function Post({
  showEditInterface,
  customMapping,
  canLike,
  videoUrl,
  actions,
  goToNextCombo,
  canGoNext,
  canGoBack,
  goToPreviousCombo,
  resetPost,
  combo,
  postAuthor,
  selectedControllerType,
  setUpcomingActionIndex,
  standalone,
  fetchComments,
  tutorMode,
  manualResetKey,
  showControllerOverlay,
  toggleShowControllerOverlay,
}) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const actionsModifiedForController = convertActionsForCustomControls(
    convertActionsForController(actions, combo.gameId, selectedControllerType),
    customMapping
  );

  useEffect(() => {
    fetchComments(combo._id);
  }, [combo._id]);

  useEffect(bindLeftRightArrowKeysToComboNavigation, [canGoNext, canGoBack]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return cleanupDataInRedux;
  }, []);

  if (showEditInterface) {
    navigate("/test");
    return null;
  }

  return (
    <Container standalone={standalone}>
      <VideoWrapper>
        <Video videoUrl={videoUrl} key={videoUrl} thumbnail={combo.thumbnail} />
        {showControllerOverlay && (
          <OverlaidControllerWrapper>
            <AnimatedController
              key={manualResetKey}
              actions={actionsModifiedForController}
              showInputsOnly
            />
          </OverlaidControllerWrapper>
        )}
      </VideoWrapper>

      <NonVideoContent>
        {!isMobile && (
          <DescriptionWrapper>
            <Card>
              <Description />
            </Card>
          </DescriptionWrapper>
        )}

        <OptionsWrapper>
          {canLike && (
            <Card>
              <Options />
            </Card>
          )}
        </OptionsWrapper>

        <ControllerWrapper>
          <Card>
            <AnimatedController
              key={manualResetKey}
              actions={actionsModifiedForController}
            />
            <SelectController />
            <ShowControllerOverlayWrapper>
              <Toggle
                isOn={showControllerOverlay}
                handleToggle={toggleShowControllerOverlay}
              />
            </ShowControllerOverlayWrapper>
          </Card>
        </ControllerWrapper>

        <InputListWrapper>
          <Card>
            <Scale>
              <AnimatedInputListWithLanes
                actions={actionsModifiedForController}
                isPlaystation={selectedControllerType === "PLAYSTATION"}
                onActionClick={(frame, actionIndex) => {
                  setUpcomingActionIndex(actionIndex);
                  setVideoTime(frame);
                }}
              />
            </Scale>
          </Card>
        </InputListWrapper>

        {isMobile && (
          <DescriptionWrapper>
            <Card>
              <Description />
            </Card>
          </DescriptionWrapper>
        )}

        {/* <AuthorBubbleWrapper>
        <Link
          to={`/profile/${postAuthor._id}`}
          style={{ textDecoration: "none" }}
        >
          <AuthorName>{postAuthor.displayName}</AuthorName>
        </Link>
      </AuthorBubbleWrapper> */}

        <CommentsWrapper>
          <Card>
            <CommentsManager />
          </Card>
        </CommentsWrapper>

        {tutorMode && (
          <ConnectedControllerPanel>
            <ConnectedController />
          </ConnectedControllerPanel>
        )}
      </NonVideoContent>
    </Container>
  );

  function cleanupDataInRedux() {
    resetPost();
    // resetPostEditor();
  }

  function bindLeftRightArrowKeysToComboNavigation() {
    function handleKeyDown() {
      const { key } = window.event;
      switch (key) {
        case "ArrowRight":
          canGoNext && goToNextCombo();
          break;
        case "ArrowLeft":
          canGoBack && goToPreviousCombo();
          break;
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    const cleanup = () => window.removeEventListener("keydown", handleKeyDown);
    return cleanup;
  }
}

export default connect(select, actions)(Post);

export function setVideoTime(frame) {
  const videoRef = document.getElementById("post-video-tag");
  videoRef.currentTime = Math.ceil((frame / 60) * 100) / 100;
  videoRef.play();
}

const Container = styled.div`
  position: relative;
  box-shadow: var(--shadow-1);
  border: ${({ standalone }) => (standalone ? "0px" : "2px")} solid
    var(--dark-purple);
  border-radius: ${({ standalone }) => (standalone ? 0 : "16px")};
  display: grid;
  margin: ${({ standalone }) => (standalone ? 0 : "1em")};
  overflow: hidden;
  grid-template-columns: 7fr 3fr;
  grid-template-rows: 400px auto auto auto auto;
  grid-template-areas:
    "video        controller"
    "options      options"
    "inputs       inputs"
    "description  description"
    "comments     comments";
  padding-bottom: 5em;
  background-color: var(--black-purple);
  min-height: 400px;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 1024px) {
    grid-template-areas:
      "video"
      "controller"
      "inputs"
      "options"
      "description"
      "comments";
    grid-template-columns: 100vw;
    grid-template-rows: 240px auto auto auto auto auto;
    width: calc(100vw);
    border-radius: 0;
    box-sizing: border-box;
    margin-top: 0;
    min-height: calc(100vh - 120px);
    background-color: var(--black-purple);
    border: none;
  }

  @media (min-width: 800px) {
    display: flex;
    height: calc(100vh - 40px);
    padding-bottom: 0;
  }

  @keyframes dropDown {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const DescriptionWrapper = styled.div`
  grid-area: description;
`;

const CommentsWrapper = styled.div`
  grid-area: comments;
`;

const AuthorName = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  margin-top: 5px;
  padding: 0 0.5em;
  padding-right: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50px;
  &:hover {
    color: var(--green);
  }
`;

const OptionsWrapper = styled.div`
  background-color: var(--black-purple);
  grid-area: options;
`;

const VideoWrapper = styled.div`
  grid-area: video;
  position: relative;
  @media (min-width: 800px) {
    flex-grow: 1;
    min-width: 60vw;
    height: 100%;
    overflow: hidden;
  }
  /* @media (max-width: 600px) {
    position: fixed;
    top: 20px;
    left: 0;
    grid-area: initial;
    z-index: 0;
    display: none;
  } */
`;

const NonVideoContent = styled.div`
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 0 5px;
  }
  @media (min-width: 800px) {
    flex-grow: 1;
    min-width: 40vw;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em;
  }
  & > div > div {
    /* border-color: var(--blue); */
  }
`;

const ControllerWrapper = styled.div`
  grid-area: controller;
  background-color: var(--black-purple);
  position: relative;
  @media (max-width: 600px) {
    margin-top: 1em;
  }
`;

const OverlaidControllerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  opacity: 0.7;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
`;

const InputListWrapper = styled.div`
  grid-area: inputs;
  background-color: var(--black-purple);
`;

const AuthorBubbleWrapper = styled.div`
  position: absolute;
  top: 1em;
  left: 0;
  background: var(--purple-gradient);
  border-radius: 0 16px 16px 0;
  box-shadow: var(--shadow-1);
  color: white;
  text-align: center;
  padding-bottom: 0.5em;
  @media (max-width: 600px) {
    top: 0.5em;
  }
`;

const AbstractNavigator = styled.div`
  height: 50px;
  width: 50px;
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: var(--shadow-1);
  position: fixed;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  transition: 0.2s ease;
  border-radius: 100px;
  transform: translateY(-50%);
  &:hover {
    color: var(--green);
  }
  top: 400px;

  @media (max-width: 600px) {
    height: 40px;
    width: 40px;
    top: 380px;
    position: absolute;
  }
`;

const ConnectedControllerPanel = styled.div`
  position: fixed;
  width: 1000px;
  bottom: 0;
  height: 300px;
  color: white;
  background: var(--purple-gradient);
  box-shadow: var(--shadow-1);
  z-index: 10;
  animation: slide-in 0.3s forwards;

  @keyframes slide-in {
    from {
      bottom: -400px;
    }
    to {
      bottom: 0px;
    }
  }
`;

const Scale = styled.div`
  @media (min-width: 800px) {
    overflow: hidden;
    width: calc(100% / 1.2);
    transform: scale(1.2);
    padding-bottom: 40px;
    transform-origin: top left;
  }
`;

const ShowControllerOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
`;
