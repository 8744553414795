import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import EmbeddedComboWithoutRedux from "../EmbeddedComboWithoutRedux";
import { Link } from "react-router-dom";

function EmbeddedCombo({ index, combo, contentEditable, onKeyDown, onFocus }) {
  return (
    <Container
      id={"section-" + index}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      contentEditable={contentEditable}
    >
      {/* <Link to={`/combo/${combo._id}`}>
        <ComboTitle>{combo.name}</ComboTitle>
      </Link> */}
      <EmbeddedComboWithoutRedux combo={combo} />
    </Container>
  );
}

export default connect()(EmbeddedCombo);

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  caret-color: transparent;
  color: white;
  margin-bottom: 1em;
  &:focus {
    outline: 3px solid var(--purple);
  }
  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const ComboTitle = styled.h2`
  text-decoration: underline;
  color: white;
  transition: 0.1s ease;
  margin-top: 0;
  opacity: 0.6;

  &:hover {
    color: var(--green);
    opacity: 1;
  }
  @media (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
  }
`;
