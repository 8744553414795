import * as buttonTypes from "../PostEditor/CreateComboInterface/ActionEditor/ducks/types";

import DarkSelect from "../../2-utils/DarkSelect";
import React from "react";
import { connect } from "react-redux";
import { doChangeControllerMapping } from "./ducks/actions";
import styled from "@emotion/styled";

const buttonOptionsRef = [
  { value: buttonTypes.A_BUTTON, label: "A" },
  { value: buttonTypes.B_BUTTON, label: "B" },
  { value: buttonTypes.X_BUTTON, label: "X" },
  { value: buttonTypes.Y_BUTTON, label: "Y" },
  { value: buttonTypes.R_TRIGGER, label: "Right Trigger" },
  { value: buttonTypes.L_TRIGGER, label: "Left Trigger" },
  { value: buttonTypes.L_Z, label: "Left Z" },
  { value: buttonTypes.R_Z, label: "Right Z" }
];

const select = ({ customMappingPage, userInfo }) => ({
  activeGameId: customMappingPage.activeGameId,
  activeController: customMappingPage.activeController,
  customMapping:
    (userInfo.customMappings[customMappingPage.activeGameId] || {})[
      customMappingPage.activeController &&
        customMappingPage.activeController.value
    ] || {}
});

const actions = {
  changeMapping: doChangeControllerMapping
};

function ReconfigureMappings({
  activeController,
  activeGameId,
  changeMapping,
  customMapping
}) {
  const isPlaystation =
    activeController && activeController.value === "PLAYSTATION";
  const buttonOptions = isPlaystation
    ? buttonOptionsRef.map(option => convertOptionToPlaystation(option))
    : buttonOptionsRef;

  if (!activeController)
    return <p style={{ opacity: 0.5 }}>Please select a controller</p>;

  return (
    <Container>
      <h3>Customize mappings</h3>
      <p>
        Select what you want each input in a combo to be converted to for your
        personal setup
      </p>
      <h4 style={{ marginTop: 0 }}>{isPlaystation ? "Square" : "A"}</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.A_BUTTON])}
        onChange={newOption =>
          changeMapping(buttonTypes.A_BUTTON, newOption.value)
        }
      />

      <h4>{isPlaystation ? "Circle" : "B"}</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.B_BUTTON])}
        onChange={newOption =>
          changeMapping(buttonTypes.B_BUTTON, newOption.value)
        }
      />

      <h4>X</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.X_BUTTON])}
        onChange={newOption =>
          changeMapping(buttonTypes.X_BUTTON, newOption.value)
        }
      />

      <h4>{isPlaystation ? "Triangle" : "Y"}</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.Y_BUTTON])}
        onChange={newOption =>
          changeMapping(buttonTypes.Y_BUTTON, newOption.value)
        }
      />

      <h4>Left Trigger</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.L_TRIGGER])}
        onChange={newOption =>
          changeMapping(buttonTypes.L_TRIGGER, newOption.value)
        }
      />

      <h4>Right Trigger</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.R_TRIGGER])}
        onChange={newOption =>
          changeMapping(buttonTypes.R_TRIGGER, newOption.value)
        }
      />

      <h4>Left Z</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.L_Z])}
        onChange={newOption => changeMapping(buttonTypes.L_Z, newOption.value)}
      />

      <h4>Right Z</h4>
      <DarkSelect
        options={buttonOptions}
        value={convertToSelectFormat(customMapping[buttonTypes.R_Z])}
        onChange={newOption => changeMapping(buttonTypes.R_Z, newOption.value)}
      />
    </Container>
  );

  function convertToSelectFormat(type) {
    return buttonOptions.find(option => option.value === type) || null;
  }
}

export default connect(select, actions)(ReconfigureMappings);

export function convertOptionToPlaystation(option) {
  let label = null;
  switch (option.value) {
    case buttonTypes.A_BUTTON:
      label = "Square";
      break;
    case buttonTypes.B_BUTTON:
      label = "Circle";
      break;
    case buttonTypes.X_BUTTON:
      label = "X";
      break;
    case buttonTypes.Y_BUTTON:
      label = "Triangle";
      break;
    default:
      return option;
  }
  return { ...option, label };
}

const Container = styled.div`
  padding-bottom: 8em;
  h4 {
    margin-bottom: 0.5em;
  }
`;
