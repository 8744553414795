import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import TextInput from "../../2-utils/editables/TextInput";
import { Email } from "styled-icons/material";
import GradientText from "../../2-utils/editables/GradientText";
import toast from "../../2-utils/toast";
import { getAuth } from "firebase/auth";

function ResetPasswordPage() {
  const [email, setEmail] = useState("");

  return (
    <Container>
      <Content>
        <Header>No worries, resetting your password is easy</Header>
        <TextInput
          placeholder="recovery email"
          IconJSX={Email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button onClick={() => sendPasswordResetEmail(email)}>
          <GradientText color="var(--green-purple-gradient)">
            <b>send recovery email</b>
          </GradientText>
        </Button>
      </Content>
    </Container>
  );
}

export default connect()(ResetPasswordPage);

async function sendPasswordResetEmail(email) {
  try {
    await getAuth().sendPasswordResetEmail(email);
    toast("Password reset email sent");
  } catch (err) {
    alert(err.message);
  }
}

const Container = styled.div`
  background-color: #121212;
  background: linear-gradient(180deg, #00bcd4, var(--dark-purple));
  overflow: hidden;
  padding: 1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5em;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 4em 2em;
    height: 100vh;
    box-sizing: border-box;
  }
`;

const Content = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;
`;

const Button = styled.div`
  background-color: white;
  border-radius: 50px;
  padding: 0.5em 1em;
  box-shadow: var(--shadow-1);
  text-align: center;
`;

const Header = styled.h2``;
