import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function ConnectControllerPrompt() {
  return (
    <Container>
      (BETA) Please connect Switch Pro Controller via bluetooth. <br />
      <br />
      Once connected press any button on your Switch Pro Controller to begin.
    </Container>
  );
}

export default connect()(ConnectControllerPrompt);

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
