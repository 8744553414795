import React from "react";

export default function Information({ activeGameName }) {
  return (
    <>
      <h2 style={{ marginBottom: "0em", marginTop: 0, paddingTop: "0.67em" }}>
        Customize Controllers
      </h2>
      <p>All combos will automatically convert to your custom controller.</p>
      <p>
        This will only effect the controller for{" "}
        {activeGameName ? (
          <b style={{ color: "var(--green)" }}>{activeGameName}</b>
        ) : (
          "the selected game."
        )}
      </p>
    </>
  );
}
