import { getStorage } from "firebase/storage";

import {
  ACTIVATED_DRAFT_MANAGER,
  ACTIVATED_PUBLISH_INTERFACE,
  ADDED_NEW_SECTION,
  CHANGED_GUIDE_PREVIEW_SUBTITLE,
  CHANGED_GUIDE_PREVIEW_TITLE,
  CHANGED_GUIDE_TITLE,
  CHANGED_PUBLISHED_STATUS,
  CHANGED_SECTION_TYPE,
  CHANGED_SECTION_VALUE,
  DEACTIVATED_DRAFT_MANAGER,
  DEACTIVATED_PUBLISH_INTERFACE,
  DESELECTED_GAME_TAG_FOR_GUIDE,
  FINISHED_CREATING_NEW_GUIDE_DRAFT,
  FINISHED_DELETING_GUIDE,
  FINISHED_FETCHING_GUIDE,
  FINISHED_FETCHING_GUIDES_BY_USER,
  FINISHED_SAVING_GUIDE,
  FINISHED_UPLOADING_IMAGE_FOR_GUIDE,
  REMOVED_SECTION,
  SELECTED_GAME_TAG_FOR_GUIDE,
  SELECTED_GUIDE_TO_EDIT,
  SET_LAST_FOCUSED_SECTION_INDEX,
  STARTED_CREATING_NEW_GUIDE_DRAFT,
  STARTED_DELETING_GUIDE,
  STARTED_FETCHING_GUIDE,
  STARTED_FETCHING_GUIDES_BY_USER,
  STARTED_SAVING_GUIDE,
  STARTED_UPLOADING_IMAGE_FOR_GUIDE,
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";

export function doFetchGuideById(guideId) {
  return async (dispatch) => {
    dispatch({
      type: STARTED_FETCHING_GUIDE,
    });
    const url = `${BACKEND_URL}/guides/id/${guideId}`;
    const response = await Axios.get(url);
    dispatch({
      type: FINISHED_FETCHING_GUIDE,
      payload: {
        guide: response.data.guide,
      },
    });
  };
}

export function doDeleteGuide(guideId) {
  return async (dispatch) => {
    dispatch({ type: STARTED_DELETING_GUIDE });
    const url = `${BACKEND_URL}/guides/delete`;
    const response = await Axios.post(url, { guideId });
    dispatch({
      type: FINISHED_DELETING_GUIDE,
      payload: {
        guideId,
      },
    });
  };
}

export function doCreateNewGuideDraft() {
  return async (dispatch, getState) => {
    const userId = getState().userInfo._id;
    dispatch({ type: STARTED_CREATING_NEW_GUIDE_DRAFT });
    const url = `${BACKEND_URL}/guides/create`;
    const response = await Axios.post(url, {
      authorId: userId,
    });
    dispatch({
      type: FINISHED_CREATING_NEW_GUIDE_DRAFT,
      payload: {
        draft: response.data.draft,
      },
    });
  };
}

export function doFetchGuidesMadeByUser(userId) {
  return async (dispatch, getState) => {
    dispatch({ type: STARTED_FETCHING_GUIDES_BY_USER });
    const url = `${BACKEND_URL}/guides/user/${userId}`;
    const results = await Axios.get(url);
    const drafts = results.data.filter((guide) => !guide.metaData.isPublished);
    const publications = results.data.filter(
      (guide) => guide.metaData.isPublished
    );
    dispatch({
      type: FINISHED_FETCHING_GUIDES_BY_USER,
      payload: {
        drafts,
        publications,
      },
    });
    const guideToFocusNotSpecifiedInURL =
      window.location.href.split("/").reverse()[0] === "default";
    if (guideToFocusNotSpecifiedInURL) {
      const guideToFocusOn =
        (drafts[0] && drafts[0]._id) ||
        (publications[0] && publications[0]._id);
      dispatch(doSelectGuideToEdit(guideToFocusOn));
    }
  };
}

export function doChangeGuideTitle(title) {
  return {
    type: CHANGED_GUIDE_TITLE,
    payload: {
      title,
    },
  };
}

export function doMergeSectionWithPreviousIndex(index) {
  return (dispatch, getState) => {
    if (!index) return;
    const sections = getState().guidePage.sections;
    const targetSection = sections[index];
    const prevSection = sections[index - 1];
    const prevSectionEl = document.getElementById("section-" + (index - 1));
    const targetCaretPos = prevSection.value.length;

    // if its a video, just focus on the video
    if (prevSection.type === "COMBO_SELECTOR") {
      focusSectionEl(index - 1);
      return;
    }

    prevSection.value += targetSection.value;
    prevSectionEl.innerHTML = prevSection.value;
    const sectionsWithoutIndex = sections.filter((s, i) => i !== index);

    dispatch({
      type: REMOVED_SECTION,
      payload: {
        sections: sectionsWithoutIndex,
      },
    });

    setCaretPosition(prevSectionEl, targetCaretPos);
  };
}

export function doAddNewSectiontoEnd() {
  return (dispatch, getState) => {
    const sections = getState().guidePage.sections;
    const lastSectionIndex = sections.length - 1;
    const lastSectionIsText = sections[lastSectionIndex].type === "TEXT";
    if (!lastSectionIsText) dispatch(doAddNewSection(lastSectionIndex));
  };
}

export function doAddNewSection(sectionIndex, caretPosition) {
  return (dispatch, getState) => {
    const sections = getState().guidePage.sections;
    const prevSection = sections[sectionIndex];
    const left = sections.slice(0, sectionIndex + 1);
    const newSection = {
      type: prevSection.type === "COMBO_SELECTOR" ? "TEXT" : prevSection.type,
      value: "",
      id: getUniqueId(),
    };
    const right = sections.slice(sectionIndex + 1);

    const newSections = [...left, newSection, ...right];

    if (caretPosition !== undefined && prevSection.type !== "COMBO_SELECTOR") {
      const oldVal = prevSection.value;

      // update the state
      prevSection.value = oldVal.slice(0, caretPosition);
      newSections[sectionIndex + 1].value = oldVal.slice(caretPosition);

      // update the dom
      setTimeout(() => {
        document.getElementById("section-" + sectionIndex).innerHTML =
          oldVal.slice(0, caretPosition);
        document.getElementById("section-" + (sectionIndex + 1)).innerHTML =
          oldVal.slice(caretPosition);
      }, 0);
    }

    dispatch({
      type: ADDED_NEW_SECTION,
      payload: { sections: newSections },
    });

    setTimeout(() => focusSectionEl(sectionIndex + 1), 0);
  };
}

export function doRemoveSection(sectionIndex) {
  return (dispatch, getState) => {
    const sections = getState().guidePage.sections;
    const isLastSection = sectionIndex === sections.length - 1;
    const isFirstSection = sectionIndex === 0;

    if (isFirstSection) return;

    if (isLastSection) {
      if (sections[sectionIndex].type === "TEXT")
        return dispatch(doSetLastFocusedSectionIndex(sectionIndex - 1));
    }

    const newSections = [
      ...sections.slice(0, sectionIndex),
      ...sections.slice(sectionIndex + 1),
    ];

    dispatch({
      type: REMOVED_SECTION,
      payload: { sections: newSections },
    });
  };
}

export function doSetLastFocusedSectionIndex(index) {
  return {
    type: SET_LAST_FOCUSED_SECTION_INDEX,
    payload: {
      index,
    },
  };
}

function focusSectionEl(index) {
  const section = document.getElementById("section-" + index);
  if (section) section.focus();
}

export function doChangeSectionValue(sectionIndex, value) {
  return (dispatch, getState) => {
    const sections = getState().guidePage.sections;
    sections[sectionIndex].value = value;
    dispatch({
      type: CHANGED_SECTION_VALUE,
      payload: {
        sections: [...sections],
      },
    });
  };
}

export function doChangeSectionType(sectionIndex, type) {
  return (dispatch, getState) => {
    const sections = getState().guidePage.sections;
    sections[sectionIndex].type = type;
    dispatch({
      type: CHANGED_SECTION_TYPE,
      payload: {
        sections: [...sections],
      },
    });
  };
}

function getUniqueId() {
  const MAX = 9999999;
  return Math.floor(Math.random() * Math.floor(MAX));
}

export function setCaretPosition(node, char) {
  var sel = window.getSelection();
  sel.collapse(node.firstChild, char);
}

export function doActivateDraftManager() {
  return {
    type: ACTIVATED_DRAFT_MANAGER,
  };
}

export function doDeactivateDraftManager() {
  return {
    type: DEACTIVATED_DRAFT_MANAGER,
  };
}

export function doActivatePublishInterface() {
  return {
    type: ACTIVATED_PUBLISH_INTERFACE,
  };
}

export function doDeactivatePublishInterface() {
  return {
    type: DEACTIVATED_PUBLISH_INTERFACE,
  };
}

export function doSelectGuideToEdit(guideId) {
  return async (dispatch) => {
    window.scrollTo(0, 0);
    await dispatch(doFetchGuideById(guideId));
    dispatch({
      type: SELECTED_GUIDE_TO_EDIT,
      payload: {
        guideId,
      },
    });
  };
}

export function doSelectGameForGuide(gameId) {
  return {
    type: SELECTED_GAME_TAG_FOR_GUIDE,
    payload: {
      gameId,
    },
  };
}

export function doDeselectGameForGuide(gameId) {
  return {
    type: DESELECTED_GAME_TAG_FOR_GUIDE,
    payload: {
      gameId,
    },
  };
}

export function doSaveGuideToBackend() {
  return async (dispatch, getState) => {
    // might have race conditions
    dispatch({ type: STARTED_SAVING_GUIDE });
    const state = getState().guidePage;
    const guide = {
      _id: state.activeGuideId,
      sections: state.sections,
      metaData: state.metaData,
    };
    const url = `${BACKEND_URL}/guides/update`;
    await Axios.post(url, { guide });
    dispatch({ type: FINISHED_SAVING_GUIDE });
  };
}

export function doChangeGuidePreviewTitle(previewTitle) {
  return {
    type: CHANGED_GUIDE_PREVIEW_TITLE,
    payload: {
      previewTitle,
    },
  };
}

export function doChangeGuidePreviewSubTitle(previewSubtitle) {
  return {
    type: CHANGED_GUIDE_PREVIEW_SUBTITLE,
    payload: {
      previewSubtitle,
    },
  };
}

export function doPublishGuide() {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGED_PUBLISHED_STATUS,
      payload: {
        isPublished: true,
      },
    });
    dispatch(doSaveGuideToBackend());
  };
}

export function doUnPublishGuide() {
  return (dispatch, getState) => {
    dispatch({
      type: CHANGED_PUBLISHED_STATUS,
      payload: {
        isPublished: false,
      },
    });
    dispatch(doSaveGuideToBackend());
  };
}

export function doSelectPreviewImageForGuide(fileUrl) {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = state.userInfo._id;
    const guideId = state.guidePage.activeGuideId;
    dispatch({ type: STARTED_UPLOADING_IMAGE_FOR_GUIDE });
    const imageUrl = await uploadPrevierwImageForGuideToFirebase(
      fileUrl,
      userId,
      guideId
    );
    dispatch({
      type: FINISHED_UPLOADING_IMAGE_FOR_GUIDE,
      payload: {
        previewImage: imageUrl,
      },
    });
  };
}

async function uploadPrevierwImageForGuideToFirebase(fileUrl, userId, guideId) {
  const filePath = getPathWherePictureWillLive(userId, guideId);
  const storageRef = getFirebaseStorageRef(filePath);
  const fileObject = await getFileObject(fileUrl);
  const task = await storageRef.put(fileObject);
  const imageUrl = await storageRef.getDownloadURL();
  return imageUrl;
}

function getPathWherePictureWillLive(userId, guideId) {
  return `user-${userId}/guides/guide-${guideId}/preview-image`;
}

function getFirebaseStorageRef(filepath) {
  const storage = getStorage();
  return storage().ref(filepath);
}

function getFileObject(fileUrl) {
  return fetch(fileUrl).then((r) => r.blob());
}

function getDownloadUrl(storageRef) {
  return storageRef.getDownloadURL();
}
