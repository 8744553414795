import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import Nav from "../HomePage/Nav";
import Posts from "../ProfilePage/Posts";
import PostPreviews from "../ResultsPage/PostPreviews";
import {
  doFetchCombos,
  doClosePost,
  doResetResultsPage
} from "../ResultsPage/ducks/actions";
import Post from "../Post";
import Modal from "../ResultsPage/Modal";
import { doFetchUserCombos } from "../ProfilePage/ducks/actions";

const select = ({ resultsPage, userInfo }) => ({
  showModal: resultsPage.activePostIndex !== null,
  userId: userInfo._id
});

const actions = {
  fetchFavoriteCombos: doFetchUserCombos,
  closePost: doClosePost,
  resetResultsPage: doResetResultsPage
};

function FavoriteCombos({
  fetchFavoriteCombos,
  showModal,
  closePost,
  resetResultsPage,
  userId
}) {
  useEffect(() => {
    if (userId) fetchFavoriteCombos(userId);
  }, [userId]);

  return (
    <Container>
      <PostPreviews />
    </Container>
  );
}

export default connect(
  select,
  actions
)(FavoriteCombos);

const Container = styled.div`
  background-color: var(--black-purple);
  overflow: hidden;
  padding: 3em;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const Content = styled.div`
  margin: 1em 5em;
  height: 100%;
`;
