import React from "react";
import styled from "@emotion/styled";

export default function toast(message) {
  const newDiv = document.createElement("div");
  const newText = document.createTextNode(message);
  newDiv.appendChild(newText);
  newDiv.className="toast"
  const root = document.getElementById("root");
  document.body.append(newDiv);
}

const ToastJSX = styled.div`
  position: fixed;
  bottom: 50px;
  right: 50px;
  background-color: var(--green);
`;
