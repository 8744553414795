export const SELECTED_GAME_FOR_NEW_COMBO = "SELECTED_GAME_FOR_NEW_COMBO";
export const SELECTED_CHARACTER_FOR_NEW_COMBO =
  "SELECTED_CHARACTER_FOR_NEW_COMBO";
export const POSTED_TO_BACKEND = "POSTED_COMBO_TO_BACKEND";
export const CHANGED_NEW_COMBO_NAME = "CHANGED_NEW_COMBO_NAME";
export const CHANGED_NEW_COMBO_DESCRIPTION = "CHANGED_NEW_COMBO_DESCRIPTION";

export const MOVED_TO_NEXT_FRAME = "MOVED_TO_NEXT_FRAME";
export const MOVED_TO_PREVIOUS_FRAME = "MOVED_TO_PREVIOUS_FRAME";
export const JUMPED_FRAME = "JUMPED_FRAME";

export const SELECTED_PRESS = "SELECTED_PRESS";

export const SET_VIDEO_DURATION = "SET_VIDEO_DURATION";
export const SELECTED_VIDEO = "SELECTED_VIDEO";
export const RESET_POST_EDITOR = "RESET_POST_EDITOR";

export const FINISHED_FETCHING_CHARACTERS_FOR_POST =
  "FINISHED_FETCHING_CHARACTERS_FOR_POST";
export const STARTED_POSTING_COMBO_TO_BACKEND =
  "STARTED_POSTING_COMBO_TO_BACKEND";
export const FINISHED_POSTING_COMBO_TO_BACKEND =
  "FINISHED_POSTING_COMBO_TO_BACKEND";
export const UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE =
  "UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE";

export const SET_COMBO_IN_POST_EDITOR = "SET_COMBO_IN_POST_EDITOR";
export const STARTED_UPDATING_COMBO_IN_BACKEND =
  "STARTED_UPDATING_COMBO_IN_BACKEND";
export const FINISHED_UPDATING_COMBO_IN_BACKEND =
  "FINISHED_UPDATING_COMBO_IN_BACKEND";

export const STARTED_HOLD = "STARTED_HOLD";
export const REMOVED_STARTED_HOLD = "REMOVED_STARTED_HOLD";
export const REMOVED_HOLD = "REMOVED_HOLD";
export const ENDED_HOLD = "ENDED_HOLD";
export const REMOVED_ENDED_HOLD = "REMOVED_ENDED_HOLD";

export const REMOVED_CONTROL_STICK_FROM_FRAME =
  "REMOVED_CONTROL_STICK_FROM_FRAME";

export const CHANGED_PERCENT_RANGE = "CHANGED_PERCENT_RANGE";
export const TOGGLED_TAG = "TOGGLED_TAG";
export const SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR =
  "SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR";
