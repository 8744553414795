import styled from "@emotion/styled";

const SlidePanel = styled.div`
  width: 250px;
  height: calc(100vh);
  background-color: var(--black-purple);
  border-right: 2px solid var(--purple);
  border-bottom: 2px solid var(--purple);
  box-shadow: var(--shadow-1);
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  transform: translateX(${({ show }) => (show ? "0" : "-100%")});
  transition: 0.2s ease;
`;

export default SlidePanel;
