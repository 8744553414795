import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function Video({ videoFile }) {
  return (
    <Container>
      <VideoTag>
        <source src={videoFile} />
      </VideoTag>
    </Container>
  );
}

export default connect()(Video);

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VideoTag = styled.video`
  width: 100%;
  object-fit: contain;
  padding: 2em 0;
`;
