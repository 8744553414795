import "rc-slider/assets/index.css";

import React from "react";
import Slider from "rc-slider";
import { connect } from "react-redux";
import { doChangePercentRange } from "../ducks/actions";
import styled from "@emotion/styled";

// const createSliderWithTooltip = Slider.createSliderWithTooltip;
// const Range = createSliderWithTooltip(Slider.Range);

const select = ({ postEditor }) => ({
  percentRange: postEditor.percentRange,
});

const actions = {
  changePercentRange: doChangePercentRange,
};

function PercentSelector({ percentRange, changePercentRange }) {
  return (
    <Container>
      <h3>
        Percent Range Combo Works For{" "}
        <b>
          {percentRange[0]}% - {percentRange[1]}%
        </b>
      </h3>
      <Slider
        range
        min={0}
        max={200}
        value={percentRange}
        tipFormatter={tipFormatter}
        trackStyle={trackStyle}
        handleStyle={handleStyle}
        onChange={(range) => changePercentRange(range)}
      />
    </Container>
  );
}

export default connect(select, actions)(PercentSelector);

const Container = styled.div`
  margin: 2em 0;
  position: relative;
  margin-left: auto;
  b {
    color: var(--green);
  }
`;

function tipFormatter(value) {
  return value + "%";
}

var trackStyle = [
  {
    backgroundColor: "var(--green)",
  },
];

var handleStyle = [
  {
    borderColor: "var(--green)",
    backgroundColor: "var(--green)",
    height: "20px",
    width: "20px",
    marginTop: "-8px",
  },
];
