import { Edit } from "styled-icons/material";
import { Link } from "react-router-dom";
import React from "react";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import styled from "@emotion/styled";

export default function EditComboButton({ combo }) {
  return (
    <ToolTip message="Edit combo">
      <Link to={`/test/${combo._id}`}>
        <EditIcon />
      </Link>
    </ToolTip>
  );
}

const EditIcon = styled(Edit)`
  transition: 0.2s ease;
  color: white;
  width: 25px;
  cursor: pointer;
  &:hover {
    color: var(--green);
  }
`;
