import React, { useEffect, useRef } from "react";

import { connect } from "react-redux";
import { doSetVideoDuration } from "../../ducks/actions";
import styled from "@emotion/styled";

const FRAME_TIME = 1 / 60;

const select = ({ postEditor }) => ({
  frame: postEditor.frame,
  videoFile: postEditor.videoFile
});

const actions = {
  setVideoDuration: doSetVideoDuration
};

function Video({ frame, setVideoDuration, videoFile }) {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current.pause();
  }, []);

  useEffect(() => {
    const handleCleanup = onAfterVideoLoaded(registerVideoMetadata);
    return handleCleanup;
  }, [videoFile]);

  // changes video position when frame changes
  useEffect(() => {
    goToFrame(frame);
  }, [frame]);

  return (
    <VideoTag ref={videoRef} playsInline autoPlay muted>
      {videoFile && <source src={videoFile} type="video/mp4" />}
    </VideoTag>
  );

  function goToFrame(frame) {
    const sourceElement = videoRef.current;
    sourceElement.currentTime = frame * FRAME_TIME;
  }

  function onAfterVideoLoaded(callback) {
    videoRef.current.addEventListener("loadedmetadata", callback);
    return () =>
      videoRef.current.removeEventListener("loadedmetadata", callback);
  }

  function registerVideoMetadata(r) {
    const { duration } = r.srcElement;
    setVideoDuration(duration);
  }
}

export default connect(select, actions)(Video);

const VideoTag = styled.video`
  width: 100%;
  object-fit: contain;
`;
