const ANIMATION_DURATION = 350;

export function moveStick(ref, direction, holdStatus) {
  const className = "move-" + direction;
  const element = ref.current;
  if (holdStatus !== "END_HOLD") element.classList.add(className);
  if (holdStatus === "START_HOLD" || holdStatus === "MAINTAIN_HOLD") return;
  if (holdStatus === "END_HOLD")
    return setTimeout(() => element.classList.remove(className), 0);
  setTimeout(() => element.classList.remove(className), ANIMATION_DURATION);
}

export function pressButton(ref, holdStatus) {
  const element = ref.current;
  if (holdStatus !== "END_HOLD") element.classList.add("pressed");
  if (holdStatus === "START_HOLD" || holdStatus === "MAINTAIN_HOLD") return;
  if (holdStatus === "END_HOLD")
    return setTimeout(() => element.classList.remove("pressed"), 0);
  setTimeout(() => element.classList.remove("pressed"), ANIMATION_DURATION);
}
