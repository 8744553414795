import React from "react";
import styled from "@emotion/styled";

function GamePreviewSquare({ imageUrl, gameTitle, onClick, selected, small }) {
  return (
    <Container
      onClick={onClick}
      selected={selected}
      small={small}
      className="game-preview-square"
    >
      <GameTitle className="game-title" selected={selected}>
        {gameTitle}
      </GameTitle>
      <BackgroundImage src={imageUrl} selected={selected} />
    </Container>
  );
}

export default GamePreviewSquare;

const Container = styled.div`
  width: ${({ small }) => (small ? "calc(1000px / 8)" : "calc(1000px / 2)")};
  height: ${({ small }) => (small ? "calc(1000px / 8)" : "calc(1000px / 6)")};
  box-sizing: border-box;
  color: white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > div {
    font-weight: bold;
    font-size: 18px;
    max-width: 400px;
  }
  overflow: hidden;
  &:hover {
    img {
      opacity: 1;
      filter: none;
    }
    .game-title {
      z-index: 0;
      filter: blur(2px);
    }
  }

  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const GameTitle = styled.div`
  text-shadow: 0px 5px 10px var(--green);
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.05s ease;
  filter: blur(${({ selected }) => (selected ? "2px" : "0")});
  z-index: ${({ selected }) => (selected ? "0" : "1")};
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: var(--black-purple); */
  z-index: 0;
  transition: 0.05s ease;
  opacity: ${({ selected }) => (selected ? 1 : 0.2)};
  filter: blur(${({ selected }) => (selected ? "0" : "2px")});
  border: 1px solid
    ${({ selected }) => (selected ? "var(--green)" : "var(--black-purple)")};
  box-sizing: border-box;
`;
