import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { MoonAnimation } from "../../2-utils/loadingAnimations";

const select = ({ guidePage }) => ({
  saveStatus: guidePage.saveStatus
});

function AutoSaveSpinner({ saveStatus }) {
  let message = getMessageFromSaveStatus(saveStatus);
  return (
    <Container>
      <div style={{ marginRight: "0.5em" }}>{message}</div>
      {saveStatus === "SAVING" && <MoonAnimation size={12}/>}
    </Container>
  );
}

function getMessageFromSaveStatus(saveStatus) {
  switch (saveStatus) {
    case "NOT_SAVED":
      return null;
    case "SAVING":
      return "saving";
    case "SAVED":
      return "saved";
  }
}

export default connect(select)(AutoSaveSpinner);

const Container = styled.div`
  display: flex;
  align-items: center;
  color: var(--green);
`;
