import React, { useEffect } from "react";
import {
  doChangeGameForDiscoverPage,
  doFetchGuidesByGame
} from "./ducks/actions";

import GuidePreviewsList from "./GuidePreviewsList";
import Nav from "./Nav";
import { connect } from "react-redux";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

const select = ({ discoverPage, homePage }) => {
  const activeGameId = discoverPage.activeGameId;
  const currentGame = homePage.availableGames.find(
    game => game._id === activeGameId
  );
  return {
    currentGame: currentGame || {}
  };
};

const actions = {
  fetchGuidesByGame: doFetchGuidesByGame,
  changeGame: doChangeGameForDiscoverPage
};

function DiscoverPage({ currentGame, changeGame, fetchGuidesByGame }) {
  useEffect(() => {
    recordPageView();
  }, []);
  useEffect(() => {
    fetchGuidesByGame(currentGame._id);
  }, [currentGame]);

  return (
    <>
      <BackgroundFiller />
      <Container>
        <Nav onSelect={changeGame} activeGameId={currentGame._id} />
        <Content>
          <GuidePreviewsList />
        </Content>
      </Container>
    </>
  );
}

export default connect(select, actions)(DiscoverPage);

const Container = styled.div`
  background-color: var(--black-purple);
  color: white;
  min-height: 100vh;
`;

const Content = styled.div`
  padding: 1em;
`;

const GameTitle = styled.h2`
  margin-left: 0.5em;
  @media (max-width: 600px) {
    margin-left: 5px;
  }
`;

const BackgroundFiller = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 500px;
  width: 100vw;
  background: var(--purple-gradient);
  transform: translateY(-50%);
  z-index: -1;
  @media (min-width: 600px) {
    display: none;
  }
`;
