import { CHANGED_GAME_FOR_DISCOVER_PAGE, FINISHED_FETCHING_GUIDES_BY_GAME } from "./types";

const initialState = {
  activeGameId: "5d5b6b21fe706ce02edf1a86",
  guides: []
};

export default function discoverPageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case CHANGED_GAME_FOR_DISCOVER_PAGE: {
      return {
        ...state,
        activeGameId: payload.activeGameId
      };
    }
    case FINISHED_FETCHING_GUIDES_BY_GAME: {
      return {
        ...state,
        guides: payload.guides
      };
    }
    default:
      return state;
  }
}
