import React, { useEffect } from "react";

import LoginForm from "./LoginForm";
import { connect } from "react-redux";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

function LoginPage() {
  useEffect(() => {
    recordPageView();
  }, []);
  return (
    <Container>
      <LoginFormWrapper>
        <LoginForm />
      </LoginFormWrapper>
    </Container>
  );
}

export default connect()(LoginPage);

const Container = styled.div`
  background-color: #121212;
  background: var(--black-purple);
  overflow: hidden;
  padding: 1em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 5em;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 4em 2em;
    height: 100vh;
    box-sizing: border-box;
  }
`;

const LoginFormWrapper = styled.div`
  width: 550px;
`;
