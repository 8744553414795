import {
  CHANGED_ACTIVE_CONTROLLER_FOR_CUSTOM_MAPPING_PAGE,
  CHANGED_CONTROLLER_MAPPING,
  CHANGED_GAME_FOR_CUSTOM_MAPPING_PAGE
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";

export function doChangeGameForCustomMappingPage(activeGameId) {
  return {
    type: CHANGED_GAME_FOR_CUSTOM_MAPPING_PAGE,
    payload: {
      activeGameId
    }
  };
}

export function doChangeControllerForCustomMappingPage(controllerType) {
  return {
    type: CHANGED_ACTIVE_CONTROLLER_FOR_CUSTOM_MAPPING_PAGE,
    payload: {
      activeController: controllerType
    }
  };
}

export function doChangeControllerMapping(type, mappedType) {
  return (dispatch, getState) => {
    const state = getState();
    const customMappings = state.userInfo.customMappings;
    const { activeGameId, activeController } = state.customMappingPage;

    // add custom controller fields if they don't exists
    if (!customMappings[activeGameId]) customMappings[activeGameId] = {};
    if (!customMappings[activeGameId][activeController])
      customMappings[activeGameId][activeController] = {};

    const newActiveControllerMapping = {
      ...customMappings[activeGameId][activeController.value]
    };
    newActiveControllerMapping[type] = mappedType;

    // overwrite local field
    customMappings[activeGameId][
      activeController.value
    ] = newActiveControllerMapping;

    // optimistic? store to backend
    const userId = state.userInfo._id;
    const url = `${BACKEND_URL}/user/set-custom-controls`;
    Axios.post(url, {
      userId,
      customMappings
    });

    // update state locally
    dispatch({
      type: CHANGED_CONTROLLER_MAPPING,
      payload: { customMappings }
    });
  };
}
