import React from "react";
import styled from "@emotion/styled";
import { CheckBox, CheckBoxOutlineBlank } from "styled-icons/material";

export default function Checkbox({ text, active, onClick }) {
  return (
    <Container active={active} onClick={onClick}>
      {active ? <ActiveCheckBoxIcon /> : <InactiveCheckBoxIcon />}
      <Text>{text}</Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  cursor: pointer;
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  &:hover {
    color: var(--green);
    > svg {
      color: var(--green);
    }
  }
`;

const Text = styled.div`
  @media (max-width: 600px) {
    font-size: 18px;
    font-weight: bold;
  }
`;

const ActiveCheckBoxIcon = styled(CheckBox)`
  width: 20px;
  height: 20px;
  color: var(--green);
  margin-right: 5px;
  @media (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;

const InactiveCheckBoxIcon = styled(CheckBoxOutlineBlank)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.5);
  &:hover {
    color: var(--green);
  }
  @media (max-width: 600px) {
    width: 25px;
    height: 25px;
  }
`;
