import React, { useRef } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { Button } from "../../../../2-utils/buttons";
import { doSelectVideo } from "../../ducks/actions";
import { InvisibleFileInput } from "../../../../2-utils/inputs";

const actions = {
  selectVideoFile: doSelectVideo
};

function UploadVideoButton({ selectVideoFile }) {
  const videoInputRef = useRef();

  return (
    <Container>
      <Button onClick={clickInvisibleFileInput}>upload video</Button>

      <InvisibleFileInput
        ref={videoInputRef}
        onChange={handleUpload}
        type="file"
        accept="video/*"
      />
    </Container>
  );

  function clickInvisibleFileInput() {
    videoInputRef.current.click();
  }

  function handleUpload(e) {
    const file = e.target.files[0];
    const BYTES_PER_MB = 1000000;
    const fileSizeInMb = file.size / BYTES_PER_MB;
    if (fileSizeInMb > 20) {
      alert(
        "Video size is larger than 20MB. Please compress your video to 480, 720, or 1080p"
      );
    } else {
      const fileUrl = window.URL.createObjectURL(file);
      selectVideoFile(fileUrl);
    }
  }
}

export default connect(
  null,
  actions
)(UploadVideoButton);

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
