import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import NewComboCreationInterface from "./PostEditor/NewComboCreationInterface";

function Test() {
  return (
    <Container>
      <NewComboCreationInterface />
    </Container>
  );
}

export default connect()(Test);

const Container = styled.div`
  min-height: 100vh;
  background: var(--black-purple);
`;
