import React, { useEffect, useRef, useState } from "react";
import {
  doAddNewSection,
  doChangeSectionValue,
  doMergeSectionWithPreviousIndex,
  doRemoveSection,
  doSetLastFocusedSectionIndex,
  setCaretPosition
} from "./ducks/actions";

import ComboSelector from "./ComboSelector";
import EmbeddedCombo from "./EmbeddedCombo";
import Toolbar from "./Toolbar";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function inputRenderMapper(type) {
  const renderers = {
    TEXT: Input,
    TITLE: TitleInput,
    HEADER: HeaderInput
  };
  return renderers[type];
}

const actions = {
  addNewSection: doAddNewSection,
  removeSection: doRemoveSection,
  setIndex: doSetLastFocusedSectionIndex,
  changeSectionValue: doChangeSectionValue,
  mergeSectionWithPrevIndex: doMergeSectionWithPreviousIndex
};

function Section({
  addNewSection,
  removeSection,
  changeSectionValue,
  mergeSectionWithPrevIndex,
  setIndex,
  id,
  type,
  value,
  index,
  contentEditable,
  shouldAnimate
}) {
  const [inFocus, setInFocus] = useState(false);
  const inputRef = useRef();
  const content = getContent(type);

  useEffect(() => {
    // the problem is I want new sections to autofocus
    // but I only want the title to focus on first render
    if (inputRef.current) {
      inputRef.current.textContent = value;
      // inputRef.current.focus();
      if (id === -1) inputRef.current.focus();
    }
  }, [type]);

  return (
    <Container animationDelay={shouldAnimate ? index * 0.15 : -1}>
      <ToolbarWrapper>
        {!value && index !== 0 && <Toolbar index={index} disable={inFocus} />}
      </ToolbarWrapper>
      <ContentWrapper>{content}</ContentWrapper>
    </Container>
  );

  function handleKeyDown(e, index, isVideo) {
    const caretPosition = getCaretPosition(inputRef.current);
    if (e.key === "Enter") {
      e.preventDefault();
      addNewSection(index, caretPosition);
    }
    if (e.key === "Backspace") {
      if (!value || isVideo) {
        e.preventDefault();
        removeSection(index);
        if (index) moveCursorToEnd("section-" + (index - 1), !isVideo);
      } else if (value && caretPosition === 0) {
        e.preventDefault();
        mergeSectionWithPrevIndex(index);
      }
    }
    if (e.key === "ArrowUp") focusSectionByIndex(index - 1, caretPosition);
    if (e.key === "ArrowDown") focusSectionByIndex(index + 1, caretPosition);
    if (e.key === "ArrowLeft") {
      if (caretPosition === 0 && index) {
        e.preventDefault();
        moveCursorToEnd("section-" + (index - 1));
      }
    }

    if (e.key === "ArrowRight") {
      if (caretPosition === value.length) {
        e.preventDefault();
        focusSectionByIndex(index + 1);
      }
    }
  }

  function showToolTip() {
    const selectedText = window.getSelection();
    if (selectedText.type === "Range") {
    }
  }

  function getContent(type) {
    if (inputRenderMapper(type)) return getInputJSX(type);
    if (type === "COMBO_SELECTOR") {
      if (!value) return <ComboSelector index={index} />;
      else
        return (
          <EmbeddedCombo
            index={index}
            combo={value}
            onFocus={() => setIndex(index)}
            onKeyDown={e => handleKeyDown(e, index, true)}
            contentEditable={contentEditable}
          />
        );
    }
  }

  function getInputJSX(type) {
    const InputTag = inputRenderMapper(type);
    return (
      <InputTag
        contentEditable={contentEditable}
        autoComplete="off"
        id={"section-" + index}
        ref={inputRef}
        onDoubleClick={showToolTip}
        onMouseUp={showToolTip}
        value={value}
        onInput={e => changeSectionValue(index, e.target.textContent)}
        onFocus={() => {
          setIndex(index);
          setInFocus(true);
        }}
        onBlur={() => setInFocus(false)}
        onKeyDown={e => handleKeyDown(e, index)}
      />
    );
  }
}

function focusSectionByIndex(index, caretPosition) {
  const section = document.getElementById("section-" + index);
  if (section) section.focus();
}

export function moveCursorToEnd(id, shouldSetCursor = true) {
  const section = document.getElementById(id);
  if (section) {
    section.focus();
    const targetCursorPos = section.innerText.length;
    if (!shouldSetCursor) setCaretPosition(section, targetCursorPos);
  }
}

export default connect(null, actions)(Section);

const Container = styled.div`
  display: grid;
  grid-template-areas: "toolbar content";
  grid-template-columns: 50px 1fr;
  @media (max-width: 600px) {
    grid-template-areas: "content";
    grid-template-columns: 1fr;
  }
  ${({ animationDelay }) =>
    animationDelay !== -1 &&
    `
    animation: animateIn 0.8s ease forwards;
    transform: translateY(70px);
    opacity: 0;
    animation-delay: ${animationDelay}s;
  `}
  @keyframes animateIn {
    from {
      transform: translateY(70px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const ToolbarWrapper = styled.div`
  grid-area: toolbar;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  grid-area: content;
  padding: 0 1em;
  box-sizing: border-box;
  padding-right: 4em;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const Input = styled.div`
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 2px;
  color: white;
  box-sizing: border-box;
  padding-bottom: 1em;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
  }
  @media (max-width: 600px) {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 18px;
  }
  @media (min-width: 1000px) {
    font-size: 28px;
  }
`;

const TitleInput = styled(Input)`
  font-size: 46px;
  padding-bottom: 0.5em;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 28px;
  }
  @media (min-width: 1000px) {
    font-size: 60px;
  }
`;

const HeaderInput = styled(Input)`
  font-size: 32px;
  padding-bottom: 0.5em;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 22px;
  }
  @media (min-width: 1000px) {
    font-size: 50px;
  }
`;

const VideoTag = styled.video`
  width: 80%;
  margin-bottom: 1em;
  &:focus {
    outline: 3px solid var(--purple);
  }
`;

function setEndOfContenteditable(contentEditableElement) {
  var range, selection;
  if (document.createRange) {
    //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    selection = window.getSelection(); //get the selection object (allows you to change selection)
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range); //make the range you have just created the visible selection
  } else if (document.selection) {
    //IE 8 and lower
    range = document.body.createTextRange(); //Create a range (a range is a like the selection but invisible)
    range.moveToElementText(contentEditableElement); //Select the entire contents of the element with the range
    range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
    range.select(); //Select the range (make it the visible selection
  }
}

function getCaretPosition(editableDiv) {
  var caretPos = 0,
    sel,
    range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode == editableDiv) {
        caretPos = range.endOffset;
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    if (range.parentElement() == editableDiv) {
      var tempEl = document.createElement("span");
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      var tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      caretPos = tempRange.text.length;
    }
  }
  return caretPos;
}
