import styled from "@emotion/styled";

const SearchBar = styled.input`
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0.5em 0.3em 0.5em 1em;
  outline: none;
  border: none;

  background: rgba(255, 255, 255, 0.15);
  /* border: 2px solid white; */
  border-radius: 100px;
  font-size: 16px;
  color: var(--green);
  font-weight: bold;
  font-family: avenir, nunito;

  &::placeholder {
    font-family: avenir, nunito;
    font-weight: normal;
  }
  &:focus {
    border-color: var(--green);
  }
  @media (min-width: 1000px) {
    font-size: 20px;
    margin-left: 24px;
  }
`;

export default SearchBar;
