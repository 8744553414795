import {
  CHANGED_ACTIVE_CONTROLLER_FOR_CUSTOM_MAPPING_PAGE,
  CHANGED_GAME_FOR_CUSTOM_MAPPING_PAGE
} from "./types";

const initialState = {
  activeGameId: "5d5b6b21fe706ce02edf1a86",
  activeController: null
};

export default function customMappingsPageReducer(
  state = initialState,
  action
) {
  const { type, payload } = action;
  switch (type) {
    case CHANGED_GAME_FOR_CUSTOM_MAPPING_PAGE:
      return {
        ...state,
        activeGameId: payload.activeGameId,
        activeController: null
      };
    case CHANGED_ACTIVE_CONTROLLER_FOR_CUSTOM_MAPPING_PAGE: {
      return {
        ...state,
        activeController: payload.activeController
      };
    }
    default:
      return state;
  }
}
