const SHIELD_DROP_LAG = 11;
const SHIELD_GRAB_LOCKOUT_LAG = 4;
const JUMPSQUAT_LAG = 3;
const FRAMES_BEFORE_ROLL_IS_INVULNERABLE = 4;
const FRAMES_BEFORE_SPOT_DODGE_IS_INVULNERABLE = 3;

/*
  Notes: 

  All aerials are out of shield options with a jumpsquat penalty
  up smash and up special are out of shield options
  spot dodge roll are out of shield options
  grab is out of shield with a grab penaltya
*/

export default function getAttackOptions(defender, attacker, attackerMove) {
  const attackerAdvantage = getAttackersAdvantageFromMove(attackerMove);
  let options = [];
  options = options.concat(checkSmashes(defender, attackerAdvantage));
  options = options.concat(checkTilts(defender, attackerAdvantage));
  options = options.concat(checkAerials(defender, attackerAdvantage));
  options = options.concat(checkSpecials(defender, attackerAdvantage));
  options = options.concat(checkOtherOptions(defender, attackerAdvantage));

  return options.sort(sortOptionsByNetAdvantage);
}

function sortOptionsByNetAdvantage(l, r) {
  return l.defenderAdvantage - l.attackerAdvantage.advantage >
    r.defenderAdvantage - r.attackerAdvantage.advantage
    ? 1
    : -1;
}

function checkSmashes(defender, attackerAdvantage) {
  const options = [];
  ["up", "forward", "down"].forEach(direction => {
    const isOutOfShieldOption = direction === "up";
    let defenderAdvantage = getDefendersAdvantageToAttack(
      defender.smashes[direction],
      isOutOfShieldOption
    );
    if (defenderAdvantage < attackerAdvantage.advantage)
      options.push({
        move: `${direction} smash`,
        defenderAdvantage,
        attackerAdvantage
      });
  });
  return options;
}

function checkSpecials(defender, attackerAdvantage) {
  const options = [];

  ["up", "forward", "neutral", "down"].forEach(direction => {
    const isOutOfShieldOption = direction === "up";
    const move = defender.specials[direction];
    if (!move) return;
    const defenderAdvantage = getDefendersAdvantageToAttack(
      move,
      isOutOfShieldOption
    );
    if (defenderAdvantage < attackerAdvantage.advantage)
      options.push({
        move: `${direction} special`,
        defenderAdvantage,
        attackerAdvantage
      });
  });
  return options;
}

function checkTilts(defender, attackerAdvantage) {
  const options = [];

  ["up", "forward", "down"].forEach(direction => {
    let defenderAdvantage = getDefendersAdvantageToAttack(
      defender.tilts[direction]
    );
    if (defenderAdvantage < attackerAdvantage.advantage)
      options.push({
        move: `${direction} tilt`,
        defenderAdvantage,
        attackerAdvantage
      });
  });
  return options;
}

function checkAerials(defender, attackerAdvantage) {
  const options = [];

  ["up", "down", "forward", "back", "neutral"].forEach(direction => {
    let defenderAdvantage = getDefendersAdvantageToAerial(
      defender.aerials[direction]
    );
    if (defenderAdvantage < attackerAdvantage.advantage)
      options.push({
        move: `${direction} air`,
        defenderAdvantage,
        attackerAdvantage
      });
  });
  return options;
}

function checkOtherOptions(defender, attackerAdvantage) {
  const options = [];
  let defenderAdvantage = null;
  // grab
  defenderAdvantage = getDefendersAdvantageToGrab(defender.grab);
  if (defenderAdvantage < attackerAdvantage.advantage)
    options.push({
      move: "grab",
      defenderAdvantage,
      attackerAdvantage
    });

  // jab
  defenderAdvantage = getDefendersAdvantageToAttack(defender.jab);
  if (defenderAdvantage < attackerAdvantage.advantage)
    options.push({
      move: "jab",
      defenderAdvantage,
      attackerAdvantage
    });

  // roll
  if (FRAMES_BEFORE_ROLL_IS_INVULNERABLE < attackerAdvantage.advantage)
    options.push({
      move: "roll",
      defenderAdvantage: FRAMES_BEFORE_ROLL_IS_INVULNERABLE,
      attackerAdvantage
    });

  // spot dodge
  if (FRAMES_BEFORE_SPOT_DODGE_IS_INVULNERABLE < attackerAdvantage.advantage)
    options.push({
      move: "spot dodge",
      defenderAdvantage: FRAMES_BEFORE_SPOT_DODGE_IS_INVULNERABLE,
      attackerAdvantage
    });

  return options;
}

function getDefendersAdvantageToAttack(move, outOfShieldOption) {
  return move.startup[0] + (outOfShieldOption ? 0 : SHIELD_DROP_LAG);
}

function getDefendersAdvantageToGrab(move) {
  return move.startup[0] + SHIELD_GRAB_LOCKOUT_LAG;
}

function getDefendersAdvantageToAerial(move) {
  return move.startup[0] + JUMPSQUAT_LAG;
}

// attackers advantage

function getAttackersAdvantageFromMove(move) {
  if (move.type === "ATTACK") {
    const totalFrames = move.totalFrames[0];
    const frameOfLastHit = move.startup.reverse()[0];
    const shieldStun = move.shieldStun[0];
    return {
      advantage: totalFrames - frameOfLastHit - shieldStun,
      rationale: `The move hitting your shield has ${totalFrames -
        frameOfLastHit} frames of lag, but you're stunned for ${shieldStun} frames`
    };
  }
  if (move.type === "AERIAL") {
    const landingLag = move.landingLag;
    const shieldStun = move.shieldStun[0];

    return {
      advantage: landingLag - shieldStun,
      rationale: `The move hitting your shield has ${landingLag} frames of landing lag, but your stunned for ${shieldStun} frames`
    };
  }
  if (move.type === "PROJECTILE") {
    const totalFrames = move.totalFrames[0];
    const frameOfLastHit = move.startup.reverse()[0];
    const shieldStun = move.shieldStun[0];
    const shieldLag = move.shieldLag[0];
    return {
      advantage: totalFrames - frameOfLastHit - shieldStun - shieldLag,
      rational: `The move hitting your shield has ${totalFrames -
        frameOfLastHit} frames of end lag, but you're in ${shieldLag} frames of shield lag, and then another ${shieldStun} frames of shield stun`
    };
  }
  if (move.type === "GRAB") {
    return {
      advantage: move.totalFrames[0] - move.startup[0],
      rationale: `The move hitting your shield has ${move.totalFrames[0] -
        move.startup[0]} frames of end lag`
    };
  }

  throw new Error("unknown type");
}
