import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";

function TotalGames() {
  return (
    <Container>
      <h1>
        1 <br />
        game
      </h1>
    </Container>
  );
}

export default connect()(TotalGames);

const Container = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
  margin: 0 1em;
`;
