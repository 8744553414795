export const ADDED_NEW_SECTION = "ADDED_NEW_SECTION";
export const REMOVED_SECTION = "REMOVED_SECTION";
export const SET_LAST_FOCUSED_SECTION_INDEX = "SET_LAST_FOCUSED_SECTION_INDEX";
export const ACTIVATED_DRAFT_MANAGER = "ACTIVATED_DRAFT_MANAGER";
export const DEACTIVATED_DRAFT_MANAGER = "DEACTIVATED_DRAFT_MANAGER";
export const SELECTED_GUIDE_TO_EDIT = "SELECTED_GUIDE_TO_EDIT";
export const ACTIVATED_PUBLISH_INTERFACE = "ACTIVATED_PUBLISH_INTERFACE";
export const DEACTIVATED_PUBLISH_INTERFACE = "DEACTIVATED_PUBLISH_INTERFACE";
export const SELECTED_GAME_TAG_FOR_GUIDE = "SELECT_GAME_TAG_FOR_GUIDE";
export const DESELECTED_GAME_TAG_FOR_GUIDE = "DESELECT_GAME_TAG_FOR_GUIDE";
export const CHANGED_SECTION_VALUE = "CHANGED_SECTION_VALUE";
export const CHANGED_SECTION_TYPE = "CHANGED_SECTION_TYPE";
export const CHANGED_GUIDE_TITLE = "CHANGED_GUIDE_TITLE";
export const CHANGED_GUIDE_PREVIEW_TITLE = "CHANGED_GUIDE_PREVIEW_TITLE";
export const CHANGED_GUIDE_PREVIEW_SUBTITLE = "CHANGED_GUIDE_PREVIEW_SUBTITLE";
export const STARTED_SAVING_GUIDE = "STARTED_SAVING_GUIDE";
export const FINISHED_SAVING_GUIDE = "FINISHED_SAVING_GUIDE";
export const STARTED_FETCHING_GUIDES_BY_USER = 'STARTED_FETCHING_GUIDES_BY_USER'
export const FINISHED_FETCHING_GUIDES_BY_USER = 'FINISHED_FETCHING_GUIDES_BY_USER'
export const STARTED_CREATING_NEW_GUIDE_DRAFT = 'STARTED_CREATING_NEW_GUIDE_DRAFT'
export const FINISHED_CREATING_NEW_GUIDE_DRAFT = 'FINISHED_CREATING_NEW_GUIDE_DRAFT'
export const STARTED_FETCHING_GUIDE = 'STARTED_FETCHING_GUIDE'
export const FINISHED_FETCHING_GUIDE = 'FINISHED_FETCHING_GUIDE'
export const STARTED_DELETING_GUIDE = 'STARTED_DELETING_GUIDE'
export const FINISHED_DELETING_GUIDE = 'FINISHED_DELETING_GUIDE'
export const CHANGED_PUBLISHED_STATUS = 'CHANGED_PUBLISHED_STATUS'
export const STARTED_UPLOADING_IMAGE_FOR_GUIDE = 'STARTED_UPLOADING_IMAGE_FOR_GUIDE'
export const FINISHED_UPLOADING_IMAGE_FOR_GUIDE = 'FINISHED_UPLOADING_IMAGE_FOR_GUIDE'