import { CHANGED_GAME_FOR_DISCOVER_PAGE, FINISHED_FETCHING_GUIDES_BY_GAME } from "./types";
import { BACKEND_URL } from "../../../App";
import Axios from "axios";

export function doChangeGameForDiscoverPage(activeGameId) {
  return {
    type: CHANGED_GAME_FOR_DISCOVER_PAGE,
    payload: {
      activeGameId
    }
  };
}

export function doFetchGuidesByGame(gameId) {
  return async dispatch => {
    if (!gameId) return;
    const url = `${BACKEND_URL}/guides/game/${gameId}`;
    const response = await Axios.get(url);
    dispatch({
      type: FINISHED_FETCHING_GUIDES_BY_GAME,
      payload: {
        guides: response.data
      }
    });
  };
}
