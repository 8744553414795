import { useEffect, useState } from "react";
// Make Text Areas Auto Resizable Snippet
export function makeTextAreasAutoResizeable() {
  var textareas = getTextAreas();
  for (var i = 0; i < textareas.length; i++) {
    makeTextAreaAutoResiable(textareas[i]);
  }
}

function getTextAreas() {
  return document.getElementsByTagName("textarea");
}

function makeTextAreaAutoResiable(textarea) {
  textarea.setAttribute(
    "style",
    "height:" + textarea.scrollHeight + "px;overflow-y:hidden;"
  );
  textarea.removeEventListener("input", OnInput, false);
  textarea.addEventListener("input", OnInput, false);
}

function OnInput() {
  this.style.height = "auto";
  this.style.height = this.scrollHeight + "px";
}

export function debounce(callback, waitTime) {
  let timeout;
  return (e) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => callback(e), waitTime);
  };
}

export function useIsMobile() {
  return isMobile();
}

export function isMobile() {
  return window.innerWidth <= 600;
}

export function useScrollPosition() {
  const [scrollPos, setScrollPos] = useState(0);
  const debouncedSetScrollPos = () => setScrollPos(window.scrollY);
  useEffect(() => {
    window.addEventListener("scroll", debouncedSetScrollPos);
    return () => window.removeEventListener("scroll", debouncedSetScrollPos);
  });
  return scrollPos;
}
