import {
  SELECTED_GAME_FOR_NEW_GUIDE,
  SELECTED_CHARACTER_FOR_NEW_GUIDE,
  RESET_NEW_GUIDE,
  ADDED_COMBO_TO_NEW_GUIDE,
  REMOVED_COMBO_FROM_NEW_GUIDE,
  EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE,
  EDITED_TITLE_FOR_NEW_GUIDE,
  EDITED_SECTION_HEADER_FOR_NEW_GUIDE,
  FINISHED_FETCHING_USER_COMBOS,
  FINISHED_FETCHING_USER_FOR_PROFILE_PAGE
} from "./types";

const initialState = {
  displayName: "",
  photoUrl: null,
  userType: null,

  stats: {
    views: 187000
    // numCombos: 50,
    // numGames: 1,
    // numGuides: 9
  },

  guides: [
    {
      title: "Beginner Pikachu Tutorial",
      coverImage:
        "http://www.geek.com/wp-content/uploads/2018/10/pikachu-smash-ultimate.png",
      id: 0
    },
    {
      title: "Intermediate Pikachu Tutorial",
      coverImage:
        "http://www.geek.com/wp-content/uploads/2018/10/pikachu-smash-ultimate.png",
      id: 1
    }
  ],

  combos: [],

  newGuide: {
    gameId: null,
    characterId: null,
    title: null,
    sections: []
  }
};

export default function profilePageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FINISHED_FETCHING_USER_FOR_PROFILE_PAGE: {
      return {
        ...state,
        userType: payload.userType,
        favoriteCombos: payload.favoriteCombos,
        displayName: payload.displayName,
        photoUrl: payload.photoUrl,
        userType: payload.userType
      };
    }

    case SELECTED_GAME_FOR_NEW_GUIDE:
      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          gameId: payload.game._id
        }
      };
    case SELECTED_CHARACTER_FOR_NEW_GUIDE:
      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          characterId: payload.character._id
        }
      };
    case RESET_NEW_GUIDE:
      return {
        ...state,
        newGuide: { ...initialState.newGuide }
      };
    case ADDED_COMBO_TO_NEW_GUIDE:
      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          sections: [
            ...state.newGuide.sections,
            {
              combo: payload.combo,
              description: ""
            }
          ]
        }
      };
    case REMOVED_COMBO_FROM_NEW_GUIDE: {
      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          sections: payload.sections
        }
      };
    }
    case EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE: {
      const sections = state.newGuide.sections;
      sections[payload.sectionIndex] = {
        ...sections[payload.sectionIndex],
        description: payload.description
      };

      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          sections: sections
        }
      };
    }
    case EDITED_SECTION_HEADER_FOR_NEW_GUIDE: {
      const sections = state.newGuide.sections;
      sections[payload.sectionIndex] = {
        ...sections[payload.sectionIndex],
        header: payload.header
      };

      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          sections: sections
        }
      };
    }
    case EDITED_TITLE_FOR_NEW_GUIDE: {
      return {
        ...state,
        newGuide: {
          ...state.newGuide,
          title: payload.title
        }
      };
    }
    case FINISHED_FETCHING_USER_COMBOS: {
      return {
        ...state,
        combos: payload.combos
      };
    }
    default:
      return state;
  }
}
