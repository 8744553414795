import React, { useEffect } from "react";

import GuideContent from "../GuidePage/GuideContent";
import { connect } from "react-redux";
import { doFetchGuideForStandalonePage } from "./ducks/actions";
import styled from "@emotion/styled";

const select = ({ singleGuidePage }) => ({
  sections: singleGuidePage.guide.sections,
  previewImage: singleGuidePage.guide.metaData.previewImage,
  guideId: singleGuidePage.guide._id
});

const actions = {
  fetchGuide: doFetchGuideForStandalonePage
};

function SingleGuidePage({ fetchGuide, guideId, previewImage, sections }) {
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = getParamsFromURL();
    fetchGuide(params.guideId);
  }, []);

  return (
    <Container>
      <GuideHeaderImage url={previewImage} />
      <GuideContent sections={sections} key={guideId} />
    </Container>
  );
}

export default connect(select, actions)(SingleGuidePage);

const Container = styled.div`
  background-color: var(--black-purple);
  padding: 0 0 2em 0;
  @media (max-width: 600px) {
    padding: 1em 0;
  }
  position: relative;
`;

function getParamsFromURL() {
  const vars = {};
  const url = window.location.href;
  url.replace(
    /[?&]+([^=&]+)=([^&]*)/gi,
    (m, key, value) => (vars[key] = value)
  );
  return vars;
}

const GuideHeaderImage = styled.div`
  width: 100%;
  padding-bottom: 50.65%;
  background: linear-gradient(
      0deg,
      var(--black-purple),
      rgba(7, 10, 41, 1),
      rgba(7, 10, 41, 0.95),
      rgba(7, 10, 41, 0.95),
      rgba(7, 10, 41, 0.9),
      rgba(7, 10, 41, 0.8),
      rgba(0, 0, 0, 0.9)
    ),
    url(${({ url }) => url});
  background-position: center center;
  background-size: cover;
  margin-bottom: -25em;
  @media (max-width: 600px) {
    /* background: linear-gradient(
        0deg,
        var(--black-purple),
        rgba(7, 10, 41, 0.9),
        rgba(7, 10, 41, 0.6),
        rgba(0, 0, 0, 0.6)
      ),
      url(${({ url }) => url}); */
    display: none;
    /* padding-bottom: 40.75%;
    margin-bottom: -3em; */
  }
  @media(min-width: 1000px) {
    margin-bottom: -35em;
  }
`;
