import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { doActivatePublishInterface } from "./ducks/actions";

const select = ({ guidePage }) => ({
  canPublish: guidePage.sections[0] && !!guidePage.sections[0].value,
  isPublished: guidePage.metaData.isPublished
});

const actions = {
  activatePublishInterface: doActivatePublishInterface
};

function SaveOrPublishButton({
  activatePublishInterface,
  canPublish,
  isPublished
}) {
  if (!canPublish) return null;
  return (
    <Container onClick={activatePublishInterface}>
      {isPublished ? "change publish status" : "publish"}
    </Container>
  );
}

export default connect(
  select,
  actions
)(SaveOrPublishButton);

const Container = styled.div`
  padding: 0.5em 0.5em 0.5em 1em;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  background: var(--purple);
`;
