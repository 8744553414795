import { ToggleOn } from "@styled-icons/bootstrap/ToggleOn";
import { ToggleOff } from "@styled-icons/bootstrap/ToggleOff";
import styled from "@emotion/styled";

export default function Toggle({ isOn, handleToggle }) {
  const Icon = isOn ? ToggleOn : ToggleOff;
  return (
    <Container>
      <Icon
        onClick={handleToggle}
        size="30"
        color={isOn ? "var(--green)" : "white"}
      />
    </Container>
  );
}

const Container = styled.div`
  cursor: pointer;
`;
