import React from "react";
import styled from "@emotion/styled";

export default function ToolTip({ message, children, ...props }) {
  return (
    <Container {...props}>
      {children}
      <Message className="tooltip-message">
        <div>{message}</div>
        <Triangle />
      </Message>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  @media (min-width: 600px) {
    &:hover {
      > span.tooltip-message {
        opacity: 1;
        z-index: 5000;
        transition-delay: 1s;
      }
    }
  }
`;

const Message = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  opacity: 0;
  z-index: -1;
  transform: translate(-50%, calc(-100% - 5px));
  background-color: var(--purple);
  border-radius: 4px;
  color: white;
  padding: 0.2em 0.5em;
  font-size: 12px;
  transition: 0.4s ease;
  box-shadow: var(--shadow-1);
  width: 130px;
  text-align: center;
`;

const Triangle = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% - 1px));
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--purple);
  z-index: 5001;
  box-shadow: var(--shadow-1);
`;
