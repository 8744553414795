import DarkSelect, { DarkSelectMulti } from "../../../2-utils/DarkSelect";
import React, { useEffect, useState } from "react";
import {
  doFetchCharactersFromGame,
  doSelectCharacterForNewCombo,
  doSelectPrimaryCharacterForNewCombo
} from "../ducks/actions";

import Collapsible from "../../../2-utils/Collapsible";
import GamePreviewSquare from "../../HomePage/GameSelector/GamePreviewSquare";
import SelectCharacterForModal from "../../../2-utils/SelectCharacterForModal";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  characterId: postEditor.characterId,
  selectedGameId: postEditor.gameId
});

const actions = {
  selectCharacter: doSelectPrimaryCharacterForNewCombo,
  fetchCharacters: doFetchCharactersFromGame
};

function SelectPrimaryCharacterForPostEditor({
  characters,
  characterId,
  selectCharacter,
  selectedGameId
}) {
  const selectedCharacter = characters.find(char => char._id === characterId);
  return (
    <div
      style={{
        opacity: selectedGameId ? 1 : 0.2,
        pointerEvents: selectedGameId ? "auto" : "none"
      }}
    >
      <h3>* Select Attacker</h3>
      <DarkSelect
        placeholder="Select attacker..."
        options={convertCharactersToSelectFormat(characters)}
        value={convertSingleCharacterToSelectFormat(selectedCharacter)}
        onChange={newOption => {
          selectCharacter(newOption);
        }}
      />
    </div>
  );
}

export default connect(select, actions)(SelectPrimaryCharacterForPostEditor);

function convertCharactersToSelectFormat(characters) {
  return characters.map(convertSingleCharacterToSelectFormat);
}

function convertSingleCharacterToSelectFormat(character) {
  if (!character) return null;
  return { value: character, label: character.name };
}
