import { Button, GreenButton } from "../../../2-utils/buttons";
import { Card, CardLabel } from "../../../2-utils/Card";
import React, { useState } from "react";
import {
  doAddCharacterToResultFilters,
  doClearResultFilters,
  doFetchCombos,
  doRemoveCharacterFromResultFilters
} from "../ducks/actions";

import { BeatLoader } from "react-spinners";
import Checkbox from "./Checkbox";
import FilterCharacters from "./FilterCharacters";
import FilterPercentRange from "./FilterPercentRange";
import FilterPrimaryCharacter from "./FilterPrimaryCharacter";
import FilterTags from "./FilterTags";
import Fuse from "fuse.js";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const select = ({ resultsPage }) => ({
  gameId: resultsPage.gameId,
  characters: resultsPage.characters,
  filteredCharacters: resultsPage.filteredCharacters,
  percentRange: resultsPage.percentRange
});

const actions = {
  addCharacterToFilters: doAddCharacterToResultFilters,
  removeCharacterFromFilters: doRemoveCharacterFromResultFilters,
  clearResultFilters: doClearResultFilters,
  fetchCombos: doFetchCombos
};

function FilterManager({
  characters,
  gameId,
  hideMobileFilters,
  filteredCharacters,
  addCharacterToFilters,
  removeCharacterFromFilters,
  clearResultFilters,
  percentRange,
  fetchCombos
}) {
  const isMobile = useIsMobile();
  const [query, setQuery] = useState("");
  const charactersFilteredByQuery = getCharactersThatMatchQuery(
    characters,
    query
  );

  return (
    <Container>
      <MiniCard>
        <CardLabel>Attacker</CardLabel>
        <FilterPrimaryCharacter />
      </MiniCard>
      <MiniCard>
        <CardLabel>Defender(s)</CardLabel>
        <FilterCharacters />
      </MiniCard>

      <MiniCard>
        <CardLabel>Combo Type</CardLabel>
        <FilterTags />
      </MiniCard>
      <MiniCard>
        <CardLabel style={{ display: "flex" }}>
          Percent{" "}
          <b style={{ color: "var(--green)", marginLeft: "auto" }}>
            {percentRange[0]}-{percentRange[1]}%
          </b>
        </CardLabel>
        <FilterPercentRange />
      </MiniCard>
      {isMobile && (
        <Button
          onClick={() => {
            fetchCombos(gameId);
            isMobile && hideMobileFilters();
          }}
        >
          Apply Filters
        </Button>
      )}
    </Container>
  );

  function getCharactersThatMatchQuery(characters, query) {
    if (query === "") return characters;
    const options = {
      keys: ["name"],
      threshold: 0.2
    };
    const fuse = new Fuse(characters, options);
    return fuse.search(query);
  }
}

export default connect(select, actions)(FilterManager);

const Container = styled.div`
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  padding-bottom: 1em;
  border-radius: 8px;
  @media (max-width: 600px) {
    border: none;
    opacity: 1;
    animation: none;
    margin-top: 0;
    box-shadow: none;
  }
`;

const MiniCard = styled.div`
  /* background-color: rgba(255, 255, 255, 0.1);
  border: none; */
  margin: 24px 0;
  @media (max-width: 600px) {
    padding: 0 0.5em;
    border-top: none;
    border-left: none;
    border-bottom: none;
    padding-bottom: 15px;
    border-radius: 0;
    box-shadow: none;
    border-right: none;
  }
`;

const Scrollable = styled.div`
  max-height: calc(100vh - 150px);
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 600px) {
    max-height: inherit;
  }
`;

const ClearButton = styled.div`
  position: absolute;
  top: -2em;
  right: 1em;
  font-size: 14px;
  font-weight: bold;
  color: white;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
