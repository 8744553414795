import DarkSelect, { DarkSelectMulti } from "../../../2-utils/DarkSelect";

import React from "react";
import { connect } from "react-redux";
import { doSetResultsPageFilters } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => ({
  characters: resultsPage.characters,
  filteredCharacters: resultsPage.filteredCharacters
});

const actions = {
  setResultsPageFilters: doSetResultsPageFilters
  // addCharacterToFilters: doAddCharacterToResultFilters,
  // removeCharacterFromFilters: doRemoveCharacterFromResultFilters,
  // clearResultFilters: doClearResultFilters
};

function FilterCharacters({
  characters,
  filteredCharacters,
  setResultsPageFilters
}) {
  return (
    <DarkSelect
      placeholder="Select defender..."
      options={convertCharactersToSelectFormat(characters)}
      value={convertCharactersToSelectFormat(filteredCharacters)}
      onChange={newOption => {
        setResultsPageFilters(newOption ? [newOption] : []);
      }}
    />
  );
}

export default connect(select, actions)(FilterCharacters);

const Container = styled.div``;

function convertCharactersToSelectFormat(characters) {
  return characters.map(convertSingleCharacterToSelectFormat);
}

function convertSingleCharacterToSelectFormat(character) {
  if (!character) return {};
  return { value: character, label: character.name };
}
