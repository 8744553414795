import { DeleteForever } from "styled-icons/material";
import React from "react";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import { doActivateConfirmationModal } from "../../ConfirmationModal/ducks/actions";
import { doDeleteCombo } from "../ducks/actions";
import styled from "@emotion/styled";

const actions = {
  activateConfirmationModal: doActivateConfirmationModal,
  deleteCombo: doDeleteCombo
};

function DeleteComboButton({ activateConfirmationModal, deleteCombo }) {
  return (
    <ToolTip message="Delete combo">
      <DeleteIcon
        onClick={() =>
          activateConfirmationModal(
            deleteCombo,
            "This will delete this combo forever"
          )
        }
      />
    </ToolTip>
  );
}

export default connect(null, actions)(DeleteComboButton);

const DeleteIcon = styled(DeleteForever)`
  transition: 0.2s ease;
  margin: 0 1em;
  cursor: pointer;
  width: 25px;
  color: white;
  &:hover {
    color: #f44336;
  }
`;
