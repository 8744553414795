import React from "react";
import styled from "@emotion/styled";

export default function SelectMove({ attackerMove, setAttackerMove }) {
  const smashes = ["up smash", "down smash", "forward smash"];
  const tilts = ["up tilt", "down tilt", "forward tilt"];
  const aerials = [
    "up aerial",
    "down aerial",
    "forward aerial",
    "back aerial",
    "neutral aerial"
  ];
  const specials = [
    "up special",
    "down special",
    "forward special",
    "neutral special"
  ];
  const other = ["jab", "dash"];

  return (
    <Container>
      {/* Smashes */}
      <Row>
        <RowName>smashes:</RowName>
        {smashes.map(option => (
          <Option
            active={attackerMove === option}
            onClick={() => setAttackerMove(option)}
          >
            {getMoveDirection(option)}
          </Option>
        ))}
      </Row>

      {/* Specials */}
      <Row>
        <RowName>specials:</RowName>
        {specials.map(option => (
          <Option
            active={attackerMove === option}
            onClick={() => setAttackerMove(option)}
          >
            {getMoveDirection(option)}
          </Option>
        ))}
      </Row>

      {/* Tilts */}
      <Row>
        <RowName>tilts:</RowName>
        {tilts.map(option => (
          <Option
            active={attackerMove === option}
            onClick={() => setAttackerMove(option)}
          >
            {getMoveDirection(option)}
          </Option>
        ))}
      </Row>

      {/* Aerials */}
      <Row>
        <RowName>aerials:</RowName>
        {aerials.map(option => (
          <Option
            active={attackerMove === option}
            onClick={() => setAttackerMove(option)}
          >
            {getMoveDirection(option)}
          </Option>
        ))}
      </Row>

      {/* Other */}
      <Row>
        <RowName>other:</RowName>
        {other.map(option => (
          <Option
            active={attackerMove === option}
            onClick={() => setAttackerMove(option)}
          >
            {option}
          </Option>
        ))}
      </Row>
    </Container>
  );
}

function getMoveDirection(move) {
  return move.split(" ")[0];
}

const Container = styled.div``;

const Row = styled.div`
  display: flex;
  margin: 0.5em 0;
`;

const Option = styled.div`
  margin: 0 0.5em;
  padding: 0.05em 0.5em;
  width: 50px;
  text-align: center;
  border-radius: 4px;
  background-color: ${({ active }) =>
    active ? "var(--green)" : "rgba(255,255,255,0.2)"};
  color: black;
  box-shadow: var(--shadow-1);
  font-size: 14px;
  cursor: pointer;
  @media (max-width: 600px) {
    font-size: 12px;
    width: 35px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const RowName = styled.div`
  width: 60px;
  font-weight: bold;
  font-size: 14px;
`;
