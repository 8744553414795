import { buildAttack, buildAerial, buildGrab, buildProjectile } from "../utils";

const smashes = {
  up: buildAttack({
    startup: [9],
    totalFrames: [39],
    shieldStun: [10],
    other: { invulnerable: [9, 12] }
  }),
  down: buildAttack({
    startup: [5, 14],
    totalFrames: [43],
    shieldStun: [7, 8]
  }),
  forward: buildAttack({
    startup: [15],
    totalFrames: [47],
    shieldStun: [12, 10]
  })
};

const tilts = {
  up: buildAttack({
    startup: [5],
    totalFrames: [29],
    shieldStun: [6]
  }),
  down: buildAttack({
    startup: [5],
    totalFrames: [27],
    shieldStun: [6, 7]
  }),
  forward: buildAttack({
    startup: [5],
    totalFrames: [25],
    shieldStun: [7]
  })
};

const aerials = {
  up: buildAerial({
    startup: [3],
    totalFrames: [45],
    shieldStun: [3],
    landingLag: 6,
    autoCancelWindow: [[1, 2], [39, 999]]
  }),
  down: buildAerial({
    startup: [5, 7, 9, 11, 13, 23],
    totalFrames: [37],
    shieldStun: [2, 3, 3],
    landingLag: 15,
    autoCancelWindow: [[1 - 4], [33, 999]]
  }),
  forward: buildAerial({
    startup: [16],
    totalFrames: [59],
    shieldStun: [5],
    landingLag: 17,
    autoCancelWindow: [[1, 2], [43, 999]]
  }),
  back: buildAerial({
    startup: [6],
    totalFrames: [33],
    shieldStun: [4, 3],
    landingLag: 6,
    autoCancelWindow: [[1, 5], [19, 999]]
  }),
  neutral: buildAerial({
    startup: [3],
    totalFrames: [45],
    shieldStun: [4, 3],
    landingLag: 6,
    autoCancelWindow: [[1, 2], [39, 999]]
  })
};

const specials = {
  up: buildAttack({
    startup: [3, 7, 9, 11, 13, 15, 17],
    // deal with landing lag here
    landingLag: 30,
    totalFrames: 47, // temp solution
    shieldStun: [6, 2, 2, 2, 2, 2, 4]
  }),
  // deal with later
  down: null,
  forward: buildAttack({
    startup: [12],
    totalFrames: [35],
    shieldStun: [7]
  }),
  neutral: buildProjectile({
    startup: [17],
    totalFrames: [49],
    shieldStun: [3, 2],
    shieldLag: [6, 5]
  })
};

const dashAttack = buildAttack({
  startup: [6],
  totalFrames: [37],
  shieldStun: [14, 11]
});

const jab = buildAttack({
  startup: [2],
  totalFrames: [5],
  shieldStun: [3]
});

const grab = buildGrab({ startup: [6], totalFrames: [34] });

export default {
  name: "Mario",
  smashes,
  tilts,
  specials,
  aerials,
  dashAttack,
  jab,
  grab
};
