import { DarkSelectMulti } from "../../../2-utils/DarkSelect";
import React from "react";
import { connect } from "react-redux";
import { doChangeResultsPageTags } from "../ducks/actions";
import styled from "@emotion/styled";

const tagOptions = [
  "True Combo",
  "Edgeguard",
  "String",
  "Read",
  "For Fun",
  "Ledge Trap",
  "Tech Chase"
];

const select = ({ resultsPage }) => ({
  selectedTags: resultsPage.selectedTags
});

const actions = {
  changeTags: doChangeResultsPageTags
};

function FilterTags({ selectedTags, changeTags }) {
  return (
    <DarkSelectMulti
      placeholder="Select combo type..."
      options={convertToSelectFormat(tagOptions)}
      value={convertToSelectFormat(selectedTags)}
      onChange={newTags => changeTags((newTags || []).map(tag => tag.value))}
    />
  );
}

export default connect(select, actions)(FilterTags);

const Container = styled.div``;

function convertToSelectFormat(labels) {
  return labels.map(label => ({ value: label, label: label }));
}
