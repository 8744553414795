import AddComment from "./AddComment";
import Comment from "./Comment";
import React from "react";
import { connect } from "react-redux";
import { doRemoveComment } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ resultsPage, userInfo }) => ({
  comments: resultsPage.comments,
  userId: userInfo._id,
  authenticated: userInfo.authenticated
});

const actions = {
  removeComment: doRemoveComment
};

function CommentsManager({ comments, removeComment, userId, authenticated }) {
  return (
    <Container>
      <Header>{comments.length} Comments</Header>
      {authenticated && <AddComment />}

      {comments.map(commentData => (
        <Comment
          {...commentData}
          key={commentData._id}
          isOwner={commentData.authorData[0]._id === userId}
          onDelete={() => removeComment(commentData._id)}
        />
      ))}
    </Container>
  );
}

export default connect(select, actions)(CommentsManager);

const Container = styled.div`
  background-color: var(--black-purple);
`;

const Header = styled.h3`
  color: white;
  margin: 0;
  padding: 1em;
  padding-top: 0;
  @media (max-width: 600px) {
    padding: 0;
  }
`;
