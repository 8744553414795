import React, { useState } from "react";

import styled from "@emotion/styled";

export default function TextInput({
  onChange,
  placeholder,
  IconJSX,
  value,
  ...props
}) {
  const [isFocused, setIsFocused] = useState(false);
  const placeholderOffset = "";
  const isActive = value || isFocused;
  const modifiedPlaceholder = isActive ? "" : placeholderOffset + placeholder;

  return (
    <Container>
      <IconWrapper active={isActive}>
        <IconJSX size={isActive ? "12" : "18"} />
        {isActive && (
          <div style={{ fontSize: "11px", marginLeft: "2px" }}>
            {placeholder}
          </div>
        )}
      </IconWrapper>

      <Input
        placeholder={modifiedPlaceholder}
        onChange={onChange}
        name="hiya"
        autoComplete="off"
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        {...props}
      />

      <Line active={isActive} />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  margin: 1.5em 0 2em 0;
  width: calc(100% - 1em);
  box-sizing: border-box;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 5px;
  transform: ${({ active }) => active && "translateY(-20px)"};
  opacity: ${({ active }) => (active ? "0.6" : "1")};
  transition: 0.3s ease;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  color: var(--green);
  padding: 0;
  font-weight: bold;
  font-family: avenir, nunito;
  &::placeholder {
    color: rgba(255, 255, 255, 0.7);
    font-weight: normal;
    padding-left: 22px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  &:after {
    content: "";
    transition: 0.5s ease;
    width: ${({ active }) => (active ? "100%" : "0%")};
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
