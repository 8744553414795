import React from "react";
import { connect } from "react-redux";
import { getDifficulty } from "../ResultsPage/ducks/actions";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => ({
  currentCombo: resultsPage.posts[resultsPage.activePostIndex]
});

function AdditionalComboInfo({ currentCombo }) {
  const percentRange = currentCombo.percentRange || [0, 0];
  const difficulty = getDifficulty(currentCombo);
  const tagString = capitalizeFirstLetter(currentCombo.tags.join(" / "));
  return (
    <Container>
      <b>{tagString}</b> that works from{" "}
      <b>
        {percentRange[0]} to {percentRange[1]}%
      </b>{" "}
      and is likely <b>{difficulty.toLowerCase()}</b> to pull off.
    </Container>
  );
}

export default connect(select)(AdditionalComboInfo);

const Container = styled.div`
  font-size: 20px;
  color: white;
  font-weight: bold;
  margin-left: auto;
  font-weight: normal;

  @media (max-width: 600px) {
    font-size: 16px;
  }

  b {
    color: var(--green);
    font-weight: normal;
  }
`;

function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
