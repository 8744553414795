import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { MoonAnimation } from "../../2-utils/loadingAnimations";
import { doCreateNewGuideDraft } from "./ducks/actions";

const select = ({ guidePage }) => ({
  loading: guidePage.isCreatingNewDraft
});

const actions = {
  createNewGuideDraft: doCreateNewGuideDraft
};

function NewGuideButton({ createNewGuideDraft, loading }) {
  return (
    <Container
      loading={loading}
      onClick={e => {
        e.stopPropagation();
        !loading && createNewGuideDraft();
      }}
    >
      {loading ? <MoonAnimation size={15} /> : "new"}
    </Container>
  );
}

export default connect(
  select,
  actions
)(NewGuideButton);

const Container = styled.div`
  margin-left: auto;
  margin-right: 1em;
  border-radius: ${({ loading }) => (loading ? 50 : 8)}px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? "none" : "initial")};
  border: 2px solid var(--purple);
  font-size: 12px;
  box-sizing: border-box;
  cursor: pointer;
  transition: width 0.4s ease, opacity 0.2s ease;
  width: ${({ loading }) => (loading ? 35 : 50)}px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    border-color: var(--green);
  }
`;
