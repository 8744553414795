import React, { useState } from "react";

import { Gamepad } from "styled-icons/fa-solid";
import Modal from "../../ResultsPage/Modal";
import { PlaystationControllerImage } from "../AnimatedController/customControllers/Playstation";
import { XBoxControllerImage } from "../AnimatedController/customControllers/XBox";
import { connect } from "react-redux";
import { doSetPreferredController } from "../../ResultsPage/ducks/actions";
import styled from "@emotion/styled";
import switchProControllerImage from "../../../3-misc/switchProController.png";

const select = ({ homePage, resultsPage }) => {
  const { activePostIndex, posts } = resultsPage;
  const post = posts[activePostIndex];
  const game =
    homePage.availableGames.filter(game => game._id === post.gameId)[0] || {};
  const { controllers } = game;

  return {
    controllers: controllers || []
  };
};

const actions = {
  setPrefferedController: doSetPreferredController
};

function SelectController({ controllers, setPrefferedController }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <Button onClick={revealModal}>
        <GameIcon />
      </Button>

      {showModal && (
        <Modal onClose={hideModal}>
          <ModalContent>
            {controllers.map(controllerType => (
              <BackgroundImage
                key={controllerType}
                zoom={calculateImageZoom(controllerType)}
                src={getControllerImage(controllerType)}
                onClick={() => {
                  setPrefferedController(controllerType);
                  hideModal();
                }}
              />
            ))}
          </ModalContent>
        </Modal>
      )}

      {/* <DemoContent show={showModal} /> */}
    </Container>
  );

  function revealModal() {
    setShowModal(true);
  }

  function hideModal() {
    setShowModal(false);
  }
}

function calculateImageZoom(controllerType) {
  if (controllerType === "XBOX") return 1.5;
  if (controllerType === "PLAYSTATION") return 1.2;
  return 1;
}

function getControllerImage(controllerType) {
  switch (controllerType) {
    case "SWITCH":
      return switchProControllerImage;
    case "XBOX":
      return XBoxControllerImage;
    case "PLAYSTATION":
      return PlaystationControllerImage;
    case "GAMECUBE":
      return "https://upload.wikimedia.org/wikipedia/commons/a/a5/GameCube_controller.png";
  }
}

export default connect(select, actions)(SelectController);

const Container = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 10001;
`;

const Button = styled.div`
  color: white;
  background: var(--dark-purple);
  padding: 0.3em 0.8em;
  font-size: 12px;
  border-radius: 50px;
  font-weight: bold;
`;

const GameIcon = styled(Gamepad)`
  width: 24px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 1em;
  box-sizing: border-box;
  background: var(--black-purple);
  border: 2px solid var(--purple);
  width: 100vw;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const BackgroundImage = styled.div`
  background-image: url(${({ src }) => src});
  height: calc(115px * ${({ zoom }) => zoom});
  min-width: calc(115px * ${({ zoom }) => zoom});
  background-size: contain;
  background-position: center center;
  flex: 1;
  margin: 0em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  background-repeat: no-repeat;
`;
