import {
  CHANGED_USERNAME_FOR_LOGIN,
  CHANGED_PASSWORD_FOR_LOGIN,
  CHANGED_EMAIL_FOR_LOGIN,
  STARTED_AUTHENTICATING_USER,
  FINISHED_AUTHENTICATING_USER_ON_FIREBASE,
  ERRED_AUTHENTICATING_USER
} from "./types";

const initialState = {
  username: "",
  password: "",
  email: "",
  fetching: false,
  errorMessage: ""
};

export default function loginPageReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STARTED_AUTHENTICATING_USER: {
      return {
        ...state,
        fetching: true
      };
    }
    case ERRED_AUTHENTICATING_USER: {
      return {
        ...state,
        fetching: false,
        errorMessage: payload.errorMessage
      }
    }
    
    case FINISHED_AUTHENTICATING_USER_ON_FIREBASE: {
      return {
        ...state,
        errorMessage: "",
        fetching: false
      };
    }

    case CHANGED_USERNAME_FOR_LOGIN: {
      return {
        ...state,
        username: payload.username
      };
    }

    case CHANGED_PASSWORD_FOR_LOGIN: {
      return {
        ...state,
        password: payload.password
      };
    }

    case CHANGED_EMAIL_FOR_LOGIN: {
      return {
        ...state,
        email: payload.email
      };
    }

    default:
      return state;
  }
}
