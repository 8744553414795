import {
  FINISHED_AUTHENTICATING_USER_ON_FIREBASE,
  SIGNED_OUT_USER,
} from "../../LoginPage/ducks/types";
import {
  FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND,
  FINISHED_UPDATING_USER,
  STARTED_UPDATING_USER,
} from "./types";

import { CHANGED_CONTROLLER_MAPPING } from "../../CustomMappingsPage/ducks/types";
import { SET_COMBO_FAVORITE_STATUS } from "../../Post/ducks/types";
import { SET_PREFERRED_CONTROLLER } from "../../ResultsPage/ducks/types";

const initialState = {
  _id: null,
  userType: "",
  favoriteCombos: [],
  displayName: "",
  email: "",
  photoUrl: null,
  refreshToken: "",
  emailVerified: false,
  authenticated: null,
  updating: false,
  preferredControllers: {},
  customMappings: {},
};

export default function userInfoReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PREFERRED_CONTROLLER: {
      return {
        ...state,
        preferredControllers: {
          ...state.preferredControllers,
          [payload.gameId]: payload.preferredController,
        },
      };
    }
    case STARTED_UPDATING_USER: {
      return {
        ...state,
        updating: true,
      };
    }

    case FINISHED_UPDATING_USER: {
      return {
        ...state,
        photoUrl: payload.photoUrl,
        displayName: payload.displayName,
        updating: false,
      };
    }

    case SIGNED_OUT_USER: {
      return {
        ...initialState,
        authenticated: false,
      };
    }

    case FINISHED_AUTHENTICATING_USER_ON_FIREBASE: {
      return {
        ...state,
        _id: payload.userInfo._id,
        email: payload.userInfo.email,
        emailVerified: payload.userInfo.emailVerified,
        refreshToken: payload.userInfo.refreshToken,
        authenticated: true,
      };
    }

    case FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND: {
      return {
        ...state,
        userType: payload.userType,
        favoriteCombos: payload.favoriteCombos,
        displayName: payload.displayName,
        photoUrl: payload.photoUrl,
        preferredControllers: payload.preferredControllers,
        customMappings: payload.customMappings || {},
      };
    }
    case SET_COMBO_FAVORITE_STATUS: {
      return {
        ...state,
        favoriteCombos: payload.favoriteCombos,
      };
    }
    case CHANGED_CONTROLLER_MAPPING: {
      return {
        ...state,
        customMappings: payload.customMappings,
      };
    }
    default:
      return state;
  }
}
