import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { doChangeSectionType } from "./ducks/actions";
import styled from "@emotion/styled";

const select = ({ guidePage }) => ({
  currentSectionIndex: guidePage.lastFocusedIndex,
  currentSectionType:
    guidePage.lastFocusedIndex &&
    guidePage.sections[guidePage.lastFocusedIndex] &&
    guidePage.sections[guidePage.lastFocusedIndex].type
});

const actions = {
  changeSectionType: doChangeSectionType
};

// height and with of the options box should be set here
const CONTAINER_WIDTH = 90;
const CONTAINER_HEIGHT = 35;

function SelectOptions({
  changeSectionType,
  currentSectionIndex,
  currentSectionType
}) {
  const [coordinates, setCoordinates] = useState(null);

  useEffect(listenForSelectionsEffect, []);
  useEffect(listenForKeyDownEffect, []);

  const shouldNotShow =
    !coordinates ||
    currentSectionIndex == 0 ||
    currentSectionType === "COMBO_SELECTOR";

  if (shouldNotShow) return null;

  return (
    <Container
      top={coordinates.top}
      left={coordinates.left}
      id="select-options"
    >
      <Option onClick={() => handleChangeSection("HEADER")}>T</Option>
      <Option onClick={() => handleChangeSection("TEXT")}>t</Option>
    </Container>
  );

  function handleChangeSection(type) {
    changeSectionType(currentSectionIndex, type);
    setCoordinates(null);
  }

  function listenForSelectionsEffect() {
    document.addEventListener("mouseup", handleSelectionChange);
    return () => document.removeEventListener("mouseup", handleSelectionChange);
  }

  function listenForKeyDownEffect() {
    document.addEventListener("keydown", hideCoordinates);
    return () => document.removeEventListener("keydown", hideCoordinates);
  }

  function hideCoordinates() {
    setCoordinates(null);
  }

  // this is sensitive to changes in padding and margins in other parts of the page
  function handleSelectionChange(mouseEvent) {
    const selection = window.getSelection();
    if (selection.type !== "Range") {
      if (mouseEvent.srcElement.parentElement.id !== "select-options")
        hideCoordinates();
      return;
    }
    const clientRectOfRange = selection.getRangeAt(0).getBoundingClientRect();
    setCoordinates(() => ({
      top: getTopPosition(clientRectOfRange),
      left: getLeftPosition(clientRectOfRange)
    }));
  }
}

function getTopPosition(clientRect) {
  const SUM_OF_VERTICAL_PADDING = 95;
  const SHIFT_THAT_LOOKS_NICE = 10;
  return (
    clientRect.y -
    CONTAINER_HEIGHT -
    SUM_OF_VERTICAL_PADDING +
    SHIFT_THAT_LOOKS_NICE +
    window.scrollY
  );
}

function getLeftPosition(clientRect) {
  return clientRect.x + clientRect.width / 2;
}

export default connect(select, actions)(SelectOptions);

const Container = styled.div`
  --color: var(--green);
  position: absolute;
  width: ${CONTAINER_WIDTH}px;
  height: ${CONTAINER_HEIGHT}px;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  background-color: #121212;
  border: 2px solid var(--color);
  border-radius: 4px;
  box-shadow: var(--green-shadow);
  display: flex;
  padding: 0 0.5em;
  box-sizing: border-box;
  transition: 0.2s ease;
  user-select: none;
  z-index: 100;
  transform: translateX(-50%);
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0;
    height: 0;
    z-index: 1;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--color);
    transform: translate(-50%, 100%);
  }
`;

const Option = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 900;
  &:hover {
    color: var(--green);
  }
`;
