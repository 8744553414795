import React, { useEffect, useState } from "react";
import {
  doClearPostMetadata,
  doDeactivateTutorMode,
  doResetPost
} from "../ducks/actions";

import ConnectControllerPrompt from "./ConnectControllerPrompt";
import { connect } from "react-redux";
import { pressToTextRepresentation } from "../../PostEditor/CreateComboInterface/ActionEditor/ducks/types";
import styled from "@emotion/styled";

const select = ({ resultsPage, post }) => {
  const combo = resultsPage.posts[resultsPage.activePostIndex];
  const upcomingAction = combo.actions[post.upcomingActionIndex] || {};
  return {
    upcomingAction,
    frame: post.frame,
    upcomingActionIndex: post.upcomingActionIndex
  };
};

const actions = {
  resetVideo: doClearPostMetadata,
  deactivateTutorMode: doDeactivateTutorMode
};

function ConnectedController({
  deactivateTutorMode,
  upcomingAction,
  resetVideo,
  frame,
  upcomingActionIndex
}) {
  const [gamepad, setGamepad] = useState(null);
  const [startedTutorial, setStartedTutorial] = useState(false);
  const videoRef = document.getElementById("post-video-tag");
  const upcomingPresses = upcomingAction.presses || [];
  const instructions = upcomingPresses.map(pressToTextRepresentation);
  const voice = window.speechSynthesis
    .getVoices()
    .find(voice => voice.name === "Google US English");

  useEffect(() => {
    handleInitialization();
  }, []);

  useEffect(() => {
    dispatchGamepadState(gamepad);
  }, [gamepad]);

  useEffect(() => {
    handleSpeakInstructions(upcomingAction, voice);
  }, [upcomingAction, voice]);

  if (!gamepad) return <ConnectControllerPrompt />;

  if (!startedTutorial) startTutorial();
  return (
    <Container fade={frame < upcomingAction.frame}>
      {frame === 0 && (
        <StartButton onClick={startTutorial}>Begin Lesson</StartButton>
      )}
      <CloseButton onClick={deactivateTutorMode}>&times;</CloseButton>
      <Instructions fade={frame < upcomingAction.frame}>
        {frame !== 0 && instructions.map((instr, index) => <h2>{instr}</h2>)}
        {!instructions.length && <h1>DONE!</h1>}
      </Instructions>
    </Container>
  );

  function listenForInteractions() {
    const newGamepad = navigator.getGamepads()[0];
    setGamepad(newGamepad);
    newGamepad && window.requestAnimationFrame(listenForInteractions);
  }

  // function useVideoIsPlaying() {
  //   const [isPlaying, setIsPlaying] = useState(false);
  //   const handlePause = () => setIsPlaying(false);
  //   const handlePlay = () => setIsPlaying(true);

  //   useEffect(() => {
  //     videoRef.addEventListener("play", handlePlay);
  //     videoRef.addEventListener("pause", handlePause);
  //     return () => {
  //       videoRef.removeEventListener("play", handlePlay);
  //       videoRef.removeEventListener("pause", handlePause);
  //     };
  //   });
  //   return isPlaying;
  // }

  function startTutorial() {
    setStartedTutorial(true);
    resetVideo();
    videoRef.play();
  }

  function handleInitialization() {
    // gamepad exists
    const newGamepad = navigator.getGamepads()[0];
    if (newGamepad) {
      setGamepad(newGamepad);
      listenForInteractions();
      return () => null;
    }
    // gamepad does not exist
    else {
      window.addEventListener("gamepadconnected", listenForInteractions);
      return () => {
        window.removeEventListener("gamepadconnected", listenForInteractions);
      };
    }
  }

  function handleSpeakInstructions(upcomingAction, voice) {
    const speechInstructions = [...instructions];

    if (instructions.length > 1) {
      speechInstructions[speechInstructions.length - 1] =
        "and " + speechInstructions[speechInstructions.length - 1];
    }
    if (gamepad && gamepad.connected)
      speak(speechInstructions.join(", "), voice);
  }
}

function speak(text, voice) {
  const utterThis = new SpeechSynthesisUtterance(text);
  utterThis.voice = voice;
  window.speechSynthesis.speak(utterThis);
}

export default connect(select, actions)(ConnectedController);

function dispatchGamepadState(gamepad) {
  const gamepadEvent = new CustomEvent("gamepadstate", { detail: gamepad });
  window.dispatchEvent(gamepadEvent);
}

const Container = styled.div`
  padding: 1em;
  font-weight: bold;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: ${({ fade }) => (fade ? "#212121" : "#388e3c")};
`;

const Instructions = styled.div`
  opacity: ${({ fade }) => (fade ? 0 : 1)};
`;

const StartButton = styled.div`
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  color: var(--purple);
  font-weight: bold;
  padding: 1em;
  box-shadow: var(--shadow-1);
`;

const CloseButton = styled.div`
  position: absolute;
  right: 1em;
  top: 1em;
  background-color: white;
  color: var(--purple);
  border-radius: 50px;
  box-shadow: var(--shadow-1);
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 30px;
`;
