import React from "react";
import { connect } from "react-redux";
import { doJumpPostVideoFrame } from "./ducks/actions";
import { doSetUpcomingActionIndex } from "../EmbeddedComboWithoutRedux/ducks/actions";
import { setVideoTime } from ".";
import styled from "@emotion/styled";

const select = ({ post, resultsPage }) => {
  const { activePostIndex, posts } = resultsPage;
  const currentPost = posts[activePostIndex];
  return {
    frame: post.frame,
    actionFrames: currentPost.actions.map(action => action.frame)
  };
};

const actions = {
  setUpcomingActionIndex: doSetUpcomingActionIndex
};

function ProgressBar({
  actionFrames,
  duration,
  frame,
  setUpcomingActionIndex
}) {
  const percentProgressed = Math.min((frame / 60 / duration) * 100, 100);
  return (
    <Container>
      {actionFrames.map((frame, index) => (
        <Stamp
          left={duration ? (frame / 60 / duration) * 100 : -20}
          onClick={() => {
            setUpcomingActionIndex(index);
            setVideoTime(frame);
          }}
        >
          <StampBubbleWrapper>
            <StampBubble />
          </StampBubbleWrapper>
        </Stamp>
      ))}
      <Bar percentProgressed={percentProgressed} />
    </Container>
  );
}

export default connect(select, actions)(ProgressBar);

const Container = styled.div`
  position: relative;
  height: 10px;
  width: 100%;
  background: var(--dark-purple);

  @media (min-width: 1000px) {
    height: 20px;
  }
`;

const Bar = styled.div`
  background: var(--green);
  position: absolute;
  top: 0;
  left: 0;
  height: 10px;
  z-index: 1;
  width: ${({ percentProgressed }) => percentProgressed}%;
  @media (min-width: 1000px) {
    height: 20px;
  }
`;

const Stamp = styled.div`
  position: absolute;
  height: 10px;
  width: 2px;
  background: var(--green);
  bottom: 0;
  z-index: 2;
  left: ${({ left }) => left}%;
  @media (min-width: 1000px) {
    height: 20px;
  }
  cursor: pointer;
`;

const StampBubble = styled.div`
  border: 2px solid var(--green);
  z-index: 2;
  height: var(--size);
  width: var(--size);
  border-radius: 50px;
  background-color: var(--purple);
`;

const StampBubbleWrapper = styled.div`
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: calc(-1 * 10px - 9px);
  left: calc((-1 * var(--size) - 2 * 10px - 2px) / 2);
  --size: 15px;
  &:hover > div {
    transform: scale(1.5);
    transform-origin: center center;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;
