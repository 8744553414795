import { VolumeOff, VolumeUp } from "styled-icons/material";

import React from "react";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import { doTogglePostAudio } from "../ducks/actions";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const actions = {
  toggleAudio: doTogglePostAudio
};

const select = ({ post }) => ({
  audioEnabled: post.audioEnabled
});

function SoundControls({ toggleAudio, audioEnabled }) {
  const iconSize = "25";
  const Icon = audioEnabled ? SoundOnIcon : SoundOffIcon;
  return (
    <ToolTip message="Hear clicks when buttons are pressed">
      <Icon size={iconSize} onClick={toggleAudio} />
    </ToolTip>
  );
}

export default connect(select, actions)(SoundControls);

const SoundOnIcon = styled(VolumeUp)`
  color: var(--green);
  cursor: pointer;
`;

const SoundOffIcon = styled(VolumeOff)`
  color: white;
  cursor: pointer;
`;
