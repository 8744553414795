import { PlayArrow, School } from "styled-icons/material";
import React, { useEffect, useRef, useState } from "react";
import {
  doActivateTutorMode,
  doJumpPostVideoFrame,
  doResolveBreakpoint,
  doSetUpcomingActionIndex,
} from "../ducks/actions";

import { MoonAnimation } from "../../../2-utils/loadingAnimations";
import ProgressBar from "../ProgressBar";
import SpeedControls from "../Options/SpeedControls";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const select = ({ post }) => ({
  animationSpeed: post.animationSpeed,
  tutorMode: post.tutorMode,
  hitBreakpoint: post.hitBreakpoint,
});

const actions = {
  setFrame: doJumpPostVideoFrame,
  setUpcomingActionIndex: doSetUpcomingActionIndex,
  activateTutorMode: doActivateTutorMode,
  resolveBreakpoint: doResolveBreakpoint,
};

function Video({
  activateTutorMode,
  videoUrl,
  setFrame,
  animationSpeed,
  setUpcomingActionIndex,
  tutorMode,
  hitBreakpoint,
  resolveBreakpoint,
  thumbnail,
}) {
  const videoRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const isPlaying = useVideoIsPlaying();
  const isMobile = useIsMobile();

  useEffect(() => {
    setPlaybackRate(animationSpeed);
  }, [videoRef, animationSpeed]);

  return (
    <>
      <Container>
        {isLoading && (
          <LoadingOverlay>
            <MoonAnimation />
          </LoadingOverlay>
        )}

        <VideoTag
          ref={videoRef}
          id="post-video-tag"
          onTimeUpdate={handleTimeChange}
          onEnded={handleVideoEnded}
          onCanPlayThrough={hideLoadingAnimation}
          onClick={handlePauseVideo}
          fade={!isPlaying && !isLoading}
          playsInline
          autoPlay
          muted
        >
          <source src={getTemporaryVideoURL(videoUrl)} type="video/mp4" />
        </VideoTag>

        {!isPlaying && !isLoading && !tutorMode && !hitBreakpoint && (
          <Overlay>
            <PlayButton onClick={handlePlayVideo}>
              <PlayIcon />
            </PlayButton>

            {/* {!isMobile && (
              <PlayButton onClick={activateTutorMode}>
                <SchoolIcon />
              </PlayButton>
            )} */}

            {isMobile && (
              <SpeedControlsWrapper>
                <SpeedControls />
              </SpeedControlsWrapper>
            )}
          </Overlay>
        )}
        {!isMobile && (
          <SpeedControlsWrapper>
            <SpeedControls />
          </SpeedControlsWrapper>
        )}
        {!isPlaying && !isLoading && !tutorMode && hitBreakpoint && (
          <Overlay
            onClick={() => {
              resolveBreakpoint();
              handlePlayVideo();
            }}
          >
            <ContinueButton>continue</ContinueButton>
          </Overlay>
        )}
      </Container>
      <ProgressBarWrapper>
        <ProgressBar duration={videoRef.current && videoRef.current.duration} />
      </ProgressBarWrapper>
    </>
  );

  function hideLoadingAnimation() {
    setIsLoading(false);
  }

  function handleTimeChange(e) {
    const FRAMES_PER_SECOND = 60;
    const currentTimeInSeconds = e.target.currentTime;
    const currentTimeInFrames = currentTimeInSeconds * FRAMES_PER_SECOND;
    setFrame(currentTimeInFrames);
  }

  function setPlaybackRate(rate) {
    videoRef.current.playbackRate = rate;
  }

  function handleVideoEnded(e) {
    setFrame(0);
    setUpcomingActionIndex(0);
  }

  function handlePlayVideo() {
    videoRef.current.play();
  }

  function handlePauseVideo() {
    videoRef.current.pause();
  }

  function useVideoIsPlaying() {
    const [isPlaying, setIsPlaying] = useState(false);
    const handlePause = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    useEffect(() => {
      videoRef.current.addEventListener("play", handlePlay);
      videoRef.current.addEventListener("pause", handlePause);
      return () => {
        try {
          videoRef.current.removeEventListener("play", handlePlay);
          videoRef.current.removeEventListener("pause", handlePause);
        } catch (e) {
          console.log("Can't remove event listener for videos.");
        }
      };
    });
    return isPlaying;
  }
}

export default connect(select, actions)(Video);

const Container = styled.div`
  /* background-color: var(--black-purple); */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--black-purple);
  position: relative;
  @media (min-width: 800px) {
    position: absolute;
    top: 50%;
    height: auto;
    width: 100%;
    left: 50%;
    transform: translate(-50%, -60%);
    z-index: 2;
  }
`;

const VideoTag = styled.video`
  width: 100%;
  height: 100%;
  opacity: ${({ fade }) => (fade ? 0.4 : 1)};
  object-fit: contain;
  cursor: pointer;
`;

const PlayButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: var(--green-purple-gradient);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: var(--shadow-1);
  margin: 0 0.3em;
  cursor: pointer;
  @media (min-width: 1000px) {
    transform: scale(1.5);
  }
`;

const PlayIcon = styled(PlayArrow)`
  width: 35px;
`;

const SchoolIcon = styled(School)`
  width: 30px;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  box-sizing: border-box;
`;

const LoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
`;

const SpeedControlsWrapper = styled.div`
  position: absolute;
  top: 1em;
  right: 0.2em;
  @media (min-width: 800px) {
    position: fixed;
    top: initial;
    bottom: -60px;
    right: initial;
  }
`;

const ContinueButton = styled.div`
  font-weight: bold;
  color: var(--green);
`;

const ProgressBarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  @media (min-width: 800px) {
    width: 60vw;
  }
`;

export function getTemporaryVideoURL(videoUrl) {
  return videoUrl;
  // const questionIndex = videoUrl.indexOf("?");
  // return (
  //   videoUrl.slice(0, questionIndex) + ".mp4" + videoUrl.slice(questionIndex)
  // );
}
