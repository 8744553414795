// import * as firebase from "firebase";
import { getAuth } from "firebase/auth";

import "../firebase";

import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import {
  doHandleAuthenticatedUser,
  doRecordSignedOut,
  getRelevantInfoFromCurrentUser,
} from "./LoginPage/ducks/actions";

import CustomMappingsPage from "./CustomMappingsPage";
import DiscoverPage from "./DiscoverPage";
import FavoriteCombos from "./Favorites/FavoriteCombos";
import GuidePage from "./GuidePage";
import LandingPage from "./LandingPage";
import LoginPage from "./LoginPage";
import Nav from "./HomePage/Nav";
import PostEditor from "./PostEditor";
import ProfilePage from "./ProfilePage";
import ResetPasswordPage from "./ResetPasswordPage";
import ResultsPage from "./ResultsPage";
import SettingsPage from "./SettingsPage";
import SingleGuidePage from "./SingleGuidePage";
import StandalonePostPage from "./StandalonePostPage";
import Test from "./Test";
import Tutorial from "./PostEditor/NewComboCreationInterface/Tutorial";
import WhatCanIDo from "./WhatCanIDo";
import { connect } from "react-redux";
import { doFetchAvailableGames } from "./HomePage/ducks/actions";
import styled from "@emotion/styled";

const select = ({ resultsPage, settingsPage }) => ({
  fullScreenMode: settingsPage.fullscreenMode,
  manualResetKey: resultsPage.manualResetKey,
});

const actions = {
  handleAuthenticatedUser: doHandleAuthenticatedUser,
  handleNonAuthenticatedUser: doRecordSignedOut,
  fetchAvaiableGames: doFetchAvailableGames,
};

function Root({
  manualResetKey,
  fetchAvaiableGames,
  fullScreenMode,
  handleAuthenticatedUser,
  handleNonAuthenticatedUser,
}) {
  useEffect(() => {
    listenForAuthChanges(handleAuthenticatedUser, handleNonAuthenticatedUser);
    // listenForAuthChanges(
    //   handleNonAuthenticatedUser,
    //   handleNonAuthenticatedUser
    // );

    document.addEventListener("paste", function (e) {
      // cancel paste
      e.preventDefault();
      // get text representation of clipboard
      var text = (e.originalEvent || e).clipboardData.getData("text/plain");
      text = text.replace(/<[^>]*>?/gm, "");
      // insert text manually
      document.execCommand("insertHTML", false, text);
    });
  }, []);

  useEffect(() => {
    fetchAvaiableGames();
  }, []);

  useEffect(() => {
    const voice = window.speechSynthesis.getVoices();
  }, []);

  return (
    <Container>
      {/* <ParrallaxBackground /> */}
      <Router>
        <Content id="root-content">
          <Nav />
          <Routes>
            <Route path="/discover" element={<DiscoverPage />} />
            <Route
              path="/game"
              element={<ResultsPage key={manualResetKey} />}
            />
            <Route path="/edit" element={<PostEditor />} />
            <Route path="/single-guide-page" element={<SingleGuidePage />} />
            <Route path="/guide/:guideId" element={<GuidePage />} />
            <Route
              path="/profile/:profileId"
              element={<ProfilePage key={2} />}
            />
            <Route path="/my-profile" element={<ProfilePage key={1} />} />
            <Route path="/combo/:comboId" element={<StandalonePostPage />} />
            <Route path="/favorites" element={<FavoriteCombos />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/authenticate" element={<LoginPage />} />
            <Route path="/forgot-password" element={<ResetPasswordPage />} />
            <Route path="/what-can-i-do" element={<WhatCanIDo />} />
            <Route path="/test/:comboId" element={<Test />} />
            <Route path="/custom-mappings" element={<CustomMappingsPage />} />
            <Route path="/tutorial" element={<Tutorial />} />
            <Route
              path="/results"
              element={<ResultsPage key={manualResetKey} />}
            />
            <Route path="/" element={<LandingPage />} />
          </Routes>
        </Content>
      </Router>
    </Container>
  );
}

function listenForAuthChanges(onAuthenticated, onNotAuthenticated) {
  const auth = getAuth();
  auth.onAuthStateChanged((user) => {
    if (user) onAuthenticated(getRelevantInfoFromCurrentUser(user));
    else onNotAuthenticated();
  });
}

export default connect(select, actions)(Root);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  background: var(--black-purple);
  position: relative;
`;

const Content = styled.div`
  width: 1000px;
  margin-top: 3em;
  /* ${`zoom: ${window.innerWidth / 1000}; margin-top: 0;`} */
  margin-bottom: 2em;
  z-index: 2;
  /* -webkit-transform:translate3d(0,0,0); */
  border-radius: 0 0 16px 16px;
  @media (max-width: 600px) {
    zoom: normal;
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    border-radius: 0px;
  }
  @media (min-width: 600px) {
    width: 100vw;
    box-sizing: border-box;
    margin: 0;
  }
`;
