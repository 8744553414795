// import "./controllerAnimations.css";
import "../../Post/AnimatedController/controllerAnimations.css";

import * as buttonControls from "./buttonTypes";

import {
  AButton,
  AnimatedControllerJoystick,
  BButton,
  Joystick,
  LeftRTrigger,
  LeftZTrigger,
  RightRTrigger,
  RightZTrigger,
  XButton,
  YButton
} from "./switchProController";
import React, { useContext, useEffect, useRef } from "react";

import { StateContext } from "../index.js";
import { connect } from "react-redux";
import { doSetUpcomingActionIndex } from "../ducks/actions";
import { getGamecubeController } from "../../Post/AnimatedController/customControllers/switchAndGamecube";
import styled from "@emotion/styled";
import switchProControllerImage from "./switchProController.png";

var metronomeAudio = new Audio(
  "https://github.com/wilson428/metronome/blob/master/tick.wav?raw=true"
);
const ANIMATION_DURATION = 250;

export default function AnimatedController() {
  const { state, dispatch } = useContext(StateContext);
  const { frame, upcomingActionIndex } = state;
  const { actions } = state.combo;
  const preferredController = "SWITCH";
  const setUpcomingActionIndex = doSetUpcomingActionIndex;

  // hooks into the control sticks and buttons
  const controlStickRef = useRef();
  const cStickRef = useRef();
  const AButtonRef = useRef();
  const BButtonRef = useRef();
  const XButtonRef = useRef();
  const YButtonRef = useRef();
  const RTriggerRef = useRef();
  const LTriggerRef = useRef();
  const RZRef = useRef();
  const LZRef = useRef();

  const Controller = selectProperController(preferredController);

  useEffect(() => {
    animateActionWhenInFrame();
  }, [frame]);

  return (
    <Container>
      <ControllerBackdrop src={Controller.backgroundImage} />

      <Controller.JoyStickWrapper>
        <AnimatedControllerJoystick ref={controlStickRef} />
      </Controller.JoyStickWrapper>

      <Controller.BButtonWrapper>
        <BButton ref={BButtonRef} />
      </Controller.BButtonWrapper>

      <Controller.XButtonWrapper>
        <XButton ref={XButtonRef} />
      </Controller.XButtonWrapper>

      <Controller.YButtonWrapper>
        <YButton ref={YButtonRef} />
      </Controller.YButtonWrapper>

      <Controller.AButtonWrapper>
        <AButton ref={AButtonRef} />
      </Controller.AButtonWrapper>

      <Controller.CStickWrapper>
        <AnimatedControllerJoystick ref={cStickRef} />
      </Controller.CStickWrapper>

      <Controller.LeftTriggerWrapper>
        <LeftRTrigger ref={LTriggerRef}>LR</LeftRTrigger>
      </Controller.LeftTriggerWrapper>

      <Controller.RightTriggerWrapper>
        <RightRTrigger ref={RTriggerRef}>RR</RightRTrigger>
      </Controller.RightTriggerWrapper>

      <Controller.LeftZWrapper>
        <LeftZTrigger ref={LZRef}>LZ</LeftZTrigger>
      </Controller.LeftZWrapper>

      <Controller.RightZWrapper>
        <RightZTrigger ref={RZRef}>RZ</RightZTrigger>
      </Controller.RightZWrapper>
    </Container>
  );

  function selectProperController(controllerType) {
    switch (controllerType) {
      case "GAMECUBE":
        return getGamecubeController();
      case "SWITCH":
      default:
        return getSwitchController();
    }
  }

  function getSwitchController() {
    return {
      backgroundImage: switchProControllerImage,
      AButtonWrapper: SwitchAButtonWrapper,
      BButtonWrapper: SwitchBButtonWrapper,
      XButtonWrapper: SwitchXButtonWrapper,
      YButtonWrapper: SwitchYButtonWrapper,
      JoyStickWrapper: SwitchJoyStickWrapper,
      CStickWrapper: SwitchCStickWrapper,
      LeftTriggerWrapper: SwitchLeftTriggerWrapper,
      RightTriggerWrapper: SwitchRightTriggerWrapper,
      LeftZWrapper: SwitchLeftZWrapper,
      RightZWrapper: SwitchRightZWrapper
    };
  }

  function animateActionWhenInFrame() {
    const action = actions[upcomingActionIndex];
    if (action) {
      const frameForAction = action.frame;
      const presses = action.presses;
      if (frame > frameForAction) {
        animatePresses(presses);
        dispatch(setUpcomingActionIndex(upcomingActionIndex + 1));
      }
    }
  }

  function animatePresses(presses) {
    presses.forEach(press => {
      switch (press.type) {
        case buttonControls.CONTROL_STICK_UP:
          return moveJoystick("up", press.hold);
        case buttonControls.CONTROL_STICK_DOWN:
          return moveJoystick("down", press.hold);
        case buttonControls.CONTROL_STICK_RIGHT:
          return moveJoystick("right", press.hold);
        case buttonControls.CONTROL_STICK_LEFT:
          return moveJoystick("left", press.hold);
        case buttonControls.CONTROL_STICK_UP_RIGHT:
          return moveJoystick("up-right", press.hold);
        case buttonControls.CONTROL_STICK_UP_LEFT:
          return moveJoystick("up-left", press.hold);
        case buttonControls.CONTROL_STICK_DOWN_RIGHT:
          return moveJoystick("down-right", press.hold);
        case buttonControls.CONTROL_STICK_DOWN_LEFT:
          return moveJoystick("down-left", press.hold);
        case buttonControls.C_STICK_UP:
          return moveCStick("up", press.hold);
        case buttonControls.C_STICK_DOWN:
          return moveCStick("down", press.hold);
        case buttonControls.C_STICK_RIGHT:
          return moveCStick("right", press.hold);
        case buttonControls.C_STICK_LEFT:
          return moveCStick("left", press.hold);
        case buttonControls.C_STICK_UP_RIGHT:
          return moveCStick("up-right", press.hold);
        case buttonControls.C_STICK_UP_LEFT:
          return moveCStick("up-left", press.hold);
        case buttonControls.C_STICK_DOWN_RIGHT:
          return moveCStick("down-right", press.hold);
        case buttonControls.C_STICK_DOWN_LEFT:
          return moveCStick("down-left", press.hold);

        case buttonControls.A_BUTTON:
          return pressButton(AButtonRef, press.hold);
        case buttonControls.B_BUTTON:
          return pressButton(BButtonRef, press.hold);
        case buttonControls.X_BUTTON:
          return pressButton(XButtonRef, press.hold);
        case buttonControls.Y_BUTTON:
          return pressButton(YButtonRef, press.hold);

        case buttonControls.R_TRIGGER:
          return pressButton(RTriggerRef, press.hold);
        case buttonControls.L_TRIGGER:
          return pressButton(LTriggerRef, press.hold);
        case buttonControls.L_Z:
          return pressButton(LZRef, press.hold);
        case buttonControls.R_Z:
          return pressButton(RZRef, press.hold);
      }
    });
  }

  function moveJoystick(direction, holdStatus) {
    const className = "move-" + direction;
    const element = controlStickRef.current;
    if (holdStatus !== "END_HOLD") element.classList.add(className);
    if (holdStatus === "START_HOLD" || holdStatus === "MAINTAIN_HOLD") return;
    if (holdStatus === "END_HOLD")
      return setTimeout(() => element.classList.remove(className), 0);
    setTimeout(() => element.classList.remove(className), ANIMATION_DURATION);
  }

  function moveCStick(direction, holdStatus) {
    const className = "move-" + direction;
    const element = cStickRef.current;
    if (holdStatus !== "END_HOLD") element.classList.add(className);
    if (holdStatus === "START_HOLD" || holdStatus === "MAINTAIN_HOLD") return;
    if (holdStatus === "END_HOLD")
      return setTimeout(() => element.classList.remove(className), 0);
    setTimeout(() => element.classList.remove(className), ANIMATION_DURATION);
  }

  function pressButton(ref, holdStatus) {
    const element = ref.current;
    if (holdStatus !== "END_HOLD") element.classList.add("pressed");
    if (holdStatus === "START_HOLD" || holdStatus === "MAINTAIN_HOLD") return;
    if (holdStatus === "END_HOLD")
      return setTimeout(() => element.classList.remove("pressed"), 0);
    setTimeout(() => element.classList.remove("pressed"), ANIMATION_DURATION);
  }
}

function verifyGamepadState(e, presses) {
  if (!e.detail) return false;
  const state = normalizeStateToStandardController(e.detail);
  let verified = true;
  presses.forEach(press => {
    if (!pressSatisfied(press, state)) verified = false;
  });
  return verified;
}

function pressSatisfied(press, gamepad) {
  const parts = press.type.split("_");
  if (parts[1] === "BUTTON") {
    const buttonKey = parts[0].toLowerCase();
    return gamepad.buttons[buttonKey].pressed;
  }

  if (parts[1] === "TRIGGER") {
    const buttonKey = parts[0] === "L" ? "left" : "right";
    return gamepad.triggers[buttonKey].pressed;
  }

  if (parts[1] === "Z") {
    const buttonKey = parts[0] === "L" ? "left" : "right";
    return gamepad.bumpers[buttonKey].pressed;
  }

  if (parts[0] === "CONTROL") {
    return true;
  }

  if (parts[0] === "C") {
    return true;
  }
}

function normalizeStateToStandardController(gamepad) {
  return {
    controlStick: {
      xaxis: gamepad.axes[0],
      yaxis: gamepad.axes[1]
    },
    cStick: {
      xaxis: gamepad.axes[2],
      yaxis: gamepad.axes[3]
    },
    buttons: {
      b: gamepad.buttons[0],
      a: gamepad.buttons[1],
      y: gamepad.buttons[2],
      x: gamepad.buttons[3]
    },
    bumpers: {
      left: gamepad.buttons[4],
      right: gamepad.buttons[5]
    },
    triggers: {
      left: gamepad.buttons[6],
      right: gamepad.buttons[7]
    }
  };
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    transform: scale(0.8);
  }
  @media (max-width: 600px) {
    transform: scale(0.8);
  }
`;

const ControllerBackdrop = styled.img`
  height: 200px;
  filter: grayscale(1);
`;

const SwitchLeftTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 85px);
  left: calc(50% - 100px);
`;

const SwitchLeftZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 60px);
  left: calc(50% - 90px);
`;

const SwitchRightTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 85px);
  left: calc(50% + 50px);
`;

const SwitchRightZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 60px);
  left: calc(50% + 40px);
`;

const SwitchJoyStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 5px);
  left: calc(50% - 89px);
  > div {
    background-color: var(--purple);
  }
`;

const SwitchCStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50% - 23px);
  right: calc(50% - 55px);
  > div {
    background-color: var(--purple);
  }
`;

const AbstractButtonWRapper = styled.div`
  position: absolute;
  > div {
    background-color: var(--purple);
    width: 30px;
    height: 30px;
  }
`;

const SwitchAButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 18px);
  right: calc(50% - 92px);
`;

const SwitchBButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 2px);
  right: calc(50% - 74px);
`;

const SwitchXButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 32px);
  right: calc(50% - 74px);
`;

const SwitchYButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 18px);
  right: calc(50% - 55px);
`;

// Gamecube

const GamecubeAButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 16px);
  right: calc(50% - 80px);
  > div {
    transform: scale(1.2);
  }
`;

const GamecubeBButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 5px);
  right: calc(50% - 60px);
`;

const GamecubeYButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 34px);
  right: calc(50% - 74px);
`;

const GamecubeXButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 21px);
  right: calc(50% - 102px);
`;

const GamecubeCStickWrapper = styled(SwitchCStickWrapper)`
  bottom: calc(50% - 45px);
  right: calc(50% - 60px);
`;

const GamecubeJoyStickWrapper = styled(SwitchJoyStickWrapper)``;

const GamecubeRightZWrapper = styled(SwitchRightZWrapper)`
  bottom: calc(50% + 60px);
  left: calc(50% + 40px);
`;
