import React, { useState } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import Overlay from "../../2-utils/Overlay";
import { MoonAnimation } from "../../2-utils/loadingAnimations";

export default function GuidePreview({ guideData, animationDelay }) {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Container
      to={`/single-guide-page/?guideId=${guideData._id}`}
      animationDelay={animationDelay}
    >
      <Image
        onLoad={() => setImageLoaded(true)}
        src={
          guideData.metaData.previewImage ||
          "https://cnet2.cbsistatic.com/img/haPWDZSnHzMlq_h3v2ZQB0SJe4o=/1092x0/2018/06/12/79cc03c2-a7c1-4687-9254-adb72a5f55a0/super-smash-bros-ultimate-nintendo.png"
        }
      />
      <Title>{guideData.sections[0].value}</Title>
      {!imageLoaded && (
        <Overlay style={{ backgroundColor: "rgba(11, 16, 66)" }}>
          <MoonAnimation color="white" />
        </Overlay>
      )}
    </Container>
  );
}

const Container = styled(Link)`
  width: calc(33% - 0.8em - 4px);
  margin: 0.5em;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 2px solid var(--black-purple);
  cursor: pointer;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards
    ${({ animationDelay }) => animationDelay}s;
  &:hover {
    border: 2px solid var(--green);
  }
  @media (max-width: 600px) {
    width: calc(100% + 16px);
    margin: 4px -8px;
  }
`;

const Image = styled.img`
  height: 150px;
  object-fit: cover;
  object-position: center center;
  background-color: rgba(255, 255, 255, 0.2);
  /* @media (max-width: 600px) {
    height: 180px;
  } */
`;

const Title = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.7em 1em;
  box-sizing: border-box;
  text-align: center;
  flex-shrink: 0;
  font-weight: bold;
  color: white;
`;
