import AdditionalComboInfo from "../AdditionalComboInfo";
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => {
  const { activePostIndex, posts } = resultsPage;
  const post = posts[activePostIndex];
  return {
    title: post.name,
    description: post.description
  };
};

function Description({ title, description }) {
  const paragraphs = separateDescriptionIntoParagraphs(description);
  return (
    <Container>
      <Title>{title}</Title>
      <Content>
        <AdditionalComboInfo />
        <br />
        {paragraphs.map((p, i) => (
          <Paragraph key={i}>{p}</Paragraph>
        ))}
      </Content>
    </Container>
  );

  function separateDescriptionIntoParagraphs(description) {
    return description.split("\n").filter(p => p !== "");
  }
}

export default connect(select)(Description);

const Container = styled.div`
  height: 100%;
  overflow-y: auto;
  background-color: var(--black-purple);
  color: white;
  box-sizing: border-box;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  @media (max-width: 600px) {
    padding: 0;
    margin-top: 1em;
  }
`;

const Title = styled.div`
  padding: 0;
  font-size: 24px;
  padding-right: 40px;
  margin: 0.5em 0;
  margin-top: 0;
  display: inline-block;
  font-weight: bold;
  color: white;
  @media (min-width: 800px) {
    font-size: 40px;
  }
`;

const Content = styled.div`
  margin-top: 0;
`;

const Paragraph = styled.p`
  margin-top: 0;
  @media (min-width: 800px) {
    font-size: 20px;
  }
`;

const AuthorBubbleWrapper = styled(Link)`
  /* transform: translateX(1.5em); */
  position: absolute;
  top: 0;
  right: 0;
  float: right;
`;
