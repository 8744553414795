import { BeatLoader } from "react-spinners";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ homePage }) => ({
  availableGames: homePage.availableGames
});

function Nav({ activeGameId, availableGames, onSelect }) {
  return (
    <Container>
      {availableGames.length === 0 && (
        <Center>
          <BeatLoader size={10} color="white" />
        </Center>
      )}

      {availableGames.map((game, i) => (
        <Tab
          key={game._id}
          active={activeGameId === game._id}
          onClick={() => onSelect(game._id)}
        >
          {game.name}
        </Tab>
      ))}
    </Container>
  );
}

export default connect(select)(Nav);

const Container = styled.div`
  height: 55px;
  background: var(--purple-gradient);
  display: flex;
  color: white;
  padding-top: 5px;
  padding-left: 5px;
  overflow-x: scroll;
  box-sizing: border-box;
  user-select: none;
  z-index: 2;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 600px) {
    height: 45px;
  }
  display: none;
`;

const Tab = styled.div`
  padding: 1em 1em;
  font-size: 14px;
  background: ${({ active }) =>
    active ? "var(--black-purple)" : "transparent"};
  cursor: pointer;
  border-radius: 8px 8px 0 0;
  flex-shrink: 0;
  @media (max-width: 600px) {
    padding: 0.7em 1em;
  }
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
`;
