import axios from "axios";
import {
  FINISHED_FETCHING_GAMES_AVAILABLE,
  STARTED_FETCHING_GAMES_AVAILABLE
} from "./types";
import { BACKEND_URL } from "../../../App";
import { doResetPost } from "../../Post/ducks/actions";
import { doResetPostEditor } from "../../PostEditor/ducks/actions";
import { doResetResultsPage } from "../../ResultsPage/ducks/actions";
import { doResetSinglePageGuide } from "../../SingleGuidePage/ducks/actions";

export function doResetState() {
  return dispatch => {
    dispatch(doResetPost());
    dispatch(doResetResultsPage());
    dispatch(doResetSinglePageGuide());
  };
}

export function doFetchAvailableGames() {
  return (dispatch, getState) => {
    const { availableGames } = getState().homePage;
    if (!availableGames.length) queryBackendForAvailableGames(dispatch);
  };
}

function queryBackendForAvailableGames(dispatch) {
  dispatch({ type: STARTED_FETCHING_GAMES_AVAILABLE });
  axios.get(`${BACKEND_URL}/available-games`).then(({ data }) => {
    const { availableGames } = data;
    dispatch({
      type: FINISHED_FETCHING_GAMES_AVAILABLE,
      payload: { availableGames }
    });
  });
}
