import React, { useEffect } from "react";
import { doFetchUserCombos, doFetchUserForProfilePage } from "./ducks/actions";

import Collapsible from "../../2-utils/Collapsible";
import ConfirmationModal from "../ConfirmationModal";
import { Link } from "react-router-dom";
import Posts from "./Posts";
import ProfilePicture from "./ProfilePicture";
import ProfileStats from "./ProfileStats";
import { connect } from "react-redux";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

const select = ({ confirmationModal, resultsPage }) => ({
  showConfirmationModal: confirmationModal.message,
  combos: resultsPage.posts,
});

const actions = {
  fetchUserCombos: doFetchUserCombos,
  fetchUser: doFetchUserForProfilePage,
};

function ProfilePage({
  combos,
  fetchUserCombos,
  fetchUser,
  showConfirmationModal,
}) {
  useEffect(() => {
    recordPageView();
  }, []);

  useEffect(() => {
    const userId = getUserIdFromUrlForProfilePage();
    fetchUser(userId);
    fetchUserCombos(userId);
  }, []);

  console.log("BENJI");

  return (
    <Container>
      <AsideWrapper>
        <ProfilePicture />
      </AsideWrapper>

      <StatsWrapper>
        <ProfileStats />
      </StatsWrapper>

      {/* <GuidesWrapper>
          <Guides />
        </GuidesWrapper> */}

      <PostsWrapper>
        <Collapsible
          expandedInitially
          header={<Header>My combos &bull; {combos.length}</Header>}
          content={combos.map((combo) => (
            <LinkToCombo key={combo._id} to={"/combo/" + combo._id}>
              {combo.name}
            </LinkToCombo>
          ))}
        />
      </PostsWrapper>

      {showConfirmationModal && <ConfirmationModal />}
    </Container>
  );
}

export default connect(select, actions)(ProfilePage);

export function getUserIdFromUrlForProfilePage() {
  return document.location.href.split("/").reverse()[0];
}

const Container = styled.div`
  width: 1000px;
  margin: 1em auto;
  background: var(--black-purple);
  min-height: 100vh;
  padding-bottom: 5em;
  color: white;
  display: grid;
  grid-template-areas:
    "aside  stats"
    /* "guides guides" */
    "posts  posts";
  grid-template-rows: 150px auto;
  grid-template-columns: 115px 1fr;
  @media (max-width: 600px) {
    width: 100vw;
    margin: 0;
  }
`;

const AsideWrapper = styled.div`
  height: 100%;
  grid-area: aside;
`;

const StatsWrapper = styled.div`
  height: 100%;
  grid-area: stats;
`;

const GuidesWrapper = styled.div`
  grid-area: guides;
`;

const PostsWrapper = styled.div`
  grid-area: posts;
  margin-top: 1em;
`;

const LinkToCombo = styled(Link)`
  font-weight: bold;
  color: var(--green);
  display: block;
  margin: 1em;
  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled.h4`
  margin: 0;
`;
