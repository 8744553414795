import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import {
  doFetchCombos,
  doChangeResultsPageQuery,
  doResetResultsPage
} from "../ResultsPage/ducks/actions";
import ComboPreview from "./ComboPreview";
import { doChangeSectionValue, doAddNewSectiontoEnd } from "./ducks/actions";

const select = ({ resultsPage }) => ({
  combos: resultsPage.posts
});

const actions = {
  fetchCombos: doFetchCombos,
  changeQuery: doChangeResultsPageQuery,
  changeSectionValue: doChangeSectionValue,
  addSectionToEnd: doAddNewSectiontoEnd,
  resetResults: doResetResultsPage
};

function ComboSelector({
  combos,
  fetchCombos,
  index,
  changeQuery,
  changeSectionValue,
  addSectionToEnd,
  resetResults,
}) {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
    return resetResults;
  }, []);

  return (
    <Container>
      <Input
        ref={inputRef}
        placeholder="keyword search for combo, and press Enter"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
      />
      <Results>
        {combos.map(data => (
          <ComboPreview
            {...data}
            key={data._id}
            onClick={() => {
              changeSectionValue(index, data);
              addSectionToEnd();
            }}
          />
        ))}
      </Results>
    </Container>
  );

  function handleKeyDown(e) {
    const { key } = e;
    if (key === "Enter") fetchCombos("5d5b6b21fe706ce02edf1a86");
  }

  function handleChange(e) {
    changeQuery(e.target.value);
  }
}

export default connect(
  select,
  actions
)(ComboSelector);

const Container = styled.div`
  background-color: var(--background-purple);

  margin-bottom: 1em;
  .game-preview-square {
    width: 20%;
  }
`;

const Input = styled.input`
  outline: none;
  border: none;
  width: 100%;
  background-color: transparent;
  font-size: 21px;
  color: white;
  padding-bottom: 0.5em;
  word-break: break-all;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

const Results = styled.div`
  max-height: 450px;
  overflow: scroll;
`;
