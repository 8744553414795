import { Book, Dashboard, Home, Settings } from "styled-icons/material";
import { useIsMobile, useScrollPosition } from "../../../2-utils/snippets";

import AuthorBubble from "../../Post/Description/AuthorBubble";
import { BeatLoader } from "react-spinners";
import CreateComboButton from "../../../2-utils/CreateComboButton";
import GradientText from "../../../2-utils/editables/GradientText";
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { doLogoutUser } from "../../LoginPage/ducks/actions";
import { doResetState } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ userInfo }) => ({
  displayName: userInfo.displayName,
  authenticated: userInfo.authenticated,
  userId: userInfo._id,
  photoUrl: userInfo.photoUrl
});

const actions = {
  logoutUser: doLogoutUser,
  resetState: doResetState
};

let previousScrollPosition = null;

function Nav({
  displayName,
  resetState,
  authenticated,
  logoutUser,
  userId,
  photoUrl
}) {
  const NavItems = authenticated ? LoggedInNavItems : LoggedOutNavItems;
  const scrollPosition = useScrollPosition();
  const isMobile = useIsMobile();
  const showFixed = isMobile;
  const showNav =
    previousScrollPosition > scrollPosition || scrollPosition < 200;
  previousScrollPosition = scrollPosition;
  return (
    <>
      {showFixed && <Spacer />}
      <Container showFixed={showFixed} showNav={showFixed && showNav}>
        <Logo to="/results" onClick={resetState}>
          comboguides
        </Logo>
        {authenticated === null && (
          <div style={{ marginLeft: "auto", marginRight: "1em" }}>
            <BeatLoader size={10} color="white" />
          </div>
        )}
        {authenticated !== null && (
          <NavItems
            resetState={resetState}
            name={displayName}
            onLogout={logoutUser}
            userId={userId}
            photoUrl={photoUrl}
          />
        )}
      </Container>
    </>
  );
}

export default connect(select, actions)(Nav);

function LoggedInNavItems({ name, onLogout, userId, photoUrl, resetState }) {
  const isMobile = useIsMobile();
  return (
    <>
      <ShiftedRightAlign>
        <Option
          to="/"
          onClick={() => {
            resetState();
            scrollToTop();
          }}
        >
          <HomeIcon />
        </Option>
        {!isMobile && (
          <Option to="/guide/default">
            <BookIcon />
          </Option>
        )}
        {/* <Option to="/discover">
          <DashboardIcon onClick={resetState} />
        </Option> */}

        <Option to="/settings">
          <SettingIcon />
        </Option>

        <CreateComboButton />

        <Option to={`/my-profile/${userId}`} style={{ marginRight: 0 }}>
          <AuthorBubble name={name} src={photoUrl} hideName />
        </Option>
      </ShiftedRightAlign>
    </>
  );
}

function LoggedOutNavItems({ name, resetState }) {
  return (
    <>
      <RightAlign>
        {/* <Option to="/discover" onClick={resetState}>
          <DashboardIcon onClick={resetState} />
        </Option> */}
        <Option to="/authenticate">
          <SignupButton>
            <GradientText color="var(--green-purple-gradient)">
              signup / login to create
            </GradientText>
          </SignupButton>
        </Option>
      </RightAlign>
    </>
  );
}

function scrollToTop() {
  window.scrollTo(0, 0);
}

const Container = styled.div`
  height: 40px;
  background: var(--purple-gradient);
  color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0em 1em;
  transition: 0.5s ease;
  padding-right: 0;
  ${({ showFixed }) =>
    showFixed &&
    `position: fixed;
    z-index: 101;
    width: 100%;
     top: 0;
      left: 0;
      transform: translateY(-120%);
      `}
  ${({ showNav }) => showNav && `transform: translateY(0%);`}
`;

const Spacer = styled.div`
  height: 40px;
  width: 100%;
`;

const Logo = styled(Link)`
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s ease;
  color: white;
  &:hover {
    color: var(--green);
  }
`;

const RightAlign = styled.div`
  margin-left: auto;
`;

const ShiftedRightAlign = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Option = styled(Link)`
  text-decoration: none;
  color: white;
  margin-right: 1em;
  &:hover {
    color: var(--green);
  }
`;

const SignupButton = styled.div`
  display: inline-block;
  background-color: white;
  padding: 0.5em 1em;
  box-shadow: var(--shadow-1);
  border-radius: 20px;
  color: var(--purple);
  font-size: 12px;
  font-weight: bold;
`;

const HomeIcon = styled(Home)`
  height: 20px;
`;

const BookIcon = styled(Book)`
  height: 20px;
`;

const DashboardIcon = styled(Dashboard)`
  height: 20px;
`;

const SettingIcon = styled(Settings)`
  height: 20px;
`;
