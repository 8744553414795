import DarkSelect, { DarkSelectMulti } from "../../../2-utils/DarkSelect";
import React, { useEffect, useState } from "react";
import {
  doFetchCharactersFromGame,
  doSelectCharacterForNewCombo
} from "../ducks/actions";

import Collapsible from "../../../2-utils/Collapsible";
import GamePreviewSquare from "../../HomePage/GameSelector/GamePreviewSquare";
import SelectCharacterForModal from "../../../2-utils/SelectCharacterForModal";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  selectedGameId: postEditor.gameId,
  selectedCharacterIds: postEditor.characterIds
});

const actions = {
  selectCharacter: doSelectCharacterForNewCombo
};

function SelectCharacterForPostEditor({
  characters,
  newComboCharacterId,
  selectedGameId,
  selectedCharacterIds,
  selectCharacter
}) {
  const selectedCharacters = characters.filter(char =>
    selectedCharacterIds.includes(char._id)
  );

  return (
    <div
      style={{
        opacity: selectedGameId ? 1 : 0.2,
        pointerEvents: selectedGameId ? "auto" : "none"
      }}
    >
      <h3>* Select Defender</h3>
      <DarkSelect
        options={convertCharactersToSelectFormat(characters)}
        value={convertCharactersToSelectFormat(selectedCharacters)[0]}
        placeholder="Select defender..."
        onChange={newOption => {
          console.log(newOption);
          selectCharacter([newOption] || []);
        }}
      />
    </div>
  );
}

export default connect(select, actions)(SelectCharacterForPostEditor);

function convertCharactersToSelectFormat(characters) {
  return characters.map(convertSingleCharacterToSelectFormat);
}

function convertSingleCharacterToSelectFormat(character) {
  if (!character) return {};
  return { value: character, label: character.name };
}
