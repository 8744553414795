export const JUMPED_POST_VIDEO_FRAME = "JUMPED_POST_VIDEO_FRAME";
export const SET_ANIMATION_SPEED = "SET_ANIMATION_SPEED";
export const DELTED_COMBO = "DELETED_COMBO";
export const CHANGED_LIKE_STATUS_FOR_COMBO = "CHANGED_LIKE_STATUS_FOR_COMBO";
export const TOGGLED_POST_AUDIO = "TOGGLED_POST_AUDIO";
export const RESET_POST = "RESET_POST";
export const SET_COMBO_FAVORITE_STATUS = "SET_COMBO_FAVORITE_STATUS";
export const SET_UPCOMING_ACTION_INDEX = "SET_UPCOMING_ACTION_INDEX";
export const TOGGLED_EDITING_POST = "TOGGLED_EDITING_POST";
export const CHANGED_NEW_COMMENT = "CHANGED_NEW_COMMENT";
export const STARTED_POSTING_COMMENT = "STARTED_POSTING_COMMENT";
export const FINISHED_POSTING_COMMENT = "FINISHED_POSTING_COMMENT";
export const FINISHED_FETCHING_COMMENTS_FOR_POST =
  "FINISHED_FETCHING_COMMENTS_FOR_POST";
export const FINISHED_REMOVING_COMMENT = "FINISHED_REMOVING_COMMENT";
export const ACTIVATED_TUTOR_MODE = "ACTIVATED_TUTOR_MODE";
export const DEACTIVATED_TUTOR_MODE = "DEACTIVATED_TUTOR_MODE";
export const CLEARED_POST_METADATA = "CLEARED_POST_METADATA";
export const TOGGLED_BREAKPOINT = "TOGGLED_BREAKPOINT";
export const HIT_BREAKPOINT = "HIT_BREAKPOINT";
export const RESOLVED_BREAKPOINT = "RESOLVED_BREAKPOINT";
export const TOGGLED_POST_VERIFICATION = "TOGGLED_POST_VERIFICATION";
export const TOGGLED_SHOW_CONTROLLER_OVERLAY =
  "TOGGLED_SHOW_CONTROLLER_OVERLAY";
