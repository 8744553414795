import Mario from "./characters/Mario";
import Ness from "./characters/Ness";
import DonkeyKong from "./characters/DonkeyKong";

const smashFrameData = {
  Mario,
  Ness,
  "Donkey Kong" : DonkeyKong
};

export default smashFrameData;
