import "../../../firebase";

// import * as firebase from "firebase";

import { BACKEND_URL, FRONT_END_URL } from "../../../App";
import {
  CHANGED_EMAIL_FOR_LOGIN,
  CHANGED_PASSWORD_FOR_LOGIN,
  CHANGED_USERNAME_FOR_LOGIN,
  ERRED_AUTHENTICATING_USER,
  FINISHED_AUTHENTICATING_USER_ON_FIREBASE,
  SIGNED_OUT_USER,
  STARTED_AUTHENTICATING_USER,
} from "./types";

import Axios from "axios";
import { doFetchAuthenticatedUser } from "../../UserInfo/ducks/actions";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const auth = getAuth();

export function doChangeUsernameForLogin(username) {
  const modifiedUsername = username.replace(/\s/g, "").toLowerCase();

  return {
    type: CHANGED_USERNAME_FOR_LOGIN,
    payload: { username: modifiedUsername },
  };
}

export function doChangePasswordForLogin(password) {
  return {
    type: CHANGED_PASSWORD_FOR_LOGIN,
    payload: { password },
  };
}

export function doChangeEmailForLogin(email) {
  return {
    type: CHANGED_EMAIL_FOR_LOGIN,
    payload: { email },
  };
}

export function doCreateUser() {
  return async (dispatch, getState) => {
    const { email, username, password } = getState().loginPage;

    // load spinner
    dispatch({ type: STARTED_AUTHENTICATING_USER });

    try {
      await createUserOnFirebabse(email, password, username);
      const currentUser = getCurrentAuthenticatedUser();
      const { _id } = getRelevantInfoFromCurrentUser(currentUser);
      await createUserOnBackend(email, username, _id);
      redirectToHomePage();
    } catch (err) {
      dispatch({
        type: ERRED_AUTHENTICATING_USER,
        payload: {
          errorMessage: err.message,
        },
      });
    }
  };
}

async function createUserOnFirebabse(email, password, username) {
  const { user } = await auth.createUserWithEmailAndPassword(email, password);
  // await user.updateProfile({ displayName: username });
}

async function createUserOnBackend(email, displayName, _id) {
  const url = `${BACKEND_URL}/new-user`;
  await Axios.post(url, {
    email,
    displayName,
    _id,
  });
}

function getCurrentAuthenticatedUser() {
  return auth.currentUser;
}

export function getRelevantInfoFromCurrentUser(currentUser) {
  return {
    email: currentUser.email,
    emailVerified: currentUser.emailVerified,
    refreshToken: currentUser.refreshToken,
    _id: currentUser.uid,
  };
}

export function doLoginUser() {
  return async (dispatch, getState) => {
    const { email, password } = getState().loginPage;
    try {
      dispatch({ type: STARTED_AUTHENTICATING_USER });
      await loginUserOnFirebase(email, password);
      redirectToHomePage();
    } catch (err) {
      dispatch({
        type: ERRED_AUTHENTICATING_USER,
        payload: {
          errorMessage: err.message,
        },
      });
    }
  };
}

async function loginUserOnFirebase(email, password) {
  await signInWithEmailAndPassword(auth, email, password);
}

function redirectToHomePage() {
  window.location.href = FRONT_END_URL;
}

export function doHandleAuthenticatedUser(userInfo) {
  return (dispatch, getState) => {
    dispatch(doFetchAuthenticatedUser(userInfo._id));
    dispatch({
      type: FINISHED_AUTHENTICATING_USER_ON_FIREBASE,
      payload: { userInfo },
    });
  };
}

export function doLogoutUser() {
  return async (dispatch, getState) => {
    try {
      await auth.signOut();
      dispatch(doRecordSignedOut());
      window.location.href = FRONT_END_URL + "/authenticate";
    } catch (err) {
      alert(err.message);
    }
  };
}

export function doRecordSignedOut() {
  return {
    type: SIGNED_OUT_USER,
  };
}
