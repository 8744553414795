import React, { useEffect, useRef, useState } from "react";
import { debounce, useIsMobile } from "../../../2-utils/snippets";
import { doFetchNextPageOfCombos, getDifficulty } from "../ducks/actions";
import {
  doGeneratePreview,
  generatePreviewAndDeleteOld
} from "../../Post/ducks/actions";

import { BeatLoader } from "react-spinners";
import GhostPreview from "./GhostPreview";
import { MoonAnimation } from "../../../2-utils/loadingAnimations";
import Preview from "./Preview";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => ({
  posts: resultsPage.posts,
  isLoadingMoreCombos: resultsPage.loadingMoreCombos,
  noMoreCombos: resultsPage.noMoreCombos,
  numberOfCombosLoadedAtATime: resultsPage.limit
});

const actions = {
  loadMoreCombos: doFetchNextPageOfCombos,
  generatePreview: doGeneratePreview
};

function PostPreviews({
  posts,
  loadMoreCombos,
  isLoadingMoreCombos,
  noMoreCombos,
  numberOfCombosLoadedAtATime,
  generatePreview
}) {
  const isMobile = useIsMobile();
  const scrollPosition = useScrollPosition();
  const shouldRefresh = useShouldRefresh(scrollPosition);
  const [firstPostLoaded, setFirstPostLoaded] = useState(false);

  console.log(
    JSON.stringify(
      posts.map(post => ({ videoFile: post.videoFile, preview: post.preview }))
    )
  );

  useEffect(() => {
    shouldRefresh && !isLoadingMoreCombos && !noMoreCombos && loadMoreCombos();
  }, [shouldRefresh, isLoadingMoreCombos, noMoreCombos]);

  return (
    <Container>
      {posts.length === 0 && noMoreCombos && (
        <NoCombosMessage>
          Sorry, no combos for your search. Try uploading your own!
        </NoCombosMessage>
      )}
      {posts.map((post, i) => (
        <DesktopGridWrapper>
          <Preview
            scrollPosition={scrollPosition}
            numberOfCombosLoadedAtATime={numberOfCombosLoadedAtATime}
            postId={post._id}
            authorId={post.authorData[0] && post.authorData[0]._id}
            characterIds={post.characterIds}
            characterId={post.characterId}
            videoFile={post.preview}
            verified={post.verified}
            thumbnail={post.thumbnail}
            title={post.name}
            tags={post.tags}
            percentRange={post.percentRange}
            numLikes={post.numLikes}
            numDislikes={post.numDislikes}
            index={i}
            key={`${post._id}-${post.name}`}
            authorName={post.authorData[0] && post.authorData[0].displayName}
            difficulty={getDifficulty(post)}
            onFirstPostLoaded={() => setFirstPostLoaded(true)}
          />
        </DesktopGridWrapper>
      ))}

      {isLoadingMoreCombos && posts && posts.length !== 0 && isMobile && (
        <LoadingAnimationWrapper>
          <BeatLoader size={10} color="white" />
        </LoadingAnimationWrapper>
      )}
      {isLoadingMoreCombos && !firstPostLoaded && isMobile && (
        <>
          <GhostPreview delay={0} />
          <GhostPreview delay={0.5} />
          <GhostPreview delay={1} />
        </>
      )}
      {isLoadingMoreCombos && !firstPostLoaded && !isMobile && (
        <>
          <GhostPreview delay={0} />
          <GhostPreview delay={0.5} />
          <GhostPreview delay={1} />
          <GhostPreview delay={1.5} />
          <GhostPreview delay={2} />
          <GhostPreview delay={2.5} />
          <GhostPreview delay={3} />
          <GhostPreview delay={3.5} />
          <GhostPreview delay={4} />
        </>
      )}
    </Container>
  );
}

export default connect(select, actions)(PostPreviews);
const Container = styled.div`
  @media (max-width: 600px) {
    min-height: 100vh;
  }
  padding-bottom: 100px;
  @media (min-width: 800px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

function useShouldRefresh(scrollPos) {
  const bottomOfPage = document.body.offsetHeight - window.innerHeight;
  const THRESHOLD_TO_LOAD_MORE = 200;
  return scrollPos + THRESHOLD_TO_LOAD_MORE > bottomOfPage;
}

function useScrollPosition() {
  const [scrollPos, setScrollPos] = useState(0);
  const debouncedSetScrollPos = () => setScrollPos(window.scrollY);
  useEffect(() => {
    window.addEventListener("scroll", debouncedSetScrollPos);
    return () => window.removeEventListener("scroll", debouncedSetScrollPos);
  });
  return scrollPos;
}

const LoadingAnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
`;

const DesktopGridWrapper = styled.div`
  @media (min-width: 600px) {
    min-width: calc(50% - 2em);
    width: calc(50% - 2em);
    margin: 0 1em;
    height: 100%;
  }
  @media (min-width: 1200px) {
    min-width: calc(33.3333% - 2em);
    width: calc(33.3333% - 2em);
    margin: 0 1em;
  }
`;

const NoCombosMessage = styled.h2`
  color: var(--pink);
`;
