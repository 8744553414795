import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ profilePage }) => ({
  numCombos: profilePage.combos.length
});

function TotalViews({ numCombos }) {
  return (
    <Container>
      <h1>
        {numCombos} <br />
        combos
      </h1>
    </Container>
  );
}

export default connect(select)(TotalViews);

const Container = styled.aside`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
`;
