import React, { useRef } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import Overlay from "../../2-utils/Overlay";
import { InvisibleFileInput } from "../../2-utils/inputs";
import { doSelectPreviewImageForGuide } from "./ducks/actions";
import { MoonLoader } from "react-spinners";

const actions = {
  selectPreviewImageForGuide: doSelectPreviewImageForGuide
};

const select = ({ guidePage }) => ({
  previewImage: guidePage.metaData.previewImage,
  isUploadingPreviewImage: guidePage.isUploadingPreviewImage
});

function ImageUploadForGuideInterface({
  isUploadingPreviewImage,
  previewImage,
  selectPreviewImageForGuide
}) {
  const imageInputRef = useRef();
  return (
    <Container>
      <Image src={previewImage} />

      <Overlay>
        {!isUploadingPreviewImage && (
          <Button onClick={clickImageInputRef}>
            {previewImage ? "upload a different picture" : "upload picture"}
          </Button>
        )}
        {isUploadingPreviewImage && <MoonLoader color="var(--green)" />}
        <InvisibleFileInput
          accept="image/*"
          type="file"
          onChange={handleFileUpload}
          ref={imageInputRef}
        />
      </Overlay>
    </Container>
  );

  function clickImageInputRef() {
    imageInputRef.current.click();
  }

  function handleFileUpload(e) {
    try {
      const file = e.target.files[0];
      const BYTES_PER_MB = 1000000;
      const fileSizeInMb = file.size / BYTES_PER_MB;
      if (fileSizeInMb > 5) {
        alert("Picture size is bigger than 5MB. Please compress your picture");
      } else {
        const fileUrl = window.URL.createObjectURL(file);
        selectPreviewImageForGuide(fileUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default connect(
  select,
  actions
)(ImageUploadForGuideInterface);

const Container = styled.div`
  position: relative;
`;

const Image = styled.div`
  width: 100%;
  padding-bottom: 56.75%;
  background: ${({ src }) => (src ? `url(${src})` : "rgba(255,255,255,0.1)")};
  background-size: cover;
`;

const Button = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5em 1em;
  color: black;
  box-shadow: var(--shadow-1);
  font-weight: bold;
  border-radius: 8px;
`;
