import {
  ADDED_CHARACTER_TO_RESULTS_FILTER,
  CHANGED_RESULTS_PAGE_QUERY,
  CLEARED_RESULT_FILTERS,
  CLOSED_POST,
  FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE,
  FINISHED_FETCHING_COMBOS,
  FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST,
  FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS,
  MODIFIED_COMBO_IN_RESULTS_PAGE,
  MOVED_TO_NEXT_COMBO,
  MOVED_TO_PREVIOUS_COMBO,
  OPENED_POST,
  REMOVED_CHARACTER_FROM_RESULTS_FILTER,
  RESET_RESULTS_PAGE,
  SELECTED_GAME_FOR_RESULTS_PAGE,
  SET_PERCENT_RANGE_RESULTS_PAGE,
  SET_PREFERRED_CONTROLLER,
  SET_RESULTS_PAGE_FILTERS,
  SET_RESULTS_PAGE_PRIMARY_CHARACTER,
  SET_TAGS_FILTER_RESULTS_PAGE,
  STARTED_FETCHING_COMBOS,
  STARTED_FETCHING_NEXT_PAGE_OF_COMBOS,
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";
import { doFetchAvailableGames } from "../../HomePage/ducks/actions";
import { isMobile as isMobileFunc } from "../../../2-utils/snippets";

let debouncedFetchCombosTimeout = null;

const isMobile = isMobileFunc();

export function doSetPercentRangeForResultsPage(percentRange) {
  return (dispatch) => {
    dispatch({
      type: SET_PERCENT_RANGE_RESULTS_PAGE,
      payload: {
        percentRange,
      },
    });
    if (debouncedFetchCombosTimeout) {
      clearTimeout(debouncedFetchCombosTimeout);
    }
    debouncedFetchCombosTimeout = setTimeout(
      () => dispatch(doFetchCombos()),
      500
    );
  };
}

export function doSetResultsPageFilters(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RESULTS_PAGE_FILTERS,
      payload: {
        filteredCharacters: filters.map((f) => f.value),
      },
    });
    !isMobile && dispatch(doFetchCombos());
  };
}

export function doModifyComboInResultsPage({
  comboId,
  actions,
  name,
  description,
  characterIds,
  characterId,
  percentRange,
  tags,
}) {
  return (dispatch, getState) => {
    const { posts, activePostIndex } = getState().resultsPage;
    if (!activePostIndex) return;
    const combo = posts[activePostIndex];
    combo.name = name;
    combo.description = description;
    combo.actions = actions;
    combo.characterIds = characterIds;
    combo.percentRange = percentRange;
    combo.tags = tags;
    combo.characterId = characterId;
    dispatch({
      type: MODIFIED_COMBO_IN_RESULTS_PAGE,
      payload: {
        posts,
      },
    });
  };
}

export function doGoToNextCombo() {
  return {
    type: MOVED_TO_NEXT_COMBO,
  };
}

export function doGoToPreviousCombo() {
  return {
    type: MOVED_TO_PREVIOUS_COMBO,
  };
}

export function doSetPreferredController(controllerType) {
  return (dispatch, getState) => {
    const state = getState();
    const { posts, activePostIndex } = state.resultsPage;
    const currentCombo = posts[activePostIndex];

    const url = `${BACKEND_URL}/set-preferred-controller`;
    if (state.userInfo.authenticated) {
      Axios.post(url, {
        userId: state.userInfo._id,
        controllerType: controllerType,
        gameId: currentCombo.gameId,
      });
    }

    dispatch({
      type: SET_PREFERRED_CONTROLLER,
      payload: {
        preferredController: controllerType,
        gameId: currentCombo.gameId,
      },
    });
  };
}

export function doOpenPost(postIndex) {
  return {
    type: OPENED_POST,
    payload: {
      postIndex,
    },
  };
}

export function doClosePost() {
  return {
    type: CLOSED_POST,
  };
}

export function doSelectGameForResultsPage(gameId) {
  return (dispatch, getState) => {
    if (gameId === getState().resultsPage.gameId) return;
    dispatch({
      type: SELECTED_GAME_FOR_RESULTS_PAGE,
      payload: {
        gameId,
      },
    });
  };
}

export function doChangeResultsPageQuery(query) {
  return (dispatch, getState) => {
    const state = getState();
    const characters = state.resultsPage.characters;
    const charactersInQuery = getCharactersInQuery(query, characters);
    dispatch({
      type: CHANGED_RESULTS_PAGE_QUERY,
      payload: {
        query,
        charactersInQuery,
      },
    });
  };
}

function getCharactersInQuery(query, characters) {
  const words = query.split(" ").map((word) => word.toLowerCase());
  const charactersInQuery = characters.filter((char) =>
    words.includes(char.name.toLowerCase())
  );
  return charactersInQuery;
}

function getQueryWithoutCharacterNames(query, characters) {
  const words = query.split(" ").map((word) => word.toLowerCase());
  const charactersInQuery = characters
    .filter((char) => words.includes(char.name.toLowerCase()))
    .map((char) => char.name.toLowerCase());
  console.log(charactersInQuery);
  return words.filter((word) => !charactersInQuery.includes(word)).join(" ");
}

export function doFetchCombos() {
  const skip = 0;
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: STARTED_FETCHING_COMBOS,
    });

    const {
      limit,
      filteredCharacters,
      charactersInQuery,
      percentRange,
      selectedTags,
      primaryCharacterId,
      gameId,
    } = state.resultsPage;
    const userId = state.userInfo._id;
    const shouldWait = state.userInfo.authenticated === null;
    if (shouldWait) {
      return setTimeout(() => dispatch(doFetchCombos(gameId)), 200);
    }
    window.scrollTo(0, 0);

    const queryWithoutCharacters = getQueryWithoutCharacterNames(
      state.resultsPage.query,
      state.resultsPage.characters
    );
    const filters = [...filteredCharacters, ...charactersInQuery];
    const url = `${BACKEND_URL}/game/combos/${userId}/${gameId}/${skip}/${limit}/${primaryCharacterId}/${JSON.stringify(
      filters.map((char) => char._id)
    )}/${queryWithoutCharacters || "null"}/${JSON.stringify(
      percentRange
    )}/${JSON.stringify(selectedTags)}`;
    console.log(url);
    Axios.get(url, { filteredCharacters })
      .then((r) => {
        const combos = r.data;
        dispatch(doFetchAvailableGames());
        dispatch({
          type: FINISHED_FETCHING_COMBOS,
          payload: { combos, skip: limit },
        });
      })
      .catch((e) => {
        console.log("ERROR IN FETCHING COMBOS", e);
      });
  };
}

export function doFetchComboForStandalonePost(comboId) {
  return (dispatch, getState) => {
    dispatch({
      type: STARTED_FETCHING_COMBOS,
    });

    const shouldWait = getState().userInfo.authenticated === null;
    const userId = getState().userInfo._id;
    if (shouldWait) {
      return setTimeout(
        () => dispatch(doFetchComboForStandalonePost(comboId)),
        200
      );
    }

    const url = `${BACKEND_URL}/combo/${comboId}/${userId}`;

    Axios.get(url).then((r) => {
      const combos = r.data;
      dispatch(doFetchAvailableGames());
      dispatch({
        type: FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST,
        payload: { combos: combos },
      });
    });
  };
}

export function fetchCombo(comboId, userId) {
  const url = `${BACKEND_URL}/combo/${comboId}/${userId}`;

  return Axios.get(url).then((r) => {
    const combos = r.data;
    return combos[0];
  });
}

export function doFetchNextPageOfCombos() {
  return (dispatch, getState) => {
    dispatch({ type: STARTED_FETCHING_NEXT_PAGE_OF_COMBOS });

    const state = getState();
    const userId = state.userInfo._id;
    const gameId = state.resultsPage.gameId;
    const queryWithoutCharacters = getQueryWithoutCharacterNames(
      state.resultsPage.query,
      state.resultsPage.characters
    );
    const {
      limit,
      skip,
      filteredCharacters,
      charactersInQuery,
      percentRange,
      selectedTags,
      primaryCharacterId,
    } = state.resultsPage;
    const filters = [...filteredCharacters, ...charactersInQuery];
    const url = `${BACKEND_URL}/game/combos/${userId}/${gameId}/${skip}/${limit}/${primaryCharacterId}/${JSON.stringify(
      filters.map((char) => char._id)
    )}/${queryWithoutCharacters || "null"}/${JSON.stringify(
      percentRange
    )}/${JSON.stringify(selectedTags)}`;

    Axios.get(url)
      .then((r) => {
        const combos = r.data;
        dispatch({
          type: FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS,
          payload: {
            combos,
            skip: skip + combos.length,
          },
        });
      })
      .catch((e) => {
        console.log("ERROR IN FETCHING NEXT PAGE OF COMBOS", e);
      });
  };
}

export function doResetResultsPage() {
  return {
    type: RESET_RESULTS_PAGE,
  };
}

export function doFetchCharactersForFilters(gameId) {
  return (dispatch, getState) => {
    const url = BACKEND_URL + `/game/characters/${gameId}`;
    Axios.get(url).then((r) =>
      dispatch({
        type: FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE,
        payload: {
          characters: r.data,
        },
      })
    );
  };
}

export function doAddCharacterToResultFilters(character) {
  return (dispatch, getState) => {
    const gameId = getState().resultsPage.gameId;
    dispatch({
      type: ADDED_CHARACTER_TO_RESULTS_FILTER,
      payload: {
        character,
      },
    });
    dispatch(doFetchCombos(gameId));
  };
}

export function doRemoveCharacterFromResultFilters(character) {
  return (dispatch, getState) => {
    const gameId = getState().resultsPage.gameId;
    dispatch({
      type: REMOVED_CHARACTER_FROM_RESULTS_FILTER,
      payload: {
        character,
      },
    });
    dispatch(doFetchCombos(gameId));
  };
}

export function doClearResultFilters() {
  return (dispatch, getState) => {
    const gameId = getState().resultsPage.gameId;
    dispatch({ type: CLEARED_RESULT_FILTERS });
    dispatch(doFetchCombos(gameId));
  };
}

export function getDifficulty(combo) {
  if (!combo.actions) return "Easy";
  let score = 0;
  const actions = combo.actions;

  // Combos with fast inputs are harder
  const averageTimeBetweenFrames = _getAverageTimeBetweenFrames(actions);
  if (averageTimeBetweenFrames > 20) score += 1;
  else if (averageTimeBetweenFrames > 10) score += 3;
  else score += 5;

  // Longer combos are harder
  if (actions.length > 10) score += 6;
  if (actions.length > 8) score += 5;
  else if (actions.length > 4) score += 3;
  else score += 1;

  if (score > 7) return "Hard";
  if (score > 4) return "Medium";
  if (score > 0) return "Easy";
}

export function getColorFromDifficulty(difficulty) {
  switch (difficulty) {
    case "Easy":
      return "#76ff03";
    case "Medium":
      return "yellow";
    case "Hard":
      return "red";
    default:
      return "pink";
  }
}

function _getAverageTimeBetweenFrames(actions) {
  if (!actions) return 0;
  return (
    actions.reduce((total, currentValue, currentIndex, arr) => {
      if (currentIndex + 1 < actions.length) {
        return total + arr[currentIndex + 1].frame - currentValue.frame;
      }
      return total;
    }, 0) / actions.length
  );
}

export function doChangeResultsPageTags(selectedTags) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_TAGS_FILTER_RESULTS_PAGE,
      payload: {
        selectedTags,
      },
    });
    !isMobile && dispatch(doFetchCombos());
  };
}

export function doSetResultsPagePrimaryCharacter(primaryCharacter) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_RESULTS_PAGE_PRIMARY_CHARACTER,
      payload: {
        primaryCharacterId: primaryCharacter
          ? primaryCharacter.value._id
          : null,
      },
    });
    !isMobile && dispatch(doFetchCombos());
  };
}
