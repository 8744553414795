import {
  CHANGED_NEW_COMBO_DESCRIPTION,
  CHANGED_NEW_COMBO_NAME,
  CHANGED_PERCENT_RANGE,
  FINISHED_POSTING_COMBO_TO_BACKEND,
  JUMPED_FRAME,
  MOVED_TO_NEXT_FRAME,
  MOVED_TO_PREVIOUS_FRAME,
  REMOVED_CONTROL_STICK_FROM_FRAME,
  RESET_POST_EDITOR,
  SELECTED_CHARACTER_FOR_NEW_COMBO,
  SELECTED_GAME_FOR_NEW_COMBO,
  SELECTED_PRESS,
  SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR,
  SELECTED_VIDEO,
  SET_COMBO_IN_POST_EDITOR,
  SET_VIDEO_DURATION,
  STARTED_HOLD,
  STARTED_POSTING_COMBO_TO_BACKEND,
  TOGGLED_TAG,
  UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE,
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";
import { doFetchUserCombos } from "../../ProfilePage/ducks/actions";
import { doModifyComboInResultsPage } from "../../ResultsPage/ducks/actions";
import toast from "../../../2-utils/toast";
import { getStorage } from "firebase/storage";

// todo, on pushing combo to backend, make sure there's no empty frames

export function doFetchCharactersFromGame(gameId) {
  return (dispatch, getState) => {
    const url = BACKEND_URL + `/game/characters/${gameId}`;
    return Axios.get(url).then((r) => r.data);
  };
}

export function doSelectGameForNewCombo(game) {
  return {
    type: SELECTED_GAME_FOR_NEW_COMBO,
    payload: {
      game,
    },
  };
}

export function doChangeNewComboName(comboName) {
  return {
    type: CHANGED_NEW_COMBO_NAME,
    payload: {
      comboName,
    },
  };
}

export function doChangeNewComboDescription(description) {
  return {
    type: CHANGED_NEW_COMBO_DESCRIPTION,
    payload: {
      description,
    },
  };
}

export function doSelectCharacterForNewCombo(characters) {
  return {
    type: SELECTED_CHARACTER_FOR_NEW_COMBO,
    payload: {
      characterIds: characters
        .filter((char) => char)
        .map((char) => char.value._id),
    },
  };
}

export function doSelectPrimaryCharacterForNewCombo(character) {
  return {
    type: SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR,
    payload: {
      characterId: character && character.value._id,
    },
  };
}

export function doSetComboInPostEditor(combo) {
  return {
    type: SET_COMBO_IN_POST_EDITOR,
    payload: { combo },
  };
}

export function doSaveCombo(callback) {
  return (dispatch, getState) => {
    const {
      comboId,
      name,
      actions,
      description,
      gameId,
      characterIds,
      characterId,
      percentRange,
      tags,
    } = getState().postEditor;

    const updatableFields = {
      comboId,
      name,
      actions,
      description,
      gameId,
      characterIds,
      characterId,
      percentRange,
      tags,
    };
    const url = BACKEND_URL + "/update-combo";
    Axios.post(url, updatableFields).then((r) => {
      toast("Combo has been updated, heck yeah");
      dispatch(
        doModifyComboInResultsPage({
          comboId,
          actions,
          name,
          description,
          characterId,
          characterIds,
          percentRange,
          tags,
        })
      );
      callback();
    });
  };
}

export function doPostComboNew(callback) {
  return async (dispatch, getState) => {
    const { postEditor, userInfo } = getState();
    const {
      characterIds,
      characterId,
      gameId,
      actions,
      name,
      videoFile,
      percentRange,
      tags,
      description,
    } = postEditor;

    const userId = userInfo._id;

    const videoData = await getFileFromLocalUrl(videoFile);
    var bodyFormData = new FormData();

    bodyFormData.set("characterId", characterId);
    bodyFormData.set("characterIds", JSON.stringify(characterIds));
    bodyFormData.set("gameId", gameId);
    bodyFormData.set("actions", JSON.stringify(actions));
    bodyFormData.set("authorId", userId);
    bodyFormData.set("name", name);
    bodyFormData.set("description", description);
    bodyFormData.append("videoFile", videoData);
    bodyFormData.append("percentRange", JSON.stringify(percentRange));
    bodyFormData.append("tags", JSON.stringify(tags));

    dispatch({ type: STARTED_POSTING_COMBO_TO_BACKEND });

    Axios({
      method: "post",
      // url: "http://localhost:3005/add-combo",
      url: BACKEND_URL + "/add-combo",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((r) => {
        dispatch({ type: FINISHED_POSTING_COMBO_TO_BACKEND });
        setTimeout(() => {
          if (callback) callback();
          toast("Awesome! Your combo has been created");
        }, 500);
      })
      .catch(() => console.log("oh no"));
  };
}

export function reprocessVideo(videoFile) {
  Axios.post(BACKEND_URL + "/re-process-videos", { videoFile });
}

export function doPostCombo(callback) {
  return async (dispatch, getState) => {
    const { postEditor, userInfo } = getState();
    const {
      characterId,
      characterIds,
      gameId,
      actions,
      name,
      videoFile,
      description,
    } = postEditor;
    const userId = userInfo._id;

    dispatch({ type: STARTED_POSTING_COMBO_TO_BACKEND });
    const videoData = await getFileFromLocalUrl(videoFile);
    uploadFileToFirebase(videoData);

    function uploadFileToFirebase(file) {
      const filePath = getPathWhereFileWillLive(userId, gameId, characterId);
      const storageRef = getFirebaseStorageRef(filePath);
      const task = storageRef.put(file);
      task.on("state_changed", progress, error, complete(storageRef));
    }

    function progress(snapshot) {
      const { bytesTransferred, totalBytes } = snapshot;
      const percentageUploaded = Math.floor(
        (bytesTransferred / totalBytes) * 100
      );
      dispatch({
        type: UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE,
        payload: {
          percentUploaded: percentageUploaded,
        },
      });
    }

    function error(err) {
      console.log(err);
    }

    function complete(storageRef) {
      return async () => {
        const downloadUrl = await storageRef.getDownloadURL();

        const comboToSave = {
          characterId,
          characterIds,
          gameId,
          actions,
          name,
          videoFile: downloadUrl,
          authorId: userId,
          description,
        };

        const backendURL = BACKEND_URL + "/add-combo";

        Axios.post(backendURL, comboToSave).then((r) => {
          dispatch({ type: FINISHED_POSTING_COMBO_TO_BACKEND });
          setTimeout(() => {
            if (callback) callback();
            toast("Awesome! Your combo has been created");
          }, 500);
        });
      };
    }
  };
}

function getFileFromLocalUrl(fileUrl) {
  return fetch(fileUrl).then((r) => r.blob());
}

function getPathWhereFileWillLive(userId, gameId, characterId) {
  const uniqueId = Date.now();
  return `user-${userId}/game-${gameId}/char-${characterId}/${uniqueId}`;
}

function getFirebaseStorageRef(filepath) {
  return getStorage().ref(filepath);
}

// FRAME STUFF

export function doMoveToNextFrame() {
  return {
    type: MOVED_TO_NEXT_FRAME,
  };
}

export function doMoveToPreviousFrame() {
  return {
    type: MOVED_TO_PREVIOUS_FRAME,
  };
}

export function doJumpToFrame(newFrame) {
  return (dispatch, getState) => {
    const { videoDuration } = getState().postEditor;
    const videoDurationInFrames = Math.floor(videoDuration * 60);
    if (newFrame < 0) newFrame = 0;
    if (newFrame > videoDurationInFrames) newFrame = videoDurationInFrames;
    dispatch({
      type: JUMPED_FRAME,
      payload: {
        newFrame,
      },
    });
  };
}

export function doTogglePress(type) {
  return (dispatch, getState) => {
    const { frame, actions } = getState().postEditor;
    let newActions = deepCopy(actions);
    const frameData = newActions.filter((action) => action.frame === frame)[0];
    if (!frameData) {
      newActions = createNewActionFrame(newActions, frame);
      dispatch(doSelectPress(frame, newActions, type));
    } else {
      const selected = isAlreadyPressed(frameData, type);
      !selected
        ? dispatch(doSelectPress(frame, newActions, type))
        : dispatch(doDeselectPress(frame, newActions, type));
    }
  };
}

export function doRemoveControlStickFromFrame() {
  return (dispatch, getState) => {
    const { frame, actions } = getState().postEditor;
    let newActions = deepCopy(actions);
    const frameData = newActions.filter((action) => action.frame === frame)[0];
    if (!frameData) return;
    frameData.presses = frameData.presses.filter(
      handleFilterOutControlSticksThatAreNotEndHolds
    );
    if (isEmptyFrame(frameData.presses))
      newActions = removeFrameFromActions(newActions, frame);
    dispatch({
      type: REMOVED_CONTROL_STICK_FROM_FRAME,
      payload: {
        newActions,
      },
    });
  };
}

function handleFilterOutControlSticksThatAreNotEndHolds(press) {
  const isControlStick = press.type.split("_")[0] === "CONTROL";
  if (isControlStick && press.hold !== "END_HOLD") return false;
  return true;
}

export function doRemoveCStickFromFrame() {
  return (dispatch, getState) => {
    const { frame, actions } = getState().postEditor;
    let newActions = deepCopy(actions);
    const frameData = newActions.filter((action) => action.frame === frame)[0];
    if (!frameData) return;
    frameData.presses = frameData.presses.filter(
      handleFilterOutCSticksThatAreNotEndHolds
    );
    if (isEmptyFrame(frameData.presses))
      newActions = removeFrameFromActions(newActions, frame);
    dispatch({
      type: REMOVED_CONTROL_STICK_FROM_FRAME,
      payload: {
        newActions,
      },
    });
  };
}

function handleFilterOutCSticksThatAreNotEndHolds(press) {
  const isControlStick = press.type.split("_")[0] === "C";
  if (isControlStick && press.hold !== "END_HOLD") return false;
  return true;
}

export function doChangeHoldForPress(type) {
  return (dispatch, getState) => {
    const { frame, actions } = getState().postEditor;
    let newActions = deepCopy(actions);
    let frameData = newActions.find((action) => action.frame === frame);

    // If there is no frame data, this means this is a empty action
    // and the first press is ending a hold
    if (!frameData) {
      const previousAction = actions
        .filter((action) => action.frame < frame)
        .reverse()[0];

      let holds = previousAction.presses
        .filter((press) => ["START_HOLD", "MAINTAIN_HOLD"].includes(press.hold))
        .map((press) => ({ ...press, hold: "MAINTAIN_HOLD" }));

      const targetPress = holds.find((press) => press.type === type);
      targetPress.hold = "END_HOLD";
      newActions = createNewActionFrame(newActions, frame);
      const targetAction = newActions.find((action) => action.frame === frame);
      targetAction.presses = holds;

      frameData = newActions.find((action) => action.frame === frame);
      const startPress = findStartPress();
      startPress.forEach(
        (item) =>
          (item.presses.find((press) => press.type === type).isComplete = true)
      );
      removeMaintainHoldsToTheRight();
    } else {
      const press = frameData.presses.find((press) => press.type === type);

      // starting a new hold
      if (!press.hold || press.hold === "SELECT_NO_HOLD") {
        press.hold = "START_HOLD";
        press.isComplete = false;
        addMaintainHoldsToTheRight(newActions, frameData, type);
      }
      // cancelling a start hold
      else if (press.hold === "START_HOLD") {
        press.hold = null;
        removeMaintainHoldsToTheRight();
      }
      // ending a hold
      else if (press.hold === "MAINTAIN_HOLD") {
        press.hold = "END_HOLD";
        const startPress = findStartPress();
        startPress.forEach(
          (item) =>
            (item.presses.find(
              (press) => press.type === type
            ).isComplete = true)
        );
        removeMaintainHoldsToTheRight();
      }
      // unending a hold
      else if (press.hold === "END_HOLD") {
        press.hold = "MAINTAIN_HOLD";
        const startPress = findStartPress();
        startPress.forEach(
          (item) =>
            (item.presses.find(
              (press) => press.type === type
            ).isComplete = false)
        );

        addMaintainHoldsToTheRight(newActions, frameData, type);

        if (isEmptyFrame(frameData.presses))
          newActions = removeFrameFromActions(newActions, frame);
      }
    }

    dispatch({
      type: STARTED_HOLD,
      payload: {
        newActions,
      },
    });

    function findStartPress() {
      const result = [];
      let index = newActions.indexOf(frameData);
      while (true) {
        const startPress = newActions[index].presses.find(
          (press) => press.type === type && press.hold === "START_HOLD"
        );
        result.push(newActions[index]);
        if (startPress) return result;
        else index -= 1;
      }
    }

    function removeMaintainHoldsToTheRight() {
      const startIndex = newActions.indexOf(frameData) + 1;

      for (let i = startIndex; i < newActions.length; i++) {
        const action = newActions[i];

        const pressOfSameType = action.presses.find(
          (press) => press.type === type
        );

        // we remove the maintains and corresponding end-hold
        if (!pressOfSameType) break;
        if (
          pressOfSameType.hold !== "MAINTAIN_HOLD" &&
          pressOfSameType.hold !== "END_HOLD"
        )
          break;

        action.presses = action.presses.filter((press) => press.type !== type);

        // if the action now has no presses, mark to remove it
        if (action.presses.length === 0) {
          action.markedForDeletion = true;
        }
      }

      newActions = newActions.filter((action) => !action.markedForDeletion);
    }
  };
}

// there is a bug on trying to end a hold on a press of the same class
function addMaintainHoldsToTheRight(newActions, frameData, type) {
  const startIndex = newActions.indexOf(frameData) + 1;

  for (let i = startIndex; i < newActions.length; i++) {
    const action = newActions[i];

    const pressOfSameType = action.presses.find(
      (press) => press.type.slice(0, 7) === type.slice(0, 7)
    );

    action.presses.push({
      type,
      hold: "MAINTAIN_HOLD",
      isComplete: false,
    });

    //this could be a solution
    if (pressOfSameType) {
      return;
    }
  }
}

function createNewActionFrame(newActions, frame) {
  newActions.push({
    frame: frame,
    presses: getDefaultPressData(newActions),
  });
  newActions = newActions.sort(sortActionsComparator);
  return newActions;

  function getDefaultPressData(actions) {
    const previousAction = actions
      .filter((action) => action.frame < frame)
      .reverse()[0];
    if (!previousAction) return [];

    let holds = previousAction.presses
      .filter((press) => ["START_HOLD", "MAINTAIN_HOLD"].includes(press.hold))
      .map((press) => ({ ...press, hold: "MAINTAIN_HOLD" }));

    return holds;
  }
}

function isAlreadyPressed(frameData, type) {
  return frameData.presses.filter((press) => press.type === type).length;
}

export function doSelectPress(frame, newActions, type) {
  return (dispatch, getState) => {
    const frameData = newActions.filter((action) => action.frame === frame)[0];
    frameData.presses.push({ type });
    dispatch({
      type: SELECTED_PRESS,
      payload: {
        newActions,
      },
    });
  };
}

export function doDeselectPress(frame, newActions, type) {
  return (dispatch, getState) => {
    const frameDataIndex = newActions.findIndex(
      (action) => action.frame === frame
    );
    const frameData = newActions[frameDataIndex];

    // first look at the previous frame
    if (frameDataIndex) {
      const previousFrameData = newActions[frameDataIndex - 1];
      const samePressInPreviousFrame = previousFrameData.presses.find(
        (press) => press.type === type
      );

      // if there is a MAINTAIN_HOLD for this press, change this and don't remove, add maintins tot the right
      if (
        samePressInPreviousFrame &&
        (samePressInPreviousFrame.hold === "MAINTAIN_HOLD" ||
          samePressInPreviousFrame.hold === "START_HOLD")
      ) {
        const thisFramesPress = frameData.presses.find(
          (press) => press.type === type
        );
        thisFramesPress.hold = "MAINTAIN_HOLD";
        thisFramesPress.isComplete = false;
        addMaintainHoldsToTheRight(newActions, frameData, type);
      }
      // otherwise remove like noraml
      else {
        frameData.presses = removePressFromFrame(frameData, type);
      }
    }
    // if nothing is behind it, just remove
    else {
      frameData.presses = removePressFromFrame(frameData, type);
    }

    // check if the previous frame has maintain holds for this type
    // then extend maintian holds here and to the right

    if (isEmptyFrame(frameData.presses))
      newActions = removeFrameFromActions(newActions, frame);

    dispatch({
      type: SELECTED_PRESS,
      payload: {
        newActions,
      },
    });
  };
}

function isEmptyFrame(presses) {
  const filterOutMaintainHolds = (press) => {
    if (press.hold === "MAINTAIN_HOLD") return false;
    return true;
  };
  const importantPresses = presses.filter(filterOutMaintainHolds);

  if (importantPresses.length == 0) return true;
}

function removeFrameFromActions(newActions, frame) {
  return newActions.filter((action) => action.frame !== frame);
}

function removePressFromFrame(frameData, type) {
  return frameData.presses.filter((press) => press.type !== type);
}

function sortActionsComparator(left, right) {
  return left.frame > right.frame ? 1 : -1;
}

function deepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

export function doSetVideoDuration(videoDuration) {
  return {
    type: SET_VIDEO_DURATION,
    payload: {
      videoDuration,
    },
  };
}

export function doSelectVideo(videoFile) {
  return {
    type: SELECTED_VIDEO,
    payload: {
      videoFile,
    },
  };
}

export function doResetPostEditor() {
  return {
    type: RESET_POST_EDITOR,
  };
}

export function doChangePercentRange(percentRange) {
  return {
    type: CHANGED_PERCENT_RANGE,
    payload: {
      percentRange,
    },
  };
}

export function doToggleTag(tag) {
  return (dispatch, getState) => {
    const tags = getState().postEditor.tags;
    let newTags = tags.includes(tag)
      ? tags.filter((t) => t !== tag)
      : [...tags, tag];
    dispatch({
      type: TOGGLED_TAG,
      payload: {
        tags: newTags,
      },
    });
  };
}
