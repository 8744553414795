import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

function Modal({ children, onClose, noCentering, ...props }) {
  return (
    <Container onClick={handleClose} {...props} noCentering={noCentering}>
      <Content onClick={disableBackgroundClick}>{children}</Content>
    </Container>
  );

  function handleClose(e) {
    onClose();
  }

  function disableBackgroundClick(e) {
    e.stopPropagation();
  }
}

export default connect()(Modal);

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: ${({ noCentering }) => (noCentering ? "flex-start" : "center")};
  cursor: pointer;
  z-index: 1000;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
`;

const Content = styled.div`
  cursor: default;
  animation: fadeIn 0.5s ease;
  box-sizing: border-box;
  @media (max-width: 600px) {
    animation: none;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
