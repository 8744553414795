export const CONTROL_STICK_UP = "CONTROL_STICK_UP";
export const CONTROL_STICK_DOWN = "CONTROL_STICK_DOWN";
export const CONTROL_STICK_RIGHT = "CONTROL_STICK_RIGHT";
export const CONTROL_STICK_LEFT = "CONTROL_STICK_LEFT";

export const CONTROL_STICK_UP_LEFT = "CONTROL_STICK_UP_LEFT";
export const CONTROL_STICK_UP_RIGHT = "CONTROL_STICK_UP_RIGHT";
export const CONTROL_STICK_DOWN_LEFT = "CONTROL_STICK_DOWN_LEFT";
export const CONTROL_STICK_DOWN_RIGHT = "CONTROL_STICK_DOWN_RIGHT";

export const A_BUTTON = "A_BUTTON";
export const B_BUTTON = "B_BUTTON";
export const X_BUTTON = "X_BUTTON";
export const Y_BUTTON = "Y_BUTTON";

export const C_STICK_UP = "C_STICK_UP";
export const C_STICK_DOWN = "C_STICK_DOWN";
export const C_STICK_RIGHT = "C_STICK_RIGHT";
export const C_STICK_LEFT = "C_STICK_LEFT";

export const C_STICK_UP_LEFT = "C_STICK_UP_LEFT";
export const C_STICK_UP_RIGHT = "C_STICK_UP_RIGHT";
export const C_STICK_DOWN_LEFT = "C_STICK_DOWN_LEFT";
export const C_STICK_DOWN_RIGHT = "C_STICK_DOWN_RIGHT";

export const L_TRIGGER = "L_TRIGGER";
export const R_TRIGGER = "R_TRIGGER";

export const L_Z = "L_Z";
export const R_Z = "R_Z";

export function pressToTextRepresentation(press) {
  const parts = press.type.split("_");
  let action = getAction(press);
  if (press.hold === "END_HOLD") return;
  // buttons
  if (["BUTTON", "Z", "TRIGGER"].includes(parts[parts.length - 1])) {
    action = action || "Press";
    return `${action} ${parts[0]}`;
  }

  // axes
  const stickType = parts[0] === "C" ? "C Stick" : "Joystick";
  const direction = parts.slice(2).join("-");
  action = action || "Move";
  return `${action} the ${stickType} ${direction}`;
}

function getAction(press) {
  switch (press.hold) {
    case "START_HOLD":
      return "Start holding";
    case "MAINTAIN_HOLD":
      return "Keep holding";
    case "END_HOLD":
      return "Release";
    default:
      return null;
  }
}
