import "./firebase";

import { applyMiddleware, createStore } from "redux";

import { Provider } from "react-redux";
import React from "react";
import Root from "./1-modules/Root";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

export const BACKEND_URL =
  "http://ec2-3-83-108-60.compute-1.amazonaws.com:3005";
export const FRONT_END_URL = "http://www.comboguides.com";

// export const FRONT_END_URL = "http://loalhost:3000";
// export const BACKEND_URL = "http://localhost:3005";

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

function App() {
  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
