import React from "react";
import { connect } from "react-redux";
import { doJumpToFrame } from "../../../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  frame: postEditor.frame
});

const actions = {
  jumpToFrame: doJumpToFrame
};

function FrameController({ frame, jumpToFrame }) {
  return (
    <Container>
      <JumpBackButton onClick={() => jumpToFrame(frame - 20)}>
        -20fr
      </JumpBackButton>

      <BackButton onClick={() => jumpToFrame(frame - 5)}>-5fr</BackButton>

      <OneBackButton onClick={() => jumpToFrame(frame - 1)}>-1fr</OneBackButton>

      <FrameLabel>{frame}</FrameLabel>

      <OneNextButton onClick={() => jumpToFrame(frame + 1)}>+1fr</OneNextButton>

      <NextButton onClick={() => jumpToFrame(frame + 5)}>+5fr</NextButton>

      <JumpForwardButton onClick={() => jumpToFrame(frame + 20)}>
        +20fr
      </JumpForwardButton>
    </Container>
  );
}

export default connect(select, actions)(FrameController);

const Container = styled.div`
  height: 100%;
  background-color: #212121;
  display: flex;
  color: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 6px -6px rgba(33, 148, 182, 0.5),
    0 -10px 6px -6px rgba(33, 148, 182, 0.5),
    -10px 0px 6px -6px rgba(33, 148, 182, 0.5),
    10px 0px 6px -6px rgba(33, 148, 182, 0.5);
  @media (max-width: 600px) {
    width: 100vw;
    border-radius: 0;
    box-shadow: none;
  }
`;

const AbstractButton = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    color: var(--green);
  }
  @media (max-width: 600px) {
    font-size: 14px;
    flex: 1;
  }
`;

const BackButton = styled(AbstractButton)``;

const OneNextButton = styled(AbstractButton)`
  margin-left: auto;
`;

const OneBackButton = styled(AbstractButton)`
  margin-right: auto;
`;

const NextButton = styled(AbstractButton)``;

const JumpForwardButton = styled(AbstractButton)``;

const JumpBackButton = styled(AbstractButton)``;

const FrameLabel = styled.div`
  grid-area: label;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  font-weight: bold;
  @media (max-width: 600px) {
    padding: 0 0.5em;
  }
`;
