import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import TextInput from "../../2-utils/editables/TextInput";
import { Title, Subtitles } from "styled-icons/material";
import ImageUploadForGuideInterface from "./ImageUploadForGuideInterface";
import {
  doChangeGuidePreviewSubTitle,
  doChangeGuidePreviewTitle
} from "./ducks/actions";

const select = ({ guidePage, homePage }) => {
  const { availableGames } = homePage;
  const { gameTags } = guidePage.metaData;

  const previewImage =
    gameTags.length === 0
      ? null
      : availableGames.find(game => game._id == gameTags[0]).imageUrl;

  const subtitle =
    guidePage.sections[1] &&
    ["TEXT", "HEADER"].includes(guidePage.sections[1].type) &&
    guidePage.sections[1].value;

  return {
    previewTitle: guidePage.metaData.previewTitle,
    previewSubtitle: guidePage.metaData.previewSubtitle,
    title: guidePage.sections[0].value,
    subtitle,
    previewImage
  };
};

const actions = {
  changePreviewTitle: doChangeGuidePreviewTitle,
  changePreviewSubTitle: doChangeGuidePreviewSubTitle
};

function PublishPreview({
  changePreviewTitle,
  changePreviewSubTitle,
  title,
  subtitle,
  previewTitle,
  previewSubtitle,
  previewImage
}) {
  return (
    <Container>
      <h3>Story Preview</h3>

      <ImageUploadForGuideInterface />

      <TextInput
        placeholder="Write a preview title..."
        IconJSX={Title}
        value={previewTitle !== null ? previewTitle : title}
        onChange={e => changePreviewTitle(e.target.value)}
        type="text"
      />
      <TextInput
        placeholder="Write a preview subtitle..."
        IconJSX={Subtitles}
        value={previewSubtitle !== null ? previewSubtitle : subtitle}
        onChange={e => changePreviewSubTitle(e.target.value)}
        type="text"
      />
      <Note>
        <b>Note:</b> Changes here will affect how your story appears in public
        places like the homepage — not the guide itself.
      </Note>
    </Container>
  );
}

export default connect(
  select,
  actions
)(PublishPreview);

const Container = styled.div`
  padding: 1em 1em 2em 1em;
  box-sizing: border-box;
`;

const Note = styled.div`
  opacity: 0.5;
  font-size: 14px;
`;
