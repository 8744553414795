import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { doSelectGuideToEdit, doDeleteGuide } from "./ducks/actions";
import { MoreVert, Delete, Publish } from "styled-icons/material";
import { MoonAnimation } from "../../2-utils/loadingAnimations";
import { doActivateConfirmationModal } from "../ConfirmationModal/ducks/actions";
import { Link } from "react-router-dom";

const select = ({ guidePage }) => ({
  isDeletingGuide: guidePage.isDeletingGuide
});

const actions = {
  selectGuide: doSelectGuideToEdit,
  deleteGuide: doDeleteGuide,
  activateConfirmationModal: doActivateConfirmationModal
};

function Draft({
  activateConfirmationModal,
  deleteGuide,
  _id,
  title,
  activeGuideId,
  isDeletingGuide,
  selectGuide
}) {
  const [showMenu, setShowMenu] = useState(false);
  const Container = activeGuideId === _id ? ActiveContainer : InactiveContainer;
  return (
    <Container>
      <Title to={"/guide/" + _id} onClick={selectGuideIfNotAlreadyActive}>
        {title || "Untitled"}
      </Title>
      <OptionsButton onClick={toggleMenu} />
      {showMenu && (
        <>
          <Backdrop onClick={toggleMenu} />
          {!isDeletingGuide && (
            <MenuWrapper>
              <MenuOption
                onClick={() =>
                  activateConfirmationModal(
                    () => deleteGuide(_id),
                    "This will delete this guide forever"
                  )
                }
              >
                delete
              </MenuOption>
              <MenuOption onClick={toggleMenu}>cancel</MenuOption>
            </MenuWrapper>
          )}
          {isDeletingGuide && (
            <MenuWrapper>
              <MoonAnimation />
            </MenuWrapper>
          )}
        </>
      )}
    </Container>
  );

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  function selectGuideIfNotAlreadyActive() {
    activeGuideId !== _id && selectGuide(_id);
  }
}

export default connect(
  select,
  actions
)(Draft);

const AbstractContainer = styled.div`
  &:first-of-type {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: white;
  border-left: 4px solid transparent;
  position: relative;
  display: flex;
`;

const ActiveContainer = styled(AbstractContainer)`
  border-left: 4px solid var(--green);
`;

const InactiveContainer = styled(AbstractContainer)`
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const Title = styled(Link)`
  color: white;
  padding: 1em;
  flex: 1;
  cursor: pointer;
`;

const OptionsButton = styled(MoreVert)`
  margin-left: auto;
  width: 25px;
  padding: 0 0.5em;
  color: white;
  cursor: pointer;
  &:hover {
    color: var(--green);
  }
`;

const MenuWrapper = styled.div`
  --circle-width: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: 100;
  transform: translateX(calc(100% - 100px));
  padding: 0.5em;
  background-color: var(--black-purple);
  border: 2px solid var(--purple);
  box-shadow: var(--shadow-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: var(--circle-width);
    height: var(--circle-width);
    border-radius: var(--circle-width);
    background-color: var(--purple);
    transform: translate(50%, -50%);
    box-sizing: border-box;
    border: 2px solid white;
    z-index: 1;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  transition: 0.2s ease;
`;

const MenuOption = styled.div`
  cursor: pointer;
  padding: 0.5em;
  font-weight: bold;
  &:hover {
    color: var(--green);
  }
`;

const DeleteButton = styled(Delete)`
  width: 25px;
  &:hover {
    color: red;
  }
`;

const PublishButton = styled(Publish)`
  width: 25px;
  &:hover {
    color: var(--green);
  }
`;
