import React from "react";
import styled from "@emotion/styled";

export default function Results({ options = [] }) {
  return (
    <Container>
      {options.length !== 0 && (
        <div>
          <h3>
            You can pick one of{" "}
            <b style={{ color: "var(--green)" }}>{options.length}</b> moves that
            could hit before your opponents lag is finished
          </h3>
          <h4>
            This does not guaranteed your attack will be in range. Just that it
            would have an active hitbox out in time
          </h4>
        </div>
      )}
      {options.length === 0 && (
        <h3>
          Your opponent has used a move that you can't take advantage of. This
          is called "safe on shield."
        </h3>
      )}
      <OptionsWrapper>
        {options.map(option => (
          <Option>
            <h3>{option.move}</h3>
            <p>
              This would take <b>{option.defenderAdvantage}</b> frames to have
              an active hit box if you buffered it while in shield. <br />
              <br />
              Your opponent can't move until{" "}
              <b>{option.attackerAdvantage.advantage}</b> frames after you can
              <p style={{ opacity: 0.5 }}>
                {option.attackerAdvantage.rationale}
              </p>
            </p>
          </Option>
        ))}
      </OptionsWrapper>
    </Container>
  );
}

const Container = styled.div`
  padding: 1em;
  padding-right: 2em;
`;

const Option = styled.div`
  padding: 1em;
  border: 1px solid white;
  border-radius: 8px;
  margin-bottom: 1em;
  margin-left: 1em;
  float: left;
  max-width: 250px;
  b:first-of-type {
    color: var(--green);
  }
  b:last-of-type {
    color: lightcoral;
  }
  @media (max-width: 600px) {
    max-width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
`;

const OptionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
