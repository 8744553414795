export const OPENED_POST = "OPENED_POST";
export const CLOSED_POST = "CLOSED_POST";
export const STARTED_FETCHING_COMBOS = "STARTED_FETCHING_COMBOS";
export const FINISHED_FETCHING_COMBOS = "FINISHED_FETCHING_COMBOS";
export const SELECTED_GAME_FOR_RESULTS_PAGE = "SELECTED_GAME_FOR_RESULTS_PAGE";
export const MOVED_TO_NEXT_COMBO = "MOVED_TO_NEXT_COMBO";
export const MOVED_TO_PREVIOUS_COMBO = "MOVED_TO_PREVIOUS_COMBO";
export const MODIFIED_COMBO_IN_RESULTS_PAGE = "MODIFY_COMBO_IN_RESULTS_PAGE";
export const STARTED_FETCHING_NEXT_PAGE_OF_COMBOS =
  "STARTED_FETCHING_NEXT_PAGE_OF_COMBOS";
export const FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS =
  "FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS";
export const RESET_RESULTS_PAGE = "RESET_RESULTS_PAGE";
export const FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE =
  "FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE";
export const ADDED_CHARACTER_TO_RESULTS_FILTER =
  "ADDED_CHARACTER_TO_RESULTS_FILTER";
export const REMOVED_CHARACTER_FROM_RESULTS_FILTER =
  "REMOVED_CHARACTER_FROM_RESULTS_FILTER";
export const SET_RESULTS_PAGE_FILTERS = "SET_RESULTS_PAGE_FILTERS";
export const CLEARED_RESULT_FILTERS = "CLEARED_RESULT_FILTERS";
export const FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST =
  "FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST";
export const SET_PREFERRED_CONTROLLER = "SET_PREFERRED_CONTROLLER";
export const CHANGED_RESULTS_PAGE_QUERY = "CHANGED_RESULTS_PAGE_QUERY";
export const SET_PERCENT_RANGE_RESULTS_PAGE = "SET_PERCENT_RANGE_RESULTS_PAGE";
export const SET_TAGS_FILTER_RESULTS_PAGE = "SET_TAGS_FILTER_RESULTS_PAGE";
export const SET_RESULTS_PAGE_PRIMARY_CHARACTER =
  "SET_RESULTS_PAGE_PRIMARY_CHARACTER";
