import * as buttonTypes from "../ducks/types";
import * as switchProController from "../../../../../2-utils/switchProController";

import AnimatedInputListWithLanes from "../../../../Post/AnimatedInputList/AnimatedInputListWithLanes";
import React from "react";
import { connect } from "react-redux";
import { doJumpToFrame } from "../../../ducks/actions";
import { pressToJSX } from "../../../../../2-utils/pressToJSX";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  actions: postEditor.actions,
  frame: postEditor.frame
});

const actions = {
  jumpToFrame: doJumpToFrame
};

function ActionQueue({ actions, jumpToFrame, frame }) {
  const showHelpMessage = actions.length === 0;
  return (
    <Container>
      {showHelpMessage && (
        <HelpMessage>
          To create combos, select from the inputs above on the appropriate
          animation frames
        </HelpMessage>
      )}
      <AnimatedInputListWithLanes
        actions={actions}
        onActionClick={frame => jumpToFrame(frame)}
        highlightWhenPressed={false}
        highlightBorder={true}
        isPostEditor={true}
      />
    </Container>
  );
}

export default connect(select, actions)(ActionQueue);

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #150b40;
  display: flex;
  overflow-x: auto;
  padding: 1em 0;
`;

const HelpMessage = styled.div`
  color: white;
  padding: 1em;
`;

export function getActionsAsJSXForFrame(
  action,
  frame,
  jumpToFrame = () => null,
  onClick = () => null,
  actionIndex,
  highlightWhenSelected = true
) {
  return (
    <PressesWrapper
      className="gamepad-button"
      onClick={() => {
        jumpToFrame(action.frame);
        onClick(action.frame, actionIndex);
      }}
      selected={frame === action.frame && highlightWhenSelected}
    >
      <Timestamp active={action.frame === frame}>{action.frame}</Timestamp>
      {action.presses.sort(sortByHold).map(press => pressToJSX(press))}
    </PressesWrapper>
  );
}

function sortByHold(left, right) {
  if (left.hold && !right.hold) return -1;
  else return 1;
}

const PressesWrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin: 0 0.5em 0 0.5em;
  position: relative;
  align-items: center;
  > div {
    margin: 0.5em 0em;
  }
  @media (max-width: 600px) {
    margin: 0;
  }

  background-color: ${({ selected }) =>
    selected ? "rgba(255,255,255,0.5)" : "transparent"};
`;

const Timestamp = styled.div`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  border-radius: 50px;
  font-weight: bold;
  margin: 0 !important;
  padding: 0.2em 0.5em;
  font-size: 12px;
  @media (max-width: 600px) {
    top: 25px;
  }
`;
