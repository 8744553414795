import React, { useEffect, useState, useReducer, createContext } from "react";
import styled from "@emotion/styled";
import Video from "./Video";
import AnimatedController from "./AnimatedController/AnimatedController";
import AnimatedInputList from "./AnimatedInputList";
import reducer from "./ducks/reducers";
import { FINISHED_FETCHING_COMBO } from "./ducks/types";
import Description from "./Description";
import { useIsMobile } from "../../2-utils/snippets";

const initialState = {
  combo: {
    actions: []
  },
  activatedByUser: false,
  frame: 0,
  videoDuration: 0,
  animationSpeed: 1,
  upcomingActionIndex: 0,
  manualResetKey: 0
};

const renderMapper = {
  CONTROLLER: AnimatedController,
  INPUTS: AnimatedInputList,
  DESCRIPTION: Description
};

export const StateContext = createContext({
  state: {},
  dispatch: () => console.log("nothing")
});

export default function EmbeddedComboWithoutRedux({ combo }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeSection, setActiveSection] = useState("CONTROLLER");
  const Controller = renderMapper[activeSection];
  const isMobile = useIsMobile();

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      <Container active={state.activatedByUser}>
        <VideoWrapper>
          <Video videoUrl={state.combo.videoFile} combo={combo} />
        </VideoWrapper>

        <ControllerWrapper hide={!state.activatedByUser && isMobile}>
          <TabWrapper>
            <Tab
              onClick={() => setActiveSection("CONTROLLER")}
              active={activeSection === "CONTROLLER"}
            >
              controller
            </Tab>
            <Tab
              onClick={() => setActiveSection("INPUTS")}
              active={activeSection === "INPUTS"}
            >
              inputs
            </Tab>
            <Tab
              onClick={() => setActiveSection("DESCRIPTION")}
              active={activeSection === "DESCRIPTION"}
            >
              notes
            </Tab>
          </TabWrapper>
          <Controller />
        </ControllerWrapper>
      </Container>
    </StateContext.Provider>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    margin-bottom: 1em;
    box-sizing: border-box;
    box-shadow: var(--shadow-1);
    border: 2px solid
      ${({ active }) =>
        active ? "var(--purple)" : "transparent"};
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    transition: 0.5s ease;
  }
  overflow: hidden;
`;

const VideoWrapper = styled.div`
  width: 60%;
  @media (max-width: 600px) {
    width: 100%;
    overflow: hidden;
    border-radius: 4px 4px 0 0;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`;

const ControllerWrapper = styled.div`
  width: 40%;
  @media (max-width: 600px) {
    width: 100%;
    flex: 1;
  }
  position: ${({ hide }) => (hide ? "absolute" : "static")};
  z-index: ${({ hide }) => (hide ? "-1" : "0")};
  transform: translateX(${({ hide }) => (hide ? "-100%" : "0")});
  transition: 0.3s ease;
`;

const TabWrapper = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  margin-left: 1em;
  padding: 0 0.5em;
  box-sizing: border-box;
  @media (max-width: 600px) {
    margin: 0 40px;
    margin-top: 1em;
    border-radius: 20px 20px 20px 20px;
    /* padding: 0 2em; */
  }
`;

const Tab = styled.div`
  padding: 0.5em;
  flex: 1;
  cursor: ${({ active }) => (active ? "initial" : "pointer")};
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  font-weight: bold;
  font-size: 18px;
  text-align: center;
`;
