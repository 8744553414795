import React from "react";
import { connect } from "react-redux";
import { doToggleTag } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  tags: postEditor.tags
});

const actions = {
  toggleTag: doToggleTag
};

function TagSelector({ tags, toggleTag }) {
  const tagOptions = [
    "True Combo",
    "Edgeguard",
    "String",
    "Read",
    "For Fun",
    "Ledge Trap",
    "Tech Chase"
  ];
  return (
    <div>
      <h3>What Kind of Combo</h3>
      <Container>
        {tagOptions.map(tagOption => (
          <Bubble
            active={tags.includes(tagOption)}
            onClick={() => toggleTag(tagOption)}
          >
            {tagOption}
          </Bubble>
        ))}
      </Container>
    </div>
  );
}

export default connect(select, actions)(TagSelector);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1em 0;
`;

const Bubble = styled.div`
  padding: 0.3em 0.8em;
  margin-bottom: 0.5em;
  border: 1px solid currentColor;
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  opacity: ${({ active }) => (active ? "1" : "0.5")};
  &:hover {
    opacity: 1;
  }
  border-radius: 50px;
  cursor: pointer;
  margin-right: 0.5em;
`;
