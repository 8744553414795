import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import GamePreviewSquare from "../../1-modules/HomePage/GameSelector/GamePreviewSquare";

function SelectCharacterForModal({
  characters,
  selectedCharacterId,
  onCharacterClick
}) {
  return (
    <Container>
      {characters.map(character => (
        <GamePreviewSquare
          key={character._id}
          selected={character._id === selectedCharacterId}
          gameTitle={character.name}
          imageUrl={character.imageUrl}
          onClick={() => onCharacterClick(character)}
          small
        />
      ))}
    </Container>
  );
}

export default connect()(SelectCharacterForModal);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-height: 700px;
`;
