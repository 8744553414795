import React, { useRef } from "react";

import { Link } from "styled-icons/material";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import toast from "../../../2-utils/toast";

function CopyToClipboardButton({ text }) {
  const textareaRef = useRef();
  return (
    <Container onClick={handleCopy} message="Copy link to clipboard">
      <Icon />
      <HiddenTextarea value={text} ref={textareaRef} contentEditable />
    </Container>
  );

  function handleCopy() {
    textareaRef.current.select();
    document.execCommand("copy");
    toast("A link to this combo has been copied to your clipboard");
  }
}

export default connect()(CopyToClipboardButton);

const Container = styled(ToolTip)`
  margin: 0 1em;
`;

const Icon = styled(Link)`
  color: white;
  width: 25px;
  cursor: pointer;
  margin-right: -2px;
  &:hover {
    color: var(--green);
  }
`;

const HiddenTextarea = styled.textarea`
  /* display: none; */
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;
