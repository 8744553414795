import React, { useRef } from "react";

import { Edit } from "styled-icons/material";
import { connect } from "react-redux";
import { doSelectProfilePicture } from "./ducks/actions";
import styled from "@emotion/styled";

const select = ({ userInfo, settingsPage }) => {
  return {
    displayName: userInfo.displayName,
    profilePicture: settingsPage.newProfilePicture || userInfo.photoUrl,
  };
};

const actions = { selectProfilePicture: doSelectProfilePicture };

function ProfilePictureUploadInterface({
  displayName,
  profilePicture,
  selectProfilePicture,
}) {
  const pictureInputRef = useRef();

  return (
    <Container>
      {profilePicture ? (
        <Image src={profilePicture} />
      ) : (
        <DefaultImage>{displayName.charAt(0)}</DefaultImage>
      )}

      <EditIcon onClick={clickInvisibleFileInput} />

      <InvisibleFileInput
        ref={pictureInputRef}
        onChange={handleFileUpload}
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
      />
    </Container>
  );

  function clickInvisibleFileInput() {
    pictureInputRef.current.click();
  }

  function handleFileUpload(e) {
    try {
      const file = e.target.files[0];
      const BYTES_PER_MB = 1000000;
      const fileSizeInMb = file.size / BYTES_PER_MB;
      if (fileSizeInMb > 10) {
        alert("Picture size is bigger than 10MB. Please compress your picture");
      } else {
        const fileUrl = window.URL.createObjectURL(file);
        selectProfilePicture(fileUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default connect(select, actions)(ProfilePictureUploadInterface);

const Container = styled.div`
  position: relative;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.img`
  border-radius: 150px;
  width: 100px;
  height: 100px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  border: 1px solid #eee;
  margin-bottom: -6px;
  @media (max-width: 600px) {
    width: 150px;
    height: 150px;
  }
  @media (min-width: 600px) {
    width: 150px;
    height: 150px;
  }
`;

const DefaultImage = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 150px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background: var(--background-gradient);
  color: white;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const EditIcon = styled(Edit)`
  background: white;
  color: var(--purple);
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 18px;
  padding: 5px;
  border-radius: 100px;
  cursor: pointer;
  &:hover {
    background: var(--green-purple-gradient);
    color: white;
  }
  @media (max-width: 600px) {
    left: calc(50% + 30px);
    bottom: 0;
  }
`;

const InvisibleFileInput = styled.input`
  display: none;
`;
