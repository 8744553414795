import { Favorite, ThumbDown, ThumbUp } from "styled-icons/material";
import {
  doDislikeCombo,
  doLikeCombo,
  doSetComboFavoriteStatus
} from "../ducks/actions";

import React from "react";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const select = ({ resultsPage, userInfo }) => {
  const { activePostIndex, posts } = resultsPage;
  const post = posts[activePostIndex];
  const { favoriteCombos } = userInfo;
  const isFavorited = favoriteCombos.filter(id => id === post._id).length;
  return {
    likeStatus: post.likeStatus,
    dislikeStatus: post.dislikeStatus,
    numLikes: post.numLikes,
    isFavorited: isFavorited
  };
};

const actions = {
  handleLikeCombo: doLikeCombo,
  handleDislikeCombo: doDislikeCombo,
  handleFavoriteCombo: doSetComboFavoriteStatus
};

function UserFeedback({
  numLikes,
  handleLikeCombo,
  likeStatus,
  dislikeStatus,
  handleDislikeCombo,
  handleFavoriteCombo,
  isFavorited
}) {
  const iconSize = useIsMobile() ? "25" : "20";
  return (
    <Container>
      <IconWrapper message="Like combo">
        <ThumbUpIcon size={iconSize} onClick={handleLike} active={likeStatus} />
      </IconWrapper>

      <IconWrapper message="Dislike combo">
        <ThumbDownIcon
          size={iconSize}
          onClick={handleDislike}
          active={dislikeStatus}
        />
      </IconWrapper>

      <IconWrapper onClick={handleFavoriteCombo} message="Favorite combo">
        <FavoriteIcon size={iconSize} active={isFavorited} />
      </IconWrapper>
    </Container>
  );

  function handleLike() {
    handleLikeCombo();
  }

  function handleDislike() {
    handleDislikeCombo();
  }
}

export default connect(select, actions)(UserFeedback);

const Container = styled.div`
  display: flex;
  background-color: var(--black-purple);
  padding: 0 0.5em;
`;

const IconWrapper = styled(ToolTip)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.3em;
`;

const ThumbUpIcon = styled(ThumbUp)`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  cursor: pointer;
`;

const FavoriteIcon = styled(Favorite)`
  color: ${({ active }) => (active ? "var(--green)" : "white ")};
  cursor: pointer;
`;

const ThumbDownIcon = styled(ThumbDown)`
  color: ${({ active }) => (active ? "red" : "white")};
  cursor: pointer;
`;

const Number = styled.div`
  font-size: 14px;
  margin-top: 0.5em;
`;
