import { FINISHED_FETCHING_COMBO, SET_ACTIVATED_BY_USER } from "../ducks/types";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  doJumpPostVideoFrame,
  doSetAnimationSpeed,
  doSetUpcomingActionIndex
} from "../ducks/actions";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";
import { MoonLoader } from "react-spinners";
import SpeedControls from "../SpeedControls";
import { StateContext } from "../index.js";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

export default function Video({ combo }) {
  const isMobile = useIsMobile();
  const { state, dispatch } = useContext(StateContext);
  const videoUrl = state.combo.videoFile;
  const { animationSpeed } = state;
  const setFrame = doJumpPostVideoFrame;
  const setUpcomingActionIndex = doSetUpcomingActionIndex;
  const videoRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const isPlaying = useVideoIsPlaying();
  const previewImage =
    combo.thumbnail ||
    "https://corporate.bestbuy.com/wp-content/uploads/2018/12/Super-Smash-Bros_For-Blog.jpg";

  useEffect(() => {
    setPlaybackRate(animationSpeed);
  }, [videoRef, animationSpeed]);

  return (
    <Container>
      {isLoading && (
        <Overlay>
          {state.activatedByUser && (
            <MoonLoader color="var(--green)" size={40} />
          )}
          {!state.activatedByUser && (
            <PlayButton onClick={() => fetchCombo(combo)}>
              <PlayIcon />
            </PlayButton>
          )}
          <PreviewImage src={previewImage} />
        </Overlay>
      )}

      <VideoTag
        ref={videoRef}
        id={"post-video-tag-" + state.combo._id}
        onTimeUpdate={handleTimeChange}
        onEnded={handleVideoEnded}
        onCanPlayThrough={() => {
          hideLoadingAnimation();
          handlePlayVideo();
        }}
        onClick={handlePauseVideo}
        fade={!isPlaying && !isLoading}
        playsInline
        preload="metadata"
        muted
        key={videoUrl}
      >
        <source src={`${videoUrl}#t=0.01`} type="video/mp4" />
      </VideoTag>

      {!isPlaying && !isLoading && (
        <Overlay>
          <PlayButton onClick={handlePlayVideo}>
            <PlayIcon />
          </PlayButton>
          <SpeedControlsWrapper>
            <SpeedControls
              animationSpeed={animationSpeed}
              setSpeed={speed => dispatch(doSetAnimationSpeed(speed))}
            />
          </SpeedControlsWrapper>
        </Overlay>
      )}
    </Container>
  );

  async function fetchCombo(combo) {
    dispatch({
      type: SET_ACTIVATED_BY_USER
    });
    const url = `${BACKEND_URL}/combo/${combo._id}/${null}`;
    const result = await Axios.get(url);
    dispatch({
      type: FINISHED_FETCHING_COMBO,
      payload: {
        combo: result.data[0]
      }
    });
  }

  function hideLoadingAnimation() {
    setIsLoading(false);
  }

  function handleTimeChange(e) {
    const FRAMES_PER_SECOND = 60;
    const currentTimeInSeconds = e.target.currentTime;
    const currentTimeInFrames = currentTimeInSeconds * FRAMES_PER_SECOND;
    dispatch(setFrame(currentTimeInFrames));
  }

  function setPlaybackRate(rate) {
    videoRef.current.playbackRate = rate;
  }

  function handleVideoEnded(e) {
    dispatch(setFrame(0));
    dispatch(setUpcomingActionIndex(0));
  }

  function handlePlayVideo() {
    videoRef.current.play();
  }

  function handlePauseVideo() {
    videoRef.current.pause();
  }

  function useVideoIsPlaying() {
    const [isPlaying, setIsPlaying] = useState(false);
    const handlePause = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);

    useEffect(() => {
      videoRef.current.addEventListener("play", handlePlay);
      videoRef.current.addEventListener("pause", handlePause);
      // return () => {
      //   videoRef.current.removeEventListener("play", handlePlay);
      //   videoRef.current.removeEventListener("pause", handlePause);
      // };
    });
    return isPlaying;
  }
}

const Container = styled.div`
  background-color: var(--black-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const VideoTag = styled.video`
  width: 100%;
  height: 100%;
  opacity: ${({ fade }) => (fade ? 0.4 : 1)};
  object-fit: cover;
  cursor: pointer;
`;

const PlayButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: var(--green-purple-gradient);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: var(--shadow-1);
  margin: 0 0.3em;
  cursor: pointer;
`;

const PlayIcon = styled.div`
  width: 1px;
  height: 1px;
  border-left: 10px solid white;
  border-right: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
  transform: translateX(25%);
`;

const SpeedControlsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  box-sizing: border-box;
`;

const LoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
`;

const PreviewImage = styled.img`
  position: absolute;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  filter: blur(2px);
  z-index: -1;
  object-fit: cover;
`;
