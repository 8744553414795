import React from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import TotalViews from "./TotalViews";
import TotalPosts from "./TotalPosts";
import TotalGames from "./TotalGames";
import TotalGuides from "./TotalGuides";

function ProfileStats() {
  return (
    <Container>
      {/* <TotalViews /> */}
      <TotalPosts />
      {/* <TotalGames /> */}
      {/* <TotalGuides /> */}
    </Container>
  );
}

export default connect()(ProfileStats);

const Container = styled.aside`
  width: 100%;
  height: 100%;
  display: flex;
`;
