import React from "react";
import { connect } from "react-redux";
import { doChangeNewComboDescription } from "../../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  description: postEditor.description
});

const actions = {
  changeDescription: doChangeNewComboDescription
};

function DescriptionEditor({ description, changeDescription }) {
  return (
    <>
      <Header>Combo Description</Header>
      <EditableTextArea
        placeholder="To unleash destruction, make sure you do ..."
        value={description}
        onChange={e => changeDescription(e.target.value)}
      />
    </>
  );
}

export default connect(select, actions)(DescriptionEditor);

const EditableTextArea = styled.textarea`
  display: block;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-size: 18px;
  color: white;
  position: relative;
  width: 100%;
  background-color: var(--black-purple);
  color: var(--green);
  min-height: 180px;
  font-family: avenir, roboto;
  font-weight: bold;
`;

const Header = styled.h3`
  /* margin: 0; */
`;
