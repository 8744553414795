import {
  ADDED_CHARACTER_TO_RESULTS_FILTER,
  CHANGED_RESULTS_PAGE_QUERY,
  CLEARED_RESULT_FILTERS,
  CLOSED_POST,
  FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE,
  FINISHED_FETCHING_COMBOS,
  FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST,
  FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS,
  MODIFIED_COMBO_IN_RESULTS_PAGE,
  MOVED_TO_NEXT_COMBO,
  MOVED_TO_PREVIOUS_COMBO,
  OPENED_POST,
  REMOVED_CHARACTER_FROM_RESULTS_FILTER,
  RESET_RESULTS_PAGE,
  SELECTED_GAME_FOR_RESULTS_PAGE,
  SET_PERCENT_RANGE_RESULTS_PAGE,
  SET_PREFERRED_CONTROLLER,
  SET_RESULTS_PAGE_FILTERS,
  SET_RESULTS_PAGE_PRIMARY_CHARACTER,
  SET_TAGS_FILTER_RESULTS_PAGE,
  STARTED_FETCHING_COMBOS,
  STARTED_FETCHING_NEXT_PAGE_OF_COMBOS
} from "./types";
import {
  CHANGED_LIKE_STATUS_FOR_COMBO,
  FINISHED_FETCHING_COMMENTS_FOR_POST,
  FINISHED_POSTING_COMMENT,
  FINISHED_REMOVING_COMMENT,
  RESET_POST,
  TOGGLED_BREAKPOINT,
  TOGGLED_POST_VERIFICATION
} from "../../Post/ducks/types";

const initialState = {
  activePostIndex: null,
  posts: [],
  selectedTags: [],
  percentRange: [0, 200],
  gameId: null,
  primaryCharacterId: null,
  limit: 10,
  skip: 0,
  loadingMoreCombos: false,
  noMoreCombos: false,
  characters: [],
  filteredCharacters: [],
  charactersInQuery: [],
  comments: [],
  query: "",
  manualResetKey: 0
};

export default function resultsPageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_RESULTS_PAGE_PRIMARY_CHARACTER: {
      return {
        ...state,
        primaryCharacterId: payload.primaryCharacterId
      };
    }
    case TOGGLED_POST_VERIFICATION: {
      return {
        ...state,
        posts: state.posts.map(post => {
          if (post._id === payload._id) {
            return {
              ...post,
              verified: payload.verified
            };
          } else return post;
        })
      };
    }
    case SET_TAGS_FILTER_RESULTS_PAGE: {
      return {
        ...state,
        selectedTags: payload.selectedTags
      };
    }
    case SET_PERCENT_RANGE_RESULTS_PAGE: {
      return {
        ...state,
        percentRange: payload.percentRange
      };
    }
    case SET_RESULTS_PAGE_FILTERS: {
      return {
        ...state,
        filteredCharacters: payload.filteredCharacters
      };
    }
    case CHANGED_RESULTS_PAGE_QUERY: {
      return {
        ...state,
        query: payload.query,
        charactersInQuery: payload.charactersInQuery
      };
    }
    case FINISHED_REMOVING_COMMENT: {
      return {
        ...state,
        comments: state.comments.filter(c => c._id !== payload.commentId)
      };
    }

    case FINISHED_POSTING_COMMENT: {
      return {
        ...state,
        comments: [...state.comments, payload.comment]
      };
    }

    case FINISHED_FETCHING_COMMENTS_FOR_POST: {
      return {
        ...state,
        comments: payload.comments
      };
    }
    case CLEARED_RESULT_FILTERS: {
      return {
        ...state,
        filteredCharacters: []
      };
    }

    case ADDED_CHARACTER_TO_RESULTS_FILTER: {
      return {
        ...state,
        filteredCharacters: [...state.filteredCharacters, payload.character]
      };
    }

    case REMOVED_CHARACTER_FROM_RESULTS_FILTER: {
      return {
        ...state,
        filteredCharacters: state.filteredCharacters.filter(
          fchar => fchar._id !== payload.character._id
        )
      };
    }

    case FINISHED_FETCHING_CHARACTERS_FOR_RESULTS_PAGE: {
      return {
        ...state,
        characters: payload.characters
      };
    }
    case RESET_RESULTS_PAGE: {
      return {
        ...initialState,
        manualResetKey: state.manualResetKey + 1
      };
    }
    case RESET_POST: {
      return {
        ...state,
        comments: [],
        activePostIndex: null
      };
    }
    case CHANGED_LIKE_STATUS_FOR_COMBO: {
      return {
        ...state,
        posts: payload.posts
      };
    }
    case TOGGLED_BREAKPOINT: {
      return {
        ...state,
        posts: payload.posts
      };
    }
    case MODIFIED_COMBO_IN_RESULTS_PAGE: {
      return {
        ...state,
        posts: payload.posts
      };
    }

    case MOVED_TO_NEXT_COMBO: {
      return {
        ...state,
        activePostIndex: state.activePostIndex + 1
      };
    }

    case MOVED_TO_PREVIOUS_COMBO: {
      return {
        ...state,
        activePostIndex: state.activePostIndex - 1
      };
    }

    case OPENED_POST:
      return {
        ...state,
        activePostIndex: payload.postIndex
      };

    case CLOSED_POST:
      return {
        ...state,
        activePostIndex: null
      };

    case FINISHED_FETCHING_COMBO_FOR_STANDALONE_POST:
      return {
        ...state,
        posts: payload.combos,
        noMoreCombos: false,
        loadingMoreCombos: false,
        activePostIndex: 0
      };

    case FINISHED_FETCHING_COMBOS:
      return {
        ...state,
        posts: payload.combos,
        skip: payload.skip,
        noMoreCombos: payload.combos.length < state.limit,
        loadingMoreCombos: false
      };

    case FINISHED_FETCHING_NEXT_PAGE_OF_COMBOS: {
      return {
        ...state,
        posts: [...state.posts, ...payload.combos],
        skip: payload.skip,
        loadingMoreCombos: false,
        noMoreCombos: payload.combos.length < state.limit
      };
    }
    case STARTED_FETCHING_COMBOS: {
      return {
        ...state,
        loadingMoreCombos: true,
        posts: [],
        ...(state.skip !== 0 && { manualResetKey: state.manualResetKey + 1 }),
        noMoreCombos: false,
        skip: 0
      };
    }
    case STARTED_FETCHING_NEXT_PAGE_OF_COMBOS: {
      return {
        ...state,
        loadingMoreCombos: true
      };
    }

    case SELECTED_GAME_FOR_RESULTS_PAGE:
      return {
        ...initialState,
        gameId: payload.gameId
      };
    default:
      return state;
  }
}
