import React, { useEffect, useState } from "react";
import { doResetPostEditor, doSetComboInPostEditor } from "../ducks/actions";

import ActionQueue from "../CreateComboInterface/ActionEditor/ActionQueue";
import { Button } from "../../../2-utils/buttons";
import Controller from "./Controller";
import { Link } from "react-router-dom";
import Metadata from "./Metadata";
import { PacmanAnimation } from "../../../2-utils/loadingAnimations";
import SubmitButton from "./SubmitButton";
import Video from "./Video";
import VideoEditor from "../CreateComboInterface/VideoEditor";
import { connect } from "react-redux";
import { fetchCombo } from "../../ResultsPage/ducks/actions";
import { recordPageView } from "../../reactAnalytics";
import styled from "@emotion/styled";

const select = ({ postEditor, userInfo }) => ({
  isUploading: postEditor.postingToBackendStatus === "IN_PROGRESS",
  // percentUploaded: postEditor.percentUploaded,
  userId: userInfo._id
});

const actions = {
  setComboInPostEditor: doSetComboInPostEditor,
  resetPostEditor: doResetPostEditor
};

function NewComboCreationInterface({
  setComboInPostEditor,
  resetPostEditor,
  isUploading,
  // percentUploaded,
  userId
}) {
  const [percentUploaded, setPercentUploaded] = useState(0);
  useEffect(() => {
    recordPageView();
  }, []);

  useEffect(() => {
    if (isUploading && percentUploaded < 99) {
      setTimeout(() => setPercentUploaded(percentUploaded + 1), 250);
    }
    if (!isUploading) setPercentUploaded(0);
  }, [percentUploaded, isUploading]);

  useEffect(() => {
    if (userId) {
      const lastChunkOfURL = window.location.href.split("/").reverse()[0];
      if (lastChunkOfURL !== "test") {
        fetchCombo(lastChunkOfURL, userId).then(combo => {
          setComboInPostEditor(combo);
        });
      }
    }
  }, [userId]);

  useEffect(() => {
    return resetPostEditor;
  }, []);

  return (
    <Container>
      <VideoWrapper>
        <VideoEditor />
      </VideoWrapper>

      <ControllerWrapper>
        <Controller />
        <Message>
          All combos must be input on a switch controller with the appropriate
          switch button-mappings.
        </Message>
      </ControllerWrapper>

      <AnnotationsWrapper>
        <ActionQueue />
      </AnnotationsWrapper>

      <MetadataWrapper>
        <Metadata />
      </MetadataWrapper>

      <SubmitWrapper>
        <SubmitButton />
      </SubmitWrapper>

      {isUploading && (
        <LoadingOverlay>
          <div>
            <PacmanAnimation />
            <h1>{percentUploaded}%</h1>
          </div>
        </LoadingOverlay>
      )}

      <HelpWrapper>
        <Link
          to="/tutorial"
          style={{ color: "var(--green)", fontWeight: "bold" }}
        >
          help
        </Link>
      </HelpWrapper>
    </Container>
  );
}

export default connect(select, actions)(NewComboCreationInterface);

const Container = styled.div`
  display: grid;
  grid-template-areas:
    "video        controller"
    "annotations  annotations"
    "metadata     metadata"
    "submit       submit";
  grid-template-columns: 1fr auto;
  position: relative;
  color: white;
  @media (max-width: 600px) {
    grid-template-areas:
      "video"
      "controller"
      "annotations"
      "metadata"
      "submit";
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto auto auto auto;
  }
  @media (min-width: 100px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const VideoWrapper = styled.div`
  grid-area: video;
`;
const ControllerWrapper = styled.div`
  position: relative;
  grid-area: controller;
`;

const AnnotationsWrapper = styled.div`
  grid-area: annotations;
`;

const MetadataWrapper = styled.div`
  grid-area: metadata;
`;

const SubmitWrapper = styled.div`
  grid-area: submit;
  padding-bottom: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.95);
  h2 {
    margin-top: 3em;
    margin-bottom: 0;
  }
  > div {
    display: flex;
    h1 {
      margin: 0;
      margin-top: 10px;
      margin-left: 150px;

      color: var(--green);
      font-size: 50px;
    }
  }
`;

const HelpWrapper = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 4;
  padding: 0.5em 0.5em;
  font-size: 16px;
  border: 1px solid var(--green);
  border-radius: 50px;
  background: var(--black-purple);
  box-shadow: var(--shadow-1);
  cursor: pointer;
`;

const Message = styled.div`
  position: absolute;
  bottom: 1em;
  text-align: center;
  width: 100%;
`;
