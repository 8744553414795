import { Delete, DeleteForever } from "styled-icons/material";

import { FRONT_END_URL } from "../../../App";
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

export default function Comment({ text, authorData, onDelete, isOwner }) {
  let { displayName, photoUrl } = authorData[0];
  const profileImage = photoUrl ? (
    <AuthorCircle src={photoUrl} />
  ) : (
    <DefaultImage>{displayName.charAt(0)}</DefaultImage>
  );
  return (
    <Container>
      <AuthorWrapper to={"/profile/" + authorData[0]._id}>
        {profileImage}
        <AuthorName>{displayName}</AuthorName>
      </AuthorWrapper>

      <TextWrapper>
        <Text>{text}</Text>
      </TextWrapper>

      {isOwner && (
        <DeleteWrapper>
          <DeleteIcon onClick={onDelete} />
        </DeleteWrapper>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0.5em;
  background-color: var(--black-purple);
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  &:last-of-type {
    border-bottom: none;
  }
  display: flex;
  box-sizing: border-box;
`;

const DeleteIcon = styled(DeleteForever)`
  width: 25px;
  color: white;
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const DeleteWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const AuthorCircle = styled.img`
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 45px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background-color: var(--purple);
  border: 1px solid #eee;
  margin-right: 0.5em;
  margin-left: 0.5em;
  cursor: pointer;
`;

const DefaultImage = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background: var(--background-gradient);
  color: white;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  margin-right: 0.5em;
  margin-left: 0.5em;
  align-items: center;
`;

const TextWrapper = styled.div`
  margin: auto 1em;
`;

const Text = styled.p`
  margin: 0;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const AuthorWrapper = styled(Link)`
  color: white;
  text-decoration: none;
`;

const AuthorName = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
