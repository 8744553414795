import styled from "@emotion/styled";

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
`;

export default Overlay;