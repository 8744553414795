import { Email, Lock, Person } from "styled-icons/material";
import React, { useState } from "react";
import {
  doChangeEmailForLogin,
  doChangePasswordForLogin,
  doChangeUsernameForLogin,
  doCreateUser,
  doLoginUser
} from "./ducks/actions";

import { BounceLoader } from "react-spinners";
import GradientText from "../../2-utils/editables/GradientText";
import { GreenButton } from "../../2-utils/buttons";
import { Link } from "react-router-dom";
import TextInput from "../../2-utils/editables/TextInput";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ loginPage }) => ({
  username: loginPage.username,
  password: loginPage.password,
  email: loginPage.email,
  fetching: loginPage.fetching,
  errorMessage: loginPage.errorMessage
});

const actions = {
  changeUsername: doChangeUsernameForLogin,
  changePassword: doChangePasswordForLogin,
  changeEmail: doChangeEmailForLogin,
  createUser: doCreateUser,
  loginUser: doLoginUser
};

function LoginForm({
  username,
  password,
  changeUsername,
  changePassword,
  createUser,
  loginUser,
  email,
  changeEmail,
  fetching,
  errorMessage
}) {
  const [formType, setFormType] = useState("SIGN_UP_FORM");
  const isLoginForm = formType === "LOGIN_FORM";
  const onSubmit = isLoginForm ? loginUser : createUser;

  const toggleFormType = isLoginForm
    ? () => setFormType("SIGN_UP_FORM")
    : () => setFormType("LOGIN_FORM");

  const headerText = formType === "LOGIN_FORM" ? "login" : "sign up";
  const canSubmit = canSubmitToBackend(username, password, email, isLoginForm);

  return (
    <Container>
      <Header>{headerText}</Header>
      {errorMessage && <Error>{errorMessage}</Error>}
      {!isLoginForm && (
        <TextInput
          placeholder="display name (4+ chars)"
          IconJSX={Person}
          value={username}
          onChange={e => changeUsername(e.target.value)}
          type="username"
        />
      )}

      <TextInput
        placeholder="email"
        IconJSX={Email}
        value={email}
        onChange={e => changeEmail(e.target.value)}
        type="email"
      />

      <TextInput
        placeholder="password 8+ characters"
        IconJSX={Lock}
        type="password"
        value={password}
        onChange={e => changePassword(e.target.value)}
      />

      <LoginButtonWrapper>
        <GreenButton disable={!canSubmit} onClick={onSubmit}>
          {headerText}
        </GreenButton>
      </LoginButtonWrapper>

      {isLoginForm && (
        <ForgotPasswordWrapper>
          <ForgotPasswordButton to="/forgot-password">
            forgot password?
          </ForgotPasswordButton>
        </ForgotPasswordWrapper>
      )}

      <ChangeFormTypeButton onClick={toggleFormType}>
        {isLoginForm ? "or sign up here" : "or login here"}
      </ChangeFormTypeButton>

      {fetching && (
        <Overlay>
          <BounceLoader size="150" color="var(--green)" />
        </Overlay>
      )}
    </Container>
  );
}

export default connect(select, actions)(LoginForm);

function canSubmitToBackend(username, password, email, isLoginForm) {
  const validEmail = email.split("").filter(ch => ch === "@").length === 1;
  if (isLoginForm) {
    return validEmail && password.length >= 8;
  } else {
    return validEmail && username.length >= 4 && password.length >= 8;
  }
}

const Container = styled.form`
  height: 100%;
  padding: 3em;
  padding-top: 2em;
  padding-bottom: 2em;
  box-sizing: border-box;
  border-radius: 16px;
  position: relative;
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const Error = styled.p`
  background-color: #b71c1c;
  border-radius: 4px;
  padding: 0.5em 1em;
  color: white;
  font-size: 12px;
  box-shadow: var(--shadow-1);
`;

const Header = styled.h2`
  margin-top: 1em;
  margin-bottom: 0;
`;

const ChangeFormTypeButton = styled.p`
  margin-top: 0;
  color: white;
  position: absolute;
  top: 4.5em;
  right: 4em;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    cursor: pointer;
    color: var(--purple);
  }
  @media (max-width: 600px) {
    top: 0;
    right: 0;
  }
`;

const LoginButtonWrapper = styled.div`
  margin-top: 2em;
`;

const ForgotPasswordWrapper = styled.div`
  margin-top: 1em;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

const ForgotPasswordButton = styled(Link)`
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    color: var(--green);
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
`;
