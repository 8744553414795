import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import GuidePreview from "./GuidePreview";

const select = ({ discoverPage }) => ({
  guides: discoverPage.guides
});

function GuidePreviewsList({ guides = [] }) {
  return (
    <Container>
      {guides.map((guideData, index) => (
        <GuidePreview
          key={guideData._id}
          guideData={guideData}
          animationDelay={index * 0.15}
        />
      ))}
    </Container>
  );
}

export default connect(select)(GuidePreviewsList);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
