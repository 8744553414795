import * as buttonTypes from "../1-modules/PostEditor/CreateComboInterface/ActionEditor/ducks/types";
import * as playstationController from "./playstationController";
import * as switchProController from "./switchProController";

import React from "react";

export function pressToJSX(press, isPlaystation) {
  switch (press.type) {
    case buttonTypes.CONTROL_STICK_LEFT:
      return (
        <switchProController.ControlStickLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_RIGHT:
      return (
        <switchProController.ControlStickRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_UP:
      return (
        <switchProController.ControlStickUp
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN:
      return (
        <switchProController.ControlStickDown
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.C_STICK_LEFT:
      return (
        <switchProController.CStickLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_RIGHT:
      return (
        <switchProController.CStickRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_UP:
      return (
        <switchProController.CStickUp
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_DOWN:
      return (
        <switchProController.CStickDown
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_UP_LEFT:
      return (
        <switchProController.CStickUpLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_UP_RIGHT:
      return (
        <switchProController.CStickUpRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_DOWN_LEFT:
      return (
        <switchProController.CStickDownLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.C_STICK_DOWN_RIGHT:
      return (
        <switchProController.CStickDownRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_UP_RIGHT:
      return (
        <switchProController.ControlStickUpRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_UP_LEFT:
      return (
        <switchProController.ControlStickUpLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN_RIGHT:
      return (
        <switchProController.ControlStickDownRight
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.CONTROL_STICK_DOWN_LEFT:
      return (
        <switchProController.ControlStickDownLeft
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.A_BUTTON:
      if (isPlaystation)
        return (
          <playstationController.SquareButton
            hold={press.hold}
            isComplete={press.isComplete}
          />
        );
      return (
        <switchProController.AButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.B_BUTTON:
      if (isPlaystation)
        return (
          <playstationController.CircleButton
            hold={press.hold}
            isComplete={press.isComplete}
          />
        );
      return (
        <switchProController.BButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.X_BUTTON:
      return (
        <switchProController.XButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.Y_BUTTON:
      if (isPlaystation)
        return (
          <playstationController.TriangleButton
            hold={press.hold}
            isComplete={press.isComplete}
          />
        );
      return (
        <switchProController.YButton
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );

    case buttonTypes.L_TRIGGER:
      return (
        <switchProController.LTrigger
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.R_TRIGGER:
      return (
        <switchProController.RTrigger
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.L_Z:
      return (
        <switchProController.LZ
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
    case buttonTypes.R_Z:
      return (
        <switchProController.RZ
          hold={press.hold}
          isComplete={press.isComplete}
        />
      );
  }
}
