import React, { useEffect } from "react";

import Collapsible from "../../../../2-utils/Collapsible";
import { connect } from "react-redux";
import { recordPageView } from "../../../reactAnalytics";
import styled from "@emotion/styled";
import tutorialImage from "../../../../3-misc/helpPicture.png";

function Tutorial() {
  useEffect(() => {
    recordPageView();
  }, []);
  return (
    <Container>
      <h1>How to create a combo</h1>
      <Collapsible
        header={<h4>The tools to make your combo</h4>}
        content={
          <>
            <p>The combo creation interface has 4 main interfaces</p>
            <HelpPicture src={tutorialImage} />
            <ol>
              <li>
                <b>Video</b> <br />
                <span>
                  Where you upload and see your video. You can move back and
                  forward in time using the frame controller.
                </span>
              </li>
              <li>
                <b>Controller</b>
                <br />
                <span>
                  You annotate your combo by pressing buttons on this
                  controller. This allows others to see how the combo is done
                  and allows comboguides to do some magic to make things look
                  cool. This is the only way you can change presses on a combo.
                </span>
              </li>
              <li>
                <b>Input Map</b>
                <br />
                <span>
                  This is a visualization of the presses you've been applying on
                  the controller interface. The presses are organized by rows
                  and columns. Each button or joystick you press gets its own
                  row. All presses in a column happen at the exact same time. If
                  you want to remove or change any controller presses, you'll
                  have to go to the Controller inteface. Clicking on any column
                  will navigate to that time in the video.
                </span>
              </li>
              <li>
                <b>Metadata</b>
                <br />
                <span>
                  This is where you select your game and character, and write
                  your title and description for your combo.
                </span>
              </li>
            </ol>
          </>
        }
        expandedInitially
      />
      <Collapsible
        header={<h4>Video</h4>}
        content={
          <>
            <p>
              The first thing you'll need to do is upload some footage. You can
              do this by pressing the "upload video" button
            </p>
            <p>
              Once uploaded, you can move back and forwards in time by using the
              frame controller below the video.
            </p>
            <p style={{ opacity: 0.5 }}>
              A frame refers to an "animation frame," which is pretty much just
              a picture of the video game at a moment in time. Video games will
              render these animation frames really fast, typically 60 times a
              second so it looks like a smooth video. You can consider a frame
              as 1/60th of a second, which is the unit of time this website
              uses.
            </p>
          </>
        }
      />

      <Collapsible
        header={<h4>Controller Basics</h4>}
        content={
          <>
            <p>
              This controller is how you annotate what buttons are pressed at a
              particular moment in time.
            </p>
            <p>There are three types of inputs you can interact with</p>
            <ol>
              <li>
                <b>Buttons</b>
                <br />
                <span>
                  Can be pressed or held down (explained in the advanced
                  section). These include A, B, X, Y.
                </span>
              </li>
              <li>
                <b>Triggers</b>
                <br />
                <span>
                  Can be only pressed down. These include L, R, LT, LR
                </span>
              </li>
              <li>
                <b>Joysticks</b>
                <br />
                <span>
                  Can be pressed, held down, and pivoted after a hold (explained
                  in the advanced section). There are two joysticks: a
                  Control-Stick on the left, and a C-Stick on the right.
                </span>
              </li>
            </ol>
            <p>
              Pressing a button or trigger is easy. You click on the icon, it
              will turn green and pop up in the Input Visualization below. Click
              it again and it'll reset back to normal.
            </p>
            <p>
              Joysticks are different. Unlike triggers or buttons, you need to
              specify the direction it's moved in. You do this by pressing on
              one of the 8 grey dots surrounding a joystick. You can undo a
              moved joystick by pressing the red X that appears in the center.
            </p>
            <p>
              Holding is more complicated and not absolutely necessary for most
              combos. It will be explained in the advanced section
            </p>
          </>
        }
      />
      <Collapsible
        header={<h4>Holding Buttons (Advanced)</h4>}
        content={
          <>
            <p>
              Some moves and combos require you to hold down a button for a
              prolonged period of time.
            </p>
            <p>All holds have three stages</p>
            <ol>
              <li>
                <b>Start</b> <br />
                <span>
                  This is when you first begin holding down the button
                </span>
              </li>
              <li>
                <b>Holding</b> <br />
                <span>All frames between a start and a release of a hold</span>
              </li>
              <li>
                <b>Release</b> <br />
                <span>This is when you lift your finger off the button</span>
              </li>
            </ol>
            <p>
              After pressing a button, the button will give you the option to{" "}
              <u>start</u> a hold. Once you do this, you will be given the
              option to undo.
            </p>
            <p>
              All frames after the start of a hold will give you the option to{" "}
              <u>release</u> the hold for the held button button. After
              releasing, you are given the option to undo the release.
            </p>
            <p>
              All frames inbetween a start and a hold will disable the held
              button since a held button cannot do anything else.
            </p>
            <p>
              A hold that is not released will be highlighted in red in the
              visualization interface. All holds must be released before
              submitting a combo{" "}
            </p>
          </>
        }
      />
      <Collapsible
        header={<h4>Holding and Pivoting Joysticks (Advanced)</h4>}
        content={
          <>
            <p>
              Holding joysticks in a direction are the same as holding a button,
              but a few small differences
            </p>
            <ol>
              <li>
                The buttons to toggle hold states are in the center of the
                joystick interface.
              </li>
              <li>
                After a release hold, you can pivot the joystick in another
                direction and even start another hold.{" "}
              </li>
            </ol>
            <p>
              The pivot mechanic is useful for when you need to seemlessly hold
              in one direction and either imnmediately flick or hold in another
              direction{" "}
            </p>
          </>
        }
      />
      <Collapsible
        header={<h4>Adding metadata</h4>}
        content={
          <>
            <p>
              You must select a game and character for the combo, as well as
              write a title and description (optional) before submission
            </p>
          </>
        }
      />
    </Container>
  );
}

export default connect()(Tutorial);

const Container = styled.div`
  background: var(--black-purple);
  color: white;
  overflow: hidden;
  padding: 1em;
  h4 {
    margin: 0;
    color: var(--green);
  }
  span {
    margin-bottom: 0.5em;
    display: block;
  }
`;

const HelpPicture = styled.img`
  max-width: 500px;
  display: block;
  /* margin: auto; */
`;
