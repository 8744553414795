import { HEIGHT_OF_LANE } from "./index";
import React from "react";
import { pressToJSX } from "./pressToJSX";
import styled from "@emotion/styled";

export default function Column({ action, pressToLaneTable, pending, onClick }) {
  const { frame, presses } = action;
  const pressesAsJSX = getPressesAsJSX(presses, pressToLaneTable);
  return (
    <Container pending={pending} onClick={onClick}>
      <Frame>{frame}</Frame>
      {pressesAsJSX}
    </Container>
  );
}

function getPressesAsJSX(presses, pressToLaneTable) {
  return presses.map((press, i) => (
    <Lane
      key={i}
      lane={pressToLaneTable[press.type.slice(0, 7)]}
      className="gamepad-button"
    >
      {pressToJSX(press)}
    </Lane>
  ));
}

const Container = styled.div`
  position: relative;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  /* Control styling of buttons */
  .gamepad-button > div {
    margin-right: 0em;
    @media (min-width: 600px) {
      width: 35px;
      height: 35px;
      font-size: 10px;
    }
    background-color: ${({ pending }) =>
      pending ? "#545454" : "var(--green)"};
    color: ${({ pending }) => (pending ? "white" : "black")};
  }
`;

const Frame = styled.div`
  color: white;
  width: 40px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

const Lane = styled.div`
  position: absolute;
  top: calc(25px + ${({ lane }) => lane * HEIGHT_OF_LANE}px);
`;
