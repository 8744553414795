import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import getAttackOptions from "../../4-data/smash-frame-data/getAttackOptions";
import smashFrameData from "../../4-data/smash-frame-data";
import Results from "./Results";
import SelectMove from "./SelectMove";

function WhatCanIDo() {
  const characters = Object.keys(smashFrameData);
  const [defenderName, setDefenderName] = useState("Mario");
  const [attackerName, setAttackerName] = useState("Mario");
  const defender = smashFrameData[defenderName];
  const attacker = smashFrameData[attackerName];
  const [attackerMove, setAttackerMove] = useState("up smash");
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const attackerMoveData = getMoveData(attacker, attackerMove);
    setOptions(getAttackOptions(defender, attacker, attackerMoveData));
  }, [attacker, defender, attackerMove]);

  return (
    <Container>
      <DefenderWrapper>
        <h3>You are {defenderName} in shield</h3>
        {characters.map(name => (
          <Option
            active={defenderName === name}
            onClick={() => setDefenderName(name)}
          >
            {name}
          </Option>
        ))}
      </DefenderWrapper>
      <AttackerWrapper>
        <h3>select attacker</h3>
        {characters.map(name => (
          <Option
            active={attackerName === name}
            onClick={() => setAttackerName(name)}
          >
            {name}
          </Option>
        ))}
        <SelectMove
          attackerMove={attackerMove}
          setAttackerMove={setAttackerMove}
        />
      </AttackerWrapper>
      <ResultsWrapper>
        <Results options={options} />
      </ResultsWrapper>
    </Container>
  );
}

export default connect()(WhatCanIDo);

function getMoveData(attackerProfile, attackName) {
  const parts = attackName.split(" ");
  if (attackName === "dash") return attackerProfile.dashAttack;
  if (parts.length === 2) {
    const moveType = parts[1] === "smash" ? "smashes" : parts[1] + "s";
    const moveSubtype = parts[0];
    return attackerProfile[moveType][moveSubtype];
  }
  if (parts.length === 1) {
    const moveType = parts[0];
    return attackerProfile[moveType];
  }
}

const Container = styled.div`
  overflow: hidden;
  background: var(--black-purple);
  color: white;
  display: grid;
  grid-template-areas:
    "defender attacker"
    "results  results";
  grid-template-columns: auto 1fr;
  @media (max-width: 600px) {
    grid-template-areas:
      "defender"
      "attacker"
      "results";
    grid-template-columns: 1fr;
  }
`;

const AttackerWrapper = styled.div`
  grid-area: attacker;
  padding: 1em;
  border-left: 2px solid var(--purple);
  @media (max-width: 600px) {
    border-left: none;
    border-top: 2px solid var(--purple);
  }
`;

const DefenderWrapper = styled.div`
  grid-area: defender;
  padding: 1em;
`;

const ResultsWrapper = styled.div`
  grid-area: results;
  box-sizing: border-box;
  border-top: 2px solid var(--purple);
`;

const Option = styled.h4`
  display: inline-block;
  margin: 0 0.5em 0 0;
  color: ${({ active }) => (active ? "var(--green)" : "white")};
`;
