import {
  SELECTED_GAME_FOR_NEW_GUIDE,
  SELECTED_CHARACTER_FOR_NEW_GUIDE,
  RESET_NEW_GUIDE,
  ADDED_COMBO_TO_NEW_GUIDE,
  REMOVED_COMBO_FROM_NEW_GUIDE,
  EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE,
  EDITED_TITLE_FOR_NEW_GUIDE,
  EDITED_SECTION_HEADER_FOR_NEW_GUIDE,
  FINISHED_FETCHING_USER_COMBOS,
  FINISHED_FETCHING_USER_FOR_PROFILE_PAGE
} from "./types";
import Axios from "axios";
import { FINISHED_FETCHING_COMBOS } from "../../ResultsPage/ducks/types";
import { BACKEND_URL } from "../../../App";


export function doFetchUserForProfilePage(userId) {
  return (dispatch, getState) => {
    const url = `${BACKEND_URL}/user/${userId}`;
    Axios.get(url).then(r => {
      dispatch({
        type: FINISHED_FETCHING_USER_FOR_PROFILE_PAGE,
        payload: r.data
      })
    });
  };
}


export function doFetchUserCombos(userId) {
  return (dispatch, getState) => {
    const { userInfo } = getState();
    const shouldWait = userInfo.authenticated === null;
    if (shouldWait) {
      return setTimeout(() => dispatch(doFetchUserCombos(userId)), 200);
    }
    const url = BACKEND_URL + `/user/combos/${userId}`;
    Axios.get(url).then(r => {
      dispatch({
        type: FINISHED_FETCHING_USER_COMBOS,
        payload: {
          combos: r.data
        }
      });
      dispatch({
        type: FINISHED_FETCHING_COMBOS,
        payload: {
          combos: r.data
        }
      });
    });
  };
}

export function doSelectGameForNewGuide(game) {
  return {
    type: SELECTED_GAME_FOR_NEW_GUIDE,
    payload: {
      game
    }
  };
}

export function doSelectCharacterForNewGuide(character) {
  return {
    type: SELECTED_CHARACTER_FOR_NEW_GUIDE,
    payload: {
      character
    }
  };
}

export function doResetNewGuide() {
  return {
    type: RESET_NEW_GUIDE
  };
}

export function doAddComboToNewGuide(combo) {
  return {
    type: ADDED_COMBO_TO_NEW_GUIDE,
    payload: {
      combo
    }
  };
}

export function doRemoveComboFromNewGuide(combo) {
  return (dispatch, getState) => {
    const { sections } = getState().profilePage.newGuide;
    const modifiedSections = sections.filter(
      section => section.combo._id !== combo._id
    );
    dispatch({
      type: REMOVED_COMBO_FROM_NEW_GUIDE,
      payload: {
        sections: modifiedSections
      }
    });
  };
}

export function doEditSectionDescriptionForNewGuide(sectionIndex, description) {
  return {
    type: EDITED_SECTION_DESCRIPTION_FOR_NEW_GUIDE,
    payload: {
      sectionIndex,
      description
    }
  };
}

export function doEditSectionHeaderForNewGuide(sectionIndex, header) {
  return {
    type: EDITED_SECTION_HEADER_FOR_NEW_GUIDE,
    payload: {
      sectionIndex,
      header
    }
  };
}

export function doEditGuideTitle(title) {
  return {
    type: EDITED_TITLE_FOR_NEW_GUIDE,
    payload: {
      title
    }
  };
}

export function doPostGuide() {
  return (dispatch, getState) => {
    alert("todo");
  };
}
