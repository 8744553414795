import playstationImage from "../../../../3-misc/playstation.png";
import styled from "@emotion/styled";
export const PlaystationControllerImage = playstationImage;

export function getPlaystationController() {
  return {
    backgroundImage: PlaystationControllerImage,
    AButtonWrapper,
    BButtonWrapper: BButtonWrapper,
    XButtonWrapper,
    YButtonWrapper,
    JoyStickWrapper,
    CStickWrapper,
    LeftTriggerWrapper,
    RightTriggerWrapper,
    LeftZWrapper,
    RightZWrapper
  };
}

const AbstractButtonWRapper = styled.div`
  position: absolute;
  > div {
    background-color: var(--purple);
    width: 30px;
    height: 30px;
  }
`;

// X
const XButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50%);
  right: calc(50% - 100px);
`;

// Circle
const BButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 20px);
  right: calc(50% - 120px);
  & > div:after {
    content: "";
    height: 13px;
    width: 13px;
    border: 2px solid white;
    border-radius: 50px;
  }
`;

// Square
const AButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 20px);
  right: calc(50% - 80px);
  & > div:after {
    content: "";
    height: 13px;
    width: 13px;
    border: 2px solid white;
  }
`;

// Triangle
const YButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 39px);
  right: calc(50% - 100px);

  & > div:after {
    content: "";
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid white;
  }
`;

const JoyStickWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% - 28px);
  right: calc(50% + 16px);
`;

const CStickWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% - 28px);
  right: calc(50% - 70px);
`;

const LeftZWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 65px);
  right: calc(50% + 40px);
`;

const LeftTriggerWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 90px);
  right: calc(50% + 50px);
`;

const RightZWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 65px);
  left: calc(50% + 40px);
`;

const RightTriggerWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 90px);
  left: calc(50% + 50px);
`;
