var inActiveStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scale(0.6)"
};

var UP_LEFT = {
  top: "0",
  left: "0",
  transform: "translate(20%, 30%)"
};

var UP = { top: "0", left: "50%" };
var UP_RIGHT = {
  top: "0",
  left: "100%",
  transform: "translate(-120%, 30%)"
};

var LEFT = { top: "50%", left: "0" };
var middleMiddleStyle = { top: "50%", left: "50%" };
var RIGHT = { top: "50%", left: "100%" };

var DOWN_LEFT = {
  top: "100%",
  left: "0",
  transform: "translate(30%, -130%)"
};
var DOWN = { top: "100%", left: "50%" };
var DOWN_RIGHT = {
  top: "100%",
  left: "100%",
  transform: "translate(-120%, -120%)"
};

export const dots = {
  UP_LEFT,
  UP,
  UP_RIGHT,
  LEFT,
  // middleMiddleStyle,
  RIGHT,
  DOWN_LEFT,
  DOWN,
  DOWN_RIGHT
};

export const dotsAsKeyValueArray = Object.keys(dots).map(key => ({
  key,
  style: dots[key]
}));

export function handleSelectDot(direction) {
  let style = { ...dots[direction] } || { ...inActiveStyle };
  style.transform = getTransformByDirection(direction);
  style.direction = direction;
  return style;
}

function getTransformByDirection(direction) {
  let transformX = null;
  let transformY = null;
  switch (direction) {
    case "UP_LEFT":
      transformX = "-30%";
      transformY = "-30%";
      break;
    case "UP_RIGHT":
      transformX = "-65%";
      transformY = "-30%";
      break;
    case "DOWN_LEFT":
      transformX = "-30%";
      transformY = "-65%";
      break;
    case "DOWN_RIGHT":
      transformX = "-65%";
      transformY = "-65%";
      break;
    default:
      transformX = "-50%";
      transformY = "-50%";
      break;
  }
  return `translate(${transformX}, ${transformY}) scale(0.6)`;
}
