import {
  doDeactivatePublishInterface,
  doDeselectGameForGuide,
  doPublishGuide,
  doSelectGameForGuide,
  doUnPublishGuide
} from "./ducks/actions";

import { Button } from "../../2-utils/buttons";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ homePage, guidePage }) => ({
  availableGames: homePage.availableGames,
  gameTags: guidePage.metaData.gameTags,
  isPublished: guidePage.metaData.isPublished
});

const actions = {
  selectGameTag: doSelectGameForGuide,
  deselectGameTag: doDeselectGameForGuide,
  deactivatePublishInterface: doDeactivatePublishInterface,
  publishGuide: doPublishGuide,
  unPublishGuide: doUnPublishGuide
};

function PublishConfig({
  availableGames,
  gameTags,
  selectGameTag,
  deselectGameTag,
  deactivatePublishInterface,
  isPublished,
  publishGuide,
  unPublishGuide
}) {
  // add to this
  const canPublish = gameTags.length > 0;
  return (
    <Container>
      <h3>Select Game</h3>

      <SelectGamesWrapper>
        {availableGames.map(gameData => {
          const active = gameTags.includes(gameData._id);
          const handleClick = active ? deselectGameTag : selectGameTag;
          return (
            <Game
              active={active}
              key={gameData._id}
              onClick={() => handleClick(gameData._id)}
            >
              {gameData.name}
            </Game>
          );
        })}
      </SelectGamesWrapper>

      <ButtonsWrapper disable={!canPublish}>
        <Button onClick={isPublished ? unPublishGuide : publishGuide}>
          {isPublished ? "un-publish" : "publish"}
        </Button>
        <LaterButton onClick={deactivatePublishInterface}>close</LaterButton>
      </ButtonsWrapper>
    </Container>
  );
}

export default connect(select, actions)(PublishConfig);

const Container = styled.div`
  padding: 1em;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 1em;
  display: flex;
  opacity: ${({ disable }) => (disable ? 0.3 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "auto")};
`;

const LaterButton = styled(Button)`
  background-color: transparent;
  color: white;
  border: none;
  box-shadow: none;
  min-width: auto;
  margin-left: 0.5em;
  &:hover {
    background-color: transparent;
  }
`;

const SelectGamesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Game = styled.div`
  --off-white: rgba(255, 255, 255, 0.3);
  margin: 0.5em 0.5em 0.5em 0;
  border: 1px solid
    ${({ active }) => (active ? "var(--green)" : "var(--off-white)")};
  padding: 0.5em 1em;
  font-size: 14px;
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  border-radius: 50px;
  cursor: pointer;
`;
