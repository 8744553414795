import ButtonWithHold, {
  AnimatedControllerJoystick
} from "../../../2-utils/switchProController";
import React, { useEffect, useRef, useState } from "react";
import {
  doChangeHoldForPress,
  doRemoveCStickFromFrame,
  doRemoveControlStickFromFrame,
  doTogglePress
} from "../ducks/actions";
import {
  dots,
  dotsAsKeyValueArray,
  handleSelectDot
} from "./joystickDotsStyles";

import { connect } from "react-redux";
import styled from "@emotion/styled";

const actions = {
  changeHoldForPress: doChangeHoldForPress,
  togglePress: doTogglePress,
  removeControlStickFromFrame: doRemoveControlStickFromFrame,
  removeCStickFromFrame: doRemoveCStickFromFrame
};

function JoystickDots({
  changeHoldForPress,
  press,
  secondaryPress,
  removeControlStickFromFrame,
  removeCStickFromFrame,
  togglePress,
  type,
  direction,
  secondaryDirection
}) {
  const activeStyle = handleSelectDot(direction);
  const secondaryActiveStyle = handleSelectDot(secondaryDirection);
  const removeFunction =
    type === "CONTROL_STICK"
      ? removeControlStickFromFrame
      : removeCStickFromFrame;

  return (
    <JoystickDotsContainer>
      {/* Primary joystick */}
      <AnimatedControllerJoystick
        className="joystick"
        style={{
          zIndex: 2,
          position: "absolute",
          transition: "none",
          ...(direction ? activeStyle : inActiveStyle),
          ...(press && press.hold && { filter: "grayscale(1)" })
        }}
      />

      {/* Secondary Joystick */}
      {secondaryPress && (
        <AnimatedControllerJoystick
          className="joystick"
          style={{
            zIndex: 3,
            transition: "none",
            backgroundColor: "var(--purple)",
            ...(secondaryDirection ? secondaryActiveStyle : inActiveStyle),
            ...(secondaryPress &&
              secondaryPress.hold && { filter: "grayscale(0.5)" })
          }}
        />
      )}

      {/* Dots */}
      {dotsAsKeyValueArray.map(dotData => (
        <Dot
          disable={
            (press && press.hold && press.hold !== "END_HOLD") ||
            (secondaryPress && secondaryPress.hold)
          }
          style={dotData.style}
          key={type + "_" + dotData.key}
          onClick={() => {
            removeFunction();
            togglePress(type + "_" + dotData.key);
          }}
        />
      ))}

      {direction && (
        <OptionsWrapper>
          {!press.hold && (
            <BackButton onClick={removeFunction}>&times;</BackButton>
          )}
          {!press.hold && type !== "C_STICK" && (
            <HoldButton
              color="#00c853"
              onClick={() => changeHoldForPress(press.type)}
            >
              hold
            </HoldButton>
          )}
          {press.hold === "START_HOLD" && (
            <BigHoldButton
              color="#f44336"
              onClick={() => changeHoldForPress(press.type)}
            >
              un hold
            </BigHoldButton>
          )}
          {press.hold === "MAINTAIN_HOLD" && !press.isComplete && (
            <BigHoldButton
              color="#00c853"
              onClick={() => changeHoldForPress(press.type)}
            >
              release
            </BigHoldButton>
          )}
          {press.hold === "END_HOLD" && !secondaryPress && (
            <BigHoldButton
              color="#f44336"
              onClick={() => changeHoldForPress(press.type)}
            >
              un rel...
            </BigHoldButton>
          )}
          {press.hold === "END_HOLD" && secondaryPress && !secondaryPress.hold && (
            <>
              <BackButton onClick={removeFunction}>&times;</BackButton>
              <HoldButton
                color="#00c853"
                onClick={() => changeHoldForPress(secondaryPress.type)}
              >
                hold
              </HoldButton>
            </>
          )}

          {press.hold === "END_HOLD" && secondaryPress && secondaryPress.hold && (
            <>
              <BigHoldButton
                color="#f44336"
                onClick={() => changeHoldForPress(secondaryPress.type)}
              >
                un hold
              </BigHoldButton>
            </>
          )}
        </OptionsWrapper>
      )}
    </JoystickDotsContainer>
  );
}

export default connect(null, actions)(JoystickDots);

const JoystickDotsContainer = styled.div`
  position: absolute;
  height: 65px;
  width: 65px;
  z-index: 100;
  top: 50%;
  left: 50%;
  z-index: 0;
  transform: translate(-50%, -50%);
  background: rgb(27, 27, 27) !important;
  border-radius: 100px;
`;

const Dot = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 20px;
  background-color: grey;
  transform: translate(-50%, -50%);
  border: 2px solid white;
  cursor: pointer;
  box-shadow: var(--shadow-1);
  opacity: ${({ disable }) => (disable ? 0 : 1)};
  pointer-events: ${({ disable }) => (disable ? "none" : "initial")};
  transition: 0.2s ease;
  z-index: 1;
  &:hover {
    background: var(--green);
    border-color: var(--green);
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  /* background: green; */
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BackButton = styled.div`
  background-color: #f44336;
  color: white;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HoldIcon = styled.img`
  height: 25px;
  /* padding-top: 10px; */
  /* filter: invert(1); */
`;

const HoldButton = styled.div`
  overflow: hidden;
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  font-weight: bold;
  display: flex;
  text-align: center;
  line-height: 8px;
  justify-content: center;
  align-items: center;
  font-size: 8px;
`;

const BigHoldButton = styled(HoldButton)`
  height: 30px;
  width: 30px;
`;

// INLINE STYLES

var inActiveStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%) scale(0.6)"
};

{
  /* <HoldIcon 
            src="https://static.thenounproject.com/png/146289-200.png"
            // src="https://tel-a-care.co.za/wp-content/uploads/2019/01/icon.1.6.png" 
            /> */
}
