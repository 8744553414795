import React, { useEffect } from "react";
import {
  doChangeGuideTitle,
  doDeactivateDraftManager,
  doDeactivatePublishInterface,
  doFetchGuidesMadeByUser,
  doSaveGuideToBackend,
  doSelectGuideToEdit
} from "./ducks/actions";

import AutoSaveSpinner from "./AutoSaveSpinner";
import ConfirmationModal from "../ConfirmationModal";
import DraftManager from "./DraftManager";
import GuideContent from "./GuideContent";
import Modal from "../ResultsPage/Modal";
import { MoonAnimation } from "../../2-utils/loadingAnimations";
import PublishInterface from "./PublishInterface";
import SaveOrPublishButton from "./SaveOrPublishButton";
import SlidePanel from "./SlidePanel";
import { connect } from "react-redux";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

const select = ({ guidePage, userInfo, confirmationModal }) => ({
  showDraftManager: guidePage.showDraftManager,
  showPublishInterace: guidePage.showPublishInterface,
  saveStatus: guidePage.saveStatus,
  currentUserId: userInfo._id,
  sections: guidePage.sections,
  activeGuideId: guidePage.activeGuideId,
  noDraftsOrPublications:
    !guidePage.publications.length && !guidePage.drafts.length,
  showConfirmationModal: confirmationModal.message,
  showLoadingAnimation:
    guidePage.isFetchingGuides || guidePage.isFetchingIndividualGuide
});

const actions = {
  changeTitle: doChangeGuideTitle,
  deactivatePublishInterface: doDeactivatePublishInterface,
  saveGuideToBackend: doSaveGuideToBackend,
  selectGuide: doSelectGuideToEdit,
  fetchGuidesMadeByUser: doFetchGuidesMadeByUser,
  deactivateDraftManager: doDeactivateDraftManager
};

let saveGuideTimeout = null;

function GuidePage({
  activeGuideId,
  currentUserId,
  deactivateDraftManager,
  deactivatePublishInterface,
  fetchGuidesMadeByUser,
  showLoadingAnimation,
  noDraftsOrPublications,
  saveGuideToBackend,
  saveStatus,
  showConfirmationModal,
  showDraftManager,
  showPublishInterace,
  sections,
  selectGuide
}) {
  useEffect(() => {
    recordPageView();
  }, []);

  useEffect(() => {
    if (currentUserId) fetchGuidesMadeByUser(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    selectGuideIfSpecifiedByURL();
  }, []);

  useEffect(() => {
    debouncedAutosave();
  }, [saveStatus, saveGuideTimeout]);

  useEffect(overrideSaveKeyEffect, [saveStatus]);

  return (
    <Container>
      <SectionWrapper
        disable={showDraftManager}
        onClick={() => showDraftManager && deactivateDraftManager()}
      >
        {showLoadingAnimation && (
          <LoadingWrapper>
            <MoonAnimation size={50} />
          </LoadingWrapper>
        )}
        {!showLoadingAnimation && noDraftsOrPublications && (
          <h1>Create a draft</h1>
        )}
        {!showLoadingAnimation && !noDraftsOrPublications && (
          <GuideContent
            key={activeGuideId}
            sections={sections}
            contentEditable={true}
          />
        )}
      </SectionWrapper>

      <SlidePanel
        show={showDraftManager}
        style={{ position: "fixed", top: "0px" }}
      >
        <DraftManager />
      </SlidePanel>

      <ButtonsWrapper>
        <SaveOrPublishButton />
      </ButtonsWrapper>

      <SaveStatusWrapper>
        <AutoSaveSpinner />
      </SaveStatusWrapper>

      {showPublishInterace && (
        <Modal onClose={deactivatePublishInterface}>
          <PublishInterface />
        </Modal>
      )}
      {showConfirmationModal && <ConfirmationModal />}
    </Container>
  );

  function selectGuideIfSpecifiedByURL() {
    const guideId = window.location.href.split("/").reverse()[0];
    if (guideId !== "default") {
      selectGuide(guideId);
    }
  }

  function debouncedAutosave() {
    if (saveStatus === "NOT_SAVED") {
      if (saveGuideTimeout) clearTimeout(saveGuideTimeout);
      saveGuideTimeout = setTimeout(saveGuideToBackend, 3000);
    }
  }

  function overrideSaveKeyEffect() {
    document.addEventListener("keydown", handleOverrideSaveKey);
    return () => document.removeEventListener("keydown", handleOverrideSaveKey);
  }

  function handleOverrideSaveKey(e) {
    const isSKey = e.keyCode == 83;
    const isCommandKey = navigator.platform.match("Mac")
      ? e.metaKey
      : e.ctrlKey;
    if (isSKey && isCommandKey) {
      e.preventDefault();
      if (saveStatus === "NOT_SAVED") saveGuideToBackend();
    }
  }
}

export default connect(select, actions)(GuidePage);

const Container = styled.div`
  min-height: 100vh;
  background-color: var(--black-purple);
  color: white;
  font-size: 16px;
  padding: 2em 1em 2em 0;
  position: relative;
  @media (max-width: 600px) {
    padding: 2em 1em 2em 1em;
  }
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  top: 1em;
  box-sizing: border-box;
  right: 0;
  max-width: 1000px;
  text-align: right;
  border-radius: 8px 0 0 8px;
  box-shadow: var(--shadow-1);
  overflow: hidden;
  border: 2px solid white;
  border-right: none;
`;

const SectionWrapper = styled.div`
  transition: 0.2s ease;
  opacity: ${({ disable }) => (disable ? "0.3" : "1")};
`;

const SaveStatusWrapper = styled.div`
  position: absolute;
  top: 0.5em;
  left: 4em;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
