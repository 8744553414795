import {
  doCancelConfirmationModal,
  dooConfirmConfirmationModal
} from "./ducks/actions";

import GradientText from "../../2-utils/editables/GradientText";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ confirmationModal }) => ({
  message: confirmationModal.message
});

const actions = {
  onCancel: doCancelConfirmationModal,
  onConfirm: dooConfirmConfirmationModal
};

function ConfirmationModal({ message, onCancel, onConfirm }) {
  return (
    <Container>
      <Content>
        <Header>Hey, do you want to do this?</Header>

        <Message>{message}</Message>

        <Row>
          <CancelButton onClick={onCancel}>cancel</CancelButton>

          <ConfirmButton onClick={onConfirm}>
            <GradientText color="var(--green-red-gradient)">
              confirm
            </GradientText>
          </ConfirmButton>
        </Row>
      </Content>
    </Container>
  );
}

export default connect(select, actions)(ConfirmationModal);

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  animation: animateContainer 0.2s ease forwards;

  @keyframes animateContainer {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Content = styled.div`
  width: 600px;
  background: #212121;
  padding: 3em 3em 5em 3em;
  box-sizing: border-box;
  color: white;
  border-radius: 16px;
  box-shadow: var(--shadow-1);
  cursor: auto;
  border: 2px solid #ff5252;
`;

const Header = styled.h1``;

const Message = styled.h2``;

const Row = styled.div`
  display: flex;
  margin-top: 3em;
`;

const Button = styled.div`
  padding: 0.5em 1em;
  margin-right: 1em;
  color: white;
  border-radius: 50px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: var(--shadow-1);
  &:hover {
    cursor: pointer;
  }
`;

const CancelButton = styled(Button)`
  background-color: #ff5252;
`;

const ConfirmButton = styled(Button)`
  background-color: white;
  color: #121212;
`;
