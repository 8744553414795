import React from "react";
import { connect } from "react-redux";
import { doTogglePostVerification } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => ({
  verified: resultsPage.posts[resultsPage.activePostIndex].verified
});

const actions = {
  toggleVerification: doTogglePostVerification
};
function VerifyButton({ verified, toggleVerification }) {
  return (
    <Container onClick={() => toggleVerification(!verified)} active={verified}>
      verify
    </Container>
  );
}

export default connect(select, actions)(VerifyButton);

const Container = styled.div`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  font-weight: bold;
  cursor: pointer;
`;
