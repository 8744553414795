import {
  MODIFIED_NEW_DISPLAY_NAME_IN_SETTINGS,
  SELECTED_NEW_PROFILE_PICTURE_IN_SETTINGS,
  RESET_SETTINGS_PAGE,
  TOGGLED_FULLSCREEN_MODE
} from "./types";

export function doChangeNewDisplayName(displayName) {
  const modifiedDisplayName = displayName.replace(/\s/g, "").toLowerCase();
  return {
    type: MODIFIED_NEW_DISPLAY_NAME_IN_SETTINGS,
    payload: {
      displayName: modifiedDisplayName
    }
  };
}

export function doSelectProfilePicture(newProfilePicture) {
  return {
    type: SELECTED_NEW_PROFILE_PICTURE_IN_SETTINGS,
    payload: {
      newProfilePicture
    }
  };
}

export function doResetSettingsPage() {
  return {
    type: RESET_SETTINGS_PAGE
  };
}

export function doToggleFullScreenMode() {
  return {
    type: TOGGLED_FULLSCREEN_MODE
  };
}
