import React, { useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import { doJumpToFrame } from "../../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  frame: postEditor.frame,
  videoDurationInFrames: Math.floor(postEditor.videoDuration * 60),
  actions: postEditor.actions
});

const actions = {
  jumpToFrame: doJumpToFrame
};

function Scrubber({ frame, videoDurationInFrames, actions, jumpToFrame }) {
  const scrubberRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    setWidth(scrubberRef.current.clientWidth);
  }, []);
  return (
    <Container ref={scrubberRef} onClick={handleBarClick}>
      <DurationBar />

      {actions.map(action => (
        <Marker
          zIndex={3}
          key={action.frame}
          left={(action.frame / videoDurationInFrames) * width}
          color="rgba(0,0,0,0.3)"
          onClick={e => {
            e.stopPropagation();
            jumpToFrame(action.frame);
          }}
        />
      ))}
      <Marker
        left={(frame / videoDurationInFrames) * width}
        color="var(--green)"
        zIndex={2}
      />
    </Container>
  );

  function handleBarClick(e) {
    // const xPos = e.clientX;
    // const percentOfVideo = xPos / width;
    // const targetFrame = Math.ceil(percentOfVideo * videoDurationInFrames);
    // jumpToFrame(targetFrame);
  }
}

export default connect(select, actions)(Scrubber);

const Container = styled.div`
  position: relative;
  height: 100%;
`;

const DurationBar = styled.div`
  height: 30px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Marker = styled.div`
  height: 30px;
  width: 4px;
  background-color: ${({ color }) => color};
  position: absolute;
  top: 50%;
  left: ${({ left }) => left}px;
  transform: translate(-50%, -50%);
  z-index: ${({ zIndex }) => zIndex};
  cursor: pointer;
`;
