import React from "react";
import Select from "react-select";
import styled from "@emotion/styled";

export default function DarkSelect(props) {
  return (
    <Container>
      <Select {...props} styles={styles} isClearable />
    </Container>
  );
}

export function DarkSelectMulti(props) {
  return (
    <Container>
      <Select {...props} styles={multiStyles} isMulti />
    </Container>
  );
}

var styles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "rgba(255,255,255,0.15)",
    borderColor: "transparent"
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-purple)"
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--dark-purple)",
    maxHeight: "175px"
  }),
  input: (provided, state) => ({
    ...provided,
    color: "var(--green)",
    fontWeight: "bold",
    fontFamily: "avenir, nunito"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "hsl(0,0%,50%)"
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontFamily: "avenir, nunito"
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "avenir, nunito",
    fontWeight: "bold",
    color: "var(--green)"
  })
};

var multiStyles = {
  ...styles,
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--green)"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "black"
  })
};

const Container = styled.div`
  input {
    font-weight: bold;
  }
  input::placeholder {
    font-family: avenir, nunito;
  }
  * {
    font-family: avenir, nunito;
  }
`;
