import React, { useState } from "react";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { Gamepad } from "styled-icons/fa-solid";
import { doChangeSectionType, doAddNewSection } from "./ducks/actions";

const select = ({ guidePage }) => ({
  lastFocusedIndex: guidePage.lastFocusedIndex
});

const actions = {
  changeSectionType: doChangeSectionType,
  addSection: doAddNewSection
};

function Toolbar({
  lastFocusedIndex,
  index,
  disable,
  changeSectionType,
  addSection
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <Container>
      {shouldShowButton() && (
        <Button active={isActive()} onClick={toggleDrawer}>
          <div>&#43;</div>
        </Button>
      )}

      {shouldShowButton() && isActive() && (
        <Drawer>
          <SmallButton
            onClick={() => {
              changeSectionType(index, "COMBO_SELECTOR");
              setShowDrawer(false);
            }}
          >
            <GameIcon />
          </SmallButton>
        </Drawer>
      )}
    </Container>
  );

  function isActive() {
    return !disable && showDrawer;
  }

  function shouldShowButton() {
    return lastFocusedIndex === index;
  }

  function toggleDrawer() {
    setShowDrawer(!showDrawer);
  }
}

export default connect(
  select,
  actions
)(Toolbar);

const Container = styled.div`
  position: relative;
  user-select: none;
`;

const Drawer = styled.div`
  position: absolute;
  left: calc(100% + 1em);
  top: 0;
  height: 30px;
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  width: 28px;
  height: 28px;
  border: 1px solid white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  cursor: pointer;
  animation: fadeIn 0.5s forwards;
  > div {
    transform: rotate(${({ active }) => (active ? 45 : 0)}deg);
    transition: 0.2s ease;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const SmallButton = styled(Button)`
  height: 28px;
  width: 28px;
  margin-right: 0.5em;
`;

const GameIcon = styled(Gamepad)`
  width: 20px;
`;
