import React, { useContext } from "react";
import styled from "@emotion/styled";
import { StateContext } from "..";

export default function Description() {
  const { state } = useContext(StateContext);

  return <Container>"{state.combo.description}"</Container>;
}

const Container = styled.div`
  padding: 12px;
  font-style: italic;
  opacity: 0.5;
  font-size: 18px;
  padding: 1em 2em;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 1em;
    font-size: 16px;
  }
`;
