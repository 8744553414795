import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ profilePage }) => ({
  displayName: profilePage.displayName,
  userType: profilePage.userType,
  photoUrl: profilePage.photoUrl
});

function ProfilePicture({ displayName, userType, photoUrl }) {
  return (
    <Container>
      {photoUrl ? (
        <AuthorCircle src={photoUrl} />
      ) : (
        <DefaultImage>{displayName.charAt(0)}</DefaultImage>
      )}
      <AuthorName>{displayName}</AuthorName>
      <UserType>{userType}</UserType>
    </Container>
  );
}

export default connect(select)(ProfilePicture);

const Container = styled.aside`
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
`;

const AuthorCircle = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 100px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  border: 1px solid #eee;
`;

const DefaultImage = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background: var(--background-gradient);
  color: white;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
`;

const AuthorName = styled.div`
  color: white;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  margin-top: 0.5em;
`;

const UserType = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0.5em;
  text-align: center;
`;
