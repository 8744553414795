import { buildAttack, buildAerial, buildGrab, buildProjectile } from "../utils";

const smashes = {
  up: buildAttack({
    startup: [10],
    totalFrames: [52],
    shieldStun: [2, 9]
  }),
  down: buildAttack({
    startup: [12, 17, 23, 31],
    totalFrames: [45],
    shieldStun: [2, 7]
  }),
  forward: buildAttack({
    startup: [21],
    totalFrames: [54],
    shieldStun: [12, 13, 14]
  })
};

const tilts = {
  up: buildAttack({
    startup: [5],
    totalFrames: [28],
    shieldStun: [7, 6]
  }),
  down: buildAttack({
    startup: [3],
    totalFrames: [11],
    shieldStun: [5, 4]
  }),
  forward: buildAttack({
    startup: [7],
    totalFrames: [34],
    shieldStun: [10]
  })
};

const aerials = {
  up: buildAerial({
    startup: [8, 10, 12, 14, 15],
    totalFrames: [33],
    shieldStun: [2, 3],
    landingLag: 8,
    autoCancelWindow: [[1, 7], [34, 999]]
  }),
  down: buildAerial({
    startup: [20],
    totalFrames: [54],
    shieldStun: [2, 3, 3],
    landingLag: 12,
    autoCancelWindow: [[1 - 19], [41, 999]]
  }),
  forward: buildAerial({
    startup: [8, 13, 18, 20],
    totalFrames: [39],
    shieldStun: [2, 3],
    landingLag: 12,
    autoCancelWindow: [[1, 7], [33, 999]]
  }),
  back: buildAerial({
    startup: [10],
    totalFrames: [35],
    shieldStun: [5, 4],
    landingLag: 10,
    autoCancelWindow: [[1, 9], [25, 999]]
  }),
  neutral: buildAerial({
    startup: [5],
    totalFrames: [35],
    shieldStun: [4, 3],
    landingLag: 8,
    autoCancelWindow: [[1, 4], [26, 999]]
  })
};

const specials = {
  // deal with later
  up: null,
  // deal with later
  down: buildAttack({
    startup: [7],
    totalFrames: [24],
    shieldStun: [5]
  }),
  forward: buildProjectile({
    startup: [18],
    totalFrames: [55],
    shieldStun: [3],
    shieldLag: [6]
  }),
  // deal with later
  neutral: null
};

const dashAttack = buildAttack({
  startup: [8, 15, 23],
  totalFrames: [39],
  shieldStun: [5, 3, 5]
});

const jab = buildAttack({
  startup: [3],
  totalFrames: [5],
  shieldStun: [3]
});

const grab = buildGrab({ startup: [6], totalFrames: [37] });

export default {
  name: "Ness",
  smashes,
  tilts,
  specials,
  aerials,
  dashAttack,
  jab,
  grab
};
