import {
  FINISHED_FETCHING_GUIDE_FOR_STANDALONE_PAGE,
  RESET_SINGLE_PAGE_GUIDE
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";

export function doFetchGuideForStandalonePage(guideId) {
  return async dispatch => {
    const url = `${BACKEND_URL}/guides/id/${guideId}`;
    const response = await Axios.get(url);

    dispatch({
      type: FINISHED_FETCHING_GUIDE_FOR_STANDALONE_PAGE,
      payload: {
        guide: response.data.guide
      }
    });
  };
}

export function doResetSinglePageGuide() {
  return {
    type: RESET_SINGLE_PAGE_GUIDE
  };
}
