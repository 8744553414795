import * as buttonTypes from "../CreateComboInterface/ActionEditor/ducks/types";

import ButtonWithHold, {
  LeftRTrigger,
  LeftZTrigger,
  RightRTrigger,
  RightZTrigger
} from "../../../2-utils/switchProController";
import React, { useRef } from "react";

import JoystickDots from "./JoystickDots";
import { connect } from "react-redux";
import controllerImage from "../../EmbeddedComboWithoutRedux/AnimatedController/switchProController.png";
import { doTogglePress } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => {
  let { frame, actions } = postEditor;
  actions = actions || [];
  const frameData = getFrameData();
  let presses = getPresses();
  return {
    presses
  };

  function getFrameData() {
    return actions.find(action => action.frame === frame);
  }

  function getPresses() {
    return !frameData ? getDefaultPressData() : frameData.presses;
  }

  function getDefaultPressData() {
    const previousAction = actions
      .filter(action => action.frame < frame)
      .reverse()[0];
    if (!previousAction) return [];

    // if previous action has a start or maintian hold, this spot should maintain those holds
    let holds = previousAction.presses
      .filter(press => ["START_HOLD", "MAINTAIN_HOLD"].includes(press.hold))
      .map(press => ({ ...press, hold: "MAINTAIN_HOLD" }));

    return holds;
  }
};

const actions = {
  togglePress: doTogglePress
};

function Controller({ presses, togglePress }) {
  const RTriggerRef = useRef();
  const LTriggerRef = useRef();
  const RZRef = useRef();
  const LZRef = useRef();

  const primaryJoystickPress = presses
    .sort(sortPressesPrioritizedByHold)
    .find(press => press.type.split("_")[0] === "CONTROL");

  const secondaryJoystickPress = getOtherJoystickPressWhenTheresAnEndHold(
    presses
  );

  const primaryCStickPress = presses
    .sort(sortPressesPrioritizedByHold)
    .find(press => press.type.split("_")[0] === "C");

  const secondaryCStickPress = getOtherCStickPressWhenTheresAnEndHold(presses);

  return (
    <Container>
      <ControllerBackdrop src={controllerImage} />

      {/* Sticks */}
      <SwitchJoyStickWrapper>
        <JoystickDots
          type="CONTROL_STICK"
          press={primaryJoystickPress}
          direction={
            primaryJoystickPress && primaryJoystickPress.type.slice(14)
          }
          secondaryPress={secondaryJoystickPress}
          secondaryDirection={
            secondaryJoystickPress && secondaryJoystickPress.type.slice(14)
          }
        />
      </SwitchJoyStickWrapper>

      <SwitchCStickWrapper>
        <JoystickDots
          type="C_STICK"
          press={primaryCStickPress}
          direction={primaryCStickPress && primaryCStickPress.type.slice(8)}
          secondaryPress={secondaryCStickPress}
          secondaryDirection={
            secondaryCStickPress && secondaryCStickPress.type.slice(8)
          }
        />
      </SwitchCStickWrapper>

      {/* Buttons */}
      <SwitchBButtonWrapper>
        <ButtonWithHold
          state={getPressState(presses, buttonTypes.B_BUTTON)}
          onClick={() => togglePress(buttonTypes.B_BUTTON)}
          type={buttonTypes.B_BUTTON}
        >
          B
        </ButtonWithHold>
      </SwitchBButtonWrapper>

      <SwitchYButtonWrapper>
        <ButtonWithHold
          state={getPressState(presses, buttonTypes.Y_BUTTON)}
          onClick={() => togglePress(buttonTypes.Y_BUTTON)}
          type={buttonTypes.Y_BUTTON}
        >
          Y
        </ButtonWithHold>
      </SwitchYButtonWrapper>

      <SwitchAButtonWrapper>
        <ButtonWithHold
          state={getPressState(presses, buttonTypes.A_BUTTON)}
          onClick={() => togglePress(buttonTypes.A_BUTTON)}
          type={buttonTypes.A_BUTTON}
        >
          A
        </ButtonWithHold>
      </SwitchAButtonWrapper>

      <SwitchXButtonWrapper>
        <ButtonWithHold
          state={getPressState(presses, buttonTypes.X_BUTTON)}
          onClick={() => togglePress(buttonTypes.X_BUTTON)}
          type={buttonTypes.X_BUTTON}
        >
          X
        </ButtonWithHold>
      </SwitchXButtonWrapper>

      {/* Triggers */}
      <SwitchLeftTriggerWrapper>
        <LeftRTrigger
          ref={LTriggerRef}
          onClick={() => togglePress(buttonTypes.L_TRIGGER)}
        >
          LR
        </LeftRTrigger>
      </SwitchLeftTriggerWrapper>

      <SwitchRightTriggerWrapper>
        <RightRTrigger
          ref={RTriggerRef}
          onClick={() => togglePress(buttonTypes.R_TRIGGER)}
        >
          RR
        </RightRTrigger>
      </SwitchRightTriggerWrapper>

      <SwitchLeftZWrapper>
        <LeftZTrigger ref={LZRef} onClick={() => togglePress(buttonTypes.L_Z)}>
          LZ
        </LeftZTrigger>
      </SwitchLeftZWrapper>

      <SwitchRightZWrapper>
        <RightZTrigger ref={RZRef} onClick={() => togglePress(buttonTypes.R_Z)}>
          RZ
        </RightZTrigger>
      </SwitchRightZWrapper>
    </Container>
  );
}

function sortPressesPrioritizedByHold(l, r) {
  return l.hold ? -1 : 1;
}

function getOtherJoystickPressWhenTheresAnEndHold(presses) {
  const controlStickPresses = presses.filter(
    press => press.type.split("_")[0] === "CONTROL"
  );
  if (controlStickPresses.length === 1) return null;
  const pressWithEndHold = controlStickPresses.filter(
    press => press.hold === "END_HOLD"
  );
  if (!pressWithEndHold) return null;
  return controlStickPresses.find(press => press.hold !== "END_HOLD");
}

function getOtherCStickPressWhenTheresAnEndHold(presses) {
  const controlStickPresses = presses.filter(
    press => press.type.split("_")[0] === "C"
  );
  if (controlStickPresses.length === 1) return null;
  const pressWithEndHold = controlStickPresses.filter(
    press => press.hold === "END_HOLD"
  );
  if (!pressWithEndHold) return null;
  return controlStickPresses.find(press => press.hold !== "END_HOLD");
}

export default connect(select, actions)(Controller);

export function getPressState(presses, type) {
  const press = findPressOfSameType(presses, type);

  if (!press) {
    const incompleteHold = findHoldOfSameClassThatDisablesMe(presses, type);
    if (incompleteHold) return { state: "DISABLE" };
    return { state: null };
  }
  if (press.hold) return { state: press.hold, isComplete: press.isComplete };
  return { state: "SELECT_NO_HOLD" };
}

function findPressOfSameType(presses, type) {
  return presses.find(press => press.type === type);
}

function findHoldOfSameClassThatDisablesMe(presses, type) {
  // this also means that one held joystick or cstick will disable the rest
  const pressesInSameLane = presses.filter(
    press => press.type.slice(0, 7) === type.slice(0, 7)
  );

  const incompletePress = pressesInSameLane.find(
    press =>
      (press.isComplete === false || press.isComplete === true) &&
      press.hold !== "END_HOLD"
  );

  // in the case of unholding not breaking everything
  const pressesInSameLaneOfSameType = presses.filter(
    press => press.type === type
  );

  const pressesInSameLaneHasMaintainHold =
    pressesInSameLaneOfSameType.hold === "MAINTAIN_HOLD";

  if (pressesInSameLaneHasMaintainHold) return false;

  return incompletePress;
}

// OTHER

const Container = styled.div`
  padding: 2em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  @media (min-width: 1000px) {
    zoom: 1.3;
  }
  @media (max-width: 600px) {
    width: 100vw;
    overflow: hidden;
  }
`;

const ControllerBackdrop = styled.img`
  height: 350px;
  filter: grayscale(1);
  opacity: 0.5;
`;

const SwitchLeftTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 150px);
  left: calc(50% - 155px);
  cursor: pointer;
`;

const SwitchLeftZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 125px);
  left: calc(50% - 145px);
  cursor: pointer;
`;

const SwitchRightTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 150px);
  left: calc(50% + 105px);
  cursor: pointer;
`;

const SwitchRightZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 125px);
  left: calc(50% + 95px);
  cursor: pointer;
`;

const SwitchJoyStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 55px);
  left: calc(50% - 112.5px);
  cursor: pointer;
  > div > div:first-of-type {
    background-color: var(--purple);
  }
`;

const SwitchCStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50%);
  right: calc(50% - 50px);
  cursor: pointer;
  > div > div:first-of-type {
    background-color: var(--purple);
  }
`;

const AbstractButtonWRapper = styled.div`
  position: absolute;
  z-index: 2;
  > div {
    width: 28px;
    height: 28px;
  }
`;

const SwitchAButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 47.5px);
  right: calc(50% - 150px);
`;

const SwitchBButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 20px);
  right: calc(50% - 115px);
`;

const SwitchXButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 72px);
  right: calc(50% - 117px);
`;

const SwitchYButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 46px);
  right: calc(50% - 87px);
`;
