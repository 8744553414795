import {
  doSetResultsPageFilters,
  doSetResultsPagePrimaryCharacter
} from "../ducks/actions";

import DarkSelect from "../../../2-utils/DarkSelect";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ resultsPage }) => ({
  characters: resultsPage.characters,
  primaryCharacterId: resultsPage.primaryCharacterId
});

const actions = {
  setPrimaryCharacter: doSetResultsPagePrimaryCharacter
  // addCharacterToFilters: doAddCharacterToResultFilters,
  // removeCharacterFromFilters: doRemoveCharacterFromResultFilters,
  // clearResultFilters: doClearResultFilters
};

function FilterCharacters({
  characters,
  primaryCharacterId,
  setPrimaryCharacter
}) {
  const selectedCharacter = characters.find(
    char => char._id === primaryCharacterId
  );
  return (
    <DarkSelect
      placeholder="Select attacker..."
      options={convertCharactersToSelectFormat(characters)}
      value={convertSingleCharacterToSelectFormat(selectedCharacter)}
      onChange={newOption => {
        setPrimaryCharacter(newOption);
      }}
    />
  );
}

export default connect(select, actions)(FilterCharacters);

const Container = styled.div``;

function convertCharactersToSelectFormat(characters) {
  return characters.map(convertSingleCharacterToSelectFormat);
}

function convertSingleCharacterToSelectFormat(character) {
  if (!character) return null;
  return { value: character, label: character.name };
}
