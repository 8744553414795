import React, { useEffect } from "react";

import ConfirmationModal from "../ConfirmationModal";
import Post from "../Post";
import { connect } from "react-redux";
import { doFetchComboForStandalonePost } from "../ResultsPage/ducks/actions";
import { doResetPost } from "../Post/ducks/actions";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";
import { useParams } from "react-router";

const select = ({ resultsPage, confirmationModal }) => ({
  canRender: resultsPage.activePostIndex !== null,
  showConfirmationModal: confirmationModal.message,
});

const actions = {
  fetchCombo: doFetchComboForStandalonePost,
  resetPost: doResetPost,
};

function StandalonePostPage({
  match,
  canRender,
  fetchCombo,
  resetPost,
  showConfirmationModal,
}) {
  const params = useParams();
  const { comboId } = params;

  useEffect(() => {
    recordPageView();
  }, []);

  useEffect(() => {
    // resetPost();
    fetchCombo(comboId);
  }, []);

  return (
    <Container>
      {canRender && <Post standalone />}
      {showConfirmationModal && <ConfirmationModal />}
    </Container>
  );
}

export default connect(select, actions)(StandalonePostPage);

const Container = styled.div``;
