import { Link } from "react-router-dom";
import React from "react";
import { SimpleButton } from "./buttons";
import { connect } from "react-redux";
import { getUserIdFromUrlForProfilePage } from "../1-modules/ProfilePage";
import styled from "@emotion/styled";
import { useIsMobile } from "./snippets";

function CreateComboButton() {
  const isMobile = useIsMobile();
  if (isMobile)
    return (
      <Container
        onClick={() =>
          alert("Combo creation must be done on a desktop computer")
        }
      >
        create
      </Container>
    );
  return <Container to="/test">create combo</Container>;
}

export default connect()(CreateComboButton);

const Container = styled(Link)`
  background: white;
  color: black;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.5em;
  border-radius: 50px;
  font-weight: bold;
  font-size: 14px;
  &:hover {
    background: var(--green);
  }
`;
