import FrameController from "../ActionEditor/FrameController";
import React from "react";
import Scrubber from "./Scrubber";
import UploadVideoButton from "../UploadVideoButton";
import Video from "./Video";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  videoFile: postEditor.videoFile
});

function VideoEditor({ videoFile }) {
  const videoUploaded = videoFile !== null;
  return (
    <Container>
      <VideoWrapper>
        {videoUploaded ? <Video /> : <UploadVideoButton />}
      </VideoWrapper>

      <FrameWrapper>
        <FrameController />
      </FrameWrapper>

      {/* <ScrubberWrapper>
        <Scrubber />
      </ScrubberWrapper> */}
    </Container>
  );
}

export default connect(select)(VideoEditor);

const Container = styled.div`
  height: 100%;
  position: relative;
`;

const VideoWrapper = styled.div`
  grid-area: video;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media (max-width: 600px) {
    min-height: 300px;
  }
`;

const FrameWrapper = styled.div`
  position: absolute;
  bottom: 2em;
  display: flex;
  width: 100%;
  justify-content: center;
  @media (max-width: 600px) {
    bottom: 0;
  }
`;
