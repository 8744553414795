import * as buttons from "../../../EmbeddedComboWithoutRedux/AnimatedController/buttonTypes";

import { abstractConverter } from "./helpers";

export default function mortalKombat11ActionsConverter(
  actions,
  controllerType
) {
  switch (controllerType) {
    case "SWITCH":
      return actions;
    case "PLAYSTATION":
      return convertToPlaystation(actions);
    case "XBOX":
      return convertToXbox(actions);
    default:
      return actions;
  }
}

function convertToPlaystation(actions) {
  return abstractConverter(actions, switchToPlaystationMap);
}

function convertToXbox(actions) {
  return abstractConverter(actions, switchToXboxMap);
}

const switchToXboxMap = {
  [buttons.A_BUTTON]: buttons.B_BUTTON,
  [buttons.B_BUTTON]: buttons.A_BUTTON,
  [buttons.X_BUTTON]: buttons.Y_BUTTON,
  [buttons.Y_BUTTON]: buttons.X_BUTTON,

  [buttons.L_TRIGGER]: buttons.L_Z,
  [buttons.R_TRIGGER]: buttons.R_Z,
  [buttons.L_Z]: buttons.L_TRIGGER,
  [buttons.R_Z]: buttons.R_TRIGGER
};

// Circle is switch B
// X is swtich X
// Triangle is swtich Y
// Square is Switch A

const switchToPlaystationMap = {
  [buttons.A_BUTTON]: buttons.B_BUTTON,
  [buttons.B_BUTTON]: buttons.X_BUTTON,
  [buttons.X_BUTTON]: buttons.Y_BUTTON,
  [buttons.Y_BUTTON]: buttons.A_BUTTON,

  [buttons.L_TRIGGER]: buttons.L_Z,
  [buttons.R_TRIGGER]: buttons.R_Z,
  [buttons.L_Z]: buttons.L_TRIGGER,
  [buttons.R_Z]: buttons.R_TRIGGER
};

const abstractMap = {
  // [buttons.A_BUTTON]: buttons.B_BUTTON,
  // [buttons.B_BUTTON]: buttons.A_BUTTON,
  // [buttons.X_BUTTON]: buttons.Y_BUTTON,
  // [buttons.Y_BUTTON]: buttons.X_BUTTON
  // [buttons.CONTROL_STICK_UP]: buttons.CONTROL_STICK_UP,
  // [buttons.CONTROL_STICK_UP_LEFT]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_UP_RIGHT]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_DOWN]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_DOWN_LEFT]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_DOWN_RIGHT]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_RIGHT]: buttons.A_BUTTON,
  // [buttons.CONTROL_STICK_LEFT]: buttons.A_BUTTON,
  // [buttons.C_STICK_UP]: buttons.A_BUTTON,
  // [buttons.C_STICK_UP_LEFT]: buttons.A_BUTTON,
  // [buttons.C_STICK_UP_RIGHT]: buttons.A_BUTTON,
  // [buttons.C_STICK_DOWN]: buttons.A_BUTTON,
  // [buttons.C_STICK_DOWN_LEFT]: buttons.A_BUTTON,
  // [buttons.C_STICK_DOWN_RIGHT]: buttons.A_BUTTON,
  // [buttons.C_STICK_RIGHT]: buttons.A_BUTTON,
  // [buttons.C_STICK_LEFT]: buttons.A_BUTTON,
  // [buttons.L_TRIGGER]: buttons.L_TRIGGER,
  // [buttons.R_TRIGGER]: buttons.R_TRIGGER,
  // [buttons.L_Z]: buttons.L_Z,
  // [buttons.R_Z]: buttons.R_TRIGGER
};
