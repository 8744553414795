import {
  getGamecubeController,
  getSwitchController
} from "./switchAndGamecube";

import { getPlaystationController } from "./Playstation";
import { getXBoxController } from "./XBox";

export function selectProperController(controllerType) {
  switch (controllerType) {
    case "PLAYSTATION":
      return getPlaystationController();
    case "XBOX":
      return getXBoxController();
    case "GAMECUBE":
      return getGamecubeController();
    case "SWITCH":
    default:
      return getSwitchController();
  }
}
