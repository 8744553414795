import { combineReducers } from "redux";
import confirmationModalReducer from "./1-modules/ConfirmationModal/ducks/reducers";
import customMappingsPageReducer from "./1-modules/CustomMappingsPage/ducks/reducer";
import discoverPageReducer from "./1-modules/DiscoverPage/ducks/reducers";
import { guidePageReducer } from "./1-modules/GuidePage/ducks/reducers";
import homePageReducer from "./1-modules/HomePage/ducks/reducers";
import loginPageReducer from "./1-modules/LoginPage/ducks/reducers";
import postEditorReducer from "./1-modules/PostEditor/ducks/reducers";
import postReducer from "./1-modules/Post/ducks/reducers";
import profilePageReducer from "./1-modules/ProfilePage/ducks/reducers";
import resultsPageReducer from "./1-modules/ResultsPage/ducks/reducers";
import settingsPageReducer from "./1-modules/SettingsPage/ducks/reducers";
import singleGuidePageReducer from "./1-modules/SingleGuidePage/ducks/reducers";
import userInfoReducer from "./1-modules/UserInfo/ducks/reducers";

export default combineReducers({
  postEditor: postEditorReducer,
  post: postReducer,
  resultsPage: resultsPageReducer,
  homePage: homePageReducer,
  guidePage: guidePageReducer,
  singleGuidePage: singleGuidePageReducer,
  profilePage: profilePageReducer,
  userInfo: userInfoReducer,
  loginPage: loginPageReducer,
  settingsPage: settingsPageReducer,
  confirmationModal: confirmationModalReducer,
  discoverPage: discoverPageReducer,
  customMappingPage: customMappingsPageReducer
});
