import React from "react";
import styled from "@emotion/styled";
import {
  PacmanLoader,
  FadeLoader,
  BounceLoader,
  MoonLoader,
  PropagateLoader,
  GridLoader
} from "react-spinners";

export function PacmanAnimation(props) {
  return <PacmanLoader color="white" size={40} {...props} />;
}

export function SpinnerAnimation(props) {
  return <FadeLoader color="white" size={40} {...props} />;
}

export function BounceAnimation(props) {
  return <BounceLoader color="white" size={40} {...props} />;
}

export function MoonAnimation(props) {
  return <MoonLoader color="white" size={40} {...props} />;
}

export function PropagateAnimation(props) {
  return <PropagateLoader color="white" size={20} {...props} />;
}

export function GridAnimation(props) {
  return <GridLoader color="white" size={40} {...props} />;
}