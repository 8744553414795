import styled from "@emotion/styled";
import switchProControllerImage from "../../../../3-misc/switchProController.png";

export function getSwitchController() {
  return {
    backgroundImage: switchProControllerImage,
    AButtonWrapper: SwitchAButtonWrapper,
    BButtonWrapper: SwitchBButtonWrapper,
    XButtonWrapper: SwitchXButtonWrapper,
    YButtonWrapper: SwitchYButtonWrapper,
    JoyStickWrapper: SwitchJoyStickWrapper,
    CStickWrapper: SwitchCStickWrapper,
    LeftTriggerWrapper: SwitchLeftTriggerWrapper,
    RightTriggerWrapper: SwitchRightTriggerWrapper,
    LeftZWrapper: SwitchLeftZWrapper,
    RightZWrapper: SwitchRightZWrapper
  };
}

export function getGamecubeController() {
  return {
    backgroundImage:
      "https://upload.wikimedia.org/wikipedia/commons/a/a5/GameCube_controller.png",
    AButtonWrapper: GamecubeAButtonWrapper,
    BButtonWrapper: GamecubeBButtonWrapper,
    XButtonWrapper: GamecubeXButtonWrapper,
    YButtonWrapper: GamecubeYButtonWrapper,
    JoyStickWrapper: GamecubeJoyStickWrapper,
    CStickWrapper: GamecubeCStickWrapper,
    LeftTriggerWrapper: SwitchLeftTriggerWrapper,
    RightTriggerWrapper: SwitchRightTriggerWrapper,
    LeftZWrapper: SwitchLeftZWrapper,
    RightZWrapper: SwitchRightZWrapper
  };
}

const SwitchLeftTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 85px);
  left: calc(50% - 100px);
`;

const SwitchLeftZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 60px);
  left: calc(50% - 90px);
`;

const SwitchRightTriggerWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 85px);
  left: calc(50% + 50px);
`;

const SwitchRightZWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 60px);
  left: calc(50% + 40px);
`;

const SwitchJoyStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50% + 5px);
  left: calc(50% - 89px);
  > div {
    background-color: var(--purple);
  }
`;

const SwitchCStickWrapper = styled.div`
  position: absolute;
  bottom: calc(50% - 23px);
  right: calc(50% - 55px);
  > div {
    background-color: var(--purple);
  }
`;

const AbstractButtonWRapper = styled.div`
  position: absolute;
  > div {
    background-color: var(--purple);
    width: 30px;
    height: 30px;
  }
`;

const SwitchAButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 18px);
  right: calc(50% - 92px);
`;

const SwitchBButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 2px);
  right: calc(50% - 74px);
`;

const SwitchXButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 32px);
  right: calc(50% - 74px);
`;

const SwitchYButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 18px);
  right: calc(50% - 55px);
`;

// Gamecube

const GamecubeAButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 16px);
  right: calc(50% - 80px);
  > div {
    transform: scale(1.2);
  }
`;

const GamecubeBButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 5px);
  right: calc(50% - 60px);
`;

const GamecubeYButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 34px);
  right: calc(50% - 74px);
`;

const GamecubeXButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 21px);
  right: calc(50% - 102px);
`;

const GamecubeCStickWrapper = styled(SwitchCStickWrapper)`
  bottom: calc(50% - 45px);
  right: calc(50% - 60px);
`;

const GamecubeJoyStickWrapper = styled(SwitchJoyStickWrapper)``;

const GamecubeRightZWrapper = styled(SwitchRightZWrapper)`
  bottom: calc(50% + 60px);
  left: calc(50% + 40px);
`;
