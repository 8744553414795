import { doChangeNewComment, doPostComment } from "../ducks/actions";

import GradientText from "../../../2-utils/editables/GradientText";
import { InsertComment } from "styled-icons/material";
import React from "react";
import TextInput from "../../../2-utils/editables/TextInput";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const select = ({ post, userInfo }) => ({
  newComment: post.newComment,
  photoUrl: userInfo.photoUrl,
  displayName: userInfo.displayName,
  isPostingComment: post.isPostingComment
});

const actions = {
  changeNewComment: doChangeNewComment,
  postComment: doPostComment
};

function AddComment({
  newComment,
  changeNewComment,
  postComment,
  photoUrl,
  displayName,
  isPostingComment
}) {
  const isMobile = useIsMobile();
  const profileImage = photoUrl ? (
    <AuthorCircle src={photoUrl} />
  ) : (
    <DefaultImage>{displayName.charAt(0)}</DefaultImage>
  );
  return (
    <Container>
      <AuthorWrapper>{profileImage}</AuthorWrapper>

      <TextWrapper>
        <TextInput
          IconJSX={InsertComment}
          placeholder="add a comment"
          style={{ fontWeight: "bold" }}
          value={newComment}
          onChange={e => changeNewComment(e.target.value)}
        />
      </TextWrapper>

      <ButtonWrapper active={!isPostingComment && newComment !== ""}>
        <CommentButton onClick={postComment}>
          <GradientText color="var(--green-purple-gradient)">
            {isMobile ? "post" : "comment"}
          </GradientText>
        </CommentButton>
      </ButtonWrapper>
    </Container>
  );
}

export default connect(select, actions)(AddComment);

const Container = styled.div`
  padding: 0 1em;
  color: white;
  display: flex;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding: 0 0.5em;
  }
`;

const DefaultImage = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 45px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background: var(--background-gradient);
  color: white;
  border: 1px solid #eee;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CommentButton = styled.div`
  padding: 0.5em 1em;
  background-color: white;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  pointer-events: ${({ active }) => (active ? "all" : "none")};
`;

const AuthorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const AuthorCircle = styled.img`
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 45px;
  overflow: hidden;
  object-fit: cover;
  box-shadow: var(--shadow-1);
  background-color: var(--purple);
  border: 1px solid #eee;
  margin-right: 0.5em;
  margin-left: 0.5em;
`;

const TextWrapper = styled.div`
  width: 100%;
  margin-left: 0.5em;
  margin-right: 0.5em;
`;
