import {
  ADDED_NEW_SECTION,
  REMOVED_SECTION,
  SET_LAST_FOCUSED_SECTION_INDEX,
  CHANGED_SECTION_VALUE,
  CHANGED_SECTION_TYPE,
  ACTIVATED_DRAFT_MANAGER,
  DEACTIVATED_DRAFT_MANAGER,
  SELECTED_GUIDE_TO_EDIT,
  ACTIVATED_PUBLISH_INTERFACE,
  DEACTIVATED_PUBLISH_INTERFACE,
  SELECTED_GAME_TAG_FOR_GUIDE,
  DESELECTED_GAME_TAG_FOR_GUIDE,
  STARTED_SAVING_GUIDE,
  FINISHED_SAVING_GUIDE,
  CHANGED_GUIDE_PREVIEW_SUBTITLE,
  CHANGED_GUIDE_PREVIEW_TITLE,
  STARTED_FETCHING_GUIDES_BY_USER,
  FINISHED_FETCHING_GUIDES_BY_USER,
  STARTED_CREATING_NEW_GUIDE_DRAFT,
  FINISHED_CREATING_NEW_GUIDE_DRAFT,
  FINISHED_DELETING_GUIDE,
  STARTED_DELETING_GUIDE,
  FINISHED_FETCHING_GUIDE,
  STARTED_FETCHING_GUIDE,
  CHANGED_PUBLISHED_STATUS,
  STARTED_UPLOADING_IMAGE_FOR_GUIDE,
  FINISHED_UPLOADING_IMAGE_FOR_GUIDE
} from "./types";

const defaultSection = {
  type: "TITLE",
  value: "",
  id: -1
};

const initialState = {
  authorData: {},

  // current working guide
  metaData: {
    previewTitle: null,
    previewSubtitle: null,
    previewImage: null,
    gameTags: [],
    isPublished: false,
    _id: null
  },
  sections: [
    {
      type: "TITLE",
      value: "",
      id: -1
    }
  ],
  saveStatus: "NOT_SAVED",
  activeGuideId: 1,
  lastFocusedIndex: 0,
  showDraftManager: false,
  showPublishInterface: false,

  // everything else
  publications: [],
  drafts: [],
  isFetchingGuides: true,
  isFetchingIndividualGuide: false,
  isCreatingNewDraft: false,
  isDeletingGuide: false,
  isUploadingPreviewImage: false
};

export function guidePageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    // Deleting
    case STARTED_DELETING_GUIDE: {
      return {
        ...state,
        isDeletingGuide: true
      };
    }

    case FINISHED_DELETING_GUIDE: {
      return {
        ...state,
        publications: state.publications.filter(
          guide => guide._id !== payload.guideId
        ),
        drafts: state.drafts.filter(guide => guide._id !== payload.guideId),
        isDeletingGuide: false
      };
    }

    // Saving
    case STARTED_CREATING_NEW_GUIDE_DRAFT: {
      return {
        ...state,
        isCreatingNewDraft: true
      };
    }

    case FINISHED_CREATING_NEW_GUIDE_DRAFT: {
      return {
        ...state,
        isCreatingNewDraft: false,
        drafts: [...state.drafts, payload.draft]
      };
    }

    case STARTED_SAVING_GUIDE: {
      return {
        ...state,
        saveStatus: "SAVING"
      };
    }
    case FINISHED_SAVING_GUIDE: {
      return {
        ...state,
        saveStatus: "SAVED"
      };
    }

    // Fetching
    case STARTED_FETCHING_GUIDE: {
      return {
        ...state,
        isFetchingIndividualGuide: true,
        showDraftManager: false
      };
    }
    case FINISHED_FETCHING_GUIDE: {
      return {
        ...state,
        sections: payload.guide.sections.length
          ? payload.guide.sections
          : [defaultSection],
        metaData: payload.guide.metaData,
        isFetchingIndividualGuide: false
      };
    }

    case STARTED_FETCHING_GUIDES_BY_USER: {
      return {
        ...state,
        isFetchingGuides: true
      };
    }

    case FINISHED_FETCHING_GUIDES_BY_USER: {
      return {
        ...state,
        isFetchingGuides: false,
        drafts: payload.drafts,
        publications: payload.publications
        // activeGuideId:
        //   (payload.drafts[0] && payload.drafts[0]._id) ||
        //   (payload.publications[0] && payload.publications[0]._id)
      };
    }

    // Editing
    case CHANGED_SECTION_TYPE:
    case CHANGED_SECTION_VALUE:
    case REMOVED_SECTION:
    case ADDED_NEW_SECTION: {
      return {
        ...state,
        sections: payload.sections,
        saveStatus: "NOT_SAVED"
      };
    }
    case SET_LAST_FOCUSED_SECTION_INDEX: {
      return {
        ...state,
        sections: convertUnusedComboSelectorToText(
          state.sections,
          state.lastFocusedIndex
        ),
        lastFocusedIndex: payload.index
      };
    }
    case ACTIVATED_DRAFT_MANAGER: {
      return {
        ...state,
        showDraftManager: true
      };
    }
    case DEACTIVATED_DRAFT_MANAGER: {
      return {
        ...state,
        showDraftManager: false
      };
    }
    case ACTIVATED_PUBLISH_INTERFACE: {
      return {
        ...state,
        showPublishInterface: true
      };
    }
    case DEACTIVATED_PUBLISH_INTERFACE: {
      return {
        ...state,
        showPublishInterface: false
      };
    }
    case SELECTED_GUIDE_TO_EDIT: {
      return {
        ...state,
        activeGuideId: payload.guideId
      };
    }
    case SELECTED_GAME_TAG_FOR_GUIDE:
      return {
        ...state,
        saveStatus: "NOT_SAVED",
        metaData: {
          ...state.metaData,
          gameTags: [payload.gameId]
          // gameTags: [...state.metaData.gameTags, payload.gameId]
        }
      };
    case DESELECTED_GAME_TAG_FOR_GUIDE:
      return {
        ...state,
        saveStatus: "NOT_SAVED",
        metaData: {
          ...state.metaData,
          gameTags: [payload.gameId]
          // gameTags: state.metaData.gameTags.filter(
          //   gameId => gameId !== payload.gameId
          // )
        }
      };
    case CHANGED_GUIDE_PREVIEW_TITLE: {
      return {
        ...state,
        metaData: {
          ...state.metaData,
          previewTitle: payload.previewTitle
        }
      };
    }
    case CHANGED_GUIDE_PREVIEW_SUBTITLE: {
      return {
        ...state,
        metaData: {
          ...state.metaData,
          previewSubtitle: payload.previewSubtitle
        }
      };
    }
    case CHANGED_PUBLISHED_STATUS:
      const drafts = payload.isPublished
        ? state.drafts.filter(draft => draft._id !== state.activeGuideId)
        : [
            ...state.drafts,
            state.publications.find(draft => draft._id === state.activeGuideId)
          ];

      const publications = payload.isPublished
        ? [
            ...state.publications,
            state.drafts.find(draft => draft._id === state.activeGuideId)
          ]
        : state.publications.filter(draft => draft._id !== state.activeGuideId);

      return {
        ...state,
        saveStatus: "NOT_SAVED",
        metaData: {
          ...state.metaData,
          isPublished: payload.isPublished
        },
        drafts: drafts,
        publications: publications
      };

    case STARTED_UPLOADING_IMAGE_FOR_GUIDE: {
      return {
        ...state,
        isUploadingPreviewImage: true
      };
    }
    case FINISHED_UPLOADING_IMAGE_FOR_GUIDE: {
      return {
        ...state,
        saveStatus: "NOT_SAVED",
        metaData: {
          ...state.metaData,
          previewImage: payload.previewImage
        },
        isUploadingPreviewImage: false
      };
    }
    default:
      return state;
  }
}

//todo move this to an action
function convertUnusedComboSelectorToText(sections, lastFocusedIndex) {
  const newSections = sections.map((section, i) => {
    if (
      i === lastFocusedIndex &&
      section.type === "COMBO_SELECTOR" &&
      !section.value
    )
      return {
        ...section,
        type: "TEXT"
      };
    return section;
  });
  return newSections;
}
