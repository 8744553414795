import React, { useContext } from "react";
import styled from "@emotion/styled";
import Column from "./Column";
import {
  doJumpPostVideoFrame,
  doSetUpcomingActionIndex
} from "../ducks/actions";
import { StateContext } from "../index.js";
export const HEIGHT_OF_LANE = 40;

export default function AnimatedInputListWithLanes({
  onActionClick,
  highlightWhenPressed = true,
  ...props
}) {
  const { state, dispatch } = useContext(StateContext);
  const actions = state.combo.actions;
  const frame = state.frame;
  const jumpToFrame = doJumpPostVideoFrame;
  const setUpcomingActionIndex = doSetUpcomingActionIndex;
  const pressToLaneTable = generatePresstoLaneTable(actions);
  const numLanes = Object.keys(pressToLaneTable).length;

  return (
    <Container numLanes={numLanes} style={props.style}>
      {actions.map((action, actionIndex) => (
        <Column
          onClick={() => {
            dispatch(jumpToFrame(action.frame));
            dispatch(setUpcomingActionIndex(actionIndex));
            setVideoTime(action.frame, state.combo._id);
          }}
          key={action.frame}
          action={action}
          pressToLaneTable={pressToLaneTable}
          pending={getIsPending(action)}
        />
      ))}
    </Container>
  );

  function getIsPending(action) {
    if (!highlightWhenPressed) return true;
    return action.frame > frame;
  }
}

function generatePresstoLaneTable(actions) {
  const defaultTable = {};
  const tableOfKeyToTimesUsed = actions.reduce(reducer, defaultTable);
  const pressTypesInOrderOfMostUsed = Object.entries(tableOfKeyToTimesUsed)
    .sort((l, r) => r[1] - l[1])
    .map(item => item[0]);

  const table = {};
  pressTypesInOrderOfMostUsed.forEach((press, i) => (table[press] = i));
  return table;

  function reducer(table, currentAction) {
    const FIRST_N_CHARS = 7;
    currentAction.presses.forEach(press => {
      const key = press.type.slice(0, FIRST_N_CHARS);
      if (table[key] === undefined) table[key] = 0;
      table[key] += 1;
    });
    return table;
  }
}

const Container = styled.div`
  background-color: transparent;
  height: ${({ numLanes }) => numLanes * HEIGHT_OF_LANE + 25}px;
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 0.5em 1em;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 600px) {
    box-sizing: border-box;
    width: calc(100% - 6px);
    margin-bottom: 1em;
  }
  @media (min-width: 600px) {
    height: 100%;
  }
`;

function setVideoTime(frame, comboId) {
  const videoRef = document.getElementById("post-video-tag-" + comboId);
  videoRef.currentTime = Math.ceil((frame / 60) * 100) / 100;
  videoRef.play();
}
