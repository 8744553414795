import React from "react";
import ToolTip from "../../../2-utils/ToolTip";
import { connect } from "react-redux";
import { doSetAnimationSpeed } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ post }) => ({
  animationSpeed: post.animationSpeed
});

const actions = {
  setSpeed: doSetAnimationSpeed
};

function SpeedControls({ setSpeed, animationSpeed }) {
  return (
    <Container message="Control video speed">
      <Option active={animationSpeed === 1} onClick={() => setSpeed(1)}>
        1x
      </Option>
      <Option active={animationSpeed === 2 / 3} onClick={() => setSpeed(2 / 3)}>
        2/3x
      </Option>
      <Option active={animationSpeed === 1 / 2} onClick={() => setSpeed(1 / 2)}>
        1/2x
      </Option>
      <Option active={animationSpeed === 1 / 4} onClick={() => setSpeed(1 / 4)}>
        1/4x
      </Option>
    </Container>
  );
}

export default connect(select, actions)(SpeedControls);

const Container = styled.div`
  display: flex;
  background-color: var(--black-purple);
  cursor: pointer;
  overflow: hidden;
  border: 2px solid var(--blue);
  border-radius: 8px;
  box-shadow: var(--shadow-1);
`;

const Option = styled.div`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  opacity: ${({ active }) => (active ? 1 : 0.4)};
  padding: 0.5em 0.5em;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  @media (min-width: 800px) {
    font-size: 16px;
  }
`;
