import { doPostCombo, doPostComboNew, doSaveCombo } from "../ducks/actions";

import { Button } from "../../../2-utils/buttons";
import React from "react";
import { connect } from "react-redux";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  videoFile: postEditor.videoFile,
  gameId: postEditor.gameId,
  characterIds: postEditor.characterIds,
  characterId: postEditor.characterId,
  name: postEditor.name,
  isNewCombo: !postEditor.comboId,
  actions: postEditor.actions || []
});

const actions = {
  postCombo: doPostCombo,
  postComboNew: doPostComboNew,
  saveCombo: doSaveCombo
};

function SubmitButton({
  videoFile,
  gameId,
  characterIds,
  name,
  postCombo,
  characterId,
  postComboNew,
  saveCombo,
  isNewCombo,
  actions
}) {
  const incompleteHoldExists = actions.find(action =>
    action.presses.find(press => press.hold && press.isComplete === false)
  );
  const canSubmit =
    videoFile &&
    gameId &&
    characterIds.length &&
    name &&
    !incompleteHoldExists &&
    characterId;
  return (
    <Container disable={!canSubmit}>
      <Button
        onClick={() => {
          isNewCombo
            ? postComboNew(
                () => (window.location.href = "http://www.comboguides.com/")
              )
            : saveCombo(() => null);
        }}
      >
        {isNewCombo ? "Create Combo" : "Update Combo"}
      </Button>
    </Container>
  );
}

export default connect(select, actions)(SubmitButton);

const Container = styled.div`
  pointer-events: ${({ disable }) => (disable ? "none" : "initial")};
  opacity: ${({ disable }) => (disable ? "0.4" : "1")};
`;
