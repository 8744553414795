import styled from "@emotion/styled";

export const Button = styled.div`
  padding: 0.5em 1em;
  border-radius: 50px;
  color: white;
  font-size: 16px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.1s ease;
  background-color: var(--dark-purple);
  box-shadow: var(--shadow-1);
  margin: 1em 0;
  ${({ disable }) =>
    disable && `pointer-events: none; opacity: 0.3; filter: grayscale(100%);`}
  &:hover {
    background-color: var(--purple);
    color: white;
    border-color: white;
  }
`;

export const NeutralButton = styled(Button)`
  background-color: #424242;
`;

export const GreenButton = styled(Button)`
  background-color: #00c853;
`;

export const SimpleButton = styled.div`
  padding: 0.5em 1em;
  border-radius: 50px;
  color: #212121;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  cursor: pointer;
  transition: 0.2s ease;
  font-weight: bold;
  color: var(--green);
  &:hover {
    text-decoration: underline;
  }
`;
