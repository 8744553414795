import {
  FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND,
  FINISHED_UPDATING_USER,
  STARTED_UPDATING_USER,
} from "./types";

import Axios from "axios";
import { BACKEND_URL } from "../../../App";
import toast from "../../../2-utils/toast";
import { getStorage } from "firebase/storage";

export function doFetchAuthenticatedUser(userId) {
  return (dispatch, getState) => {
    const url = `${BACKEND_URL}/user/${userId}`;
    Axios.get(url).then((r) => {
      dispatch({
        type: FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND,
        payload: r.data,
      });
    });
  };
}

export function doUpdateUser() {
  return async (dispatch, getState) => {
    const { userInfo, settingsPage } = getState();
    const { newProfilePicture, newDisplayName, fullscreenMode } = settingsPage;

    // if (!(newProfilePicture || newDisplayName)) return;
    dispatch({ type: STARTED_UPDATING_USER });

    let profilePictureUrl = userInfo.photoUrl;
    let displayName = newDisplayName ? newDisplayName : userInfo.displayName;

    const userId = userInfo._id;

    if (newProfilePicture)
      profilePictureUrl = await uploadProfilePictureToFirebase(
        newProfilePicture,
        userId
      );

    const url = `${BACKEND_URL}/update-user`;
    Axios.post(url, {
      userId,
      displayName,
      photoUrl: profilePictureUrl,
      fullscreenMode,
    }).then((r) => {
      dispatch({
        type: FINISHED_UPDATING_USER,
        payload: {
          displayName,
          photoUrl: profilePictureUrl,
        },
      });
      toast("You've changed your account settings");
    });
  };
}

async function uploadProfilePictureToFirebase(fileUrl, userId) {
  const filePath = getPathWherePictureWillLive(userId);
  const storageRef = getFirebaseStorageRef(filePath);
  const fileObject = await getFileObject(fileUrl);
  const task = await storageRef.put(fileObject);
  const imageUrl = await storageRef.getDownloadURL();
  return imageUrl;
}

function getPathWherePictureWillLive(userId) {
  return `user-${userId}/profile-pic`;
}

function getFirebaseStorageRef(filepath) {
  return getStorage().ref(filepath);
}

function getFileObject(fileUrl) {
  return fetch(fileUrl).then((r) => r.blob());
}

function getDownloadUrl(storageRef) {
  return storageRef.getDownloadURL();
}
