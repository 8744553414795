import React from "react";
import styled from "@emotion/styled";

export default function ComboPreview({
  _id,
  videoFile,
  name,
  authorData,
  characterData,
  onClick
}) {
  return (
    <Container>
      <Image src={characterData[0].imageUrl} />
      <Overlay onClick={onClick}>
        <Title>{name}</Title>
        <Author>by {authorData[0].displayName}</Author>
      </Overlay>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  width: calc(25% - 4px);
  background: var(--green-red-gradient);
  height: 130px;
  margin: 2px;
  float: left;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 1em;
  box-sizing: border-box;
  text-align: center;
  transition: 0.2s ease;
  &:hover {
    background-color: rgba(0, 0, 0, 0.95);
  }
`;

const Title = styled.div`
  font-weight: bold;
`;

const Author = styled.div`
  margin-top: 5px;
`;
