import styled from "@emotion/styled";
import xboxController from "../../../../3-misc/xboxController.png";

export const XBoxControllerImage = xboxController;

export function getXBoxController() {
  return {
    backgroundImage: XBoxControllerImage,
    AButtonWrapper,
    BButtonWrapper: BButtonWrapper,
    XButtonWrapper,
    YButtonWrapper,
    JoyStickWrapper,
    CStickWrapper,
    LeftTriggerWrapper,
    RightTriggerWrapper,
    LeftZWrapper,
    RightZWrapper
  };
}

const AbstractButtonWRapper = styled.div`
  position: absolute;
  > div {
    background-color: var(--purple);
    width: 30px;
    height: 30px;
  }
`;

const AButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 5px);
  right: calc(50% - 80px);
`;

const BButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 22px);
  right: calc(50% - 97px);
`;

const XButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 22px);
  right: calc(50% - 62px);
`;

const YButtonWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 39px);
  right: calc(50% - 80px);
`;

const JoyStickWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 9px);
  right: calc(50% + 37px);
`;

const CStickWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% - 25px);
  right: calc(50% - 55px);
`;

const LeftZWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 65px);
  right: calc(50% + 40px);
`;

const LeftTriggerWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 90px);
  right: calc(50% + 50px);
`;

const RightZWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 65px);
  left: calc(50% + 40px);
`;

const RightTriggerWrapper = styled(AbstractButtonWRapper)`
  bottom: calc(50% + 90px);
  left: calc(50% + 50px);
`;
