import React from "react";
import { connect } from "react-redux";
import { doChangeNewComboName } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  comboName: postEditor.name
});

const actions = {
  changeComboName: doChangeNewComboName
};

function NameEditor({ comboName, changeComboName }) {
  return (
    <>
      <Header>* Combo Name</Header>
      <EditableInput
        value={comboName}
        placeholder="Supreme combo of destruction ..."
        onChange={e => changeComboName(e.target.value)}
      />
    </>
  );
}

export default connect(select, actions)(NameEditor);

const EditableInput = styled.input`
  background-color: var(--black-purple);
  box-sizing: border-box;
  display: block;
  border: none;
  outline: none;
  font-size: 18px;
  color: white;
  position: relative;
  width: 100%;
  margin-bottom: 0.5em;
  color: var(--green);
  font-family: avenir, nunito;
  font-weight: bold;
`;

const Header = styled.h3`
  /* margin: 0; */
`;
