import { abstractConverter } from "./helpers";
import mortalKombat11ActionsConverter from "./mortalKombat11ActionsConverter";

const SuperSmashBrosUltimateId = "5d5b6b21fe706ce02edf1a86";
const MortalKombat11Id = "5d5c5762e01b5b07a570416b";

export default function convertActionsForController(
  actions,
  gameId,
  controllerType
) {
  switch (gameId) {
    case SuperSmashBrosUltimateId:
      return actions;
    case MortalKombat11Id:
      return mortalKombat11ActionsConverter(actions, controllerType);
    default:
      return actions;
  }
}

export function convertActionsForCustomControls(actions, controlsMap) {
  if (!controlsMap) return actions;
  return abstractConverter(actions, controlsMap);
}
