import React from "react";
import styled from "@emotion/styled";

export default function SpeedControls({ setSpeed, animationSpeed }) {
  return (
    <Container message="Control video speed">
      <Option active={animationSpeed === 1} onClick={() => setSpeed(1)}>
        1x
      </Option>
      <Option active={animationSpeed === 2 / 3} onClick={() => setSpeed(2 / 3)}>
        2/3x
      </Option>
      <Option active={animationSpeed === 1 / 2} onClick={() => setSpeed(1 / 2)}>
        1/2x
      </Option>
      <Option active={animationSpeed === 1 / 4} onClick={() => setSpeed(1 / 4)}>
        1/4x
      </Option>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
  overflow: hidden;
  min-width: 150px;
  border-top: 1px solid var(--green);
  border-left: 1px solid var(--green);
  border-radius: 8px 0 0 0;
  box-shadow: var(--shadow-1);
`;

const Option = styled.div`
  color: ${({ active }) => (active ? "var(--green)" : "white")};
  opacity: ${({ active }) => (active ? 1 : 0.7)};
  padding: 0.5em 0.5em;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
`;
