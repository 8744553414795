import {
  CHANGED_NEW_COMBO_DESCRIPTION,
  CHANGED_NEW_COMBO_NAME,
  CHANGED_PERCENT_RANGE,
  FINISHED_POSTING_COMBO_TO_BACKEND,
  JUMPED_FRAME,
  MOVED_TO_NEXT_FRAME,
  MOVED_TO_PREVIOUS_FRAME,
  REMOVED_CONTROL_STICK_FROM_FRAME,
  RESET_POST_EDITOR,
  SELECTED_CHARACTER_FOR_NEW_COMBO,
  SELECTED_GAME_FOR_NEW_COMBO,
  SELECTED_PRESS,
  SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR,
  SELECTED_VIDEO,
  SET_COMBO_IN_POST_EDITOR,
  SET_VIDEO_DURATION,
  STARTED_HOLD,
  STARTED_POSTING_COMBO_TO_BACKEND,
  TOGGLED_TAG,
  UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE
} from "./types";

const intitalState = {
  frame: 0,
  name: "",
  description: "",
  videoDuration: null,
  actions: [],
  videoFile: null,
  gameId: null,
  percentRange: [0, 0],
  characterIds: [],
  postingToBackendStatus: null,
  percentUploaded: 0,
  comboId: null,
  tags: [],
  // backwawrds compatible primary character
  characterId: null
};

export default function postEditorReducer(state = intitalState, action) {
  const { type, payload } = action;
  switch (type) {
    case SELECTED_PRIMARY_CHARACTER_FOR_POST_EDITOR: {
      return {
        ...state,
        characterId: payload.characterId
      };
    }
    case TOGGLED_TAG: {
      return {
        ...state,
        tags: payload.tags
      };
    }
    case CHANGED_PERCENT_RANGE:
      return {
        ...state,
        percentRange: payload.percentRange
      };
    case REMOVED_CONTROL_STICK_FROM_FRAME:
      return {
        ...state,
        actions: payload.newActions
      };
    case SET_COMBO_IN_POST_EDITOR: {
      return {
        ...state,
        percentRange: payload.combo.percentRange || state.percentRange,
        tags: payload.combo.tags || [],
        name: payload.combo.name,
        videoFile: payload.combo.videoFile,
        gameId: payload.combo.gameId,
        actions: payload.combo.actions,
        comboId: payload.combo._id,
        characterIds: payload.combo.characterIds,
        characterId: payload.combo.characterId,
        description: payload.combo.description
      };
    }
    case UPDATED_POSTING_COMBO_UPLOAD_PERCENTAGE: {
      return {
        ...state,
        percentUploaded: payload.percentUploaded
      };
    }
    case STARTED_POSTING_COMBO_TO_BACKEND: {
      return {
        ...state,
        postingToBackendStatus: "IN_PROGRESS"
      };
    }
    case FINISHED_POSTING_COMBO_TO_BACKEND: {
      return {
        ...state,
        postingToBackendStatus: "FINISHED"
      };
    }
    case SELECTED_GAME_FOR_NEW_COMBO: {
      return {
        ...state,
        gameId: payload.game && payload.game._id,
        characterIds: []
      };
    }
    case CHANGED_NEW_COMBO_NAME: {
      return {
        ...state,
        name: payload.comboName
      };
    }

    case CHANGED_NEW_COMBO_DESCRIPTION: {
      return {
        ...state,
        description: payload.description
      };
    }

    case SELECTED_CHARACTER_FOR_NEW_COMBO: {
      return {
        ...state,
        characterIds: payload.characterIds
      };
    }
    case MOVED_TO_NEXT_FRAME:
      return {
        ...state,
        frame: state.frame + 1
      };
    case MOVED_TO_PREVIOUS_FRAME:
      return {
        ...state,
        frame: state.frame !== 0 ? state.frame - 1 : state.frame
      };
    case JUMPED_FRAME:
      return {
        ...state,
        frame: payload.newFrame
      };
    case STARTED_HOLD:
    case SELECTED_PRESS:
      return {
        ...state,
        actions: payload.newActions
      };
    case SET_VIDEO_DURATION:
      return {
        ...state,
        videoDuration: payload.videoDuration
      };
    case SELECTED_VIDEO:
      return {
        ...state,
        videoFile: payload.videoFile
      };
    case RESET_POST_EDITOR:
      return {
        ...intitalState
      };
    default:
      return state;
  }
}
