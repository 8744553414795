import {
  JUMPED_POST_VIDEO_FRAME,
  SET_UPCOMING_ACTION_INDEX,
  SET_ANIMATION_SPEED
} from "./types";

export function doJumpPostVideoFrame(newFrame) {
  return {
    type: JUMPED_POST_VIDEO_FRAME,
    payload: {
      newFrame
    }
  };
}

export function doSetUpcomingActionIndex(upcomingActionIndex) {
  return {
    type: SET_UPCOMING_ACTION_INDEX,
    payload: { upcomingActionIndex }
  };
}

export function doSetAnimationSpeed(animationSpeed) {
  return {
    type: SET_ANIMATION_SPEED,
    payload: { animationSpeed }
  };
}
