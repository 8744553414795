import {
  ACTIVATED_TUTOR_MODE,
  CHANGED_NEW_COMMENT,
  CLEARED_POST_METADATA,
  DEACTIVATED_TUTOR_MODE,
  FINISHED_POSTING_COMMENT,
  HIT_BREAKPOINT,
  JUMPED_POST_VIDEO_FRAME,
  RESET_POST,
  RESOLVED_BREAKPOINT,
  SET_ANIMATION_SPEED,
  SET_UPCOMING_ACTION_INDEX,
  STARTED_POSTING_COMMENT,
  TOGGLED_EDITING_POST,
  TOGGLED_POST_AUDIO,
  TOGGLED_POST_VERIFICATION,
  TOGGLED_SHOW_CONTROLLER_OVERLAY,
} from "./types";
import {
  MOVED_TO_NEXT_COMBO,
  MOVED_TO_PREVIOUS_COMBO,
} from "../../ResultsPage/ducks/types";

const initialState = {
  frame: 0,
  videoDuration: 0,
  animationSpeed: 1 / 2,
  audioEnabled: false,
  upcomingActionIndex: 0,
  isEditing: false,
  newComment: "",
  isPostingComment: false,
  tutorMode: false,
  manualResetKey: 0,
  hitBreakpoint: false,
  showControllerOverlay: false,
};

export default function postReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HIT_BREAKPOINT: {
      return {
        ...state,
        hitBreakpoint: true,
      };
    }
    case RESOLVED_BREAKPOINT: {
      return {
        ...state,
        hitBreakpoint: false,
      };
    }
    case ACTIVATED_TUTOR_MODE: {
      return {
        ...state,
        animationSpeed: 1 / 2,
        tutorMode: true,
      };
    }
    case DEACTIVATED_TUTOR_MODE: {
      return {
        ...state,
        tutorMode: false,
        animationSpeed: 1,
      };
    }
    case FINISHED_POSTING_COMMENT: {
      return {
        ...state,
        newComment: "",
        isPostingComment: false,
      };
    }
    case STARTED_POSTING_COMMENT: {
      return {
        ...state,
        isPostingComment: true,
      };
    }
    case CHANGED_NEW_COMMENT: {
      return {
        ...state,
        newComment: payload.newComment,
      };
    }
    case TOGGLED_EDITING_POST:
      return {
        ...state,
        isEditing: !state.isEditing,
      };

    case SET_UPCOMING_ACTION_INDEX: {
      return {
        ...state,
        upcomingActionIndex: payload.upcomingActionIndex,
      };
    }
    case JUMPED_POST_VIDEO_FRAME:
      return {
        ...state,
        frame: payload.newFrame,
        manualResetKey:
          payload.newFrame < state.frame
            ? state.manualResetKey + 1
            : state.manualResetKey,
      };
    case SET_ANIMATION_SPEED: {
      return {
        ...state,
        animationSpeed: payload.animationSpeed,
      };
    }
    case TOGGLED_POST_AUDIO: {
      return {
        ...state,
        audioEnabled: !state.audioEnabled,
      };
    }
    case RESET_POST: {
      return {
        ...state,
        frame: 0,
        videoDuration: 0,
        upcomingActionIndex: 0,
        isEditing: false,
        newComment: "",
        tutorMode: false,
        hitBreakpoint: false,
      };
    }
    case CLEARED_POST_METADATA: {
      return {
        ...state,
        frame: 0,
        hitBreakpoint: false,
        upcomingActionIndex: 0,
        manualResetKey: payload.noReset
          ? state.manualResetKey
          : state.manualResetKey + 1,
      };
    }
    case MOVED_TO_NEXT_COMBO:
    case MOVED_TO_PREVIOUS_COMBO:
      return {
        ...state,
        frame: 0,
        upcomingActionIndex: 0,
        newComment: "",
      };
    case TOGGLED_SHOW_CONTROLLER_OVERLAY: {
      return {
        ...state,
        showControllerOverlay: !state.showControllerOverlay,
      };
    }
    default:
      return state;
  }
}
