import { KeyboardArrowLeft, Sort } from "styled-icons/material";
import React, { useEffect, useState } from "react";
import {
  debounce,
  useIsMobile,
  useScrollPosition,
} from "../../2-utils/snippets";
import {
  doChangeResultsPageQuery,
  doFetchCharactersForFilters,
  doFetchCombos,
  doSelectGameForResultsPage,
} from "./ducks/actions";

import ConfirmationModal from "../ConfirmationModal";
import FilterManager from "./FilterManager";
import Nav from "../DiscoverPage/Nav";
import PostPreviews from "./PostPreviews";
import SearchBar from "../../2-utils/SearchBar";
import { connect } from "react-redux";
import { doActivateConfirmationModal } from "../ConfirmationModal/ducks/actions";
import { doResetPostEditor } from "../PostEditor/ducks/actions";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

let searchTimeout = null;

const select = ({ resultsPage, confirmationModal }) => ({
  showConfirmationModal: confirmationModal.message,
  query: resultsPage.query,
  isFetching: resultsPage.loadingMoreCombos,
  gameId: resultsPage.gameId,
  filteredCharacters: resultsPage.filteredCharacters,
  primaryCharacter: resultsPage.characters.find(
    (char) => char._id === resultsPage.primaryCharacterId
  ),
  selectedTags: resultsPage.selectedTags,
  percentRange: resultsPage.percentRange,
});

const actions = {
  fetchCombos: doFetchCombos,
  fetchFilters: doFetchCharactersForFilters,
  resetPostEditor: doResetPostEditor,
  activateConfirmationModal: doActivateConfirmationModal,
  changeQuery: doChangeResultsPageQuery,
  selectGameForResultsPage: doSelectGameForResultsPage,
};

function ResultsPage({
  gameId,
  query,
  changeQuery,
  fetchCombos,
  fetchFilters,
  filteredCharacters,
  resetPostEditor,
  activateConfirmationModal,
  showConfirmationModal,
  isFetching,
  primaryCharacter,
  selectGameForResultsPage,
  selectedTags,
  percentRange,
}) {
  const isMobile = useIsMobile();
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [showPostEditor, setShowPostEditor] = useState(false);
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    recordPageView(gameId);
  }, [gameId]);

  useEffect(() => {
    selectGameForResultsPage(getGameIdFromURL());
  }, []);

  useEffect(() => {
    if (gameId) {
      fetchCombos(gameId);
      fetchFilters(gameId);
    }
  }, [gameId]);

  return (
    <Container>
      {/* <BackgroundFiller /> */}
      <NavWrapper>
        <Nav onSelect={selectGameForResultsPage} activeGameId={gameId} />
      </NavWrapper>

      <MobileFilterWrapper
        hide={!showMobileFilters}
        onClick={() => setShowMobileFilters(false)}
      >
        <MobileFilterWrapperInner onClick={(e) => e.stopPropagation()}>
          {/* <BackButton onClick={() => setShowMobileFilters(false)}>
            <KeyboardArrowLeft />
          </BackButton> */}

          <FilterManager
            hideMobileFilters={() => setShowMobileFilters(false)}
          />
        </MobileFilterWrapperInner>
      </MobileFilterWrapper>

      <FilterWrapper scrollPosition={scrollPosition}>
        <FilterManager />
      </FilterWrapper>

      <ResultsWrapper>
        <SearchBarWrapper>
          {isMobile && (
            <MobileFilterButton onClick={() => setShowMobileFilters(true)}>
              <FilterIcon />
            </MobileFilterButton>
          )}
          <SearchBar
            placeholder="The world of combos awaits..."
            value={query}
            onChange={(e) => {
              changeQuery(e.target.value);
              clearTimeout(searchTimeout);
              searchTimeout = setTimeout(() => fetchCombos(gameId), 1000);
            }}
          />
        </SearchBarWrapper>
        {(filteredCharacters.length > 0 ||
          primaryCharacter ||
          selectedTags.length > 0 ||
          !(percentRange.includes(0) && percentRange.includes(200))) && (
          <NumFilters>
            {
              <Bubble>
                {primaryCharacter && primaryCharacter.name}{" "}
                {primaryCharacter && filteredCharacters[0] && <>&times;</>}{" "}
                {filteredCharacters[0] && filteredCharacters[0].name}{" "}
                {selectedTags.length > 0 && <>&bull;</>}{" "}
                {selectedTags.join(", ")}{" "}
                {!(percentRange.includes(0) && percentRange.includes(200))
                  ? `${percentRange[0]} to ${percentRange[1]}%`
                  : null}
              </Bubble>
            }
          </NumFilters>
        )}
        <PostPreviews />
      </ResultsWrapper>
      <GoBackToTopButton
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      >
        top
      </GoBackToTopButton>
      {showConfirmationModal && <ConfirmationModal />}
    </Container>
  );

  function onSuccess() {
    resetPostEditor();
    setShowPostEditor(false);
  }

  function onCancel() {
    activateConfirmationModal(() => {
      resetPostEditor();
      setShowPostEditor(false);
    }, "You will lose your progress");
  }
}

export default connect(select, actions)(ResultsPage);

function getGameIdFromURL() {
  const parts = document.location.href.split("/");
  if (parts.length !== 5) return "5d5b6b21fe706ce02edf1a86";
  return document.location.href.split("/").reverse()[0];
}

const Container = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-areas:
    "nav nav"
    "filter results";
  grid-template-columns: 300px 1fr;
  grid-template-rows: auto 1fr;
  background-color: var(--black-purple);
  min-height: 100vh;
  @media (max-width: 600px) {
    grid-template-areas:
      "nav"
      "results"
      "filters";
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    min-height: auto;
    overflow: hidden;
  }
  @media (min-width: 1000px) {
    grid-template-columns: 300px 1fr;
  }
`;

const GoBackToTopButton = styled.div`
  position: fixed;
  bottom: 1em;
  right: 1em;
  padding: 0.5em 1em;
  color: var(--green);
  border: 1px solid var(--green);
  background: var(--black-purple);
  border-radius: 8px;
  z-index: 2;
  box-shadow: var(--shadow-1);
`;

const NavWrapper = styled.div`
  grid-area: nav;
  overflow: hidden;
`;

const FilterWrapper = styled.div`
  grid-area: filter;
  box-sizing: border-box;
  color: white;
  margin-top: 7px;
  ${({ scrollPosition }) =>
    scrollPosition > 40 &&
    `
    position: fixed;
    top: 0px;
    z-index: 2;
    width: 300px;
  `}

  @media (max-width: 600px) {
    display: none;
  }
`;

const NumFilters = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const FilterIcon = styled(Sort)`
  color: white;
  width: 20px;
`;

const MobileFilterButton = styled.div`
  color: white;
  padding: 0.5em 1em;
  border: 2px solid white;
  font-size: 12px;
  border-radius: 50px;
  font-weight: bold;
  z-index: 3;
  margin-right: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bubble = styled.div`
  color: var(--green);
  border-bottom: 1px solid white;
  box-shadow: var(--shadow-1);
  font-weight: bold;
  font-size: 14px;
  margin-right: 0.5em;
`;

const MobileFilterWrapper = styled.div`
  position: fixed;
  top: 0px;
  z-index: 1000;
  left: 0;
  width: 100vw;
  box-sizing: border-box;
  height: 100vh;
  color: white;
  box-sizing: border-box;
  transform: translateY(${({ hide }) => (hide ? "100vh" : "0px")});
  transition: 0.3s ease;
  overflow: scroll;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
`;

const MobileFilterWrapperInner = styled.div`
  border-radius: 30px 30px 0 0;
  margin-top: 100px;
  background-color: var(--black-purple);
  box-shadow: 0 -10px 6px -6px rgba(33, 148, 182, 0.5);
  min-height: calc(100% - 100px);
  box-sizing: border-box;
  padding-top: 20px;
  border-top: 4px solid white;
`;

const SearchAnimationWrapper = styled.div`
  position: absolute;
  top: 2.6em;
  right: 4em;
  @media (max-width: 600px) {
    top: 1.6em;
    right: 1.2em;
  }
`;

const SearchBarWrapper = styled.div`
  display: flex;
  margin: 1em 0;
  @media (max-width: 600px) {
    margin: 0 0 0.5em 0;
  }
`;

const ResultsWrapper = styled.div`
  grid-area: results;
  /* padding: 1em 3em; */
  padding-top: 1em;
  padding-right: 1em;
  padding-left: 1em;
  background-color: var(--black-purple);
  position: relative;
  @media (max-width: 600px) {
    padding: 1em 0.5em;
    padding-bottom: 0;
  }
  @media (min-width: 1000px) {
    width: 100%;
    /* max-width: 800px; */
    box-sizing: border-box;
  }
`;

const BackButton = styled.div`
  /* border: 1px solid white; */
  font-size: 12px;
  border-radius: 50px;
  margin: 0.5em 0 0.5em 10px;
  color: white;
  width: 30px;
  box-shadow: var(--shadow-1);
  height: 30px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-weight: bold;
`;

const BackgroundFiller = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 500px;
  width: 100vw;
  background: var(--purple-gradient);
  transform: translateY(-50%);
  z-index: 0;
  @media (min-width: 600px) {
    display: none;
  }
`;
