import { ExpandLess, ExpandMore } from "styled-icons/material";
import React, { useState } from "react";

import styled from "@emotion/styled";

export default function Collapsible({
  content,
  header,
  expandedInitially = false,
  closeOnClick = false,
  noMargin = false
}) {
  const [expanded, setExpanded] = useState(expandedInitially);
  const Icon = expanded ? ExpandLess : ExpandMore;
  return (
    <>
      <HeaderWrapper onClick={() => setExpanded(!expanded)} noMargin={noMargin}>
        <Icon size="30" />
        <div style={{ width: "100%" }}>{header}</div>
      </HeaderWrapper>
      {expanded && (
        <div
          style={{ marginBottom: "1em" }}
          onClick={() => closeOnClick && setExpanded(false)}
        >
          {content}
        </div>
      )}
    </>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 6px;
  width: 100%;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "0.5em")};
`;
