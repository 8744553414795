import { Link, useNavigate } from "react-router-dom";

import { Button } from "../../2-utils/buttons";
import { Card } from "../../2-utils/Card";
import React from "react";
import computerImage from "../../3-misc/computer.png";
import { connect } from "react-redux";
import phoneImage from "../../3-misc/phone.png";
import styled from "@emotion/styled";
import { useIsMobile } from "../../2-utils/snippets";

const select = ({ userInfo }) => ({
  authenticated: userInfo.authenticated,
});

function LandingPage({ authenticated }) {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  if (authenticated) {
    navigate("/results");
    return null;
  }

  return (
    <Container>
      <Left>
        {!isMobile && <h1>The Only Community Driven Combo Reference</h1>}
        {/* <hr /> */}
        {isMobile && <Buttons />}
        <Flex>
          <MiniCard>
            <h3>
              See combos done on a controller{" "}
              <b>so no more guesswork or crazy forum jargon</b>
            </h3>
          </MiniCard>
          <MiniCard>
            <h3>
              Powerful search tools{" "}
              <b>including character-percents, combo-types, and matchups</b>
            </h3>
          </MiniCard>
          <MiniCard>
            <h3>
              Detailed frame data <b>for the pros</b>
            </h3>
          </MiniCard>
          <MiniCard>
            <h3>
              Custom controller mappings{" "}
              <b>for peeps who remap their controls</b>
            </h3>
          </MiniCard>
          <MiniCard>
            <h3>
              Speed Controls and Breakpoints{" "}
              <b>so you can slow down that crazy 0-to-death</b>
            </h3>
          </MiniCard>
          <MiniCard>
            <h3>
              Community Driven{" "}
              <b>
                so you can upload your own combos for <u>fame and glory</u>
              </b>
            </h3>
          </MiniCard>
        </Flex>
        {!isMobile && <Buttons />}
      </Left>
      <Right>
        <PhoneImageWrapper src={phoneImage} />
        <ComputerImageWrapper src={computerImage} />
      </Right>
    </Container>
  );
}

export default connect(select)(LandingPage);

function Buttons() {
  return (
    <ButtonsWrapper>
      <Button style={{ backgroundColor: "var(--green)" }}>
        <Link to="/authenticate" style={{ color: "black" }}>
          sign up to create combos
        </Link>
      </Button>

      <Button style={{ backgroundColor: "var(--dark-purple)", color: "white" }}>
        <Link to="/results" style={{ color: "white" }}>
          enter
        </Link>
      </Button>
    </ButtonsWrapper>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-areas: "left right";
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  height: calc(100vh - 40px);
  @media (max-width: 800px) {
    grid-template-areas: "right" "left";
    grid-template-columns: 1fr;
    grid-template-rows: 60vh auto;
  }
  /* overflow: hidden; */
  h1 {
    font-size: 30px;
    /* margin-top: 0; */
    text-align: center;
    padding: 0 12px;
    margin: auto;
    @media (min-width: 1500px) {
      font-size: 50px;
    }
  }

  h3 {
    font-size: 20px;
    color: white;
    @media (max-width: 1200px) {
      font-size: 16px;
    }
    @media (max-width: 800px) {
      font-size: 24px;
      color: white;
    }
  }

  h2 {
    margin-top: -20px;
    font-size: 24px;
    font-weight: normal;
  }

  b {
    font-weight: normal;
    color: white;
    font-size: 16px;
    opacity: 0.7;
    display: block;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  li {
    font-size: 20px;
    margin: 8px 0;
  }
`;

const Left = styled.div`
  grid-area: left;
  background-color: #0b1040;
  box-shadow: 20px 0px 20px -6px rgba(0, 0, 0, 0.5);
  max-width: 45vw;
  color: white;
  padding: 30px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media (max-width: 800px) {
    max-width: initial;
  }
`;

const Right = styled.div`
  grid-area: right;
  position: relative;
  overflow: hidden;
`;

const PhoneImageWrapper = styled.img`
  width: 300px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 1;
  @media (max-width: 800px) {
    height: 120%;
    width: auto;
    top: initial;
    bottom: 0;
    transform: translate(-50%, 20%);
  }
`;

const ComputerImageWrapper = styled.img`
  width: 1500px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: -1;
  transform: translateX(-70%);
  opacity: 0.5;
  filter: blur(5px);
`;

const MiniCard = styled.div`
  width: calc(50% - 2em);
  margin: 0.5em 1em;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: var(--shadow-1);
  background-color: var(--dark-purple);
  height: 130px;
  box-sizing: border-box;
  padding: 1em;
  padding-top: 0;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    height: 160px;
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 0.5em 0;
    height: auto;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
`;

const ButtonsWrapper = styled.div`
  margin: 1em 1em;
  margin-top: auto;
  display: flex;
  > div {
    border-radius: 8px;
    &:hover {
      color: initial;
      opacity: 0.5;
    }
    flex-grow: 1;
    color: var(--black-purple);
    font-size: 20px;
    text-align: center;
    &:first-of-type {
      margin-right: 1em;
    }
  }
`;
