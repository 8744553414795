import {
  FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND,
  FINISHED_UPDATING_USER
} from "../../UserInfo/ducks/types";
import {
  MODIFIED_NEW_DISPLAY_NAME_IN_SETTINGS,
  RESET_SETTINGS_PAGE,
  SELECTED_NEW_PROFILE_PICTURE_IN_SETTINGS,
  TOGGLED_FULLSCREEN_MODE
} from "./types";

const initialState = {
  newProfilePicture: null,
  newDisplayName: null,
  fullscreenMode: false
};

export default function settingsPageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FINISHED_FETCHING_AUTHENTICATED_USER_INFO_FROM_BACKEND: {
      return {
        ...state,
        fullscreenMode: payload.fullscreenMode
      };
    }
    case MODIFIED_NEW_DISPLAY_NAME_IN_SETTINGS: {
      return {
        ...state,
        newDisplayName: payload.displayName
      };
    }

    case FINISHED_UPDATING_USER: {
      return {
        ...initialState
      };
    }

    case SELECTED_NEW_PROFILE_PICTURE_IN_SETTINGS: {
      return {
        ...state,
        newProfilePicture: payload.newProfilePicture
      };
    }

    case RESET_SETTINGS_PAGE: {
      return { ...initialState, fullscreenMode: state.fullscreenMode };
    }

    case TOGGLED_FULLSCREEN_MODE:
      return {
        ...state,
        fullscreenMode: !state.fullscreenMode
      };
    default:
      return state;
  }
}
