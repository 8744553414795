import CopyToClipboardButton from "./CopyToClipboardButton";
import DeleteComboButton from "./DeleteComboButton";
import EditComboButton from "./EditComboButton";
import { FRONT_END_URL } from "../../../App";
import React from "react";
import SoundControls from "./SoundControls";
import UserFeedback from "./UserFeedback";
import VerifyButton from "./VerifyButton";
import { connect } from "react-redux";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../2-utils/snippets";

const select = ({ resultsPage, userInfo }) => {
  const { activePostIndex, posts } = resultsPage;
  const currentPost = posts[activePostIndex];
  return {
    canLike: userInfo.authenticated,
    combo: currentPost,
    userIsAdmin: userInfo.userType === "ADMIN",
    canEdit:
      userInfo._id === currentPost.authorData[0]._id ||
      userInfo.userType === "ADMIN"
  };
};

function Options({ canLike, combo, canEdit, userIsAdmin }) {
  const isMobile = useIsMobile();
  return (
    <Container>
      {canLike && <UserFeedback />}
      {!isMobile && <SoundControls />}
      {!isMobile && (
        <CopyToClipboardButton text={`${FRONT_END_URL}/combo/${combo._id}`} />
      )}
      {!isMobile && canEdit && <EditComboButton combo={combo} />}
      {!isMobile && canEdit && <DeleteComboButton />}
      {userIsAdmin && <VerifyButton />}
    </Container>
  );
}

export default connect(select)(Options);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 600px) {
    > div {
      flex-grow: 1;
    }
  }

  @media (max-width: 600px) {
    overflow-x: auto;
    padding-bottom: 0;
    ::-webkit-scrollbar {
      display: none;
      width: 0 !important;
    }
  }
`;
