import React from "react";
import styled from "@emotion/styled";
import Section from "./Section";
import SelectOptions from "./SelectOptions";
import { BeatLoader } from "react-spinners";

export default function GuideContent({ sections = [], contentEditable }) {
  return (
    <Container>
      {sections.length === 0 && (
        <LoadingWrapper>
          <BeatLoader size={20} color="white" />
        </LoadingWrapper>
      )}
      {sections.map((sectionData, index) => (
        <Section
          index={index}
          key={sectionData.id}
          contentEditable={contentEditable}
          shouldAnimate={!contentEditable}
          {...sectionData}
        />
      ))}
      <SelectOptions />
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  min-height: 100vh;
`;

const LoadingWrapper = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
