import React, { useEffect, useState } from "react";

import Collapsible from "../../../2-utils/Collapsible";
import CreateComboButton from "../../../2-utils/CreateComboButton";
import PostEditor from "../../PostEditor";
import PostPreviews from "../../ResultsPage/PostPreviews";
import Preview from "../../ResultsPage/PostPreviews/Preview";
import { SimpleButton } from "../../../2-utils/buttons";
import { connect } from "react-redux";
import { doActivateConfirmationModal } from "../../ConfirmationModal/ducks/actions";
import { doResetPostEditor } from "../../PostEditor/ducks/actions";
import { getUserIdFromUrlForProfilePage } from "..";
import styled from "@emotion/styled";

const select = ({ profilePage, userInfo }) => ({
  combos: profilePage.combos,
  canCreateCombo: getUserIdFromUrlForProfilePage() === userInfo._id
});

const actions = {
  resetPostEditor: doResetPostEditor,
  activateConfirmationModal: doActivateConfirmationModal
};

function Posts({
  combos,
  resetPostEditor,
  canCreateCombo,
  activateConfirmationModal
}) {
  const [showPostEditor, setShowPostEditor] = useState(false);
  const noCombos = combos.length === 0;

  return (
    <Container>
      <Header>
        <CreateComboButton />
      </Header>

      <PostsContainer>
        {noCombos && (
          <NoCombosMessage>
            No combos yet. Create one with our fancy combo analyzer
          </NoCombosMessage>
        )}
        {!noCombos && (
          <Collapsible
            header={<SectionTitle>created combos</SectionTitle>}
            content={<PostPreviews />}
          />
        )}
      </PostsContainer>

      <PostsContainer>
        {noCombos && (
          <NoCombosMessage>
            No combos yet. Create one with our fancy combo analyzer
          </NoCombosMessage>
        )}
        {!noCombos && (
          <Collapsible
            header={<SectionTitle>created guides</SectionTitle>}
            content={<div>nothing</div>}
          />
        )}
      </PostsContainer>
    </Container>
  );

  function openPostEditor() {
    setShowPostEditor(true);
  }

  function onSuccess() {
    resetPostEditor();
    setShowPostEditor(false);
  }

  function onCancel() {
    activateConfirmationModal(() => {
      resetPostEditor();
      setShowPostEditor(false);
    }, "You will lose your progress");
  }
}

function getCombosGroupedByGame(combos) {
  function reducer(cache, combo) {
    if (!cache[combo.gameId]) cache[combo.gameId] = [];
    cache[combo.gameId].push(combo);
    return cache;
  }
  let combosGroupedByGame = combos.reduce(reducer, {});
  combosGroupedByGame = Object.entries(combosGroupedByGame);
  return combosGroupedByGame;
}

export default connect(select, actions)(Posts);

const Container = styled.div`
  height: 100%;
`;

const PostsContainer = styled.div`
  padding: 1em;
  h1 {
    margin-left: 0.5em;
  }
  display: flex;
  flex-wrap: wrap;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  > h1 {
    margin-left: 30px;
    font-size: 30px;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

const SectionTitle = styled.div`
  font-size: 30px;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-weight: bold;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

const NoCombosMessage = styled.p`
  margin-left: 30px;
`;

const ButtonWrapper = styled.div`
  margin-left: 1em;
  position: relative;
  div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

{
  /* {combosGroupedByGame.map(entry => {
          const gameId = entry[0];
          const combos = entry[1];
          return (
            <div>
              <h1>Game: {gameId}</h1>
              {combos.map((combo, index) => (
                <Preview
                  onClick={openComboModal}
                  key={combo._id}
                  title={combo.name}
                  index={index}
                  imageUrl={combo.characterData[0].imageUrl}
                />
              ))}
            </div>
          );
        })} */
}
