import styled from "@emotion/styled";

export const Card = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: var(--shadow-1);
  border-radius: 20px;
  padding: 1.5em;
  box-sizing: border-box;
  margin-bottom: 1em;
`;

export const CardLabel = styled.h4`
  margin-top: 0;
  margin-bottom: 1em;
`;
