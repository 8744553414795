import React, { useEffect, useState } from "react";

import DescriptionEditor from "../CreateComboInterface/DescriptionEditor";
import NameEditor from "../CreateComboInterface/NameEditor";
import PercentSelector from "./PercentSelector";
import SelectCharacterForPostEditor from "./SelectCharacterForPostEditor";
import SelectGameForPostEditor from "./SelectGameForPostEditor";
import SelectPrimaryCharacaterForPostEditor from "./SelectPrimaryCharacaterForPostEditor";
import TagSelector from "./TagSelector";
import { connect } from "react-redux";
import { doFetchCharactersFromGame } from "../ducks/actions";
import styled from "@emotion/styled";

const select = ({ postEditor }) => ({
  selectedGameId: postEditor.gameId
});

const actions = {
  fetchCharacters: doFetchCharactersFromGame
};

function Metadata({ fetchCharacters, selectedGameId }) {
  const [characters, setCharacters] = useState([]);
  useEffect(() => {
    if (selectedGameId)
      fetchCharacters(selectedGameId).then(r => setCharacters(r));
  }, [selectedGameId]);
  return (
    <Container>
      <Row>
        <Column>
          <SelectGameForPostEditor />
          <SelectPrimaryCharacaterForPostEditor characters={characters} />
          <SelectCharacterForPostEditor characters={characters} />
          <TagSelector />
          <PercentSelector />
        </Column>
        <Column>
          <NameEditor />
          <DescriptionEditor />
        </Column>
      </Row>
    </Container>
  );
}

export default connect(select, actions)(Metadata);

const Container = styled.div`
  padding: 1em;
  background-color: var(--black-purple);
  @media (max-width: 600px) {
    padding: 0;
  }
`;

const Row = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  min-width: 50%;
  box-sizing: border-box;
  padding: 0 1em;
  @media (max-width: 600px) {
    min-width: 100%;
  }
`;
