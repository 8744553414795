import { CheckCircle, ThumbDown, ThumbUp } from "styled-icons/material";
import React, { useEffect, useRef, useState } from "react";
import { doOpenPost, getColorFromDifficulty } from "../../ducks/actions";

import { BeatLoader } from "react-spinners";
import { Link } from "react-router-dom";
import characterIdToIcon from "../../../../2-utils/characterIdToIcon";
import { connect } from "react-redux";
import { doResetPost } from "../../../Post/ducks/actions";
import { getTemporaryVideoURL } from "../../../Post/Video";
import styled from "@emotion/styled";
import { useIsMobile } from "../../../../2-utils/snippets";

const actions = {
  openPost: doOpenPost,
  resetPost: doResetPost
};

let timeout = null;

function Preview({
  openPost,
  verified,
  characterIds,
  imageUrl,
  index,
  title,
  numLikes,
  numDislikes,
  numberOfCombosLoadedAtATime,
  onClick = () => null,
  authorName,
  characterId,
  tags,
  authorId,
  postId,
  percentRange,
  scrollPosition,
  videoFile,
  thumbnail,
  difficulty,
  onFirstPostLoaded,
  resetPost
}) {
  const isMobile = useIsMobile();
  const previewRef = useRef();
  const videoRef = useRef();
  const [hasBeenSeen, setHasBeenSeen] = useState(false);
  const [canPlayThrough, setCanPlayThrough] = useState(false);
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false);

  const percentRangeString =
    percentRange && percentRange.length
      ? `${percentRange[0]} to ${percentRange[1]}%`
      : "";
  // animate if its the first time we've seen the combo
  useEffect(() => {
    handleAnimateOnFirstSeen();
  }, [index, scrollPosition, hasBeenSeen]);

  useEffect(() => {
    if (isMobile) {
      const { top, height } = previewRef.current.getBoundingClientRect();
      const inPlayWindow = top > 0 && top < window.innerHeight / 3;
      if (inPlayWindow && canPlayThrough) {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(
          () => videoRef.current && videoRef.current.play(),
          100
        );
      } else videoRef.current.pause();
    }
  }, [scrollPosition, canPlayThrough]);

  return (
    <Link
      to={"/combo/" + postId}
      style={{ textDecoration: "none" }}
      onClick={resetPost}
    >
      <Container
        onClick={() => {
          openPost(index);
        }}
        index={index}
        active={hasBeenSeen}
        ref={previewRef}
        animationDelay={(index % numberOfCombosLoadedAtATime) * 0.15}
      >
        <FlexRow>
          <Video
            ref={videoRef}
            onCanPlay={() => setCanPlayThrough(true)}
            onLoadedMetadata={() => {
              if (index === 0) {
                onFirstPostLoaded();
              }
            }}
            playsInline
            preload="metadata"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            muted
            {...(isMobile && { autoPlay: true })}
            loop
          >
            {hasBeenSeen && (
              <source src={getTemporaryVideoURL(videoFile)} type="video/mp4" />
            )}
          </Video>
          {!canPlayThrough && (
            <>
              <Overlay>
                <Thumbnail
                  src={thumbnail}
                  onLoad={() => setThumbnailLoaded(true)}
                />
              </Overlay>
            </>
          )}
        </FlexRow>

        <CharacterPictureWrapper>
          <CharacterPicture src={characterIdToIcon(characterId)} />

          {characterIds.map(id => (
            <CharacterPicture src={characterIdToIcon(id)} />
          ))}
        </CharacterPictureWrapper>

        <LikesWrapper>
          <Likes>
            <b>{numLikes}</b> <ThumbUpIcon size="16" />
          </Likes>
          <Likes>
            <b>{numDislikes}</b> <ThumbDownIcon size="16" />
          </Likes>
        </LikesWrapper>

        <FlexRow>
          <Content>
            <Row style={{ marginBottom: "5px", alignItems: "center" }}>
              {verified && (
                <CheckCircle
                  size={20}
                  style={{ color: "turquoise", margin: "6px 5px 0 0" }}
                />
              )}
              <PreviewTitle>{title} </PreviewTitle>
            </Row>
            <Row>
              <PreviewAuthor>{authorName}</PreviewAuthor>
              <Bubble>
                {[difficulty, ...tags, percentRangeString].join(" | ")}
              </Bubble>
            </Row>
          </Content>
        </FlexRow>
      </Container>
    </Link>
  );

  function handleAnimateOnFirstSeen() {
    if (hasBeenSeen) return;
    const { top, height } = previewRef.current.getBoundingClientRect();
    const isInViewPort = top - height / 2 - window.innerHeight - 200 <= 0;
    if (isInViewPort) setHasBeenSeen(true);
  }

  function handleMouseOver() {
    if (!isMobile) videoRef.current.play();
  }

  function handleMouseOut() {
    if (!isMobile) videoRef.current.pause();
  }
  // function hardcodeAnimationDelayByIndex(index) {
  //   const NUM_MILLISECONDS_IN_SECOND = 1000;
  //   const PAUSE_TO_PREVENT_THRASHING = 400;
  //   const BASE_DELAY = 0.1;
  //   const animationDelay =
  //     PAUSE_TO_PREVENT_THRASHING +
  //     index * NUM_MILLISECONDS_IN_SECOND * BASE_DELAY;
  //   setTimeout(() => setHasBeenSeen(true), animationDelay);
  // }
}

export default connect(null, actions)(Preview);

const Container = styled.div`
  min-height: 60px;
  margin: 1em 0;
  position: relative;
  border-radius: 4px 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: 0.6s;
  @media (max-width: 600px) {
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
  box-shadow: ${({ index }) =>
    index % 2
      ? "0 10px 20px -6px rgba(0, 187, 212, 0.5)"
      : "0 10px 20px -6px rgba(105, 240, 174, 0.5)"};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(2px);
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
`;

const Video = styled.video`
  width: 100%;
  height: 220px;
  @media (min-width: 600px) {
    height: 350px;
  }
  object-fit: cover;
`;

const CharacterPicture = styled.img`
  height: 25px;
  width: 25px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-of-type {
    margin-right: 5px;
  }
`;

const CharacterPictureWrapper = styled.div`
  position: absolute;
  top: 350px;
  transform: translateY(-100%);
  left: 0;
  z-index: 3;
  background-color: var(--black-purple);
  padding: 0.5em;
  display: flex;
  border-radius: 4px 4px 0 0;
  @media (max-width: 600px) {
    top: 220px;
  }
`;

const LikesWrapper = styled(CharacterPictureWrapper)`
  right: 0;
  left: initial;
  border-radius: 4px 0 0 4px;
  height: 41px;
  box-sizing: border-box;
`;

const Content = styled.div`
  padding: 0.2em 12px;
  width: 100%;
`;

const PreviewTitle = styled.div`
  font-weight: bold;
  color: var(--green);
  margin: 0.5em 0 0 0;
  display: flex;
`;

const PreviewAuthor = styled.div`
  font-weight: bold;
  color: white;
  font-size: 14px;
  color: white;
  display: flex;
  text-decoration: none;
  &:hover {
    color: var(--green);
  }
`;

const ThumbUpIcon = styled(ThumbUp)``;
const ThumbDownIcon = styled(ThumbDown)``;

const Likes = styled.div`
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  b {
    margin-right: 3px;
  }
  &:first-of-type {
    margin-right: 8px;
  }
`;

const Bubble = styled.div`
  font-size: 12px;
  text-transform: lowercase;
  color: white;
  font-weight: bold;
  opacity: 0.6;
  margin-left: auto;
`;
