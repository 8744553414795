import React, { useEffect } from "react";
import {
  doChangeControllerForCustomMappingPage,
  doChangeGameForCustomMappingPage
} from "./ducks/actions";

import DarkSelect from "../../2-utils/DarkSelect";
import Divider from "../../2-utils/Divider";
import Information from "./Information";
import Nav from "../DiscoverPage/Nav";
import ReconfigureMappings from "./ReconfigureMappings";
import { connect } from "react-redux";
import { recordPageView } from "../reactAnalytics";
import styled from "@emotion/styled";

const controllerTypeToLabel = {
  SWITCH: "Switch",
  GAMECUBE: "Gamecube",
  PLAYSTATION: "Playstation",
  XBOX: "X Box"
};

const select = ({ customMappingPage, homePage }) => ({
  activeGameId: customMappingPage.activeGameId,
  activeGame: homePage.availableGames.find(
    game => game._id === customMappingPage.activeGameId
  ),
  activeController: customMappingPage.activeController,
  controllerOptions: homePage.availableGames.length
    ? homePage.availableGames
        .find(game => game._id === customMappingPage.activeGameId)
        .controllers.map(type => ({
          value: type,
          label: controllerTypeToLabel[type]
        }))
    : []
});

const actions = {
  changeGame: doChangeGameForCustomMappingPage,
  changeController: doChangeControllerForCustomMappingPage
};

function CustomMappingsPage({
  activeController,
  activeGameId,
  activeGame,
  changeController,
  changeGame,
  controllerOptions
}) {
  useEffect(() => {
    recordPageView();
  }, []);
  return (
    <Container>
      <BackgroundFiller />
      <Nav onSelect={changeGame} activeGameId={activeGameId} />
      <Content>
        <Information activeGameName={activeGame && activeGame.name} />

        <Divider />

        <SelectWrapper>
          <h3>Select Controller</h3>
          <DarkSelect
            value={activeController}
            onChange={changeController}
            options={controllerOptions}
          />
        </SelectWrapper>

        <Divider />

        {/* <h3>Change Mappings</h3> */}
        <ReconfigureMappings />
      </Content>
    </Container>
  );
}

export default connect(select, actions)(CustomMappingsPage);

const Container = styled.div`
  color: white;
  padding-bottom: 2em;
  p {
    margin: 0.5em 0;
  }
  h3 {
    margin: 0.5em 0;
  }
`;

const Content = styled.div`
  padding: 0 0.5em;
  background: var(--black-purple);
  min-height: calc(100vh - 150px);
  @media (min-width: 800px) {
    max-width: 800px;
    margin: 1em auto;
  }
`;

const SelectWrapper = styled.div`
  margin: 0.5em 0px;
`;

const BackgroundFiller = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 500px;
  width: 100vw;
  background: var(--purple-gradient);
  transform: translateY(-50%);
  z-index: -1;
  @media (min-width: 600px) {
    display: none;
  }
`;
